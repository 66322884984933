sgx-titan-toolbar {
  position: relative;
}

.sgx-titan-toolbar-wrapper {
  sgx-titan-toolbar & {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.sgx-titan-toolbar-wrapper {
  sgx-titan-toolbar & div {
    height: 30px;
    line-height: 30px;
  }
}

.toolbuttons-right {
  .sgx-titan-toolbar &.sgx-titan-toolbar-wrapper {
    justify-content: flex-end;
  }
}

.toolbuttons-left {
  .sgx-titan-toolbar &.sgx-titan-toolbar-wrapper {
    justify-content: flex-start;
  }
}

.sgx-toolbar-button {
  sgx-titan-toolbar .sgx-titan-toolbar-wrapper & {
    position: relative;
    text-align: center;
    margin-right: $space-3;
    color: $sgx-theme-v2-medium-emp-default;

    &:hover {
      cursor: pointer;
    }
  }

  sgx-titan-toolbar .sgx-titan-toolbar-wrapper & svg {
    width: 14px;
    height: 14px;
  }

  sgx-titan-toolbar .sgx-titan-toolbar-wrapper &.sgx-notification-icon:before {
    right: -30%;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    background-color: $sgx-theme-v2-brand-03;
    
    font-size: 9px;
    color: #ffffff;
    line-height: 1.8em;
    content: attr(data-message);
  }
}

.sgx-toolbar-button {
  sgx-titan-toolbar .sgx-titan-toolbar-wrapper &.active {
    color: $sgx-theme-v2-brand-03;
  }

  sgx-titan-toolbar .sgx-titan-toolbar-wrapper & span {
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    padding-left: $space-1;
    vertical-align: middle;
  }
}

.sgx-titan-toolbar-hint-icon {
  sgx-titan-toolbar .sgx-titan-toolbar-wrapper &:hover~.sgx-input-hint {
    visibility: visible;
  }
}

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-input-hint {
  top: 50%;
  z-index: 10;
  padding: 8px;
  min-width: 200px;
  max-width: 200px;
  min-height: 18px;
  max-height: 50px;
  display: block;
  color: #f7f7f7;
  visibility: hidden;
  border-radius: 2px;
  position: absolute;
  background: #212222;
  right: calc(100% + 10px);
  transform: translateY(-60%);
  font: normal 14px/1.3 Lato,HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif;
  box-shadow: 0 0 10px 3px rgba(0,0,0,.25);
  box-sizing: content-box;
}

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-input-hint:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-left-color: #212222;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
}

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-input-hint.active {
  visibility: visible;
  min-width: 350px;
  max-width: 350px;
}
