$sgx-input-range-bg-color: #3b3b3b;

sgx-platts-market-data-chart {
  float: left;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: $space-5 $space-3 $space-3 $space-3;
  box-sizing: border-box;
}

.sgx-platts-market-data-chart-wrapper {
  sgx-platts-market-data-chart  & {
    height: 100%;
    min-height: 100%;
  }

  sgx-platts-market-data-chart  & .sgx-platts-market-data-chart-inner-wrapper {
    height: 100%;
    min-height: 100%;
  }
}

.sgx-platts-market-data-chart-container {
  sgx-platts-market-data-chart  & {
    height: 100%;
    min-height: 100%;
  }

  sgx-platts-market-data-chart & sgx-chart {
    height: 100%;
    min-height: 100%;
  }
}

.highcharts-range-input {
  sgx-platts-market-data-chart .highcharts-input-group & text {
    font-weight: 500;
    fill: $sgx-theme-v2-high-emp-default !important;
    color: $sgx-theme-v2-high-emp-default;
    background-color: $sgx-input-range-bg-color;
  }
}

.highcharts-range-label {
  sgx-platts-market-data-chart .highcharts-input-group & text {
    font-weight: 500;
    fill: $sgx-theme-v2-high-emp-default !important;
    color: $sgx-theme-v2-high-emp-default;
    background-color: $sgx-input-range-bg-color;
  }
}

.highcharts-range-label {
  sgx-platts-market-data-chart .highcharts-input-group &:first-child {
    display: none;
  }
}

.highcharts-range-selector-buttons {
  .highcharts-range-selector-group & > text {
    display: none;
  }
}

.highcharts-range-selector-group {
  sgx-platts-market-data-chart & {
    display: none;
  }
}

.highcharts-navigator-mask-inside {
  sgx-platts-market-data-chart & {
    fill: $sgx-theme-v2-medium-emp-default;
    fill-opacity: .25;
    stroke: $sgx-theme-v2-lowest-emp-default; 
  }
}

.highcharts-navigator-outline {
  sgx-platts-market-data-chart & {
    stroke: $sgx-theme-v2-lowest-emp-default;
  }
}

.highcharts-navigator-mask-outside {
  sgx-platts-market-data-chart & {
    stroke: $sgx-theme-v2-lowest-emp-default;
  }
}

.highcharts-grid-line {
  sgx-platts-market-data-chart .highcharts-navigator-xaxis & {
    stroke: $sgx-theme-v2-lowest-emp-default;
  }
}
