sgx-platts-widget-metals-data {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
}

sgx-platts-widget-metals-data .sgx-platts-widget-metals-data-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

sgx-platts-widget-metals-data .sgx-metal-widget-symbol-wrapper {
  height: 100%;
  padding-right: $space-3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

sgx-platts-widget-metals-data .sgx-metal-widget-switcher-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.sgx-platts-widget-metals-data-wrapper {
  sgx-platts-widget-metals-data &.sgx-wrapper-big .sgx-metal-widget-switcher-wrapper {
    min-width: 600px;
    max-width: 600px;
  }
}

.sgx-platts-widget-metals-data-wrapper {
  sgx-platts-widget-metals-data &.sgx-wrapper-big .sgx-metal-widget-symbol-wrapper {
    width: calc(100% - 600px);
  }
}

.sgx-platts-widget-metals-data-wrapper {
  sgx-platts-widget-metals-data &.sgx-wrapper-small .sgx-metal-widget-switcher-wrapper,
  sgx-platts-widget-metals-data &.sgx-wrapper-small .sgx-metal-widget-symbol-wrapper {
    width: 50%;
    max-width: 50%;
  }
}

.sgx-metal-widget-category-selector {
  sgx-platts-widget-metals-data & {
    width: 100%;
    height: 36px;
    max-height: 36px;
    margin-bottom: 12px;
    text-align: left;
  }

  sgx-platts-widget-metals-data & sgx-input-select {
    width: 300px;
  }
}

sgx-titan-toolbar {
  sgx-platts-widget-metals-data & {
    float: right;
    margin-top: $space-2;
    display: inline-block;
  }
}

@media screen and (min-width: 1440px) {
  .sgx-platts-widget-metals-data-wrapper {
    sgx-platts-widget-metals-data &.sgx-wrapper-normal .sgx-metal-widget-switcher-wrapper {
      min-width: 600px;
      max-width: 600px;
    }
  }

  .sgx-platts-widget-metals-data-wrapper {
    sgx-platts-widget-metals-data &.sgx-wrapper-normal .sgx-metal-widget-symbol-wrapper {
      width: calc(100% - 600px);
    }
  }
}

@media screen and (max-width: 1440px) {
  .sgx-platts-widget-metals-data-wrapper {
    sgx-platts-widget-metals-data &.sgx-wrapper-normal .sgx-metal-widget-switcher-wrapper {
      width: 50%;
      max-width: 50%;
    }
  }

  .sgx-platts-widget-metals-data-wrapper {
    sgx-platts-widget-metals-data &.sgx-wrapper-normal .sgx-metal-widget-symbol-wrapper {
      width: 50%;
      max-width: 50%;
    }
  }
}
