// define color scheme for each theme
$themelight: (
  body-bg: $white,
  ui-bg: $white,
  ui-bg-alt: #efefef,
  ui-border-primary: $totc-light-purple,
  ui-border-secondary: $sgx-dark-grey,
  ui-item-row: $white,
  ui-item-row-alt: #f5f5f5,
  ui-item-row-active: #dbdee9,
  ui-shadow: rgba(0, 0, 0, 0.1),
  ui-state-primary: #b1ca2d,
  ui-state-secondary: $sgx-light-blue,
  ui-state-error: $sgx-theme-functional-01,
  ui-state-success: $totc-green,
  ui-state-active: $sgx-green,
  ui-state-disabled: $grey,
  ui-state-selected: $sgx-blue,
  ui-state-visited: $sgx-dark-grey,
  ui-text-default: $black,
  ui-text-default-grayed: rgba(0, 0, 0, 0.4),
  ui-text-bright-grayed: rgba(0, 0, 0, 0.6),
  ui-text-inverse: $white,
  ui-text-link: $sgx-blue,
  ui-text-shadow: hsla(0, 0%, 100%, 0.3),
  ui-mobile-order-book-title-color: $totc-grey-4,
  ui-mobile-order-book-header-color: $totc-grey-4
);

// over writing colors
$theme: $themelight;
$body-bg: theme($theme, "body-bg");
$ui-bg: theme($theme, "ui-bg");
$ui-bg-alt: theme($theme, "ui-bg-alt");
$ui-item-row: theme($theme, "ui-item-row");
$ui-item-row-alt: theme($theme, "ui-item-row-alt");
$ui-item-row-active: theme($theme, "ui-item-row-active");
$ui-border-primary: theme($theme, "ui-border-primary");
$ui-border-secondary: theme($theme, "ui-border-secondary");
$ui-text-default: theme($theme, "ui-text-default");
$ui-text-default-grayed: theme($theme, "ui-text-default-grayed");
$ui-text-bright-grayed: theme($theme, "ui-text-bright-grayed");
$ui-text-inverse: theme($theme, "ui-text-inverse");
$ui-text-link: theme($theme, "ui-text-link");
$ui-text-shadow: theme($theme, "ui-text-shadow");
$ui-shadow: theme($theme, "ui-shadow");
$ui-state-primary: theme($theme, "ui-state-primary");
$ui-state-secondary: theme($theme, "ui-state-secondary");
$ui-state-error: theme($theme, "ui-state-error");
$ui-state-success: theme($theme, "ui-state-success");
$ui-state-active: theme($theme, "ui-state-active");
$ui-state-disabled: theme($theme, "ui-state-disabled");
$ui-state-selected: theme($theme, "ui-state-selected");
$ui-state-visited: theme($theme, "ui-state-visited");
$ui-mobile-order-book-title-color: theme($theme, "ui-mobile-order-book-title-color");
$ui-mobile-order-book-header-color: theme($theme, "ui-mobile-order-book-header-color");

$book-styles-order-type-0: #222222;
$book-styles-order-type-1: #4954a1;
$book-styles-hover-color: rgba(12, 35, 107, 0.07);
$book-styles-white: #ffffff;
$book-styles-up: #72af00;
$book-styles-down: #f30643;

$user-avatar-color: #999;
$user-avatar-hover: #fff;

$sgx-header-background: #fff;

$user-avatar-color: #999;
$user-avatar-hover: #222222;
$user-menu-link-color: #222222;
$user-menu-link-bg: #f5f5f5;
$user-menu-link-bg-hover: #b3c3f6;
$user-menu-info-bg: #f5f5f5;
$user-menu-info-color: #222222;
$user-menu-info-border: $black;

$sgx-tabmenu-link-font-color: $sgx-blue;
$sgx-tabmenu-link-bg-color: #fff;
$sgx-tabmenu-link-active-background: #f5f5f5;
$sgx-tabmenu-link-active-font-color: $sgx-blue;
$sgx-tabmenu-link-disable-font-color: $sgx-light-grey;
$sgx-tabmenu-link-background: #fff;
$sgx-tabmenu-link-mosbile-background: #fff;

$sgx-right-drawer-bg: #efefef !default;
$sgx-right-drawer-bg-active: #d2d5e0 !default;
$sgx-order-submission-color: #222 !default;

$sgx-table-toolbar-filter-label-color: #fff;
$sgx-text-dropdown-color: #222;

.sgx-header-logo path {
  fill: #1a256e !important;
}

button[disabled],
input[disabled] {
  cursor: not-allowed !important;
}
