.sgx-platts-historical-data-settings-container {
  width: 600px;
  max-width: 600px;
  min-width: 600px;
  padding-top: $space-5;
  padding-bottom: $space-5;
}

.sgx-platts-historical-data-settings-container .sgx-historical-section-title, 
.sgx-platts-historical-data-settings-container .sgx-span-label {
  color: $sgx-theme-v2-high-emp-default;
}

.form-row {
  .sgx-platts-historical-data-settings-container .sgx-platts-section-container & {
    display: flex;
    justify-content: start;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1 1 auto;
  }
}

.radio-column {
  .sgx-platts-historical-data-settings-container .sgx-platts-section-container .form-row & {
    flex-basis: 40px;
  }
}

.radio-column {
  .sgx-platts-historical-data-settings-container .sgx-platts-section-container .form-row & {
    flex-basis: auto;
  }
}

.settings-option {
  .sgx-platts-historical-data-settings-container .sgx-platts-section-container & {
    display: flex;
    justify-content: start;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1 0 auto;
  }
}

.trading-days-back-count {
  .sgx-platts-historical-data-settings-container .sgx-platts-section-container .settings-option & {
    width: 109px;
  }
}

.sgx-date-back-input {
  .sgx-platts-historical-data-settings-container .sgx-platts-section-container .settings-option & {
    width: 200px;
  }
}

.form-column {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.form-column .settings-option {
  height: 40px;
  flex-basis: 100%;
  padding-top: $space-3;
}

.form-column .settings-option span.sgx-span-label {
  height: 30px;
  padding: 10px $space-3;
  display: inline-block;
}

.form-column .settings-option span.sgx-span-label.extra-pad {
  padding-left: $space-6;
}

.settings-spread-option {
  .sgx-platts-historical-data-settings-container .form-column & {
    height: 52px;
    max-height: 52px;
    min-height: 52px;
  }
}

label {
  .sgx-platts-historical-data-settings-container sgx-input-radio-list.sgx-input fieldset & {
    display: block;
    height: 35px;
    margin-top: $space-3;
    padding-top: 10px;
  }
}

sgx-input-date {
  .sgx-platts-historical-data-settings-container .form-column .settings-option & {
    width: 200px;
  }
}

sgx-input-select {
  .sgx-platts-historical-data-settings-container .settings-option.settings-spread-option & {
    width: 215px;
    height: 40px;
  }
}

.sgx-span-label {
  .sgx-platts-historical-data-settings-container .sgx-platts-section-spread-selection .settings-spread-option & {
    padding-left: $space-6;
    padding-right: $space-6;
  }
}

.sgx-platts-section-spread-selection {
  .sgx-platts-historical-data-settings-container  & {
    padding-top: $space-3;
  }
}

.footer-note {
  .sgx-platts-historical-data-settings-container .sgx-platts-section-spread-selection & {
    @extend .sgx-base-text-body-12;
  }
}

button {
 sgx-platts-historical-data-settings .sgx-platts-historical-data-settings & {
    margin-left: $space-5;
  }
}

