$filter-select-bg: #f5f5f5 !default;

sgx-order-book-filter-select {
  position: relative;
  display: flex;
  font-size: 0.85rem;

  // & sgx-input-toggle {
  //   // width: 250px;
  //   margin-right: -15px;
  // }

  // override weird margin in shared component
  & .sgx-input-checkbox-label {
    margin-bottom: 0px !important
  }

}

#order-book-filter-precheck-toggle,
#order-book-filter-auto-conf-toggle {
  margin-right: 15px;
}

#order-book-filter-ID-select-container {
  cursor: pointer;
  margin: auto 0 auto 0;
  height: 22px;
  width: 40px;
  border-radius: 11px;
  color: $ui-text-default;
  display: inline-flex;
  border: 1px solid black;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  position: relative;
  z-index: 1000;

  transition: border-color 0.4s ease;

  &:hover,
  &:focus {
    border-color: #fff;
  }
}

.order-book-filter-select-number {
  margin: auto 0 auto 0;
  font-size: 12px;
}

.order-book-filter-select-popup {
  top: calc(100%);
  right: 0;
  display: none;
  width: 290px;
  position: absolute;
  background: $filter-select-bg;
  border-radius: 5px;
  color: $ui-text-default;
  padding: 0.5rem;
  z-index: 110;
  line-height: 1.2rem;
  box-shadow: 1px 0px 5px 4px $ui-shadow;
  border: 1px solid $ui-bg;

  footer {
    text-align: right;

    button {
      font-size: 0.8rem;
    }
  }

  & sgx-input-select {
    width: 100%;
  }

  & .button-clear {
    margin-right: 0.5rem;
  }

  & .sgx-input-select-filter-wrapper {
    margin-bottom: 0.5rem;
  }
}

.order-book-filter-select--isFiltering {
  & svg {
    opacity: 0.3;
    cursor: default;
  }
}

// IE stuff
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  #order-book-filter-ID-select-container {
    padding-left: 0.3rem;
  }

  .order-book-filter-select-number {
    margin-left: 0.2rem;
    margin-top: 0.3rem;
  }
}