sgx-button-radio-select {
  display: flex;
  overflow: auto;
  height: auto;
}

.sgx-radio-button-select-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: min-content;
  align-self: baseline;
  margin-bottom: $space-6;
  width: 100%;

  & .sgx-button {
    width: 160px;
    height: 46px;
    margin: $space-2 0;

    [data-type="mobile"] & {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      width: calc((100% -#{$space-3}) / 2);
      margin-bottom: $space-4;

      :nth-child(odd) {
        margin-right: $space-3;
      }
    }
  }

  .sgx-button--place-holder {
    opacity: 0;
    cursor: default;
  }
}
