#sgx-platts-widget-metal-alert-filters-dialog {
  .sgx-dialog-box {
    padding: $space-5;

    .sgx-platts-widget-metal-alert-filters-dialog-buttons-container {
      text-align: right;
      margin-top: 40px;

      button {
        margin: 0;
        margin-left: $space-5;
      }
    }

    .sgx-dialog-footer {
      display: none;
    }
  }
}

body[data-type='desktop'] {
  #sgx-platts-widget-metal-alert-filters-dialog {
    .sgx-dialog-body {
      .sgx-platts-widget-metal-alert-filters-dialog-inputs-container {
        margin: $space-4;

        color: $ui-text-default;

        // Spacing between cells
        // table, tr, td
        .sgx-filter-form,
        .sgx-filter-form-row,
        .sgx-filter-form-row > * {
          border: $space-2 inset transparent;
          border-collapse: collapse;
          border-spacing: 0;
          line-height: $space-10;
        }

        // Cells
        .sgx-filter-form-row > * {
          &:not(:last-child) {
            margin-right: $space-4;
          }

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          .sgx-filter-form-cell-inner {
            width: 80px;
          }
          
          &.sgx-filter-form-cell-label .sgx-filter-form-cell-inner {
            text-align: right;
            width: 130px;
          }

          &.sgx-filter-form-cell-operation .sgx-filter-form-cell-inner {
            width: 100px;

            input[type='text'] {
              background: transparent;
              border: none;
              text-align: right;
            }
          }
          &.sgx-filter-form-cell-input {
            $filter-form-cell-input-width: 310px;

            .sgx-filter-form-cell-input-container {
              & > * {
                width: $filter-form-cell-input-width;
              }

              & > sgx-input-number {
                width: $filter-form-cell-input-width / 2;
              }
            }

            .sgx-filter-form-cell-input-hint {
              font-size: 12px;
              color: $sgx-theme-v2-medium-emp-default;
              margin: 0;

              .sgx-filter-form-cell-input-hint-inner {
                margin-top: -4px;
                margin-bottom: -8px;
              }
            }
          }
          
          .sgx-filter-form-cell-input-options {
            fieldset > label {
              display: inline-block;
              margin-right: 20px;
              margin-bottom: 0;
            }
          }
        }

        // Non-dropdown cells
        .sgx-platts-widget-metal-alert-filters-dialog-input-keyword,
        .sgx-platts-widget-metal-alert-filters-dialog-input-pCategory,
        .sgx-platts-widget-metal-alert-filters-dialog-input-symbol,
        .sgx-platts-widget-metal-alert-filters-dialog-input-page,
        .sgx-platts-widget-metal-alert-filters-dialog-input-daysBack,
        .sgx-platts-widget-metal-alert-filters-dialog-input-maxHeadlines {
          .sgx-filter-form-cell-operation {
            text-align: right;
            padding-right: $space-3;
          }
        }
      }
    }
  }
}

body[data-type='mobile'] {
  #sgx-platts-widget-metal-alert-filters-dialog {
    .sgx-dialog-body {
      .sgx-platts-widget-metal-alert-filters-dialog-inputs-container {
        margin: $space-4;

        color: $ui-text-default;

        .sgx-filter-form,
        .sgx-filter-form > *,
        .sgx-filter-form > * > * {
          display: block;
        }

        .sgx-filter-form-row:first-child {
          border-top: 1px solid $sgx-theme-v2-lower-emp-default;
        }
        .sgx-filter-form-row  {
          border-bottom: 1px solid $sgx-theme-v2-lower-emp-default;
          padding: 12px;
          margin: 0 (-1 * $space-4);
          
          .sgx-filter-form-cell-relation {
            display: none;
          }

          .sgx-filter-form-cell-operation {
            margin-top: $space-4;
          }

          .sgx-filter-form-cell-input {
            margin-top: $space-2;

            & > * > * {
              width: 100%;
            }

            .sgx-filter-form-cell-input-hint {
              font-size: $space-3;
              color: $sgx-theme-v2-medium-emp-default;
              margin: 0;
              margin-top: $space-2;
            }
          }
        }
      }
    }
  }
}