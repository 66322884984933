$book-styles-order-type-0: $ui-text-default !default; // Regular orders
$book-styles-order-type-1: $ui-text-default !default; // Prechecked orders
$book-styles-order-type-2: $sgx-yellow !default;
$book-styles-hover-color: $ui-border-primary !default;
$book-styles-white: $ui-item-row !default;
$book-styles-up: #72af00 !default;
$book-styles-down: #f30643 !default;
$book-styles-neutral-flash: #c0d730 !default;
$book-styles-odd-row: #fff !default;
$book-styles-even-row: #f5f5f5 !default;
$book-styles-bait-order-color: theme-color($v2-blue, tint 40%, tint 40%);

body[data-type="desktop"] sgx-table-row.book-styles {
  &:nth-of-type(odd) {
    background: $book-styles-odd-row;
    &:hover {
      background: $book-styles-odd-row !important;
    }
  }
  &:nth-of-type(even) {
    background: $book-styles-even-row;
    &:hover {
      background: $book-styles-even-row !important;
    }
  }
}

sgx-order-book-grid-view {
  .sgx-table-cell {
    text-align: left;
  }
  [data-column-id="bidPrice"] {
    text-align: right;
  }
}

.book-styles {
  cursor: pointer;
  &--bid--hover {
    [data-column-id^="bid"] {
      background: $book-styles-hover-color;
    }
  }
  &--ask--hover {
    [data-column-id^="ask"] {
      background: $book-styles-hover-color;
    }
  }
  &--edit-bid-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    background: $book-styles-hover-color;
    border-radius: 30px 0px 0px 30px;
    z-index: -1;
  }

  &[data-bid-owner="true"] {
    [data-column-id*="bidPrice"] {
      &:before {
        @extend .book-styles--edit-bid-indicator;
      }
    }
  }

  &[data-ask-owner="true"] {
    [data-column-id*="askPrice"] {
      &:before {
        @extend .book-styles--edit-bid-indicator;
        border-radius: 0px 30px 30px 0px;
      }
    }
  }

  &[data-bid-order-type="0"] {
    [data-column-id*="bid"] {
      color: $book-styles-order-type-0;
    }
  }

  &[data-bid-order-type="1"] {
    [data-column-id="bidPrice"] {
      &:after {
        content: '\f00c';
        font-family: fontAwesome;
        position: absolute;
        color: #bdd831;
        font-size: 0.7rem;
        left: 5px;
      }
    }
  }
  &[data-bid-order-type="2"] {
    [data-column-id*="bid"] {
      color: $book-styles-order-type-2;
    }
  }
  &[data-bid-fill-type="0"] {
    [data-column-id="bidQuantity"] {
      &:after {
        content: '\f023';
        font-family: fontAwesome;
        position: absolute;
        font-size: 0.7rem;
        right: 5px;
      }
    }
  }
  &[data-ask-order-type="0"] {
    [data-column-id*="ask"] {
      color: $book-styles-order-type-0;
    }
  }
  &[data-ask-order-type="1"] {
    [data-column-id="askPrice"] {
      &:after {
        content: '\f00c';
        font-family: fontAwesome;
        position: absolute;
        right: 5px;
        font-size: 0.7rem;
        color: #bdd831;
      }
    }
  }
  &[data-ask-order-type="2"] {
    [data-column-id*="ask"] {
      color: $book-styles-order-type-2;
    }
  }
  &[data-ask-fill-type="0"] {
    [data-column-id="askQuantity"] {
      &:after {
        content: '\f023';
        font-family: fontAwesome;
        position: absolute;
        font-size: 0.7rem;
        right: 5px;
      }
    }
  }
  &[data-bid-auto-confirm="true"] {
    [data-column-id="bidId"] {
      &:after {
        content: '\f0e7';
        font-family: fontAwesome;
        position: absolute;
        font-size: 0.7rem;
        right: 5px;
        color: theme-color($sgx-dark-grey, shade 70, tint 95);
      }
    }
  }
  &[data-ask-auto-confirm="true"] {
    [data-column-id="askId"] {
      &:after {
        content: '\f0e7';
        font-family: fontAwesome;
        position: absolute;
        font-size: 0.7rem;
        right: 5px;
        color: theme-color($sgx-dark-grey, shade 70, tint 95);
      }
    }
  }
  &--down-triangle {
    top: 12px;
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
  }
  &--up-triangle {
    @extend .book-styles--down-triangle;
    border-top: 0;
    border-bottom: 5px solid currentColor;
  }
  &--price-up-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-price-up-flash;
  }
  &--price-down-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-price-down-flash;
  }
  &--total-volume-up-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-price-up-flash;
  }
  &--total-volume-down-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-price-down-flash;
  }
  &--neutral-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-neutral-flash;
  }
  @keyframes book-styles-price-up-flash {
    50% {
      background: $book-styles-up;
      color: white;
    }
  }
  @keyframes book-styles-price-down-flash {
    50% {
      background: $book-styles-down;
      color: white;
    }
  }
  @keyframes book-styles-neutral-flash {
    50% {
      background: $book-styles-neutral-flash;
      color: white;
    }
  }

  &[data-last-price-color="up"] {
    [data-column-id="lastPrice"] {
      color: $book-styles-up;
      &:after {
        @extend .book-styles--up-triangle;
        right: 5px;
      }
    }
  }

  &[data-last-price-color="down"] {
    [data-column-id="lastPrice"] {
      color: $book-styles-down;
      &:after {
        @extend .book-styles--down-triangle;
        right: 5px;
      }
    }
  }

  &[data-bid-order-is-bait="true"] {
    [data-column-id^="bid"] {
      color: $book-styles-bait-order-color;
    }
  }

  &[data-ask-order-is-bait="true"] {
    [data-column-id^="ask"] {
      color: $book-styles-bait-order-color;
    }
  }
}
