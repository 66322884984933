$sgx-tab-menu-height: 45px;
// card
$sgx-card-shadow-color: $sgx-theme-v2-shadow-01;
$sgx-card-bg: theme-color($sgx-theme-ui-01, tint 100%, shade 50%);
$sgx-card-header-text: theme-color($sgx-theme-text-01, shade 70%, tint 95%);
$page-background-colour: theme-color($sgx-theme-ui-01, tint 95%, shade 65%);


html {
  height: 100%;
  font-family: "Lato", sans-serif;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTJBQkFGRUZFOTIyMTFFMEJDRDNEQzkxOTVGOTNBODAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTJBQkFGRjBFOTIyMTFFMEJDRDNEQzkxOTVGOTNBODAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MkFCQUZFREU5MjIxMUUwQkNEM0RDOTE5NUY5M0E4MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MkFCQUZFRUU5MjIxMUUwQkNEM0RDOTE5NUY5M0E4MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtrV+I8AAAAQSURBVHjaYvj//z8DQIABAAj8Av7bok0WAAAAAElFTkSuQmCC',sizingMethod=crop);
  zoom: 1;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-text-size-adjust: 100%;
}

body {
  height: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  background: $body-bg;
}

#page-container {
  height: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

sgx-flexible-layout {
  .lm_header .lm_tabs {
    width: 100%;
  }
}

.website-main-layout {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.website-main-container {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}

.website-main-content {
  opacity: 1;
  transition: opacity 0.1s ease;
  height: 100%;

  .router-transition-in-progress & {
    opacity: 0;
    pointer-events: none;
  }
}

#main-container {
  background: $page-background-colour;
}

.template {
  background: theme-color($sgx-theme-ui-01, tint 95%, shade 65%);
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
}

#page-tabs {
  margin: 0.5rem 0;
}

.website-main-background {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
  transition: opacity 0.5s ease;
  opacity: 0;
  text-align: center;
  font-size: 1rem;

  .router-transition-in-progress & {
    opacity: 1;
    transition-delay: 1s;
    z-index: 100;
  }
}

.website-main-loader {
  opacity: 0;
  animation-play-state: paused;
  transition: opacity 0.75s;

  .router-transition-in-progress & {
    opacity: 1;
    animation-play-state: running;
    transition-delay: 1.5s;
  }
}

.website-overflow-wrapper {
  overflow-x: auto;
  font-size: 0;
}

.website-overflow-content {
  display: inline-block;
  font-size: 1rem;
  min-width: 100%;
}

.website-component-hide {
  display: none !important;
}

.website-component-hidden {
  visibility: hidden !important;
}

body[data-type="mobile"] {
  .website-main-background {
    font-size: 0.8rem;
  }
}

// table specific css
#sgx-table-config-dialog {
  font-size: 0.76rem;
  z-index: 10000;
}

#sgx-select-dialog {
  // for sgx-table NLT to have smaller multi select fonts
  font-size: 0.76rem;
  z-index: 100000 !important;
}

.table-header-filter-popup-input {
  font-size: 0.9rem;
  width: 100%;
}

::selection {
  background: #ddd;
  color: $ui-text-default;
  opacity: 1;
}

.sgx-card-mobile,
.sgx-card {
  .sgx-card-title {
    color: $sgx-card-header-text;
    font-size: 20px;
    margin-bottom: $space-3;
  }
  .sgx-card-subtitle {
    color: $sgx-form-input-label-text;
    font-size: 14px;
    margin-bottom: $space-5;
  }
  position: relative;
  width: 100%;
  height: 100%;
  padding: $space-5 $space-3;
  background: $sgx-card-bg;
  margin-bottom: $space-3;
  height: 100%;
  box-shadow: 0 1px 4px $sgx-card-shadow-color;
}

.sgx-card-mobile {
  .sgx-card-title {
    font-size: 16px;
    margin-bottom: $space-1;
  }

  .sgx-card-subtitle {
    font-size: 12px;
    margin-bottom: $space-3;
  }

  padding: 0px;
  margin-bottom: $space-1;
}


// transparent overlay
#sgx-quick-search-dialog .sgx-dialog-overlay,
body[data-analytics-category="Orders"] .sgx-dialog--centered .sgx-dialog-overlay {
  background: rgba(0,0,0,.2);
}

// header for support icon
$sgx-header-help-menu-color: theme-color($sgx-theme-text-02, tint 60%, tint 45%);

.sgx-header-help-menu {
  width: 64px;
  height: 40px;
  text-decoration: none;
  color: $sgx-header-help-menu-color;

  i {
    font-size: 20px;
  }
}

body[data-type="mobile"] {
  .sgx-card {
    padding-left: $space-3;
    padding-right: $space-3;
  }
}

body[data-type="tablet"] {
  .sgx-card {
    padding-left: $space-4;
    padding-right: $space-4;
  }
}
