$sgx-unsupported-browser-background-color: #0b236b !default;
$sgx-unsupported-browser-sgx-logo-width: 128px !default;
$sgx-unsupported-browser-content-background-color: #ffffff !default;
$sgx-unsupported-browser-content-width: 600px !default;
$sgx-unsupported-browser-content-height: 425px !default;
$sgx-unsupported-browser-content-border-style: solid !default;
$sgx-unsupported-browser-content-border-width: 1px !default;
$sgx-unsupported-browser-content-border-color: #cccccc !default;
$sgx-unsupported-browser-content-padding: 32px !default;
$unsupported-browser-title-font-size: 125%;
$unsupported-browser-title-font-weight: 600 !default;
$unsupported-browser-title-font-color: #5f6062 !default;
$unsupported-browser-title-margin: 16px 0 !default;
$sgx-unsupported-browser-detail-margin: 16px 0 !default;
$sgx-unsupported-browser-type-width: 100px !default;
$sgx-unsupported-browser-type-mobile-width: 49% !default;
$sgx-unsupported-browser-type-padding: 10px !default;

.unsupported-browser {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  background: $sgx-unsupported-browser-background-color;
  z-index: 200;
  line-height: 1.4;

  .sgx-logo {
    width: $sgx-unsupported-browser-sgx-logo-width;
  }
}

#unknown-error {
  .unsupported-browser-content {
    height: auto;
    padding-bottom: 4rem;
  }

  .contact-us {
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 0;
  }
}

.unsupported-browser-content {
  position: absolute;
  background: $sgx-unsupported-browser-content-background-color;
  width: $sgx-unsupported-browser-content-width;
  height: $sgx-unsupported-browser-content-height;
  border-style: $sgx-unsupported-browser-content-border-style;
  border-width: $sgx-unsupported-browser-content-border-width;
  border-color: $sgx-unsupported-browser-content-border-color;
  padding: $sgx-unsupported-browser-content-padding;
  top: 50%;
  margin-top: -$sgx-unsupported-browser-content-height / 2;
  left: 50%;
  margin-left: -$sgx-unsupported-browser-content-width / 2;
}

.unsupported-browser-title {
  font-size: $unsupported-browser-title-font-size;
  margin: $unsupported-browser-title-margin;
  font-weight: $unsupported-browser-title-font-weight;
  color: $unsupported-browser-title-font-color;
}

.browser-detail {
  text-align: center;
  margin: $sgx-unsupported-browser-detail-margin;
}

.browser-type {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: $sgx-unsupported-browser-type-width;
  padding: $sgx-unsupported-browser-type-padding;
  text-decoration: none;
  color: #5f6062;

  span {
    display: block;
    margin-top: 10px;
  }
}

.contact-us {
  text-align: center;
}

body.no-browser-support {
  .unsupported-browser {
    display: block;
  }

  sgx-menu {
    display: none;
  }
}

body[data-type='mobile'] {
  .unsupported-browser-content {
    position: relative;
    width: auto;
    height: auto;
    min-height: 100%;
    top: auto;
    left: auto;
    margin-top: auto;
    margin-left: auto;
  }

  .browser-type {
    width: $sgx-unsupported-browser-type-mobile-width;
  }
}
 