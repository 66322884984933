$imgMetalsAlert: '../static/images/emptyMetalAlerts.png' !default;
$imgMetalsData: '../static/images/emptyMetalsData.png' !default;
$imgChartData: '../static/images/emptyChartsData.png' !default;

.sgx-empty-image-placeholders {
  sgx-empty-image-placeholders & {
    top: 50%;
    left: 50%;
    height: auto;
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    transform: translate(-50%, -50%);
  }

  sgx-empty-image-placeholders & .sgx-empty-image-placeholders-title {
    font-size: 16px;
    margin-top: $space-3;
  }

  sgx-empty-image-placeholders & .sgx-empty-image-placeholders-desc {
    font-size: 12px;
    padding-top: $space-1;
  }

  sgx-empty-image-placeholders & .sgx-empty-image-placeholders-image.metalAlerts {
    content: url($imgMetalsAlert);
  }

  sgx-empty-image-placeholders & .sgx-empty-image-placeholders-image.metalsData {
    content: url($imgMetalsData);
  }

  sgx-empty-image-placeholders & .sgx-empty-image-placeholders-image.chartData {
    content: url($imgChartData);
  }
}
