template-iframe {
  height: inherit;
  overflow: hidden;
  display: none;
  
  iframe {
    position: relative;
    border: 0;
    width: 100%;
    height: 100%;
  }
}
