$edit-icon: url(sgx-base-code/svgs/regular/pencil-alt.svg);
$delete-icon: url(sgx-base-code/svgs/regular/trash-alt.svg);
sgx-widget-account-aliases {
  display: block;
  background-color: $sgx-card-background;
  width: 100%;
  height: 100%;
  padding: $space-8 calc(#{$space-27} + #{$space-8});
  & sgx-table {
    font-size: 14px;
  }
  & sgx-table-row {
    position: relative;
  }
  & sgx-table-list {
    // current sgx-table does not adjust striped background to row heights
    background-size: 100% 70px !important;
  }
}

.sgx-widget-account-aliases-row-highlight {
  background: $ui-item-row-active !important;
}

.sgx-widget-account-aliases-sub-header {
  color: $sgx-form-input-label-text;
  font-size: 14px;
  margin-bottom: $space-4;
}

.sgx-widget-account-aliases-controls {
  position: absolute;
  display: none;
  right: 0px;
}

.sgx-widget-account-aliases-edit-control {
  display: inline;
  margin-right: $space-5;
  &:before {
    icon-font: $edit-icon;
    padding-right: $space-1;
  }
  &:hover {
    cursor: pointer;
  }
}

.sgx-widget-account-aliases-delete-control {
  display: inline;
  margin-right: $space-5;
  &:before {
    icon-font: $delete-icon;
    padding-right: $space-1;
  }
  &:hover {
    cursor: pointer;
  }
  &[data-visible='false'] {
    display: none;
  }
}

.sgx-widget-account-aliases-table {
  height: 60vh;
  sgx-table-row:hover .sgx-widget-account-aliases-controls {
    display: flex;
  }
}

#sgx-account-alias-edit-dialog {
  .sgx-dialog-body,
  .sgx-dialog-footer,
  .sgx-dialog-box {
    background-color: $sgx-dialog-attached-background;
  }
  .sgx-dialog-box {
    min-width: 432px;
    padding: $space-6 $space-5 $space-5 $space-5;
  }
  sgx-input-text {
    width: 100%;
  }
  .sgx-dialog-footer {
    padding-bottom: 0px;
    padding-right: 0px;
  }
}

.sgx-account-alias-dialog-header {
  color: $sgx-card-header-text;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: $space-5;
}

sgx-widget-account-aliases-row-highlight {}