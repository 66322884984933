sgx-community-change-password {
}

#sgx-community-change-password-dialog {
  .sgx-dialog-box {
    padding: $space-8 $space-5 $space-5 $space-5;
  }

  .sgx-dialog-header {
    padding-bottom: $space-5;

    h2 {
      font-size: 24px; // NOTE: No suitable variable found
    }
  }

  form {
    display: flex;
    flex-direction: column;

    & > * {
      margin: $space-2 0;
      width: 100%;
    }

    .sgx-community-change-password-btn-container {
      text-align: right;
    }
  }

  .sgx-dialog-footer {
    display: none;
  }

  sgx-status-indicator {
    background-color: $sgx-dialog-attached-background;

    padding: $space-5;
    line-height: 1.25;

    .sgx-community-change-password-indicator-button {
      margin-top: $space-2;
    }
  }
}

body[data-type="desktop"],
body[data-type="tablet"] {
  #sgx-community-change-password-dialog {
    .sgx-dialog-box {
      min-width: 350px;
    }
  }
}
