$template-orders-report-page-background-colour: theme-color(
  $sgx-dark-grey,
  tint 95%,
  shade 65%
);
$sgx-card-header-text: theme-color($sgx-theme-text-01, shade 100%, tint 90%);
$sgx-card-info-text: theme-color($sgx-theme-text-01, shade 40%, tint 40%);

template-orders-report-page {
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $template-orders-report-page-background-colour;
  .template-orders-report-container {
    height: 100%;
    padding: $space-8 $space-27;
  }
  .template-orders-report-page-inner-wrapper {
    padding-left: $space-8;
    color: $sgx-card-header-text;
    width: max-content;
    .template-orders-report-page-title {
      padding-bottom: $space-2;
      font-size: $space-5;
    }
    .template-orders-report-page-sub-title {
      padding-bottom: $space-5;
      font-size: 14px;
      color: $sgx-card-info-text;
    }
    .date-selection-container {
      display: flex;
      flex-direction: row;
      .date-picker-separator {
        padding-left: $space-3;
        padding-right: $space-3;
      }
      sgx-input-date {
        label {
          display: flex;
          flex-direction: row;
          align-items: center;
          .sgx-input-label {
            color: $sgx-card-header-text;
          }
          input {
            width: 324px;
          }
          span {
            padding-right: $space-3;
          }
          .sgx-icon::after {
            padding-right: $space-3;
          }
        }
      }
    }
    .info-message {
      font-size: $space-3;
      color: $sgx-card-info-text;
      padding-top: $space-2;
      padding-left: $space-11;
    }
    .error-message, .empty-date-error-message {
      font-size: $space-4;
      padding-top: $space-4;
      color: #e60000;
      &.hide {
        display: none
      }
      &.show {
        display: block;
      }
    }
    .sgx-orders-report-btn-submit {
      margin-left: $space-4;
    }
    .orders-report-alert-bar {
      margin-bottom: $space-2;
    }
  }
}
