sgx-platts-market-symbol-data-table {
  height: 100%;
  position: relative;
}

.sgx-platts-market-symbol-data-table-wrapper {
  sgx-platts-market-symbol-data-table & {
    height: 100%;
  }
}

sgx-table-cell-checkbox[data-column-id='symbolstatus'] {
  sgx-platts-market-symbol-data-table sgx-table sgx-table-row & {
    max-width: 30px;
  }
}

sgx-table-toolbar {
  sgx-platts-market-symbol-data-table & {
    display: none;
  }
}

.sgx-table-header-wrapper[data-id='symbolstatus'] {
  sgx-platts-market-symbol-data-table sgx-table-column-headers-manager .sgx-table-header-container & {
    max-width: 30px;
  }
}

.sgx-market-symbol-data-table-container {
  sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper & {
    height: 100%;
    position: relative;
  }

  sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper & sgx-table {
    height: calc(100% - 25px);
    text-align: left;
  }

  sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper & .sgx-market-symbol-data-table-footer {
    height: 25px;
    text-align: left;
    position: relative;
    padding-top: $space-2;
    padding-bottom: $space-2;
  } 
}

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer a {
  text-decoration: none;
  color: $sgx-theme-v2-brand-03;
  @extend .sgx-base-text-helper-12;
}

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer a:not(:first-child):after {
  content: '|';
  padding-left: $space-2;
  padding-right: $space-2;
  color: $sgx-theme-v2-brand-03;
  @extend .sgx-base-text-helper-12;
}

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer a:last-child:after {
  content: '';
  padding-right: unset;
}

.sgx-input-hint-icon {
  sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer & {
    position: static;
    padding-left: 0px;
  }
}

sgx-scroll-shield {
  sgx-platts-market-symbol-data-table sgx-table & {
    top: 32px;
    height: calc(100% - 32px);
  }
}

.sgx-input-hint {
  sgx-platts-market-symbol-data-table .sgx-market-symbol-data-table-footer & {
    top: 0;
    left: 0;
    color: #a7a8a9;
    min-width: 286px;
    max-width: 286px;
    @extend .sgx-base-text-body-12;
  }

  sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer &:after {
    left: 1px;
  }

  sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer .sgx-input-hint-icon:hover ~ &,
  sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer .sgx-input-hint-icon:hover & {
    visibility: visible;
  }
}
