$template-dashboard-page-background-colour: theme-color(
  $sgx-dark-grey,
  tint 95%,
  shade 65%
);
$sgx-card-header-text: theme-color($sgx-theme-text-01, shade 70%, tint 95%);

.dashboard-faq-dialog-image-en,
.dashboard-faq-dialog-image-cn {
  width: 100%;
}

.dashboard-faq-dialog-title {
  color: $sgx-theme-v2-high-emp-default;
}

body[data-type="mobile"],
body[data-type="tablet"] {
  template-dashboard-page
    .lm_content[data-widget="sgx-widget-derivatives-prices-chart"]
    sgx-card-widget-wrapper {
    overflow-y: auto;
  }
}

template-dashboard-page {
  height: 100%;
  background-color: $template-dashboard-page-background-colour;
  overflow: auto;
  position: relative;

  .template-dashboard-add-widget-btn {
    position: absolute;
    top: 0;
    right: $space-5;
    margin-top: $space-3;

    &:before {
      icon-font: url("@sgx/sgx-base-code/svgs/light/plus.svg");
    }
  }

  .template-dashboard-page-greeting-section {
    padding: $space-3 0;

    body[data-type="mobile"] & {
      display: none;
    }
  }

  .template-dashboard-page-widgets-section {
    height: 100%;

    body[data-type="mobile"] & {
      padding-top: $space-3;
    }

    .sgx-dashboard-advert-container {
      margin: $space-2 0;

      & > * {
        overflow: hidden;
        padding: 0;
      }
    }

    @include media-breakpoint-up(sm) { // sm > 1023 px
      .sgx-dashboard-advert-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: $space-2;
        row-gap: $space-2;
      }
    }

    @include media-breakpoint-down(sm) { // sm > 1023 px
      .sgx-dashboard-advert-container {
        // Set up grid
        display: grid;
        grid-gap: $space-2;
        grid-template-columns: $space-5;
        grid-auto-flow: column;
        grid-auto-columns: calc(40% - 2 * #{$space-2});

        // Horizontal scrolling
        overflow-x: auto;

        // Handle edges:

        margin-left: -($space-5 + $space-2);
        margin-right: -($space-5 + $space-2);

        &::before,
        &::after {
          content: '';
          width: $space-5;
        }

        // Let card bottom shadows show up:
        padding-bottom: 2 * $space-1;
        margin-bottom: -$space-1;
      }
    }

    @include media-breakpoint-down(xs) { // xs > 767 px
      .sgx-dashboard-advert-container {
        grid-auto-columns: calc(50% - 2 * #{$space-2});
      }
    }
    
    @include media-breakpoint-down(xxs) { // xss > 599 px
      .sgx-dashboard-advert-container {
        grid-auto-columns: calc(75% - 2 * #{$space-2});
      }
    }
    
    // IE 10, 11
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .sgx-dashboard-advert-container {
        margin-right: -12px;
      }
      .sgx-dashboard-advert-wrapper {
        display: inline-block;
        width: calc(33% - #{2/3*$space-2});
        vertical-align: top;
        margin-right: $space-2;
      }

      @include media-breakpoint-down(sm) { // xs > 1023 px
        .sgx-dashboard-advert-container {
          margin-left: 0;
          margin-right: -8px;
          padding-bottom: 3/2*$space-2;
        }
        .sgx-dashboard-advert-wrapper {
          width: calc(33% - #{2/3*$space-2});
        }
      }
    }
  }

  .template-dashboard-page-title {
    color: $sgx-card-header-text;
  }

  .template-dashboard-page-dashboard-container {
    padding: 0 $space-5;
    height: auto;
  }

  .template-dashboard-page-dashboard-container.sgx-flexible-layout-container {
    padding-bottom: $space-5;
  }
}
