// $numLines: height (any unit)
// $lineHeight: number (unit: rem)
@mixin line-clamp($numLines, $lineHeight) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height: $numLines * $lineHeight + unquote('rem');
}

// $numLines: height (any unit)
// $lineHeight: number (unit: rem)
@mixin height-clamp($height, $lineHeight) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $height / $lineHeight;
  -webkit-box-orient: vertical;
  max-height: $height;
}