@charset "UTF-8";
/* FUNCTIONS */
/**
 * Slightly lighten a colour
 * @param { Colour } $colour - colour to tint
 * @param { Number } $percentage - percentage of `$colour` in returned colour
 * @return { Colour }
 */
/**
 * Slightly darken a colour
 * @param { Colour } $colour - colour to shade
 * @param { Number } $percentage - percentage of `$colour` in returned colour
 * @return { Colour }
 */
.clearfix::after {
  display: block;
  content: "";
  clear: both; }

/**
 * Sick of us using the native scrollbar. Since most of our users are on modern
 * browsers, lets use the vendor prefix to get a better look. We use the same styles
 * for http://brand.qasgx.com/. Copy pasta from there :).
 * Till we get a better solution, i.e using a scroll shield container.
 */
::-webkit-scrollbar {
  width: 5px; }

::-webkit-scrollbar-thumb {
  background: #888; }

::-webkit-scrollbar-track {
  background: #f1f1f1; }

.sgx-header-logo path {
  fill: #1a256e !important; }

button[disabled],
input[disabled] {
  cursor: not-allowed !important; }

/** Fathom UI variables for layout  **/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

*:focus {
  outline-color: #40afc6; }

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px; }
  @media (min-width: 600px) {
    .container {
      padding-right: 20px;
      padding-left: 20px; } }
  @media (min-width: 768px) {
    .container {
      padding-right: 40px;
      padding-left: 40px; } }
  @media (min-width: 1024px) {
    .container {
      padding-right: 50px;
      padding-left: 50px; } }
  @media (min-width: 1280px) {
    .container {
      padding-right: 65px;
      padding-left: 65px; } }
  @media (min-width: 600px) {
    .container {
      width: 600px;
      max-width: 100%; } }
  @media (min-width: 768px) {
    .container {
      width: 768px;
      max-width: 100%; } }
  @media (min-width: 1024px) {
    .container {
      width: 1024px;
      max-width: 100%; } }
  @media (min-width: 1280px) {
    .container {
      width: 1280px;
      max-width: 100%; } }
  @media (min-width: 1366px) {
    .container {
      width: 1366px;
      max-width: 100%; } }

.container-fluid {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 1366px; }
  @media (min-width: 600px) {
    .container-fluid {
      padding-right: 20px;
      padding-left: 20px; } }
  @media (min-width: 768px) {
    .container-fluid {
      padding-right: 40px;
      padding-left: 40px; } }
  @media (min-width: 1024px) {
    .container-fluid {
      padding-right: 50px;
      padding-left: 50px; } }
  @media (min-width: 1280px) {
    .container-fluid {
      padding-right: 65px;
      padding-left: 65px; } }

.container-fluid.container-fluid--full-width {
  max-width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  @media (min-width: 600px) {
    .row {
      margin-right: -5px;
      margin-left: -5px; } }
  @media (min-width: 768px) {
    .row {
      margin-right: -5px;
      margin-left: -5px; } }
  @media (min-width: 1024px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 1280px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }
  @media (min-width: 1366px) {
    .row {
      margin-right: -15px;
      margin-left: -15px; } }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px; }
  @media (min-width: 600px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 5px;
      padding-left: 5px; } }
  @media (min-width: 768px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 5px;
      padding-left: 5px; } }
  @media (min-width: 1024px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1280px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 15px;
      padding-left: 15px; } }
  @media (min-width: 1366px) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
      padding-right: 15px;
      padding-left: 15px; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.pull-0 {
  right: auto; }

.pull-1 {
  right: 8.33333%; }

.pull-2 {
  right: 16.66667%; }

.pull-3 {
  right: 25%; }

.pull-4 {
  right: 33.33333%; }

.pull-5 {
  right: 41.66667%; }

.pull-6 {
  right: 50%; }

.pull-7 {
  right: 58.33333%; }

.pull-8 {
  right: 66.66667%; }

.pull-9 {
  right: 75%; }

.pull-10 {
  right: 83.33333%; }

.pull-11 {
  right: 91.66667%; }

.pull-12 {
  right: 100%; }

.push-0 {
  left: auto; }

.push-1 {
  left: 8.33333%; }

.push-2 {
  left: 16.66667%; }

.push-3 {
  left: 25%; }

.push-4 {
  left: 33.33333%; }

.push-5 {
  left: 41.66667%; }

.push-6 {
  left: 50%; }

.push-7 {
  left: 58.33333%; }

.push-8 {
  left: 66.66667%; }

.push-9 {
  left: 75%; }

.push-10 {
  left: 83.33333%; }

.push-11 {
  left: 91.66667%; }

.push-12 {
  left: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 600px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-xs-0 {
    right: auto; }
  .pull-xs-1 {
    right: 8.33333%; }
  .pull-xs-2 {
    right: 16.66667%; }
  .pull-xs-3 {
    right: 25%; }
  .pull-xs-4 {
    right: 33.33333%; }
  .pull-xs-5 {
    right: 41.66667%; }
  .pull-xs-6 {
    right: 50%; }
  .pull-xs-7 {
    right: 58.33333%; }
  .pull-xs-8 {
    right: 66.66667%; }
  .pull-xs-9 {
    right: 75%; }
  .pull-xs-10 {
    right: 83.33333%; }
  .pull-xs-11 {
    right: 91.66667%; }
  .pull-xs-12 {
    right: 100%; }
  .push-xs-0 {
    left: auto; }
  .push-xs-1 {
    left: 8.33333%; }
  .push-xs-2 {
    left: 16.66667%; }
  .push-xs-3 {
    left: 25%; }
  .push-xs-4 {
    left: 33.33333%; }
  .push-xs-5 {
    left: 41.66667%; }
  .push-xs-6 {
    left: 50%; }
  .push-xs-7 {
    left: 58.33333%; }
  .push-xs-8 {
    left: 66.66667%; }
  .push-xs-9 {
    left: 75%; }
  .push-xs-10 {
    left: 83.33333%; }
  .push-xs-11 {
    left: 91.66667%; }
  .push-xs-12 {
    left: 100%; }
  .offset-xs-0 {
    margin-left: 0%; }
  .offset-xs-1 {
    margin-left: 8.33333%; }
  .offset-xs-2 {
    margin-left: 16.66667%; }
  .offset-xs-3 {
    margin-left: 25%; }
  .offset-xs-4 {
    margin-left: 33.33333%; }
  .offset-xs-5 {
    margin-left: 41.66667%; }
  .offset-xs-6 {
    margin-left: 50%; }
  .offset-xs-7 {
    margin-left: 58.33333%; }
  .offset-xs-8 {
    margin-left: 66.66667%; }
  .offset-xs-9 {
    margin-left: 75%; }
  .offset-xs-10 {
    margin-left: 83.33333%; }
  .offset-xs-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-sm-0 {
    right: auto; }
  .pull-sm-1 {
    right: 8.33333%; }
  .pull-sm-2 {
    right: 16.66667%; }
  .pull-sm-3 {
    right: 25%; }
  .pull-sm-4 {
    right: 33.33333%; }
  .pull-sm-5 {
    right: 41.66667%; }
  .pull-sm-6 {
    right: 50%; }
  .pull-sm-7 {
    right: 58.33333%; }
  .pull-sm-8 {
    right: 66.66667%; }
  .pull-sm-9 {
    right: 75%; }
  .pull-sm-10 {
    right: 83.33333%; }
  .pull-sm-11 {
    right: 91.66667%; }
  .pull-sm-12 {
    right: 100%; }
  .push-sm-0 {
    left: auto; }
  .push-sm-1 {
    left: 8.33333%; }
  .push-sm-2 {
    left: 16.66667%; }
  .push-sm-3 {
    left: 25%; }
  .push-sm-4 {
    left: 33.33333%; }
  .push-sm-5 {
    left: 41.66667%; }
  .push-sm-6 {
    left: 50%; }
  .push-sm-7 {
    left: 58.33333%; }
  .push-sm-8 {
    left: 66.66667%; }
  .push-sm-9 {
    left: 75%; }
  .push-sm-10 {
    left: 83.33333%; }
  .push-sm-11 {
    left: 91.66667%; }
  .push-sm-12 {
    left: 100%; }
  .offset-sm-0 {
    margin-left: 0%; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-md-0 {
    right: auto; }
  .pull-md-1 {
    right: 8.33333%; }
  .pull-md-2 {
    right: 16.66667%; }
  .pull-md-3 {
    right: 25%; }
  .pull-md-4 {
    right: 33.33333%; }
  .pull-md-5 {
    right: 41.66667%; }
  .pull-md-6 {
    right: 50%; }
  .pull-md-7 {
    right: 58.33333%; }
  .pull-md-8 {
    right: 66.66667%; }
  .pull-md-9 {
    right: 75%; }
  .pull-md-10 {
    right: 83.33333%; }
  .pull-md-11 {
    right: 91.66667%; }
  .pull-md-12 {
    right: 100%; }
  .push-md-0 {
    left: auto; }
  .push-md-1 {
    left: 8.33333%; }
  .push-md-2 {
    left: 16.66667%; }
  .push-md-3 {
    left: 25%; }
  .push-md-4 {
    left: 33.33333%; }
  .push-md-5 {
    left: 41.66667%; }
  .push-md-6 {
    left: 50%; }
  .push-md-7 {
    left: 58.33333%; }
  .push-md-8 {
    left: 66.66667%; }
  .push-md-9 {
    left: 75%; }
  .push-md-10 {
    left: 83.33333%; }
  .push-md-11 {
    left: 91.66667%; }
  .push-md-12 {
    left: 100%; }
  .offset-md-0 {
    margin-left: 0%; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-lg-0 {
    right: auto; }
  .pull-lg-1 {
    right: 8.33333%; }
  .pull-lg-2 {
    right: 16.66667%; }
  .pull-lg-3 {
    right: 25%; }
  .pull-lg-4 {
    right: 33.33333%; }
  .pull-lg-5 {
    right: 41.66667%; }
  .pull-lg-6 {
    right: 50%; }
  .pull-lg-7 {
    right: 58.33333%; }
  .pull-lg-8 {
    right: 66.66667%; }
  .pull-lg-9 {
    right: 75%; }
  .pull-lg-10 {
    right: 83.33333%; }
  .pull-lg-11 {
    right: 91.66667%; }
  .pull-lg-12 {
    right: 100%; }
  .push-lg-0 {
    left: auto; }
  .push-lg-1 {
    left: 8.33333%; }
  .push-lg-2 {
    left: 16.66667%; }
  .push-lg-3 {
    left: 25%; }
  .push-lg-4 {
    left: 33.33333%; }
  .push-lg-5 {
    left: 41.66667%; }
  .push-lg-6 {
    left: 50%; }
  .push-lg-7 {
    left: 58.33333%; }
  .push-lg-8 {
    left: 66.66667%; }
  .push-lg-9 {
    left: 75%; }
  .push-lg-10 {
    left: 83.33333%; }
  .push-lg-11 {
    left: 91.66667%; }
  .push-lg-12 {
    left: 100%; }
  .offset-lg-0 {
    margin-left: 0%; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1366px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-xl-0 {
    right: auto; }
  .pull-xl-1 {
    right: 8.33333%; }
  .pull-xl-2 {
    right: 16.66667%; }
  .pull-xl-3 {
    right: 25%; }
  .pull-xl-4 {
    right: 33.33333%; }
  .pull-xl-5 {
    right: 41.66667%; }
  .pull-xl-6 {
    right: 50%; }
  .pull-xl-7 {
    right: 58.33333%; }
  .pull-xl-8 {
    right: 66.66667%; }
  .pull-xl-9 {
    right: 75%; }
  .pull-xl-10 {
    right: 83.33333%; }
  .pull-xl-11 {
    right: 91.66667%; }
  .pull-xl-12 {
    right: 100%; }
  .push-xl-0 {
    left: auto; }
  .push-xl-1 {
    left: 8.33333%; }
  .push-xl-2 {
    left: 16.66667%; }
  .push-xl-3 {
    left: 25%; }
  .push-xl-4 {
    left: 33.33333%; }
  .push-xl-5 {
    left: 41.66667%; }
  .push-xl-6 {
    left: 50%; }
  .push-xl-7 {
    left: 58.33333%; }
  .push-xl-8 {
    left: 66.66667%; }
  .push-xl-9 {
    left: 75%; }
  .push-xl-10 {
    left: 83.33333%; }
  .push-xl-11 {
    left: 91.66667%; }
  .push-xl-12 {
    left: 100%; }
  .offset-xl-0 {
    margin-left: 0%; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.flex-first {
  order: -1; }

.flex-last {
  order: 1; }

.flex-unordered {
  order: 0; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 600px) {
  .flex-xs-first {
    order: -1; }
  .flex-xs-last {
    order: 1; }
  .flex-xs-unordered {
    order: 0; }
  .flex-xs-row {
    flex-direction: row !important; }
  .flex-xs-column {
    flex-direction: column !important; }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xs-wrap {
    flex-wrap: wrap !important; }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xs-start {
    justify-content: flex-start !important; }
  .justify-content-xs-end {
    justify-content: flex-end !important; }
  .justify-content-xs-center {
    justify-content: center !important; }
  .justify-content-xs-between {
    justify-content: space-between !important; }
  .justify-content-xs-around {
    justify-content: space-around !important; }
  .align-items-xs-start {
    align-items: flex-start !important; }
  .align-items-xs-end {
    align-items: flex-end !important; }
  .align-items-xs-center {
    align-items: center !important; }
  .align-items-xs-baseline {
    align-items: baseline !important; }
  .align-items-xs-stretch {
    align-items: stretch !important; }
  .align-content-xs-start {
    align-content: flex-start !important; }
  .align-content-xs-end {
    align-content: flex-end !important; }
  .align-content-xs-center {
    align-content: center !important; }
  .align-content-xs-between {
    align-content: space-between !important; }
  .align-content-xs-around {
    align-content: space-around !important; }
  .align-content-xs-stretch {
    align-content: stretch !important; }
  .align-self-xs-auto {
    align-self: auto !important; }
  .align-self-xs-start {
    align-self: flex-start !important; }
  .align-self-xs-end {
    align-self: flex-end !important; }
  .align-self-xs-center {
    align-self: center !important; }
  .align-self-xs-baseline {
    align-self: baseline !important; }
  .align-self-xs-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-sm-first {
    order: -1; }
  .flex-sm-last {
    order: 1; }
  .flex-sm-unordered {
    order: 0; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-md-first {
    order: -1; }
  .flex-md-last {
    order: 1; }
  .flex-md-unordered {
    order: 0; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1280px) {
  .flex-lg-first {
    order: -1; }
  .flex-lg-last {
    order: 1; }
  .flex-lg-unordered {
    order: 0; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1366px) {
  .flex-xl-first {
    order: -1; }
  .flex-xl-last {
    order: 1; }
  .flex-xl-unordered {
    order: 0; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.invisible {
  visibility: hidden !important; }

.hidden-xxs-up {
  display: none !important; }

@media (max-width: 599px) {
  .hidden-xxs-down {
    display: none !important; } }

@media (min-width: 600px) {
  .hidden-xs-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-xs-down {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-sm-up {
    display: none !important; } }

@media (max-width: 1023px) {
  .hidden-sm-down {
    display: none !important; } }

@media (min-width: 1024px) {
  .hidden-md-up {
    display: none !important; } }

@media (max-width: 1279px) {
  .hidden-md-down {
    display: none !important; } }

@media (min-width: 1280px) {
  .hidden-lg-up {
    display: none !important; } }

@media (max-width: 1365px) {
  .hidden-lg-down {
    display: none !important; } }

@media (min-width: 1366px) {
  .hidden-xl-up {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.visible-print-block {
  display: none !important; }
  @media print {
    .visible-print-block {
      display: block !important; } }

.visible-print-inline {
  display: none !important; }
  @media print {
    .visible-print-inline {
      display: inline !important; } }

.visible-print-inline-block {
  display: none !important; }
  @media print {
    .visible-print-inline-block {
      display: inline-block !important; } }

@media print {
  .hidden-print {
    display: none !important; } }

@font-face {
  font-family: 'Lato';
  font-display: swap;
  text-rendering: optimizeLegibility;
  src: url("../node_modules/@sgx/sgx-base-code//fonts/lato-light.woff2") format("woff2"), url("../node_modules/@sgx/sgx-base-code//fonts/lato-light.woff") format("woff");
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'Lato';
  font-display: swap;
  text-rendering: optimizeLegibility;
  src: url("../node_modules/@sgx/sgx-base-code//fonts/lato-normal.woff2") format("woff2"), url("../node_modules/@sgx/sgx-base-code//fonts/lato-normal.woff") format("woff");
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Lato';
  font-display: swap;
  text-rendering: optimizeLegibility;
  src: url("../node_modules/@sgx/sgx-base-code//fonts/lato-normal-italic.woff2") format("woff2"), url("../node_modules/@sgx/sgx-base-code//fonts/lato-normal-italic.woff") format("woff");
  font-style: italic;
  font-weight: normal; }

@font-face {
  font-family: 'Lato';
  font-display: swap;
  text-rendering: optimizeLegibility;
  src: url("../node_modules/@sgx/sgx-base-code//fonts/lato-semibold.woff2") format("woff2"), url("../node_modules/@sgx/sgx-base-code//fonts/lato-semibold.woff") format("woff");
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: 'Lato';
  font-display: swap;
  text-rendering: optimizeLegibility;
  src: url("../node_modules/@sgx/sgx-base-code//fonts/lato-bold.woff2") format("woff2"), url("../node_modules/@sgx/sgx-base-code//fonts/lato-bold.woff") format("woff");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../node_modules/@sgx/sgx-base-code//fonts/fontawesome-webfont.woff2") format("woff2"), url("../node_modules/@sgx/sgx-base-code//fonts/fontawesome-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

html {
  font-family: "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #5f6062; }

body {
  font-size: 16px; }

button {
  font-family: inherit; }

p.sgx-base-text-leading {
  font-size: 16px; }
  @media (min-width: 768px) {
    p.sgx-base-text-leading {
      font-size: 18px; } }
  @media (min-width: 1280px) {
    p.sgx-base-text-leading {
      font-size: 22px; } }

/* Typography v1 (Deprecated) */
.sgx-base-text-h1 {
  font-size: 24px; }
  @media (min-width: 768px) {
    .sgx-base-text-h1 {
      font-size: 30px; } }
  @media (min-width: 1280px) {
    .sgx-base-text-h1 {
      font-size: 36px; } }

.sgx-base-text-h2 {
  font-size: 22px; }
  @media (min-width: 768px) {
    .sgx-base-text-h2 {
      font-size: 24px; } }
  @media (min-width: 1280px) {
    .sgx-base-text-h2 {
      font-size: 30px; } }

.sgx-base-text-h3 {
  font-size: 20px; }
  @media (min-width: 768px) {
    .sgx-base-text-h3 {
      font-size: 22px; } }
  @media (min-width: 1280px) {
    .sgx-base-text-h3 {
      font-size: 24px; } }

.sgx-base-text-h4 {
  font-size: 14px; }
  @media (min-width: 768px) {
    .sgx-base-text-h4 {
      font-size: 16px; } }
  @media (min-width: 1280px) {
    .sgx-base-text-h4 {
      font-size: 18px; } }

.sgx-base-text-h5 {
  font-size: 12px; }
  @media (min-width: 768px) {
    .sgx-base-text-h5 {
      font-size: 14px; } }
  @media (min-width: 1280px) {
    .sgx-base-text-h5 {
      font-size: 16px; } }

.sgx-base-text-leading {
  font-size: 16px; }
  @media (min-width: 768px) {
    .sgx-base-text-leading {
      font-size: 18px; } }
  @media (min-width: 1280px) {
    .sgx-base-text-leading {
      font-size: 22px; } }

.sgx-base-text-body {
  font-size: 16px; }

.sgx-base-text-small, .sgx-content-table {
  font-size: 12px; }
  @media (min-width: 1280px) {
    .sgx-base-text-small, .sgx-content-table {
      font-size: 14px; } }

.sgx-base-text-caption, .sgx-content-table caption {
  font-size: 11px; }
  @media (min-width: 1280px) {
    .sgx-base-text-caption, .sgx-content-table caption {
      font-size: 12px; } }

.sgx-base-text-h1 {
  font-weight: 600;
  line-height: 1.2;
  color: #0B236B; }

.sgx-base-text-h2 {
  line-height: 1.2;
  color: #0B236B; }

.sgx-base-text-h3 {
  font-weight: 300;
  line-height: 1.25;
  color: #5f6062; }

.sgx-base-text-h4 {
  font-weight: 600;
  line-height: 1.3;
  color: #5f6062; }

.sgx-base-text-h5 {
  font-weight: 700;
  line-height: 1.35;
  color: #5f6062; }

.sgx-base-text-leading {
  font-weight: 300;
  line-height: 1.35;
  color: #5f6062; }

.sgx-base-text-body {
  line-height: 1.35; }

.sgx-base-text-small, .sgx-content-table {
  line-height: 1.4; }

.sgx-base-text-caption, .sgx-content-table caption {
  font-weight: 400;
  line-height: 1.3;
  color: #5f6062; }

.sgx-base-text-strong {
  font-weight: 700; }

/* Typography V2 */
.sgx-base-text-title-11 {
  font-size: 11px;
  font-weight: 600;
  line-height: 1.25; }

.sgx-base-text-title-12 {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25; }

.sgx-base-text-title-14 {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.25; }

.sgx-base-text-title-16, .sgx-widget-platts-subscription-form-wrapper .sgx-section h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25; }

.sgx-base-text-title-18 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25; }

.sgx-base-text-title-20, .sgx-platts-tnc-page-header h2.sgx-platts-tnc-page-title, .sgx-user-blotter-config-dialog--error .sgx-alert-dialog-content .sgx-user-blotter-confirmation-content i:before, .sgx-message-dialog .sgx-alert-dialog-content h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25; }

.sgx-base-text-title-24, .sgx-message-dialog h2.sgx-alert-dialog-title, .sgx-message-dialog .sgx-alert-dialog-content h2 i:before {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.25; }

.sgx-base-text-title-28 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.25; }

.sgx-base-text-body-12, sgx-tabs[data-type="state"], .sgx-state-tabs-navigation-dialogue,
.sgx-state-tabs-action-dialogue, sgx-platts-market-symbol-data-table .sgx-market-symbol-data-table-footer .sgx-input-hint, .sgx-platts-historical-data-settings-container .sgx-platts-section-spread-selection .footer-note {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5; }

.sgx-base-text-body-14, .sgx-content-tabs-navigation-dialogue,
.sgx-content-tabs-action-dialogue, .sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder p, .sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder ol li, .sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder ol li p, sgx-platts-copyrights .sgx-platts-page-copyrights-footer .sgx-button-copyright, sgx-widget-platts-historical sgx-tabs .sgx-tabs-scroll-wrapper ul.sgx-tabs-container li.sgx-tab {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5; }

.sgx-base-text-body-16, .sgx-page-tabs-navigation-dialogue,
.sgx-page-tabs-action-dialogue {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5; }

.sgx-base-text-body-20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5; }

.sgx-base-text-body-italics-12 {
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.5; }

.sgx-base-text-body-italics-14 {
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.5; }

.sgx-base-text-body-italics-16 {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.5; }

.sgx-base-text-body-bold-12 {
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5; }

.sgx-base-text-body-bold-14 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5; }

.sgx-base-text-body-bold-16 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5; }

.sgx-base-text-label-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5; }

.sgx-base-text-label-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5; }

.sgx-base-text-helper-10 {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5; }

.sgx-base-text-helper-12, sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer a, sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer a:not(:first-child):after {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5; }

.sgx-base-text-helper-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5; }

.sgx-base-text-helper-semibold-10 {
  font-size: 10px;
  font-weight: 600;
  line-height: 1.5; }

.sgx-base-text-title-11 {
  font-weight: 700; }

.sgx-base-text-title-12 {
  font-weight: 700; }

.sgx-base-text-h1--with-carrier {
  padding-left: 45px; }
  .sgx-base-text-h1--with-carrier:before {
    content: '';
    position: absolute;
    background: #BCD730;
    margin-top: 5px;
    width: 18px;
    height: 18px;
    box-shadow: 7px 5px 0 #091e5b;
    margin-left: -45px; }
    @media (max-width: 1279px) {
      .sgx-base-text-h1--with-carrier:before {
        width: 15px;
        height: 15px;
        box-shadow: 6px 4px 0 #091e5b;
        margin-left: -36px; } }
    @media (max-width: 767px) {
      .sgx-base-text-h1--with-carrier:before {
        width: 12px;
        height: 12px;
        box-shadow: 5px 3px 0 #091e5b;
        margin-left: -32px; } }
  @media (max-width: 1279px) {
    .sgx-base-text-h1--with-carrier {
      padding-left: 36px; } }
  @media (max-width: 767px) {
    .sgx-base-text-h1--with-carrier {
      padding-left: 32px; } }

a {
  color: #0077CC; }
  a:hover {
    text-decoration: none; }

.sgx-base-link--alt {
  color: #0B236B;
  text-decoration: none; }
  .sgx-base-link--alt:hover {
    color: #0077CC;
    text-decoration: underline; }

.sgx-base-link--block {
  display: block; }

.sgx-base-tag {
  display: inline-block;
  color: #FFFFFF;
  background: #0B236B;
  text-decoration: none;
  padding: 0.3rem 0.7rem;
  border-radius: 1rem;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 11px; }
  .sgx-base-tag:hover {
    opacity: 1; }
  @media (min-width: 1280px) {
    .sgx-base-tag {
      font-size: 12px; } }

.sgx-base-list {
  line-height: 1.8;
  list-style: none; }
  .sgx-base-list ul, .sgx-base-list ol {
    padding-left: 10px; }

ul.sgx-base-list > li {
  position: relative;
  padding-left: 25px;
  min-height: 28px; }

ul.sgx-base-list > li:before {
  content: '\25a0';
  font-size: 1rem;
  display: block;
  position: absolute;
  line-height: 1;
  top: 0;
  left: 0; }
  @media (min-width: 768px) {
    ul.sgx-base-list > li:before {
      top: 0.1rem; } }
  @media (min-width: 1280px) {
    ul.sgx-base-list > li:before {
      top: 0.25rem; } }

ul.sgx-base-list ul > li {
  padding-left: 20px;
  min-height: 0px; }

ul.sgx-base-list ul > li:before {
  content: '\25ac';
  font-size: 0.5rem;
  top: 0.4rem; }
  @media (min-width: 768px) {
    ul.sgx-base-list ul > li:before {
      top: 0.5rem; } }
  @media (min-width: 1280px) {
    ul.sgx-base-list ul > li:before {
      top: 0.6rem; } }

ol.sgx-base-list {
  counter-reset: list;
  list-style-type: none; }
  ol.sgx-base-list > li {
    position: relative;
    padding-left: 25px;
    min-height: 28px; }
  ol.sgx-base-list > li:before {
    counter-increment: list;
    content: counter(list) ".";
    display: block;
    position: absolute;
    top: 0;
    left: 0; }
  ol.sgx-base-list ol {
    counter-reset: sublist;
    position: relative;
    padding-left: 25px;
    min-height: 28px; }
  ol.sgx-base-list ol > li {
    padding-left: 20px;
    min-height: 0px; }
  ol.sgx-base-list ol > li:before {
    counter-increment: sublist;
    content: counter(sublist, lower-alpha) ".";
    padding-right: 8px; }

ul.sgx-base-list-col-two,
ol.sgx-base-list-col-two {
  columns: 2; }

ul.sgx-base-list-col-three,
ol.sgx-base-list-col-three {
  columns: 3; }

@media (max-width: 599px) {
  ul.sgx-base-list-col-two,
  ol.sgx-base-list-col-two,
  ul.sgx-base-list-col-three,
  ol.sgx-base-list-col-three {
    columns: 1; } }

.sgx-icon, .lm_controls .lm_popout, .lm_controls .lm_maximise, .lm_controls .lm_close, .lm_maximised .lm_controls .lm_maximise, .sgx-pagination-prev:before, .sgx-pagination-next:after,
.sgx-icon--after:after,
sgx-accordion.sgx-accordion--minimal .sgx-accordion-header:after,
.sgx-icon--before:before,
.lm_header .lm_tab .lm_close_tab:before {
  font-family: icon-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  text-rendering: auto;
  line-height: 1;
  vertical-align: -.125em; }

[data-sgx-badge] {
  position: relative; }
  [data-sgx-badge][data-sgx-badge--before]:before, [data-sgx-badge][data-sgx-badge--after]:after {
    content: attr(data-sgx-badge);
    position: absolute;
    top: -4px;
    right: -4px;
    width: 13px;
    height: 13px;
    line-height: 13px;
    font-family: Lato;
    font-size: 10px;
    font-weight: normal;
    border-radius: 50%;
    background: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    -webkit-font-smoothing: auto; }

@font-face {
  font-family: 'fontTotc';
  src: url("../static/fonts/fontotc.woff2") format("woff2"), url("../static/fonts/fontotc.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: fontTotc, fontAwesome;
  font-size: 200%;
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  line-height: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased; }

.icon-totc-alert:before {
  content: '\e802'; }

.icon-totc-search:before {
  content: '\f81a'; }

.icon-totc-coking-coal:before {
  content: '\e82e' !important; }

.icon-totc-cvf:before {
  content: '\e830' !important; }

.icon-totc-cwf:before {
  content: '\e80d' !important; }

.icon-totc-iron-ore:before {
  content: '\e824' !important; }

.icon-totc-m42f:before {
  content: "\f06d" !important; }

.icon-totc-buy:before {
  content: '\e820'; }

.icon-totc-cancel-trade:before {
  content: '\e80b'; }

.icon-totc-cancel:before {
  content: '\e80b'; }

.icon-totc-customise:before {
  content: '\F013'; }

.icon-totc-add:before {
  content: '\e80b';
  transform: rotateZ(45deg); }

.icon-totc-clone:before {
  content: '\e831'; }

.icon-totc-configure:before {
  content: '\e82d'; }

.icon-totc-confirm:before {
  content: '\f80e'; }

.icon-totc-copy:before {
  content: '\f80f'; }

.icon-totc-customize:before {
  content: '\e834'; }

.icon-totc-download:before {
  content: '\e823'; }

.icon-totc-edit:before {
  content: '\f811'; }

.icon-totc-filters:before {
  content: '\f812'; }

.icon-totc-history:before {
  content: '\e832'; }

.icon-totc-options:before {
  content: '\e81b'; }

.icon-totc-price-down:before {
  content: '\e999'; }

.icon-totc-price-up:before {
  content: '\f816'; }

.icon-totc-resume-trade:before {
  content: '\f818'; }

.icon-totc-route:before {
  content: '\f819'; }

.icon-totc-sell:before {
  content: '\e822'; }

.icon-totc-suspend-trade:before {
  content: '\f81c'; }

.icon-totc-system:before {
  content: '\e821'; }

.icon-totc-view-deal:before {
  content: '\e81e'; }

.icon-totc-chevron-down:before {
  content: '\e826'; }

/* '' */
.icon-totc-chevron-right:before {
  content: '\e827'; }

/* '' */
.icon-totc-chevron-left:before {
  content: '\e828'; }

/* '' */
.icon-totc-chevron-up:before {
  content: '\e829'; }

/* '' */
.action-menu-item-aggress:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/handshake-alt.svg"); }

.action-menu-item-copy-details:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/copy.svg"); }

.action-menu-item-history:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/history.svg"); }

.action-menu-item-edit:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/pencil-alt.svg"); }

.action-menu-item-user-edit:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/user-edit.svg"); }

.action-menu-item-reject:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/times.svg"); }

.action-menu-item-accept:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/check.svg"); }

.action-menu-item-resume:before, .action-menu-item-start:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/play.svg"); }

.action-menu-item-delete:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/trash-alt.svg"); }

.action-menu-item-cancel:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/times.svg"); }

.action-menu-item-suspend:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/pause.svg"); }

.action-menu-item-clone:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/clone.svg"); }

.action-menu-item-view-deal:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/eye.svg"); }

.action-menu-item-route:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/route.svg"); }

.action-menu-item-confirm-reject:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/check-square.svg"); }

.action-menu-item-clipboard-check:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/clipboard-check.svg"); }

.action-menu-item-match:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/handshake-alt.svg"); }

.action-menu-item-resend-link:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/reply.svg"); }

.action-menu-item-activate:before {
  font-size: 14px;
  icon-font: url("sgx-base-code/svgs/regular/caret-circle-right.svg"); }

/** sgx-base-code based common icons **/
.sgx-icon--spinner-third:after {
  vertical-align: 0;
  icon-font: url("@sgx/sgx-base-code/svgs/regular/spinner-third.svg"); }

.sgx-icon--spinner-first:after {
  vertical-align: 0;
  icon-font: url("@sgx/sgx-base-code/svgs/regular/spinner.svg"); }

.sgx-icon--spinner {
  animation: spin 0.6s infinite linear; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

sgx-accordion {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }
  @media (min-width: 600px) {
    sgx-accordion[direction="vertical"] {
      position: relative;
      height: 300px;
      min-width: 645px;
      border: none; }
      sgx-accordion[direction="vertical"] .sgx-accordion-header {
        width: 28%;
        box-shadow: inset -1px 0 0 0 #b5bdd2;
        position: relative;
        background: transparent; }
        sgx-accordion[direction="vertical"] .sgx-accordion-header:after {
          content: '';
          position: absolute;
          top: 0px;
          right: 0px;
          height: 100%;
          width: 6px;
          background: #BDD831;
          transition: transform 0.3s;
          transform: translate3d(150%, 0, 0); }
      sgx-accordion[direction="vertical"] .sgx-accordion-item--open {
        flex: 0 1 auto; }
        sgx-accordion[direction="vertical"] .sgx-accordion-item--open .sgx-accordion-header {
          z-index: 1;
          background: #f7f7f7;
          position: relative; }
          sgx-accordion[direction="vertical"] .sgx-accordion-item--open .sgx-accordion-header:after {
            content: '';
            transform: translate3d(0, 0, 0); }
      sgx-accordion[direction="vertical"] .sgx-accordion-item {
        border-top: none; }
      sgx-accordion[direction="vertical"] .sgx-accordion-seperator {
        width: 23%; }
      sgx-accordion[direction="vertical"] .sgx-accordion-container {
        position: absolute;
        padding: 20px;
        border-left: 1px solid #b5bdd2;
        height: 100%;
        width: 72%;
        top: 0px;
        right: 0px;
        margin-right: 1px;
        z-index: 2; } }
  sgx-accordion .sgx-accordion-item {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    border-top: none;
    border-bottom: solid 3px transparent; }
  sgx-accordion .sgx-accordion-item--open {
    flex: 1 1 auto; }
  sgx-accordion .sgx-accordion-collapser {
    cursor: pointer; }
  sgx-accordion .sgx-accordion-header {
    width: 100%;
    padding: 0.5rem 1rem;
    background: #f7f7f7; }
  sgx-accordion .sgx-accordion-header:after {
    content: "\f107";
    font-family: fontAwesome;
    font-size: 1rem;
    float: right;
    color: #628AAE; }
  sgx-accordion .sgx-accordion-item--open .sgx-accordion-header:after {
    content: "\f106";
    color: #B6B6B6; }
  sgx-accordion .sgx-accordion-item--open .sgx-accordion-header {
    font-weight: bold;
    background: #f7f7f7; }
  sgx-accordion .sgx-accordion-container {
    display: none;
    overflow: hidden;
    background: #ffffff;
    padding: 20px; }
  sgx-accordion .sgx-accordion-item--open .sgx-accordion-container {
    display: block;
    overflow: auto; }
  sgx-accordion .sgx-accordion-expandAll {
    display: none;
    align-self: flex-end;
    margin: 0 16px 20px 0;
    text-align: right;
    font-size: 16px; }
    @media (max-width: 1279px) {
      sgx-accordion .sgx-accordion-expandAll {
        font-size: 14px;
        margin-bottom: 15px; } }
    @media (max-width: 767px) {
      sgx-accordion .sgx-accordion-expandAll {
        font-size: 12px; } }
  sgx-accordion[data-expand-all] .sgx-accordion-expandAll {
    display: block; }
  sgx-accordion .sgx-accordion-expandAll-btn {
    margin: 0;
    color: #1a5488;
    background: transparent;
    border: 0;
    text-decoration: underline;
    user-select: none;
    cursor: pointer; }
    sgx-accordion .sgx-accordion-expandAll-btn:hover, sgx-accordion .sgx-accordion-expandAll-btn:active {
      text-decoration: none; }
  sgx-accordion .sgx-accordion-expandAll-icon {
    font-family: fontAwesome;
    font-size: 1rem;
    line-height: 1rem;
    text-decoration: none;
    margin-left: 10px;
    color: #628AAE;
    cursor: pointer; }
    sgx-accordion .sgx-accordion-expandAll-icon:before {
      content: '\f107'; }
  sgx-accordion .sgx-accordion-expandAll--collapse .sgx-accordion-expandAll-icon:before {
    content: '\f106'; }

/* Absolute Centre Loader with Overlay */
sgx-loader {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0.8;
  background: white;
  color: #6f7072;
  /* Spinner */ }
  sgx-loader:before {
    margin-bottom: 4px;
    icon-font: url(sgx-base-code/svgs/regular/spinner.svg);
    display: block;
    animation: spinner 1500ms infinite cubic-bezier(0.2, 0.5, 0.7, 0.5); }
  sgx-loader.sgx-loader--medium {
    font-size: 30px; }
  sgx-loader.sgx-loader--large {
    font-size: 40px; }
  sgx-loader.sgx-loader--hidden {
    display: none; }

/* Animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

sgx-dialog {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: visible; }
  sgx-dialog.is-visible {
    display: block; }
  sgx-dialog.sgx-dialog--centered {
    position: static; }

.sgx-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.9); }
  body[data-type=mobile] .sgx-dialog-overlay {
    background: white; }
  .sgx-dialog--centered .sgx-dialog-overlay {
    animation: fadeIn 0.2s ease-out; }

.sgx-dialog-box {
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: 0 8px 15px 0px rgba(34, 34, 34, 0.4);
  z-index: 1001;
  max-height: 85vh;
  animation: fadeIn 0.2s ease-out;
  padding: 20px 12px 12px; }
  @media (min-width: 1024px) {
    .sgx-dialog-box {
      padding: 32px 20px 20px; } }
  .sgx-dialog--centered .sgx-dialog-box {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
    border: 0;
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px); }
    @media (min-width: 600px) {
      .sgx-dialog--centered .sgx-dialog-box {
        min-width: calc((100vw * 0.5) - 20px);
        max-width: calc(100vw - 20px * 2); } }
    @media (min-width: 768px) {
      .sgx-dialog--centered .sgx-dialog-box {
        min-width: calc((100vw * 0.5) - 40px);
        max-width: calc(100vw - 40px * 2); } }
    @media (min-width: 1024px) {
      .sgx-dialog--centered .sgx-dialog-box {
        min-width: calc((100vw * 0.33333) - 50px);
        max-width: calc(100vw - 50px * 2); } }
    @media (min-width: 1280px) {
      .sgx-dialog--centered .sgx-dialog-box {
        min-width: calc((100vw * 0.33333) - 65px);
        max-width: calc(100vw - 65px * 2); } }
    @media (min-width: 1366px) {
      .sgx-dialog--centered .sgx-dialog-box {
        min-width: calc((1366px * 0.33333) - 65px);
        max-width: calc(1366px - 65px * 2); } }
  .sgx-dialog--fullscreen .sgx-dialog-box {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    width: 100% !important;
    height: 100% !important;
    max-height: none !important; }
  .sgx-dialog--attached .sgx-dialog-box {
    padding: 0;
    background: #f7f7f7; }
  .sgx-dialog-box.tether-target-attached-bottom {
    box-shadow: 0px 4px 8px -2px rgba(34, 34, 34, 0.4); }
  .sgx-dialog-box.tether-target-attached-top {
    top: -3px !important;
    box-shadow: 0px -4px 8px -2px rgba(34, 34, 34, 0.4); }

.sgx-dialog-header {
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  font-size: 20px;
  color: #1d1d1d;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 1024px) {
    .sgx-dialog-header {
      margin-bottom: 32px; } }
  .sgx-dialog-header > [data-dialog=title] {
    font-size: inherit;
    margin: 0; }

.sgx-dialog-body {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.sgx-dialog-footer {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row-reverse;
  margin-top: 12px; }
  @media (min-width: 1024px) {
    .sgx-dialog-footer {
      margin-top: 20px; } }
  .sgx-dialog-footer > * {
    margin-left: 12px; }

[class*=sgx-button] {
  display: inline-block;
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  transition: background-color 0.2s ease-out, color 0.2s ease-out, box-shadow 0.2s ease-out;
  padding: 0 20px;
  font-size: 16px;
  line-height: 36px;
  min-width: 64px; }
  [class*=sgx-button].sgx-icon--before:before, .lm_header .lm_tab .lm_close_tab[class*=sgx-button]:before {
    margin-right: 4px; }
  [class*=sgx-button].sgx-icon--after:after, sgx-accordion.sgx-accordion--minimal .sgx-accordion-header[class*=sgx-button]:after {
    margin-left: 4px; }
  [class*=sgx-button].sgx-icon, .lm_controls .lm_popout[class*=sgx-button], .lm_controls .lm_maximise[class*=sgx-button], .lm_controls .lm_close[class*=sgx-button], .sgx-pagination-prev[class*=sgx-button]:before, .sgx-pagination-next[class*=sgx-button]:after {
    vertical-align: middle; }

.sgx-button,
.sgx-button--secondary {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.34);
  color: #0094B3; }
  .sgx-button:focus,
  .sgx-button--secondary:focus {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button:hover,
  .sgx-button--secondary:hover {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button:active,
  .sgx-button--secondary:active {
    background-color: #006f86;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button.sgx-button--selected,
  .sgx-button--secondary.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button, .sgx-button.disabled, .sgx-button:disabled, .sgx-button[disabled], fieldset[disabled]
  .sgx-button--secondary,
  .sgx-button--secondary.disabled,
  .sgx-button--secondary:disabled,
  .sgx-button--secondary[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2) !important;
    outline: 0; }

.sgx-button--primary {
  background-color: #0094B3;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.87); }
  .sgx-button--primary:focus {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--primary:hover {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--primary:active {
    background-color: #006f86;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--primary.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--primary, .sgx-button--primary.disabled, .sgx-button--primary:disabled, .sgx-button--primary[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(0, 0, 0, 0.09) !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--link,
.sgx-button--text {
  background-color: transparent;
  box-shadow: none;
  color: #0094B3; }
  .sgx-button--link:focus,
  .sgx-button--text:focus {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--link:hover,
  .sgx-button--text:hover {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--link:active,
  .sgx-button--text:active {
    background-color: #006f86;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--link.sgx-button--selected,
  .sgx-button--text.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--link, .sgx-button--link.disabled, .sgx-button--link:disabled, .sgx-button--link[disabled], fieldset[disabled]
  .sgx-button--text,
  .sgx-button--text.disabled,
  .sgx-button--text:disabled,
  .sgx-button--text[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--danger {
  background-color: #e60000;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.87); }
  .sgx-button--danger:focus {
    background-color: #ec4040;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--danger:hover {
    background-color: #f38080;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--danger:active {
    background-color: #ad0000;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--danger.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--danger, .sgx-button--danger.disabled, .sgx-button--danger:disabled, .sgx-button--danger[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(0, 0, 0, 0.09) !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--success {
  background-color: #74AC00;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.87); }
  .sgx-button--success:focus {
    background-color: #97c140;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--success:hover {
    background-color: #bad680;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--success:active {
    background-color: #578100;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--success.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--success, .sgx-button--success.disabled, .sgx-button--success:disabled, .sgx-button--success[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(0, 0, 0, 0.09) !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--low-emp,
.sgx-button--low-toggle {
  border-radius: 2px;
  padding: 0 8px;
  font-size: 14px;
  line-height: 24px;
  min-width: 56px; }
  .sgx-button--low-emp.sgx-icon, .lm_controls .sgx-button--low-emp.lm_popout, .lm_controls .sgx-button--low-emp.lm_maximise, .lm_controls .sgx-button--low-emp.lm_close, .sgx-button--low-emp.sgx-pagination-prev:before, .sgx-button--low-emp.sgx-pagination-next:after,
  .sgx-button--low-toggle.sgx-icon,
  .lm_controls .sgx-button--low-toggle.lm_popout,
  .lm_controls .sgx-button--low-toggle.lm_maximise,
  .lm_controls .sgx-button--low-toggle.lm_close,
  .sgx-button--low-toggle.sgx-pagination-prev:before,
  .sgx-button--low-toggle.sgx-pagination-next:after {
    padding: 0;
    min-width: 24px;
    line-height: 24px; }

.sgx-button--low-toggle {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.6); }
  .sgx-button--low-toggle:focus {
    background-color: #d9eff4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-toggle:hover {
    background-color: #d9eff4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-toggle:active {
    background-color: #a6dae4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-toggle.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--low-toggle, .sgx-button--low-toggle.disabled, .sgx-button--low-toggle:disabled, .sgx-button--low-toggle[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2) !important;
    outline: 0; }

.sgx-button--low-emp {
  background-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.6); }
  .sgx-button--low-emp:focus {
    background-color: #d9eff4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-emp:hover {
    background-color: #d9eff4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-emp:active {
    background-color: #a6dae4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-emp.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--low-emp, .sgx-button--low-emp.disabled, .sgx-button--low-emp:disabled, .sgx-button--low-emp[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--lower-emp, .sgx-tooltip-button {
  background-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.34);
  padding: 0 8px;
  font-size: 14px;
  line-height: 16px;
  min-width: 16px; }
  .sgx-button--lower-emp:focus, .sgx-tooltip-button:focus {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.6);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--lower-emp:hover, .sgx-tooltip-button:hover {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.6);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--lower-emp:active, .sgx-tooltip-button:active {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--lower-emp.sgx-button--selected, .sgx-button--selected.sgx-tooltip-button {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--lower-emp, fieldset[disabled] .sgx-tooltip-button, .sgx-button--lower-emp.disabled, .disabled.sgx-tooltip-button, .sgx-button--lower-emp:disabled, .sgx-tooltip-button:disabled, .sgx-button--lower-emp[disabled], .sgx-tooltip-button[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.09) !important;
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--small {
  padding: 0 12px;
  font-size: 14px;
  line-height: 24px;
  min-width: 56px; }

.sgx-button--large {
  padding: 0 28px;
  font-size: 18px;
  line-height: 44px;
  min-width: 80px; }

.sgx-button--block {
  display: block;
  min-width: auto; }

.sgx-input {
  display: inline-block;
  vertical-align: top;
  font-family: "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative; }

.sgx-input-label {
  display: block;
  margin-bottom: 4px;
  font: normal 14px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #6f7072; }
  .sgx-input:not([hint=""]) .sgx-input-label {
    padding-right: 22px; }
  .sgx-input[hint-type="inline"] .sgx-input-label {
    padding-right: 0; }
  .sgx-input--has-error .sgx-input-label {
    color: #e60000; }
  .sgx-input[readonly] .sgx-input-label {
    margin-bottom: 4px; }

.sgx-input-control {
  width: 100%;
  height: 36px;
  text-align: left;
  font: normal 16px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 8px 12px;
  outline: 0;
  border: 0;
  border-bottom: 1px solid;
  text-overflow: ellipsis;
  border-radius: 0;
  -webkit-appearance: none;
  color: #1d1d1d;
  background: #f7f7f7;
  border-color: #9fa0a1; }
  .sgx-input-control::-ms-clear {
    display: none; }
  .sgx-input-control:hover {
    border-color: #80cad9; }
  .sgx-input--has-error .sgx-input-control {
    border-color: #e60000; }
  .sgx-input-control:focus, .sgx-input-control:active, .sgx-input-control.is-active {
    border-color: #0094b3; }
  fieldset[disabled] .sgx-input-control, .sgx-input-control:disabled, .sgx-input-control [disabled] {
    border-color: transparent !important;
    color: #c7c7c8 !important;
    cursor: not-allowed; }
  .sgx-input[readonly] .sgx-input-control {
    border-color: transparent;
    background: 0;
    padding: 0;
    height: auto;
    cursor: default; }
  .sgx-input--no-border .sgx-input-control {
    cursor: pointer;
    text-align: right; }
    .sgx-input--no-border .sgx-input-control:not(:focus) {
      background-color: transparent;
      border-color: transparent; }

.sgx-input-message {
  display: none;
  margin-top: 4px;
  font: normal 14px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #e60000; }
  .sgx-input--has-error .sgx-input-message {
    display: block; }

.sgx-input-hint-icon {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  cursor: help;
  padding-left: 8px;
  font-size: 14px;
  color: #6f7072; }
  .sgx-input-hint-icon:after {
    icon-font: url(sgx-base-code/svgs/solid/question-circle.svg); }
  .sgx-input-hint-icon:hover {
    z-index: 100; }
  .sgx-input[readonly] .sgx-input-hint-icon,
  .sgx-input[hint-type=inline] .sgx-input-hint-icon {
    display: none; }

.sgx-input-hint {
  position: absolute;
  z-index: 10;
  right: 0;
  top: -8px;
  transform: translateY(-100%);
  padding: 8px;
  font: normal 14px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #f7f7f7;
  border-radius: 2px;
  background: #212222;
  color: #f7f7f7;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25);
  display: block;
  visibility: hidden; }
  .sgx-input-hint:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #212222;
    position: absolute;
    right: 1px;
    top: 100%; }
  .sgx-input[readonly] .sgx-input-hint {
    display: none; }
  .sgx-input-hint-icon:hover ~ .sgx-input-hint, .sgx-input-hint:hover {
    visibility: visible; }
  .sgx-input[hint-type="inline"] .sgx-input-hint {
    position: static;
    visibility: visible;
    margin-top: 4px;
    padding: 0;
    transform: none;
    font: normal 14px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #6f7072;
    background: 0;
    box-shadow: none; }
    .sgx-input[hint-type="inline"] .sgx-input-hint:after {
      display: none; }

sgx-input-number .sgx-input-control {
  padding-right: 32px;
  /* autoprefixer: off */
  -moz-appearance: textfield;
  appearance: textfield; }
  sgx-input-number .sgx-input-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.sgx-input-number-wrapper {
  position: relative;
  display: block; }

.sgx-input-number-up,
.sgx-input-number-down {
  position: absolute;
  right: 0;
  height: 50%;
  padding: 0 12px;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: #1d1d1d; }
  .sgx-input-number-up:hover,
  .sgx-input-number-down:hover {
    color: #80cad9; }
  .sgx-input-number-up:active,
  .sgx-input-number-down:active {
    color: #0094b3; }
  :disabled ~ .sgx-input-number-up, :disabled ~
  .sgx-input-number-down {
    color: #c7c7c8 !important;
    pointer-events: none; }
  .sgx-input[readonly] .sgx-input-number-up, .sgx-input[readonly]
  .sgx-input-number-down {
    display: none; }

.sgx-input-number-up {
  top: 0;
  padding-top: 4px; }
  .sgx-input-number-up:after {
    icon-font: url(sgx-base-code/svgs/solid/sort-up.svg); }

.sgx-input-number-down {
  bottom: 0;
  padding-bottom: 4px; }
  .sgx-input-number-down:after {
    icon-font: url(sgx-base-code/svgs/solid/sort-down.svg); }

sgx-select-picker {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  height: 100%; }

.sgx-select-picker-list {
  flex: 1 1 auto;
  background: 0;
  border: 0; }

.sgx-select-picker-select-all {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  height: 36px;
  border-bottom: 1px solid #c7c7c8; }
  .sgx-select-picker-select-all .sgx-input-label.sgx-input-checkbox-label {
    font-size: 14px;
    font-weight: bold;
    padding-right: 0;
    margin-bottom: 0; }
  .sgx-select-picker-select-all .sgx-input-checkbox-label:before {
    font-size: 16px; }

body:not([data-type=mobile]) #sgx-select-dialog .sgx-dialog-box,
body:not([data-type=tablet]) #sgx-select-dialog .sgx-dialog-box,
#sgx-select-dialog.sgx-select-dialog--autocomplete .sgx-dialog-box {
  max-height: 40vh; }
  body:not([data-type=mobile]) #sgx-select-dialog .sgx-dialog-box.tether-target-attached-top,
  body:not([data-type=tablet]) #sgx-select-dialog .sgx-dialog-box.tether-target-attached-top,
  #sgx-select-dialog.sgx-select-dialog--autocomplete .sgx-dialog-box.tether-target-attached-top {
    top: -3px !important; }

#sgx-select-dialog.sgx-select-dialog--autocomplete.sgx-forms-dialogs--keyboard-open .sgx-dialog-box {
  max-height: 60vh; }

#sgx-select-dialog sgx-select-picker.sgx-dialog-body {
  height: auto; }

.sgx-select-picker-option {
  position: relative;
  display: block;
  border-bottom: 1px solid #e7e7e7; }
  .sgx-select-picker-option input {
    opacity: 0 !important;
    position: absolute !important; }

.sgx-select-picker-group-label {
  display: block;
  height: 100%;
  padding: 12px 12px;
  color: #1d1d1d;
  background: transparent; }
  input:disabled ~ .sgx-select-picker-group-label {
    color: #c7c7c8 !important;
    background: transparent !important; }

.sgx-select-picker-label {
  display: block;
  height: 100%;
  padding: 12px 12px;
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #1d1d1d;
  background: transparent; }
  .sgx-select-picker-label.sgx-select-picker-group-item {
    padding-left: 24px; }
  .sgx-select-picker-handle + .sgx-select-picker-label {
    padding-right: 40px; }
  body[data-type=desktop] .sgx-select-picker-option--highlighted .sgx-select-picker-label {
    color: #1d1d1d;
    background-color: #80cad9; }
  input:disabled ~ .sgx-select-picker-label {
    cursor: default !important;
    color: #c7c7c8 !important;
    background: transparent !important; }
  body[data-type=desktop] [type=radio] ~ .sgx-select-picker-label:active:not(:disabled) {
    color: #f7f7f7 !important;
    background: #006f86 !important; }
  [type=radio]:checked ~ .sgx-select-picker-label,
  .sgx-select-picker-option--highlighted [type=radio]:checked ~ .sgx-select-picker-label {
    color: #f7f7f7;
    background: #0094b3; }
  [type=checkbox] ~ .sgx-select-picker-label:before {
    icon-font: url(sgx-base-code/svgs/regular/square.svg);
    color: #1d1d1d;
    font-size: 16px;
    margin-right: 8px; }
  [type=checkbox]:checked ~ .sgx-select-picker-label:before {
    icon-font: url(sgx-base-code/svgs/regular/check-square.svg); }
  [type=checkbox]:disabled ~ .sgx-select-picker-label:before {
    border: 0;
    background: 0;
    color: inherit; }

.sgx-select-picker-option--dragging {
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  cursor: move !important; }

.sgx-select-picker-handle {
  display: block;
  position: absolute;
  right: 8px;
  top: 0;
  padding: 12px 12px;
  text-align: center;
  cursor: move; }
  .sgx-select-picker-handle:before {
    icon-font: url(sgx-base-code/svgs/solid/bars.svg);
    font-size: 14px; }
  .sgx-select-picker-option--dragging .sgx-select-picker-handle {
    color: #f7f7f7; }
    .sgx-select-picker-option--dragging .sgx-select-picker-handle:before {
      icon-font: url(sgx-base-code/svgs/solid/arrows-alt.svg); }
  [disabled] ~ .sgx-select-picker-handle {
    cursor: default !important;
    color: #c7c7c8 !important; }
    [disabled] ~ .sgx-select-picker-handle:before {
      icon-font: url(sgx-base-code/svgs/solid/lock.svg); }
  [type=radio]:checked ~ .sgx-select-picker-handle {
    color: #f7f7f7; }

.sgx-select-picker-message {
  line-height: 44px;
  padding: 0 12px 12px; }

sgx-input-select {
  display: inline-block;
  min-width: 100px; }
  sgx-input-select option {
    display: none !important; }

.sgx-input-control.sgx-input-select-filter {
  padding-right: 32px; }

.sgx-input-select-filter-wrapper {
  position: relative;
  display: block; }
  [disabled] .sgx-input-select-filter-wrapper:after {
    color: #c7c7c8; }

.sgx-select-filter-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  cursor: pointer; }
  .sgx-select-filter-icon:before {
    icon-font: url(sgx-base-code/svgs/solid/chevron-down.svg); }
  .sgx-input-control.is-active + .sgx-select-filter-icon:before {
    icon-font: url(sgx-base-code/svgs/solid/chevron-up.svg); }
  .sgx-input-select-filter-wrapper--selected .sgx-select-filter-icon:before {
    font-size: 16px;
    icon-font: url(sgx-base-code/svgs/solid/times.svg); }
  .sgx-select-filter-icon.sgx-icon.sgx-select-search-icon:before, .lm_controls .sgx-select-filter-icon.sgx-select-search-icon.lm_popout:before, .lm_controls .sgx-select-filter-icon.sgx-select-search-icon.lm_maximise:before, .lm_controls .sgx-select-filter-icon.sgx-select-search-icon.lm_close:before, .sgx-select-filter-icon.sgx-select-search-icon.sgx-pagination-prev:before {
    icon-font: url(sgx-base-code/svgs/regular/search.svg); }
  .sgx-select-filter-icon.sgx-icon.sgx-select-clear-icon:before, .lm_controls .sgx-select-filter-icon.sgx-select-clear-icon.lm_popout:before, .lm_controls .sgx-select-filter-icon.sgx-select-clear-icon.lm_maximise:before, .lm_controls .sgx-select-filter-icon.sgx-select-clear-icon.lm_close:before, .sgx-select-filter-icon.sgx-select-clear-icon.sgx-pagination-prev:before {
    icon-font: url(sgx-base-code/svgs/regular/times.svg); }
  [disabled] .sgx-select-filter-icon,
  .sgx-input[readonly] .sgx-select-filter-icon {
    display: none; }

.sgx-input-select-label--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0.5rem;
  background: white;
  z-index: 1000; }

sgx-select-inline-container {
  display: block;
  border: 1px solid #c7c7c8;
  border-top: 0; }

.sgx-datepicker-header {
  position: relative;
  height: 3.5rem;
  line-height: 3.5rem; }

.sgx-datepicker-toggle-view {
  margin: 0 2rem;
  text-align: center;
  cursor: pointer; }

.sgx-datepicker-previous,
.sgx-datepicker-next {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  width: 10%;
  text-align: center;
  color: #1d1d1d;
  border: 0;
  background: 0;
  outline: 0; }
  .sgx-datepicker-previous:hover,
  .sgx-datepicker-next:hover {
    color: #80cad9; }

.sgx-datepicker-previous {
  padding: 0 0 0 0.5rem;
  left: 0;
  right: auto; }
  .sgx-datepicker-previous:after {
    icon-font: url(sgx-base-code/svgs/regular/chevron-left.svg); }

.sgx-datepicker-next {
  padding: 0 0.5rem 0 0;
  right: 0;
  left: auto; }
  .sgx-datepicker-next:after {
    icon-font: url(sgx-base-code/svgs/regular/chevron-right.svg); }

body:not([data-type=mobile]) #sgx-date-picker-dialog .sgx-dialog-box {
  background: #f7f7f7; }
  body:not([data-type=mobile]) #sgx-date-picker-dialog .sgx-dialog-box.tether-target-attached-top {
    top: -3px !important; }

sgx-date-picker {
  display: block;
  color: #1d1d1d;
  background: #f7f7f7;
  min-width: 265px;
  max-width: 325px;
  user-select: none;
  margin: 0 auto;
  outline: 0; }
  body[data-type="mobile"] sgx-date-picker {
    max-width: none;
    background: 0; }

.sgx-datepicker-calender {
  display: block;
  padding-bottom: 0.5rem; }

.sgx-datepicker-calender--months {
  font-size: 1.2em; }

.sgx-datepicker-table {
  width: calc(100% - 2rem);
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto; }
  .sgx-datepicker-table th, .sgx-datepicker-table td {
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
    text-align: center; }
  .sgx-datepicker-table th {
    font-weight: normal;
    color: #0094b3;
    padding-bottom: 0.5rem; }
  .sgx-datepicker-calender--months .sgx-datepicker-table th {
    padding: 0;
    height: 12px; }

.sgx-datepicker-date {
  display: inline-block;
  padding: 0;
  background: 0;
  border: 0;
  cursor: pointer;
  position: relative;
  font-size: inherit;
  color: inherit; }
  .sgx-datepicker-calender--days .sgx-datepicker-date {
    width: 2rem;
    line-height: 2rem;
    height: 2rem; }
  .sgx-datepicker-calender--months .sgx-datepicker-date {
    width: 3.5rem;
    line-height: 3.5rem;
    height: 3.5rem; }
  .sgx-datepicker-date--today .sgx-datepicker-date:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #a7a8a9; }
  .sgx-datepicker-date:hover, .sgx-datepicker-date:focus {
    color: #1d1d1d;
    background: #80cad9;
    outline-style: none;
    box-shadow: none;
    border-color: transparent; }
  .sgx-datepicker-date:active {
    color: #f7f7f7;
    background: #006f86; }
  .sgx-datepicker-date--selected > .sgx-datepicker-date {
    color: #f7f7f7;
    background: #0094b3; }
  .sgx-datepicker-date:disabled {
    cursor: default !important;
    color: #a7a8a9 !important;
    background: transparent !important; }

sgx-input-date {
  display: inline-block; }
  sgx-input-date .sgx-input-control {
    padding-right: 32px; }

.sgx-input-date-wrapper {
  position: relative;
  display: inline-block;
  width: 100%; }
  .sgx-input-date-wrapper:after {
    icon-font: url(sgx-base-code/svgs/regular/calendar.svg);
    font-size: 16px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%); }
  .sgx-input[readonly] .sgx-input-date-wrapper:after {
    display: none; }

sgx-input-radio-list .sgx-input-label {
  margin-bottom: 4px; }

sgx-input-radio-list option {
  display: none; }

sgx-input-radio-list input {
  opacity: 0;
  position: absolute; }

sgx-input-radio-list.sgx-input-radio-list--toggle input {
  cursor: pointer; }

sgx-input-radio-list fieldset {
  border: 0;
  padding: 0;
  margin: 0; }

sgx-input-radio-list.sgx-input-radio-list--toggle fieldset {
  border-radius: 3px;
  background-color: #e7e7e7; }

.sgx-input-radio-list-wrapper {
  display: block;
  position: relative;
  margin-bottom: 4px; }
  .sgx-input-radio-list-wrapper:last-child {
    margin-bottom: 0; }
  .sgx-input-radio-list--inline .sgx-input-radio-list-wrapper {
    display: inline-block;
    margin-right: 40px; }
    .sgx-input-radio-list--inline .sgx-input-radio-list-wrapper:last-child {
      margin-right: 0; }
  .sgx-input-radio-list--toggle .sgx-input-radio-list-wrapper {
    margin: 0; }
  .sgx-input[readonly] .sgx-input-radio-list-wrapper {
    display: block;
    margin: 0; }

.sgx-input-radio-label {
  color: #1d1d1d;
  font: normal 16px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .sgx-input-radio-list--toggle .sgx-input-radio-label {
    display: inline-block;
    padding: 0 16px;
    line-height: 30px;
    color: #6f7072; }
  .sgx-input-radio-list--toggle.sgx-input--has-error .sgx-input-radio-label {
    color: #6f7072; }
  .sgx-input-radio-list--toggle :checked + .sgx-input-radio-label {
    width: 100%;
    border-radius: 3px;
    color: #f7f7f7;
    background-color: #0094b3; }
  .sgx-input-radio-list--toggle :active:not(:disabled) + .sgx-input-radio-label {
    width: 100%;
    border-radius: 3px;
    color: #f7f7f7;
    background-color: #006f86; }
  .sgx-input-radio-list--toggle :hover:not(:disabled):not(:checked):not(:active) + .sgx-input-radio-label {
    color: #1d1d1d; }
  .sgx-input-radio-list--toggle .sgx-input-radio-label:before {
    display: none; }
  .sgx-input-radio-label:before {
    margin-right: 8px;
    icon-font: url(sgx-base-code/svgs/regular/circle.svg); }
  :checked + .sgx-input-radio-label:before {
    icon-font: url(sgx-base-code/svgs/regular/dot-circle.svg); }
  .sgx-input-radio-label:hover:before {
    color: #80cad9; }
  :focus + .sgx-input-radio-label:before {
    color: #0094b3; }
  .sgx-input--has-error .sgx-input-radio-label {
    color: #1d1d1d; }
  :disabled + .sgx-input-radio-label {
    color: #c7c7c8 !important; }
    :disabled + .sgx-input-radio-label:before {
      color: inherit !important; }
  .sgx-input[readonly] input:not(:checked) + .sgx-input-radio-label {
    display: none; }
    .sgx-input[readonly] input:not(:checked) + .sgx-input-radio-label:before {
      display: none; }
  .sgx-input[readonly] input:checked + .sgx-input-radio-label:before {
    display: none; }

sgx-input-checkbox {
  height: auto; }
  sgx-input-checkbox .sgx-input-control {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important; }
  sgx-input-checkbox[readonly] {
    pointer-events: none; }
    sgx-input-checkbox[readonly] .sgx-input-control {
      display: none; }

.sgx-input-checkbox-label {
  display: flex;
  align-items: center;
  color: #1d1d1d;
  font: normal 16px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .sgx-input-checkbox-label:before {
    margin-right: 8px;
    icon-font: url(sgx-base-code/svgs/regular/square.svg); }
  :checked + .sgx-input-checkbox-label:before {
    icon-font: url(sgx-base-code/svgs/regular/check-square.svg); }
  :indeterminate + .sgx-input-checkbox-label:before {
    icon-font: url(sgx-base-code/svgs/regular/minus-square.svg); }
  .sgx-input-checkbox-label:hover:before {
    color: #80cad9; }
  :focus + .sgx-input-checkbox-label:before {
    color: #0094b3; }
  .sgx-input--has-error .sgx-input-checkbox-label {
    color: #1d1d1d; }
  :disabled + .sgx-input-checkbox-label,
  :disabled + .sgx-input-checkbox-label:before {
    color: #c7c7c8 !important; }
  .sgx-input-checkbox--right .sgx-input-checkbox-label {
    justify-content: space-between; }
    .sgx-input-checkbox--right .sgx-input-checkbox-label:before {
      order: 1;
      margin-right: 0;
      margin-left: 8px; }
  .sgx-input-checkbox--switch .sgx-input-checkbox-label:before {
    icon-font: url(sgx-base-code/svgs/light/toggle-off.svg);
    font-size: 20px; }
  .sgx-input-checkbox--switch :checked + .sgx-input-checkbox-label:before {
    icon-font: url(sgx-base-code/svgs/solid/toggle-on.svg); }
  .sgx-input-checkbox--switch :checked + .sgx-input-checkbox-label:before:indeterminate {
    icon-font: url(sgx-base-code/svgs/light/toggle-off.svg); }

.sgx-input-textarea-input {
  height: auto;
  padding: 0.5rem; }
  .sgx-input[readonly] .sgx-input-textarea-input {
    resize: none;
    min-height: 25px;
    height: auto; }

sgx-input-toggle {
  width: 100%; }

.sgx-input-toggle-gui-wrapper {
  width: 100%;
  padding-top: 11px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.sgx-input-toggle-centrebox {
  width: 40px;
  height: 20px;
  position: relative; }

.sgx-input-toggle-sidebox {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 0 0.4em 0 0.4em; }

.sgx-input-toggle-sidebox-left {
  justify-content: flex-end; }

.sgx-input-toggle-sidebox-right {
  justify-content: flex-start; }

.sgx-input-toggle-sidelabel {
  transition: opacity 0.12s ease-in; }
  [disabled] .sgx-input-toggle-sidelabel {
    color: rgba(34, 34, 34, 0.5); }

.sgx-input-toggle-sidelabel-left {
  text-align: right; }
  [data-isleft='false'] .sgx-input-toggle-sidelabel-left {
    opacity: 0.4; }

.sgx-input-toggle-sidelabel-right {
  text-align: left; }
  [data-isleft='true'] .sgx-input-toggle-sidelabel-right {
    opacity: 0.4; }

.sgx-input-toggle-pill {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #efefef;
  z-index: 1;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  border: 0;
  cursor: pointer; }
  input:focus + .sgx-input-toggle-pill {
    box-shadow: 0 0 2px 3px #40afc6; }

.sgx-input-toggle-circle {
  pointer-events: none;
  z-index: 2;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #ffffff;
  transition: left 0.12s ease-in; }
  [data-isleft="true"] .sgx-input-toggle-circle {
    left: 1px; }
  [data-isleft="false"] .sgx-input-toggle-circle {
    left: 21px; }

sgx-input-file {
  max-width: 80vw; }
  sgx-input-file input[type=file] {
    display: block !important;
    position: absolute !important;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important; }
    sgx-input-file input[type=file]:disabled {
      display: none !important; }

.sgx-input-file-wrapper {
  position: relative;
  display: inline-block;
  width: 100%; }
  .sgx-input-file-wrapper:after {
    icon-font: url(sgx-base-code/svgs/regular/paperclip.svg);
    font-size: 14px;
    color: #1d1d1d;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%); }
  .sgx-input[readonly] .sgx-input-file-wrapper {
    display: none; }

.sgx-input-file-handle {
  cursor: pointer;
  padding-right: 32px; }

sgx-file-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  margin: 4px 0; }

.sgx-file-preview-img,
.sgx-file-preview-icon {
  justify-self: flex-start;
  width: 50%;
  flex: 0 0 auto;
  height: auto; }

.sgx-file-preview-img {
  max-width: 200px; }

.sgx-file-preview-icon {
  width: auto;
  font-size: 20px;
  color: #1d1d1d; }
  [data-type=any] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file.svg); }
  [data-type=txt] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-alt.svg); }
  [data-type=pdf] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-pdf.svg); }
  [data-type=doc] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-word.svg); }
  [data-type=xls] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-excel.svg); }
  [data-type=ppt] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-powerpoint.svg); }
  [data-type=img] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-image.svg); }
  [data-type=vid] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-video.svg); }
  [data-type=zip] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-archive.svg); }

.sgx-file-preview-link {
  justify-self: flex-end;
  display: flex;
  justify-content: flex-start;
  margin: 0 8px;
  flex: 1 1 auto;
  width: 33%; }
  [data-type="img"] > .sgx-file-preview-link {
    justify-content: flex-end; }
  .sgx-file-preview-link:before {
    content: attr(data-name);
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .sgx-file-preview-link:after {
    content: attr(data-ext);
    flex: 0 0 auto; }

.sgx-file-preview-clear {
  justify-self: flex-end;
  background: 0;
  border: 0;
  padding: 0;
  margin-right: 8px;
  color: #1d1d1d; }
  .sgx-input[readonly] .sgx-file-preview-clear {
    display: none; }
  .sgx-file-preview-clear:before {
    icon-font: url(sgx-base-code/svgs/regular/times.svg); }

sgx-input-hidden.sgx-input {
  display: none !important; }

sgx-form .sgx-input + sgx-form .sgx-input,
.sgx-form .sgx-input + sgx-form .sgx-input, sgx-form .sgx-input +
.sgx-form .sgx-input,
.sgx-form .sgx-input +
.sgx-form .sgx-input {
  margin-left: 0.25rem; }

sgx-form .form-row,
.sgx-form .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.25rem;
  margin-left: -0.25rem; }
  sgx-form .form-row > .col,
  sgx-form .form-row > [class*="col-"],
  .sgx-form .form-row > .col,
  .sgx-form .form-row > [class*="col-"] {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    margin-left: initial;
    margin-bottom: 0.5rem; }
  sgx-form .form-row:not(:last-child),
  .sgx-form .form-row:not(:last-child) {
    margin-top: 0.5rem; }

.form-row sgx-form .sgx-input-hint-icon, .form-row
.sgx-form .sgx-input-hint-icon {
  right: 0.25rem; }

.form-row sgx-form .sgx-input-hint, .form-row
.sgx-form .sgx-input-hint {
  right: 0.25rem; }

fieldset {
  position: relative; }

fieldset[disabled]:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

sgx-tabmenu {
  position: relative;
  display: block;
  font-size: 0.85rem;
  font-family: Lato, sans-serif;
  height: 2.5rem;
  line-height: 2.5rem; }
  sgx-tabmenu .sgx-tabmenu-link {
    display: inline-block;
    text-decoration: none;
    padding: 0 0.95rem;
    cursor: pointer;
    background: #fff;
    color: #0B236B;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-right: 0.95rem;
    transition: background 0.3s ease-out, color 0.3s ease-out; }
    sgx-tabmenu .sgx-tabmenu-link:last-child {
      margin-right: 0; }
    sgx-tabmenu .sgx-tabmenu-link:active, sgx-tabmenu .sgx-tabmenu-link.sgx-tabmenu-link--active {
      cursor: default;
      background: #f5f5f5;
      color: #0B236B; }
    sgx-tabmenu .sgx-tabmenu-link:hover {
      background: #f5f5f5; }
    sgx-tabmenu .sgx-tabmenu-link.sgx-tabmenu-link--disabled {
      cursor: default;
      outline: none;
      color: #9C9E9F;
      background: #fff; }
  sgx-tabmenu .sgx-tabmenu-dropdown {
    display: none;
    background: #efefef;
    height: 2.5rem;
    line-height: 2.5rem;
    padding: 0 1rem;
    color: #0B236B; }
    sgx-tabmenu .sgx-tabmenu-dropdown:after {
      content: "\f078";
      font-family: fontAwesome;
      font-size: 70%;
      position: absolute;
      right: 1rem; }
    sgx-tabmenu .sgx-tabmenu-dropdown.sgx-tabmenu-dropdown--active:after {
      content: "\f077"; }

body[data-type='mobile'] sgx-tabmenu:not(.sgx-tabmenu-disabled-dropdown) {
  z-index: 100; }
  body[data-type='mobile'] sgx-tabmenu:not(.sgx-tabmenu-disabled-dropdown) .sgx-tabmenu-link {
    width: 100%;
    display: block;
    background: #f5f5f5;
    border-bottom: 1px solid white; }
  body[data-type='mobile'] sgx-tabmenu:not(.sgx-tabmenu-disabled-dropdown) .sgx-tabmenu-dropdown {
    display: block; }
  body[data-type='mobile'] sgx-tabmenu:not(.sgx-tabmenu-disabled-dropdown) .sgx-tabmenu-container {
    display: none; }
  body[data-type='mobile'] sgx-tabmenu:not(.sgx-tabmenu-disabled-dropdown) .sgx-tabmenu-dropdown--active + .sgx-tabmenu-container {
    display: block; }

sgx-state-tabs {
  background: #ffffff;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  height: 32px;
  box-shadow: inset 0px -1px 0 0px #b6bdd3;
  font-size: 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  @media (max-width: 1279px) {
    sgx-state-tabs {
      font-size: 14px; } }
  @media (max-width: 767px) {
    sgx-state-tabs {
      font-size: 12px; } }

.sgx-state-tabs-scroll-wrapper {
  flex: 0 1 auto;
  height: 100%;
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: touch; }

.sgx-state-tabs-container {
  display: flex;
  height: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  position: relative;
  white-space: nowrap;
  min-width: 100%;
  padding-right: 1px; }

.sgx-state-tab {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
  color: #1a5488;
  overflow-y: hidden; }
  .sgx-state-tab:first-of-type {
    padding-left: 0; }
  .sgx-state-tab:last-of-type {
    padding-right: 0; }
  .sgx-state-tab.sgx-state-tab--active {
    font-weight: bold;
    color: #222222;
    cursor: default; }
  .sgx-state-tab:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 20px;
    right: 20px;
    height: 6px;
    background: #BDD831;
    transform: translate3d(0, 150%, 0); }
  .sgx-state-tab:first-of-type:after {
    left: 0; }
  .sgx-state-tab:last-of-type:after {
    right: 0; }
  .sgx-state-tab.sgx-state-tab--active:after {
    transform: translate3d(0, 0, 0); }
  .sgx-state-tab.sgx-state-tab--animate-active:after {
    animation: tab-active 0.3s ease forwards normal; }
  .sgx-state-tab.sgx-state-tab--animate-inactive:after {
    animation: tab-active 0.3s ease forwards reverse; }

@keyframes tab-active {
  0% {
    transform: translate3d(0, 150%, 0);
    background: transparent; }
  100% {
    transform: translate3d(0, 0, 0);
    background: #BDD831; } }

[class^=sgx-state-tabs-arrow] {
  position: relative;
  z-index: 1;
  display: none;
  flex: 0 0 auto;
  font-family: FontAwesome;
  font-size: 1rem;
  height: 100%;
  padding: 0 .75rem;
  cursor: pointer;
  background: 0;
  border: 0;
  outline: 0; }
  [class^=sgx-state-tabs-arrow][disabled] {
    color: #9C9E9F;
    cursor: default;
    box-shadow: none; }

.sgx-state-tabs-arrow-left {
  box-shadow: 0px 0px 20px 0px #ffffff;
  color: #222222; }
  .sgx-state-tabs-arrow-left:after {
    content: '\f104'; }

.sgx-state-tabs-arrow-right {
  box-shadow: -10px 0px 20px -5px #ffffff;
  color: #222222; }
  .sgx-state-tabs-arrow-right:after {
    content: '\f105'; }

.sgx-state-tab-btn-edit,
.sgx-state-tab-btn-delete {
  display: none;
  font-family: fontAwesome;
  opacity: 0.5;
  padding-left: 0.5rem;
  font-size: 0.75rem;
  cursor: pointer; }
  .sgx-state-tab-btn-edit:hover,
  .sgx-state-tab-btn-delete:hover {
    opacity: 1; }
  .sgx-state-tab--active > .sgx-state-tab-btn-edit, .sgx-state-tab--active >
  .sgx-state-tab-btn-delete {
    display: inline-block; }

.sgx-state-tab-btn-edit:before {
  content: "\f040"; }

.sgx-state-tab-btn-delete:before {
  content: "\f00d"; }

.sgx-state-tab-btn-add {
  font-size: .75rem;
  padding: .5em;
  cursor: pointer;
  line-height: 1;
  align-self: center;
  opacity: .5;
  margin-left: 20px; }
  @media (max-width: 1023px) {
    .sgx-state-tab-btn-add {
      margin-left: 12.5px; } }
  .sgx-state-tab-btn-add:before {
    content: '\f067';
    font-family: fontAwesome; }
  .sgx-state-tab-btn-add:hover {
    opacity: 1; }

#sgx-state-tabs-add-dialog .sgx-dialog-body,
#sgx-state-tabs-edit-dialog .sgx-dialog-body {
  padding: 1rem; }

#sgx-state-tabs-add-dialog .sgx-input-control,
#sgx-state-tabs-edit-dialog .sgx-input-control {
  min-width: 0; }

[class*=sgx-button] {
  display: inline-block;
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;
  transition: background-color 0.2s ease-out, color 0.2s ease-out, box-shadow 0.2s ease-out;
  padding: 0 20px;
  font-size: 16px;
  line-height: 36px;
  min-width: 64px; }
  [class*=sgx-button].sgx-icon--before:before, .lm_header .lm_tab .lm_close_tab[class*=sgx-button]:before {
    margin-right: 4px; }
  [class*=sgx-button].sgx-icon--after:after, sgx-accordion.sgx-accordion--minimal .sgx-accordion-header[class*=sgx-button]:after {
    margin-left: 4px; }
  [class*=sgx-button].sgx-icon, .lm_controls .lm_popout[class*=sgx-button], .lm_controls .lm_maximise[class*=sgx-button], .lm_controls .lm_close[class*=sgx-button], .sgx-pagination-prev[class*=sgx-button]:before, .sgx-pagination-next[class*=sgx-button]:after {
    vertical-align: middle; }

.sgx-button,
.sgx-button--secondary {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.34);
  color: #0094B3; }
  .sgx-button:focus,
  .sgx-button--secondary:focus {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button:hover,
  .sgx-button--secondary:hover {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button:active,
  .sgx-button--secondary:active {
    background-color: #006f86;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button.sgx-button--selected,
  .sgx-button--secondary.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button, .sgx-button.disabled, .sgx-button:disabled, .sgx-button[disabled], fieldset[disabled]
  .sgx-button--secondary,
  .sgx-button--secondary.disabled,
  .sgx-button--secondary:disabled,
  .sgx-button--secondary[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2) !important;
    outline: 0; }

.sgx-button--primary {
  background-color: #0094B3;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.87); }
  .sgx-button--primary:focus {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--primary:hover {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--primary:active {
    background-color: #006f86;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--primary.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--primary, .sgx-button--primary.disabled, .sgx-button--primary:disabled, .sgx-button--primary[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(0, 0, 0, 0.09) !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--link,
.sgx-button--text {
  background-color: transparent;
  box-shadow: none;
  color: #0094B3; }
  .sgx-button--link:focus,
  .sgx-button--text:focus {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--link:hover,
  .sgx-button--text:hover {
    background-color: #80cad9;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--link:active,
  .sgx-button--text:active {
    background-color: #006f86;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--link.sgx-button--selected,
  .sgx-button--text.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--link, .sgx-button--link.disabled, .sgx-button--link:disabled, .sgx-button--link[disabled], fieldset[disabled]
  .sgx-button--text,
  .sgx-button--text.disabled,
  .sgx-button--text:disabled,
  .sgx-button--text[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--danger {
  background-color: #e60000;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.87); }
  .sgx-button--danger:focus {
    background-color: #ec4040;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--danger:hover {
    background-color: #f38080;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--danger:active {
    background-color: #ad0000;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--danger.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--danger, .sgx-button--danger.disabled, .sgx-button--danger:disabled, .sgx-button--danger[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(0, 0, 0, 0.09) !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--success {
  background-color: #74AC00;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.87); }
  .sgx-button--success:focus {
    background-color: #97c140;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--success:hover {
    background-color: #bad680;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--success:active {
    background-color: #578100;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--success.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--success, .sgx-button--success.disabled, .sgx-button--success:disabled, .sgx-button--success[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(0, 0, 0, 0.09) !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--low-emp,
.sgx-button--low-toggle {
  border-radius: 2px;
  padding: 0 8px;
  font-size: 14px;
  line-height: 24px;
  min-width: 56px; }
  .sgx-button--low-emp.sgx-icon, .lm_controls .sgx-button--low-emp.lm_popout, .lm_controls .sgx-button--low-emp.lm_maximise, .lm_controls .sgx-button--low-emp.lm_close, .sgx-button--low-emp.sgx-pagination-prev:before, .sgx-button--low-emp.sgx-pagination-next:after,
  .sgx-button--low-toggle.sgx-icon,
  .lm_controls .sgx-button--low-toggle.lm_popout,
  .lm_controls .sgx-button--low-toggle.lm_maximise,
  .lm_controls .sgx-button--low-toggle.lm_close,
  .sgx-button--low-toggle.sgx-pagination-prev:before,
  .sgx-button--low-toggle.sgx-pagination-next:after {
    padding: 0;
    min-width: 24px;
    line-height: 24px; }

.sgx-button--low-toggle {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.6); }
  .sgx-button--low-toggle:focus {
    background-color: #d9eff4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-toggle:hover {
    background-color: #d9eff4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-toggle:active {
    background-color: #a6dae4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-toggle.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--low-toggle, .sgx-button--low-toggle.disabled, .sgx-button--low-toggle:disabled, .sgx-button--low-toggle[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2) !important;
    outline: 0; }

.sgx-button--low-emp {
  background-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.6); }
  .sgx-button--low-emp:focus {
    background-color: #d9eff4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-emp:hover {
    background-color: #d9eff4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-emp:active {
    background-color: #a6dae4;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--low-emp.sgx-button--selected {
    background-color: #0094B3;
    color: rgba(255, 255, 255, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--low-emp, .sgx-button--low-emp.disabled, .sgx-button--low-emp:disabled, .sgx-button--low-emp[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--lower-emp, .sgx-tooltip-button {
  background-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.34);
  padding: 0 8px;
  font-size: 14px;
  line-height: 16px;
  min-width: 16px; }
  .sgx-button--lower-emp:focus, .sgx-tooltip-button:focus {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.6);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--lower-emp:hover, .sgx-tooltip-button:hover {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.6);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--lower-emp:active, .sgx-tooltip-button:active {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  .sgx-button--lower-emp.sgx-button--selected, .sgx-button--selected.sgx-tooltip-button {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }
  fieldset[disabled] .sgx-button--lower-emp, fieldset[disabled] .sgx-tooltip-button, .sgx-button--lower-emp.disabled, .disabled.sgx-tooltip-button, .sgx-button--lower-emp:disabled, .sgx-tooltip-button:disabled, .sgx-button--lower-emp[disabled], .sgx-tooltip-button[disabled] {
    cursor: default;
    transition: none;
    color: rgba(0, 0, 0, 0.09) !important;
    background-color: transparent !important;
    box-shadow: inset 0 0 0 1px transparent !important;
    outline: 0; }

.sgx-button--small {
  padding: 0 12px;
  font-size: 14px;
  line-height: 24px;
  min-width: 56px; }

.sgx-button--large {
  padding: 0 28px;
  font-size: 18px;
  line-height: 44px;
  min-width: 80px; }

.sgx-button--block {
  display: block;
  min-width: auto; }

.sgx-input {
  display: inline-block;
  vertical-align: top;
  font-family: "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative; }

.sgx-input-label {
  display: block;
  margin-bottom: 4px;
  font: normal 14px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #6f7072; }
  .sgx-input:not([hint=""]) .sgx-input-label {
    padding-right: 22px; }
  .sgx-input[hint-type="inline"] .sgx-input-label {
    padding-right: 0; }
  .sgx-input--has-error .sgx-input-label {
    color: #e60000; }
  .sgx-input[readonly] .sgx-input-label {
    margin-bottom: 4px; }

.sgx-input-control {
  width: 100%;
  height: 36px;
  text-align: left;
  font: normal 16px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 8px 12px;
  outline: 0;
  border: 0;
  border-bottom: 1px solid;
  text-overflow: ellipsis;
  border-radius: 0;
  -webkit-appearance: none;
  color: #1d1d1d;
  background: #f7f7f7;
  border-color: #9fa0a1; }
  .sgx-input-control::-ms-clear {
    display: none; }
  .sgx-input-control:hover {
    border-color: #80cad9; }
  .sgx-input--has-error .sgx-input-control {
    border-color: #e60000; }
  .sgx-input-control:focus, .sgx-input-control:active, .sgx-input-control.is-active {
    border-color: #0094b3; }
  fieldset[disabled] .sgx-input-control, .sgx-input-control:disabled, .sgx-input-control [disabled] {
    border-color: transparent !important;
    color: #c7c7c8 !important;
    cursor: not-allowed; }
  .sgx-input[readonly] .sgx-input-control {
    border-color: transparent;
    background: 0;
    padding: 0;
    height: auto;
    cursor: default; }
  .sgx-input--no-border .sgx-input-control {
    cursor: pointer;
    text-align: right; }
    .sgx-input--no-border .sgx-input-control:not(:focus) {
      background-color: transparent;
      border-color: transparent; }

.sgx-input-message {
  display: none;
  margin-top: 4px;
  font: normal 14px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #e60000; }
  .sgx-input--has-error .sgx-input-message {
    display: block; }

.sgx-input-hint-icon {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  cursor: help;
  padding-left: 8px;
  font-size: 14px;
  color: #6f7072; }
  .sgx-input-hint-icon:after {
    icon-font: url(sgx-base-code/svgs/solid/question-circle.svg); }
  .sgx-input-hint-icon:hover {
    z-index: 100; }
  .sgx-input[readonly] .sgx-input-hint-icon,
  .sgx-input[hint-type=inline] .sgx-input-hint-icon {
    display: none; }

.sgx-input-hint {
  position: absolute;
  z-index: 10;
  right: 0;
  top: -8px;
  transform: translateY(-100%);
  padding: 8px;
  font: normal 14px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #f7f7f7;
  border-radius: 2px;
  background: #212222;
  color: #f7f7f7;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25);
  display: block;
  visibility: hidden; }
  .sgx-input-hint:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top-color: #212222;
    position: absolute;
    right: 1px;
    top: 100%; }
  .sgx-input[readonly] .sgx-input-hint {
    display: none; }
  .sgx-input-hint-icon:hover ~ .sgx-input-hint, .sgx-input-hint:hover {
    visibility: visible; }
  .sgx-input[hint-type="inline"] .sgx-input-hint {
    position: static;
    visibility: visible;
    margin-top: 4px;
    padding: 0;
    transform: none;
    font: normal 14px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #6f7072;
    background: 0;
    box-shadow: none; }
    .sgx-input[hint-type="inline"] .sgx-input-hint:after {
      display: none; }

sgx-input-number .sgx-input-control {
  padding-right: 32px;
  /* autoprefixer: off */
  -moz-appearance: textfield;
  appearance: textfield; }
  sgx-input-number .sgx-input-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.sgx-input-number-wrapper {
  position: relative;
  display: block; }

.sgx-input-number-up,
.sgx-input-number-down {
  position: absolute;
  right: 0;
  height: 50%;
  padding: 0 12px;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: #1d1d1d; }
  .sgx-input-number-up:hover,
  .sgx-input-number-down:hover {
    color: #80cad9; }
  .sgx-input-number-up:active,
  .sgx-input-number-down:active {
    color: #0094b3; }
  :disabled ~ .sgx-input-number-up, :disabled ~
  .sgx-input-number-down {
    color: #c7c7c8 !important;
    pointer-events: none; }
  .sgx-input[readonly] .sgx-input-number-up, .sgx-input[readonly]
  .sgx-input-number-down {
    display: none; }

.sgx-input-number-up {
  top: 0;
  padding-top: 4px; }
  .sgx-input-number-up:after {
    icon-font: url(sgx-base-code/svgs/solid/sort-up.svg); }

.sgx-input-number-down {
  bottom: 0;
  padding-bottom: 4px; }
  .sgx-input-number-down:after {
    icon-font: url(sgx-base-code/svgs/solid/sort-down.svg); }

sgx-select-picker {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  height: 100%; }

.sgx-select-picker-list {
  flex: 1 1 auto;
  background: 0;
  border: 0; }

.sgx-select-picker-select-all {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  height: 36px;
  border-bottom: 1px solid #c7c7c8; }
  .sgx-select-picker-select-all .sgx-input-label.sgx-input-checkbox-label {
    font-size: 14px;
    font-weight: bold;
    padding-right: 0;
    margin-bottom: 0; }
  .sgx-select-picker-select-all .sgx-input-checkbox-label:before {
    font-size: 16px; }

body:not([data-type=mobile]) #sgx-select-dialog .sgx-dialog-box,
body:not([data-type=tablet]) #sgx-select-dialog .sgx-dialog-box,
#sgx-select-dialog.sgx-select-dialog--autocomplete .sgx-dialog-box {
  max-height: 40vh; }
  body:not([data-type=mobile]) #sgx-select-dialog .sgx-dialog-box.tether-target-attached-top,
  body:not([data-type=tablet]) #sgx-select-dialog .sgx-dialog-box.tether-target-attached-top,
  #sgx-select-dialog.sgx-select-dialog--autocomplete .sgx-dialog-box.tether-target-attached-top {
    top: -3px !important; }

#sgx-select-dialog.sgx-select-dialog--autocomplete.sgx-forms-dialogs--keyboard-open .sgx-dialog-box {
  max-height: 60vh; }

#sgx-select-dialog sgx-select-picker.sgx-dialog-body {
  height: auto; }

.sgx-select-picker-option {
  position: relative;
  display: block;
  border-bottom: 1px solid #e7e7e7; }
  .sgx-select-picker-option input {
    opacity: 0 !important;
    position: absolute !important; }

.sgx-select-picker-group-label {
  display: block;
  height: 100%;
  padding: 12px 12px;
  color: #1d1d1d;
  background: transparent; }
  input:disabled ~ .sgx-select-picker-group-label {
    color: #c7c7c8 !important;
    background: transparent !important; }

.sgx-select-picker-label {
  display: block;
  height: 100%;
  padding: 12px 12px;
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #1d1d1d;
  background: transparent; }
  .sgx-select-picker-label.sgx-select-picker-group-item {
    padding-left: 24px; }
  .sgx-select-picker-handle + .sgx-select-picker-label {
    padding-right: 40px; }
  body[data-type=desktop] .sgx-select-picker-option--highlighted .sgx-select-picker-label {
    color: #1d1d1d;
    background-color: #80cad9; }
  input:disabled ~ .sgx-select-picker-label {
    cursor: default !important;
    color: #c7c7c8 !important;
    background: transparent !important; }
  body[data-type=desktop] [type=radio] ~ .sgx-select-picker-label:active:not(:disabled) {
    color: #f7f7f7 !important;
    background: #006f86 !important; }
  [type=radio]:checked ~ .sgx-select-picker-label,
  .sgx-select-picker-option--highlighted [type=radio]:checked ~ .sgx-select-picker-label {
    color: #f7f7f7;
    background: #0094b3; }
  [type=checkbox] ~ .sgx-select-picker-label:before {
    icon-font: url(sgx-base-code/svgs/regular/square.svg);
    color: #1d1d1d;
    font-size: 16px;
    margin-right: 8px; }
  [type=checkbox]:checked ~ .sgx-select-picker-label:before {
    icon-font: url(sgx-base-code/svgs/regular/check-square.svg); }
  [type=checkbox]:disabled ~ .sgx-select-picker-label:before {
    border: 0;
    background: 0;
    color: inherit; }

.sgx-select-picker-option--dragging {
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  cursor: move !important; }

.sgx-select-picker-handle {
  display: block;
  position: absolute;
  right: 8px;
  top: 0;
  padding: 12px 12px;
  text-align: center;
  cursor: move; }
  .sgx-select-picker-handle:before {
    icon-font: url(sgx-base-code/svgs/solid/bars.svg);
    font-size: 14px; }
  .sgx-select-picker-option--dragging .sgx-select-picker-handle {
    color: #f7f7f7; }
    .sgx-select-picker-option--dragging .sgx-select-picker-handle:before {
      icon-font: url(sgx-base-code/svgs/solid/arrows-alt.svg); }
  [disabled] ~ .sgx-select-picker-handle {
    cursor: default !important;
    color: #c7c7c8 !important; }
    [disabled] ~ .sgx-select-picker-handle:before {
      icon-font: url(sgx-base-code/svgs/solid/lock.svg); }
  [type=radio]:checked ~ .sgx-select-picker-handle {
    color: #f7f7f7; }

.sgx-select-picker-message {
  line-height: 44px;
  padding: 0 12px 12px; }

sgx-input-select {
  display: inline-block;
  min-width: 100px; }
  sgx-input-select option {
    display: none !important; }

.sgx-input-control.sgx-input-select-filter {
  padding-right: 32px; }

.sgx-input-select-filter-wrapper {
  position: relative;
  display: block; }
  [disabled] .sgx-input-select-filter-wrapper:after {
    color: #c7c7c8; }

.sgx-select-filter-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  cursor: pointer; }
  .sgx-select-filter-icon:before {
    icon-font: url(sgx-base-code/svgs/solid/chevron-down.svg); }
  .sgx-input-control.is-active + .sgx-select-filter-icon:before {
    icon-font: url(sgx-base-code/svgs/solid/chevron-up.svg); }
  .sgx-input-select-filter-wrapper--selected .sgx-select-filter-icon:before {
    font-size: 16px;
    icon-font: url(sgx-base-code/svgs/solid/times.svg); }
  .sgx-select-filter-icon.sgx-icon.sgx-select-search-icon:before, .lm_controls .sgx-select-filter-icon.sgx-select-search-icon.lm_popout:before, .lm_controls .sgx-select-filter-icon.sgx-select-search-icon.lm_maximise:before, .lm_controls .sgx-select-filter-icon.sgx-select-search-icon.lm_close:before, .sgx-select-filter-icon.sgx-select-search-icon.sgx-pagination-prev:before {
    icon-font: url(sgx-base-code/svgs/regular/search.svg); }
  .sgx-select-filter-icon.sgx-icon.sgx-select-clear-icon:before, .lm_controls .sgx-select-filter-icon.sgx-select-clear-icon.lm_popout:before, .lm_controls .sgx-select-filter-icon.sgx-select-clear-icon.lm_maximise:before, .lm_controls .sgx-select-filter-icon.sgx-select-clear-icon.lm_close:before, .sgx-select-filter-icon.sgx-select-clear-icon.sgx-pagination-prev:before {
    icon-font: url(sgx-base-code/svgs/regular/times.svg); }
  [disabled] .sgx-select-filter-icon,
  .sgx-input[readonly] .sgx-select-filter-icon {
    display: none; }

.sgx-input-select-label--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0.5rem;
  background: white;
  z-index: 1000; }

sgx-select-inline-container {
  display: block;
  border: 1px solid #c7c7c8;
  border-top: 0; }

.sgx-datepicker-header {
  position: relative;
  height: 3.5rem;
  line-height: 3.5rem; }

.sgx-datepicker-toggle-view {
  margin: 0 2rem;
  text-align: center;
  cursor: pointer; }

.sgx-datepicker-previous,
.sgx-datepicker-next {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  width: 10%;
  text-align: center;
  color: #1d1d1d;
  border: 0;
  background: 0;
  outline: 0; }
  .sgx-datepicker-previous:hover,
  .sgx-datepicker-next:hover {
    color: #80cad9; }

.sgx-datepicker-previous {
  padding: 0 0 0 0.5rem;
  left: 0;
  right: auto; }
  .sgx-datepicker-previous:after {
    icon-font: url(sgx-base-code/svgs/regular/chevron-left.svg); }

.sgx-datepicker-next {
  padding: 0 0.5rem 0 0;
  right: 0;
  left: auto; }
  .sgx-datepicker-next:after {
    icon-font: url(sgx-base-code/svgs/regular/chevron-right.svg); }

body:not([data-type=mobile]) #sgx-date-picker-dialog .sgx-dialog-box {
  background: #f7f7f7; }
  body:not([data-type=mobile]) #sgx-date-picker-dialog .sgx-dialog-box.tether-target-attached-top {
    top: -3px !important; }

sgx-date-picker {
  display: block;
  color: #1d1d1d;
  background: #f7f7f7;
  min-width: 265px;
  max-width: 325px;
  user-select: none;
  margin: 0 auto;
  outline: 0; }
  body[data-type="mobile"] sgx-date-picker {
    max-width: none;
    background: 0; }

.sgx-datepicker-calender {
  display: block;
  padding-bottom: 0.5rem; }

.sgx-datepicker-calender--months {
  font-size: 1.2em; }

.sgx-datepicker-table {
  width: calc(100% - 2rem);
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto; }
  .sgx-datepicker-table th, .sgx-datepicker-table td {
    width: 2rem;
    height: 2rem;
    vertical-align: middle;
    text-align: center; }
  .sgx-datepicker-table th {
    font-weight: normal;
    color: #0094b3;
    padding-bottom: 0.5rem; }
  .sgx-datepicker-calender--months .sgx-datepicker-table th {
    padding: 0;
    height: 12px; }

.sgx-datepicker-date {
  display: inline-block;
  padding: 0;
  background: 0;
  border: 0;
  cursor: pointer;
  position: relative;
  font-size: inherit;
  color: inherit; }
  .sgx-datepicker-calender--days .sgx-datepicker-date {
    width: 2rem;
    line-height: 2rem;
    height: 2rem; }
  .sgx-datepicker-calender--months .sgx-datepicker-date {
    width: 3.5rem;
    line-height: 3.5rem;
    height: 3.5rem; }
  .sgx-datepicker-date--today .sgx-datepicker-date:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #a7a8a9; }
  .sgx-datepicker-date:hover, .sgx-datepicker-date:focus {
    color: #1d1d1d;
    background: #80cad9;
    outline-style: none;
    box-shadow: none;
    border-color: transparent; }
  .sgx-datepicker-date:active {
    color: #f7f7f7;
    background: #006f86; }
  .sgx-datepicker-date--selected > .sgx-datepicker-date {
    color: #f7f7f7;
    background: #0094b3; }
  .sgx-datepicker-date:disabled {
    cursor: default !important;
    color: #a7a8a9 !important;
    background: transparent !important; }

sgx-input-date {
  display: inline-block; }
  sgx-input-date .sgx-input-control {
    padding-right: 32px; }

.sgx-input-date-wrapper {
  position: relative;
  display: inline-block;
  width: 100%; }
  .sgx-input-date-wrapper:after {
    icon-font: url(sgx-base-code/svgs/regular/calendar.svg);
    font-size: 16px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%); }
  .sgx-input[readonly] .sgx-input-date-wrapper:after {
    display: none; }

sgx-input-radio-list .sgx-input-label {
  margin-bottom: 4px; }

sgx-input-radio-list option {
  display: none; }

sgx-input-radio-list input {
  opacity: 0;
  position: absolute; }

sgx-input-radio-list.sgx-input-radio-list--toggle input {
  cursor: pointer; }

sgx-input-radio-list fieldset {
  border: 0;
  padding: 0;
  margin: 0; }

sgx-input-radio-list.sgx-input-radio-list--toggle fieldset {
  border-radius: 3px;
  background-color: #e7e7e7; }

.sgx-input-radio-list-wrapper {
  display: block;
  position: relative;
  margin-bottom: 4px; }
  .sgx-input-radio-list-wrapper:last-child {
    margin-bottom: 0; }
  .sgx-input-radio-list--inline .sgx-input-radio-list-wrapper {
    display: inline-block;
    margin-right: 40px; }
    .sgx-input-radio-list--inline .sgx-input-radio-list-wrapper:last-child {
      margin-right: 0; }
  .sgx-input-radio-list--toggle .sgx-input-radio-list-wrapper {
    margin: 0; }
  .sgx-input[readonly] .sgx-input-radio-list-wrapper {
    display: block;
    margin: 0; }

.sgx-input-radio-label {
  color: #1d1d1d;
  font: normal 16px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .sgx-input-radio-list--toggle .sgx-input-radio-label {
    display: inline-block;
    padding: 0 16px;
    line-height: 30px;
    color: #6f7072; }
  .sgx-input-radio-list--toggle.sgx-input--has-error .sgx-input-radio-label {
    color: #6f7072; }
  .sgx-input-radio-list--toggle :checked + .sgx-input-radio-label {
    width: 100%;
    border-radius: 3px;
    color: #f7f7f7;
    background-color: #0094b3; }
  .sgx-input-radio-list--toggle :active:not(:disabled) + .sgx-input-radio-label {
    width: 100%;
    border-radius: 3px;
    color: #f7f7f7;
    background-color: #006f86; }
  .sgx-input-radio-list--toggle :hover:not(:disabled):not(:checked):not(:active) + .sgx-input-radio-label {
    color: #1d1d1d; }
  .sgx-input-radio-list--toggle .sgx-input-radio-label:before {
    display: none; }
  .sgx-input-radio-label:before {
    margin-right: 8px;
    icon-font: url(sgx-base-code/svgs/regular/circle.svg); }
  :checked + .sgx-input-radio-label:before {
    icon-font: url(sgx-base-code/svgs/regular/dot-circle.svg); }
  .sgx-input-radio-label:hover:before {
    color: #80cad9; }
  :focus + .sgx-input-radio-label:before {
    color: #0094b3; }
  .sgx-input--has-error .sgx-input-radio-label {
    color: #1d1d1d; }
  :disabled + .sgx-input-radio-label {
    color: #c7c7c8 !important; }
    :disabled + .sgx-input-radio-label:before {
      color: inherit !important; }
  .sgx-input[readonly] input:not(:checked) + .sgx-input-radio-label {
    display: none; }
    .sgx-input[readonly] input:not(:checked) + .sgx-input-radio-label:before {
      display: none; }
  .sgx-input[readonly] input:checked + .sgx-input-radio-label:before {
    display: none; }

sgx-input-checkbox {
  height: auto; }
  sgx-input-checkbox .sgx-input-control {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    border: 0 !important; }
  sgx-input-checkbox[readonly] {
    pointer-events: none; }
    sgx-input-checkbox[readonly] .sgx-input-control {
      display: none; }

.sgx-input-checkbox-label {
  display: flex;
  align-items: center;
  color: #1d1d1d;
  font: normal 16px/1.3 "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .sgx-input-checkbox-label:before {
    margin-right: 8px;
    icon-font: url(sgx-base-code/svgs/regular/square.svg); }
  :checked + .sgx-input-checkbox-label:before {
    icon-font: url(sgx-base-code/svgs/regular/check-square.svg); }
  :indeterminate + .sgx-input-checkbox-label:before {
    icon-font: url(sgx-base-code/svgs/regular/minus-square.svg); }
  .sgx-input-checkbox-label:hover:before {
    color: #80cad9; }
  :focus + .sgx-input-checkbox-label:before {
    color: #0094b3; }
  .sgx-input--has-error .sgx-input-checkbox-label {
    color: #1d1d1d; }
  :disabled + .sgx-input-checkbox-label,
  :disabled + .sgx-input-checkbox-label:before {
    color: #c7c7c8 !important; }
  .sgx-input-checkbox--right .sgx-input-checkbox-label {
    justify-content: space-between; }
    .sgx-input-checkbox--right .sgx-input-checkbox-label:before {
      order: 1;
      margin-right: 0;
      margin-left: 8px; }
  .sgx-input-checkbox--switch .sgx-input-checkbox-label:before {
    icon-font: url(sgx-base-code/svgs/light/toggle-off.svg);
    font-size: 20px; }
  .sgx-input-checkbox--switch :checked + .sgx-input-checkbox-label:before {
    icon-font: url(sgx-base-code/svgs/solid/toggle-on.svg); }
  .sgx-input-checkbox--switch :checked + .sgx-input-checkbox-label:before:indeterminate {
    icon-font: url(sgx-base-code/svgs/light/toggle-off.svg); }

.sgx-input-textarea-input {
  height: auto;
  padding: 0.5rem; }
  .sgx-input[readonly] .sgx-input-textarea-input {
    resize: none;
    min-height: 25px;
    height: auto; }

sgx-input-toggle {
  width: 100%; }

.sgx-input-toggle-gui-wrapper {
  width: 100%;
  padding-top: 11px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.sgx-input-toggle-centrebox {
  width: 40px;
  height: 20px;
  position: relative; }

.sgx-input-toggle-sidebox {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 0 0.4em 0 0.4em; }

.sgx-input-toggle-sidebox-left {
  justify-content: flex-end; }

.sgx-input-toggle-sidebox-right {
  justify-content: flex-start; }

.sgx-input-toggle-sidelabel {
  transition: opacity 0.12s ease-in; }
  [disabled] .sgx-input-toggle-sidelabel {
    color: rgba(34, 34, 34, 0.5); }

.sgx-input-toggle-sidelabel-left {
  text-align: right; }
  [data-isleft='false'] .sgx-input-toggle-sidelabel-left {
    opacity: 0.4; }

.sgx-input-toggle-sidelabel-right {
  text-align: left; }
  [data-isleft='true'] .sgx-input-toggle-sidelabel-right {
    opacity: 0.4; }

.sgx-input-toggle-pill {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #efefef;
  z-index: 1;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  border: 0;
  cursor: pointer; }
  input:focus + .sgx-input-toggle-pill {
    box-shadow: 0 0 2px 3px #40afc6; }

.sgx-input-toggle-circle {
  pointer-events: none;
  z-index: 2;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #ffffff;
  transition: left 0.12s ease-in; }
  [data-isleft="true"] .sgx-input-toggle-circle {
    left: 1px; }
  [data-isleft="false"] .sgx-input-toggle-circle {
    left: 21px; }

sgx-input-file {
  max-width: 80vw; }
  sgx-input-file input[type=file] {
    display: block !important;
    position: absolute !important;
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important; }
    sgx-input-file input[type=file]:disabled {
      display: none !important; }

.sgx-input-file-wrapper {
  position: relative;
  display: inline-block;
  width: 100%; }
  .sgx-input-file-wrapper:after {
    icon-font: url(sgx-base-code/svgs/regular/paperclip.svg);
    font-size: 14px;
    color: #1d1d1d;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%); }
  .sgx-input[readonly] .sgx-input-file-wrapper {
    display: none; }

.sgx-input-file-handle {
  cursor: pointer;
  padding-right: 32px; }

sgx-file-preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  margin: 4px 0; }

.sgx-file-preview-img,
.sgx-file-preview-icon {
  justify-self: flex-start;
  width: 50%;
  flex: 0 0 auto;
  height: auto; }

.sgx-file-preview-img {
  max-width: 200px; }

.sgx-file-preview-icon {
  width: auto;
  font-size: 20px;
  color: #1d1d1d; }
  [data-type=any] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file.svg); }
  [data-type=txt] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-alt.svg); }
  [data-type=pdf] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-pdf.svg); }
  [data-type=doc] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-word.svg); }
  [data-type=xls] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-excel.svg); }
  [data-type=ppt] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-powerpoint.svg); }
  [data-type=img] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-image.svg); }
  [data-type=vid] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-video.svg); }
  [data-type=zip] > .sgx-file-preview-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/file-archive.svg); }

.sgx-file-preview-link {
  justify-self: flex-end;
  display: flex;
  justify-content: flex-start;
  margin: 0 8px;
  flex: 1 1 auto;
  width: 33%; }
  [data-type="img"] > .sgx-file-preview-link {
    justify-content: flex-end; }
  .sgx-file-preview-link:before {
    content: attr(data-name);
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .sgx-file-preview-link:after {
    content: attr(data-ext);
    flex: 0 0 auto; }

.sgx-file-preview-clear {
  justify-self: flex-end;
  background: 0;
  border: 0;
  padding: 0;
  margin-right: 8px;
  color: #1d1d1d; }
  .sgx-input[readonly] .sgx-file-preview-clear {
    display: none; }
  .sgx-file-preview-clear:before {
    icon-font: url(sgx-base-code/svgs/regular/times.svg); }

sgx-input-hidden.sgx-input {
  display: none !important; }

sgx-form .sgx-input + sgx-form .sgx-input,
.sgx-form .sgx-input + sgx-form .sgx-input, sgx-form .sgx-input +
.sgx-form .sgx-input,
.sgx-form .sgx-input +
.sgx-form .sgx-input {
  margin-left: 0.25rem; }

sgx-form .form-row,
.sgx-form .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.25rem;
  margin-left: -0.25rem; }
  sgx-form .form-row > .col,
  sgx-form .form-row > [class*="col-"],
  .sgx-form .form-row > .col,
  .sgx-form .form-row > [class*="col-"] {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
    margin-left: initial;
    margin-bottom: 0.5rem; }
  sgx-form .form-row:not(:last-child),
  .sgx-form .form-row:not(:last-child) {
    margin-top: 0.5rem; }

.form-row sgx-form .sgx-input-hint-icon, .form-row
.sgx-form .sgx-input-hint-icon {
  right: 0.25rem; }

.form-row sgx-form .sgx-input-hint, .form-row
.sgx-form .sgx-input-hint {
  right: 0.25rem; }

fieldset {
  position: relative; }

fieldset[disabled]:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

sgx-dialog {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: visible; }
  sgx-dialog.is-visible {
    display: block; }
  sgx-dialog.sgx-dialog--centered {
    position: static; }

.sgx-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.9); }
  body[data-type=mobile] .sgx-dialog-overlay {
    background: white; }
  .sgx-dialog--centered .sgx-dialog-overlay {
    animation: fadeIn 0.2s ease-out; }

.sgx-dialog-box {
  position: absolute;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: 0 8px 15px 0px rgba(34, 34, 34, 0.4);
  z-index: 1001;
  max-height: 85vh;
  animation: fadeIn 0.2s ease-out;
  padding: 20px 12px 12px; }
  @media (min-width: 1024px) {
    .sgx-dialog-box {
      padding: 32px 20px 20px; } }
  .sgx-dialog--centered .sgx-dialog-box {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
    border: 0;
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px); }
    @media (min-width: 600px) {
      .sgx-dialog--centered .sgx-dialog-box {
        min-width: calc((100vw * 0.5) - 20px);
        max-width: calc(100vw - 20px * 2); } }
    @media (min-width: 768px) {
      .sgx-dialog--centered .sgx-dialog-box {
        min-width: calc((100vw * 0.5) - 40px);
        max-width: calc(100vw - 40px * 2); } }
    @media (min-width: 1024px) {
      .sgx-dialog--centered .sgx-dialog-box {
        min-width: calc((100vw * 0.33333) - 50px);
        max-width: calc(100vw - 50px * 2); } }
    @media (min-width: 1280px) {
      .sgx-dialog--centered .sgx-dialog-box {
        min-width: calc((100vw * 0.33333) - 65px);
        max-width: calc(100vw - 65px * 2); } }
    @media (min-width: 1366px) {
      .sgx-dialog--centered .sgx-dialog-box {
        min-width: calc((1366px * 0.33333) - 65px);
        max-width: calc(1366px - 65px * 2); } }
  .sgx-dialog--fullscreen .sgx-dialog-box {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
    width: 100% !important;
    height: 100% !important;
    max-height: none !important; }
  .sgx-dialog--attached .sgx-dialog-box {
    padding: 0;
    background: #f7f7f7; }
  .sgx-dialog-box.tether-target-attached-bottom {
    box-shadow: 0px 4px 8px -2px rgba(34, 34, 34, 0.4); }
  .sgx-dialog-box.tether-target-attached-top {
    top: -3px !important;
    box-shadow: 0px -4px 8px -2px rgba(34, 34, 34, 0.4); }

.sgx-dialog-header {
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  font-size: 20px;
  color: #1d1d1d;
  line-height: 1.3;
  margin-bottom: 20px; }
  @media (min-width: 1024px) {
    .sgx-dialog-header {
      margin-bottom: 32px; } }
  .sgx-dialog-header > [data-dialog=title] {
    font-size: inherit;
    margin: 0; }

.sgx-dialog-body {
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.sgx-dialog-footer {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row-reverse;
  margin-top: 12px; }
  @media (min-width: 1024px) {
    .sgx-dialog-footer {
      margin-top: 20px; } }
  .sgx-dialog-footer > * {
    margin-left: 12px; }

sgx-tooltip {
  display: inline-block;
  position: relative; }

[data-sgx-tooltip-content] {
  display: none; }

.sgx-tooltip-button {
  display: inline-block;
  width: 24px;
  height: 24px;
  min-width: auto;
  padding: 0;
  font-size: 14px;
  color: #6f7072;
  border: 0 !important; }
  .sgx-tooltip-button:after {
    icon-font: url(sgx-base-code/svgs/solid/question-circle.svg); }
  .sgx-tooltip--active .sgx-tooltip-button {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box {
  border-width: 0px;
  border-radius: 5px;
  background-color: #47484a;
  box-shadow: 0 1px 9px 2px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  max-height: 45vh; }
  @media (max-width: 767px) {
    #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box {
      max-width: 95vw; } }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-bottom {
    top: 8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-top {
    top: -8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-right {
    left: 8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-left {
    left: -8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-pinned-left {
    margin-left: 8px; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-pinned-right {
    margin-left: -8px; }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-body {
  color: rgba(255, 255, 255, 0.87); }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body {
  padding: 4px 12px; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body a {
    color: #4da0db; }
    #sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body a:hover {
      color: #0d7ecf; }

.tether-target.sgx-tooltip--active.sgx-tooltip--positioned-target {
  position: relative; }

.tether-target.sgx-tooltip--active:before {
  content: "";
  position: absolute;
  z-index: 1;
  animation: fadeIn .2s ease-out; }

.tether-target.sgx-tooltip--active.tether-target-attached-top.tether-element-attached-bottom:before, .tether-target.sgx-tooltip--active.tether-target-attached-bottom.tether-element-attached-top:before {
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; }

.tether-target.sgx-tooltip--active.tether-target-attached-top.tether-element-attached-bottom:before {
  top: -9px;
  border-top: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-bottom.tether-element-attached-top:before {
  bottom: -7px;
  border-bottom: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-left.tether-element-attached-right:before, .tether-target.sgx-tooltip--active.tether-target-attached-right.tether-element-attached-left:before {
  top: 50%;
  transform: translateY(-50%);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent; }

.tether-target.sgx-tooltip--active.tether-target-attached-left.tether-element-attached-right:before {
  left: -9px;
  border-left: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-right.tether-element-attached-left:before {
  right: -7px;
  border-right: 8px solid #47484a; }

.sgx-context-menu-item {
  display: block;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none; }
  .sgx-context-menu-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .sgx-context-menu-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .sgx-context-menu-item:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #80cad9;
    opacity: 1; }
  .sgx-context-menu-item.sgx-context-menu-item--disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background: transparent !important;
    cursor: default !important; }

.sgx-context-menu-item--icon {
  display: inline-block;
  width: 40px;
  text-align: center;
  margin-left: -12px; }

.lm_root {
  position: relative; }

.lm_row > .lm_item {
  float: left; }

.lm_content {
  overflow: hidden;
  position: relative; }

.lm_dragging,
.lm_dragging * {
  cursor: move !important;
  user-select: none; }

.lm_maximised {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 40; }

.lm_maximise_placeholder {
  display: none; }

.lm_splitter {
  position: relative;
  z-index: 2; }

.lm_splitter:hover,
.lm_splitter.lm_dragging {
  background: orange; }

.lm_splitter.lm_vertical .lm_drag_handle {
  width: 100%;
  position: absolute;
  cursor: ns-resize; }
  .lm_splitter.lm_vertical .lm_drag_handle:before {
    content: "";
    background: rgba(0, 0, 0, 0.2);
    height: 4px;
    border-radius: 20px;
    width: 60px;
    position: absolute;
    left: 50%;
    top: 35%; }

.lm_splitter.lm_horizontal {
  float: left;
  height: 100%; }

.lm_splitter.lm_horizontal .lm_drag_handle {
  height: 100%;
  position: absolute;
  cursor: ew-resize; }
  .lm_splitter.lm_horizontal .lm_drag_handle:before {
    content: "";
    background: rgba(0, 0, 0, 0.2);
    width: 4px;
    border-radius: 20px;
    height: 60px;
    position: absolute;
    left: 35%;
    top: 50%; }

.lm_header {
  overflow: visible;
  position: relative;
  z-index: 1;
  user-select: none; }

.lm_header.lm_selectable {
  cursor: pointer; }

.lm_header [class^=lm_] {
  box-sizing: content-box !important; }

.lm_header .lm_controls {
  position: absolute;
  right: 3px; }

.lm_header .lm_controls > li {
  cursor: pointer;
  float: left;
  width: 18px;
  height: 18px;
  text-align: center; }

.lm_header ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.lm_header .lm_tabs {
  position: absolute;
  display: flex;
  align-items: center; }

.lm_header .lm_tab {
  cursor: pointer;
  float: left;
  height: 20px;
  margin-top: 1px;
  padding: 0px 10px 5px;
  padding-right: 25px;
  position: relative;
  line-height: 1.5; }

.lm_header .lm_tab i {
  width: 2px;
  height: 19px;
  position: absolute; }

.lm_header .lm_tab i.lm_left {
  top: 0;
  left: -2px; }

.lm_header .lm_tab i.lm_right {
  top: 0;
  right: -2px; }

.lm_header .lm_tab .lm_title {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis; }
  .lm_header .lm_tab .lm_title:hover {
    cursor: move; }

.lm_header .lm_tab .lm_close_tab {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center; }

.lm_stack {
  position: relative; }

.lm_stack.lm_left > .lm_items {
  position: absolute;
  left: 20px;
  top: 0; }

.lm_stack.lm_right > .lm_items {
  position: absolute;
  right: 20px;
  top: 0; }

.lm_stack.lm_right > .lm_header {
  position: absolute;
  right: 0;
  top: 0; }

.lm_stack.lm_bottom > .lm_items {
  position: absolute;
  bottom: 20px; }

.lm_stack.lm_bottom > .lm_header {
  position: absolute;
  bottom: 0; }

.lm_stack.lm_docked {
  overflow: visible;
  z-index: 3; }

.lm_stack.lm_docked > .lm_header {
  z-index: 5; }

.lm_stack.lm_docked > .lm_items {
  *z-index: 3;
  transition: height 0.4s ease-in;
  border: solid 2px;
  border-image-slice: 1 !important;
  margin: -2px 0; }

.lm_stack.lm_docked.lm_left > .lm_items,
.lm_stack.lm_docked.lm_right > .lm_items {
  transition: width 0.4s ease-in, height 0.001s linear; }

.lm_left.lm_stack .lm_header,
.lm_right.lm_stack .lm_header {
  height: 100%; }

.lm_left.lm_dragProxy .lm_header,
.lm_right.lm_dragProxy .lm_header,
.lm_left.lm_dragProxy .lm_items,
.lm_right.lm_dragProxy .lm_items {
  float: left; }

.lm_left.lm_dragProxy .lm_header,
.lm_right.lm_dragProxy .lm_header,
.lm_left.lm_stack .lm_header,
.lm_right.lm_stack .lm_header {
  width: 20px;
  vertical-align: top; }

.lm_left.lm_dragProxy .lm_header .lm_tabs,
.lm_right.lm_dragProxy .lm_header .lm_tabs,
.lm_left.lm_stack .lm_header .lm_tabs,
.lm_right.lm_stack .lm_header .lm_tabs {
  transform-origin: left top;
  top: 0;
  width: 1000px;
  /*hack*/ }

.lm_left.lm_dragProxy .lm_header .lm_controls,
.lm_right.lm_dragProxy .lm_header .lm_controls,
.lm_left.lm_stack .lm_header .lm_controls,
.lm_right.lm_stack .lm_header .lm_controls {
  bottom: 0; }

.lm_dragProxy.lm_left .lm_header .lm_tabs,
.lm_stack.lm_left .lm_header .lm_tabs {
  transform: rotate(-90deg) scaleX(-1);
  left: 0; }

.lm_dragProxy.lm_left .lm_header .lm_tabs .lm_tab,
.lm_stack.lm_left .lm_header .lm_tabs .lm_tab {
  transform: scaleX(-1);
  margin-top: 1px; }

.lm_dragProxy.lm_left .lm_header .lm_tabdropdown_list,
.lm_stack.lm_left .lm_header .lm_tabdropdown_list {
  top: initial;
  right: initial;
  left: 20px; }

.lm_dragProxy.lm_right .lm_content {
  float: left; }

.lm_dragProxy.lm_right .lm_header .lm_tabs,
.lm_stack.lm_right .lm_header .lm_tabs {
  transform: rotate(90deg) scaleX(1);
  left: 100%;
  margin-left: 0; }

.lm_dragProxy.lm_right .lm_header .lm_controls,
.lm_stack.lm_right .lm_header .lm_controls {
  left: 3px; }

.lm_dragProxy.lm_right .lm_header .lm_tabdropdown_list,
.lm_stack.lm_right .lm_header .lm_tabdropdown_list {
  top: initial;
  right: 20px; }

.lm_dragProxy.lm_bottom .lm_header,
.lm_stack.lm_bottom .lm_header {
  width: 100%; }

.lm_dragProxy.lm_bottom .lm_header .lm_tab,
.lm_stack.lm_bottom .lm_header .lm_tab {
  margin-top: 0;
  border-top: none; }

.lm_dragProxy.lm_bottom .lm_header .lm_controls,
.lm_stack.lm_bottom .lm_header .lm_controls {
  top: 3px; }

.lm_dragProxy.lm_bottom .lm_header .lm_tabdropdown_list,
.lm_stack.lm_bottom .lm_header .lm_tabdropdown_list {
  top: initial;
  bottom: 20px; }

.lm_drop_tab_placeholder {
  float: left;
  width: 100px;
  height: 10px;
  visibility: hidden; }

.lm_header .lm_controls .lm_tabdropdown:before {
  content: '';
  width: 0;
  height: 0;
  vertical-align: middle;
  display: inline-block;
  border-top: 5px dashed;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  color: white; }

.lm_header .lm_tabdropdown_list {
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 5;
  overflow: hidden; }

.lm_header .lm_tabdropdown_list .lm_tab {
  clear: both;
  padding-right: 10px;
  margin: 0; }

.lm_header .lm_tabdropdown_list .lm_tab .lm_title {
  width: 100px; }

.lm_header .lm_tabdropdown_list .lm_close_tab {
  display: none !important; }

/***********************************
* Drag Proxy
***********************************/
.lm_dragProxy {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30; }

.lm_dragProxy .lm_header {
  background: transparent; }

.lm_dragProxy .lm_content {
  border-top: none;
  overflow: hidden; }

.lm_dropTargetIndicator {
  display: none;
  position: absolute;
  z-index: 20;
  transition: all 200ms ease; }

.lm_dropTargetIndicator .lm_inner {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0; }

.lm_transition_indicator {
  display: none;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20; }

.lm_popin {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9999; }

.lm_popin > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

.lm_popin > .lm_bg {
  z-index: 10; }

.lm_popin > .lm_icon {
  z-index: 20; }

.lm_goldenlayout {
  background: transparent; }

.lm_content {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); }

.lm_dragProxy .lm_content {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9); }

.lm_dropTargetIndicator {
  box-shadow: inset 0 0 30px #000000;
  outline: 1px dashed #cccccc; }

.lm_dropTargetIndicator .lm_inner {
  background: #000000;
  opacity: 0.2; }

.lm_splitter {
  transition: opacity 200ms ease; }

.lm_splitter:hover,
.lm_splitter.lm_dragging {
  background: #a6dae4; }

.lm_header {
  height: 20px; }

.lm_header .lm_tab {
  font-size: 12px;
  margin-right: 4px;
  padding-bottom: 2px;
  padding-top: 2px;
  color: rgba(0, 0, 0, 0.6);
  background: #f7f7f7;
  box-shadow: 0 -1px 1px 0px rgba(0, 0, 0, 0.1); }

.lm_header .lm_tab .lm_close_tab {
  width: 11px;
  height: 11px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAQUlEQVR4nHXOQQ4AMAgCQeT/f6aXpsGK3jSTuCVJAAr7iBdoAwCKd0nwfaAdHbYERw5b44+E8JoBjEYGMBq5gAYP3usUDu2IvoUAAAAASUVORK5CYII=");
  background-position: center center;
  background-repeat: no-repeat;
  top: 4px;
  right: 6px;
  opacity: 0.4; }

.lm_header .lm_tab .lm_close_tab:hover {
  opacity: 1; }

.lm_header .lm_tab.lm_active {
  border-bottom: none;
  padding-bottom: 3px; }

.lm_header .lm_tab.lm_active .lm_close_tab {
  opacity: 1; }

.lm_dragProxy.lm_right .lm_header .lm_tab.lm_active,
.lm_stack.lm_right .lm_header .lm_tab.lm_active {
  box-shadow: 2px -2px 2px #000000; }

.lm_dragProxy.lm_bottom .lm_header .lm_tab,
.lm_stack.lm_bottom .lm_header .lm_tab {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3); }

.lm_dragProxy.lm_bottom .lm_header .lm_tab.lm_active,
.lm_stack.lm_bottom .lm_header .lm_tab.lm_active {
  box-shadow: 0 2px 2px #000000; }

.lm_selected .lm_header {
  background-color: #452500; }

.lm_tab:hover {
  background: white; }

.lm_tab.lm_active {
  color: rgba(0, 0, 0, 0.87);
  background: white; }

.lm_header .lm_controls .lm_tabdropdown:before {
  color: #ffffff; }

.lm_controls > li {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0.4;
  transition: opacity 300ms ease; }

.lm_controls > li:hover {
  opacity: 1; }

.lm_controls .lm_popout:before {
  icon-font: url(sgx-base-code/svgs/light/window-restore.svg);
  font-size: 12px; }

.lm_controls .lm_maximise:before {
  icon-font: url(sgx-base-code/svgs/light/window-maximize.svg);
  font-size: 12px; }

.lm_controls .lm_close:before {
  icon-font: url(sgx-base-code/svgs/light/times.svg);
  font-size: 12px; }

.lm_controls .lm_dock {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH4QQLExoPDb+5JQAAAKZJREFUOMvV0tENgjAUhWFGYBQ20FG6iYzgBo4gG8gIbAAbyAafD5akwWIATYz3sWn+e/qfFsUvBsevQDzn8gmkRI8a3W4YGjQJdD0MFQ44YUQ5S/geFgF99NFhyEmOz5QtIG4a0aYJMneuSwvmiUaEhebu0Vm51s8LLKaotzY1OQjJWbvpUyJESD0li26g2goJs2feMOyCZJxVuyFp7WvTnJcg/zkPjGQks0ox9/8AAAAASUVORK5CYII=");
  transform: rotate(-45deg);
  transition: transform 300ms; }

.lm_stack.lm_docked .lm_controls .lm_dock {
  transform: rotate(0deg); }

.lm_stack.lm_docked > .lm_items {
  border-color: #555555;
  border-image: linear-gradient(to right, #444444 1%, #999999 100%);
  box-shadow: 0 2px 2px #000000; }

.lm_maximised .lm_controls .lm_maximise:before {
  icon-font: url(sgx-base-code/svgs/light/window-minimize.svg);
  font-size: 12px; }

.lm_transition_indicator {
  background-color: #000000;
  border: 1px dashed #555555; }

.lm_popin {
  cursor: pointer; }

.lm_popin .lm_bg {
  background: #ffffff;
  opacity: 0.3; }

.lm_popin .lm_icon {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAJCAYAAADpeqZqAAAAWklEQVR4nJWOyw3AIAxDHcQC7L8jbwT3AlJBfNp3SiI7dtRaLSlKKeoA1oEsKSQZCEluexw8Tm3ohk+E7bnOUHUGcNh+HwbBygw4AZ7FN/Lt84p0l+yTflV8AKQyLdcCRJi/AAAAAElFTkSuQmCC");
  background-position: center center;
  background-repeat: no-repeat;
  border-left: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  opacity: 0.7; }

.lm_popin:hover .lm_icon {
  opacity: 1; }

/*# sourceMappingURL=goldenlayout.css.map*/
sgx-flexible-layout-kebab {
  display: block; }
  sgx-flexible-layout-kebab.sgx-flexible-layout-tab-kebab-container {
    transition: opacity 0.2s ease-out;
    opacity: 0;
    padding: 0 12px;
    position: absolute;
    top: 5px;
    right: 1px;
    width: 7px; }
    sgx-flexible-layout-kebab.sgx-flexible-layout-tab-kebab-container:after {
      icon-font: url("sgx-base-code/svgs/light/ellipsis-v.svg");
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: bolder; }
  sgx-flexible-layout-kebab.sgx-flexible-layout-kebab-menu-container--show {
    opacity: 1; }

.sgx-flexible-layout-tab-dialog [data-dialog="body"] {
  padding: 1rem; }

sgx-flexible-layout {
  height: 100%;
  width: 100%;
  display: block; }
  sgx-flexible-layout .sgx-flexible-layout-add-tab {
    order: 1;
    color: rgba(0, 0, 0, 0.87);
    background: white;
    padding: 2px 8px; }
    sgx-flexible-layout .sgx-flexible-layout-add-tab:before {
      icon-font: url("sgx-base-code/svgs/light/plus.svg");
      font-size: 16px; }
  sgx-flexible-layout .sgx-flexible-layout--message-count {
    position: absolute;
    right: -2px;
    top: -11px;
    background: #aac22c;
    color: #ffffff;
    z-index: 4;
    padding: 2px 3px;
    border-radius: 50px; }
  sgx-flexible-layout .lm_splitter {
    z-index: 100; }
  sgx-flexible-layout .lm_active .sgx-flexible-layout--message-count {
    background: #97ac27; }
  sgx-flexible-layout .lm_tab:hover .sgx-flexible-layout-tab-kebab-container {
    opacity: 1;
    height: auto; }
  sgx-flexible-layout .lm_tab .lm_title:hover {
    cursor: pointer !important; }
  sgx-flexible-layout .lm_tab:hover {
    cursor: pointer !important; }

.sgx-flexible-layout-kebab-menu-list-item--edit:before {
  icon-font: url("sgx-base-code/svgs/light/edit.svg"); }

.sgx-flexible-layout-kebab-menu-list-item--remove:before {
  icon-font: url("sgx-base-code/svgs/light/times.svg"); }

/* SHARED */
/* NOTIFICATION ITEM */
/* NOTIFICATIONS PANEL */
sgx-notifications {
  display: block;
  height: 100%; }
  @media (max-width: 599px) {
    sgx-notifications {
      width: 100%; } }

.sgx-notifications-panel {
  width: 390px;
  display: flex;
  max-height: 100vh;
  flex-direction: column; }
  @media (max-width: 599px) {
    .sgx-notifications-panel {
      width: 100%; } }

#notifications-detached,
#notifications-attached {
  position: fixed;
  top: 0;
  z-index: 3000; }

#notifications-detached {
  right: 0;
  padding: 0 20px;
  height: auto; }

#notifications-attached {
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.4s ease; }
  #notifications-attached.active {
    transform: translateX(0%); }

.sgx-notifications-panel--attached {
  width: 390px;
  background-color: white;
  height: inherit;
  box-shadow: 2px 0 15px rgba(89, 89, 89, 0.5); }
  .sgx-notifications-panel--attached:focus {
    outline: none; }
  @media (max-width: 599px) {
    .sgx-notifications-panel--attached {
      width: 100%; } }
  .sgx-notifications-panel--attached sgx-notification:last-child .sgx-notification-container {
    box-shadow: none; }

.sgx-notifications-panel-body {
  width: 100%; }
  .sgx-notifications-panel-body--attached {
    overflow-y: auto;
    overflow-x: hidden; }

.sgx-notifications-panel-body--attached:empty {
  margin: 108px 0;
  text-align: center;
  color: #d7d7d8; }
  .sgx-notifications-panel-body--attached:empty:before, .sgx-notifications-panel-body--attached:empty:after {
    display: block; }
  .sgx-notifications-panel-body--attached:empty:before {
    font-size: 6rem;
    icon-font: url(sgx-base-code/svgs/light/bell.svg); }
  .sgx-notifications-panel-body--attached:empty:after {
    margin-top: 8px;
    content: attr(data-no-activity-message); }

.sgx-notifications-panel-body-outer {
  position: relative;
  display: flex;
  flex: 1 1 auto; }

.sgx-notifications-panel-body-outer--shadow-top:before {
  box-shadow: 0 1px 20px -1px #595959; }

.sgx-notifications-panel-body-outer--shadow-bottom:after {
  box-shadow: 0 1px 20px -1px #595959; }

.sgx-notifications-panel-header {
  text-align: center; }

.sgx-notifications-panel-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding: 8px 16px; }

.sgx-notifications-panel-header-container .sgx-button--link {
  text-decoration: none;
  padding: 0; }

.sgx-notifications-panel-header-dismiss-button,
.sgx-notifications-panel-header-alert-button {
  flex: 0 0 10%;
  min-width: 0; }
  .sgx-notifications-panel-header-dismiss-button:before,
  .sgx-notifications-panel-header-alert-button:before {
    color: #6f7072; }

.sgx-notifications-panel-header-dismiss-button:hover:before,
.sgx-notifications-panel-header-alert-button:hover:before {
  color: #7f8081; }

.sgx-notifications-panel-header-dismiss-button:before {
  icon-font: url(sgx-base-code/svgs/regular/arrow-left.svg); }

.sgx-notifications-panel-header-alert-button:before {
  icon-font: url(sgx-base-code/svgs/regular/volume-up.svg); }

.sgx-notifications-panel-header-alert-button--muted:before {
  icon-font: url(sgx-base-code/svgs/regular/volume-slash.svg); }

.sgx-notifications-panel-header-title {
  flex: 0 0 80%;
  padding: 0;
  margin: 8px 0;
  color: #6f7072; }

.sgx-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; }

.sgx-notification--attached {
  height: auto; }

.sgx-notification--entry {
  animation-name: sgx-notifications-entry-container-dettached;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; }

.sgx-notification--entry.sgx-notification--attached {
  animation-name: sgx-notifications-entry-container; }

.sgx-notification--remove {
  animation-name: sgx-notifications-remove-container-dettached;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.4s; }

.sgx-notification--remove.sgx-notification--attached {
  animation-name: sgx-notifications-remove-container;
  animation-delay: 0.4s; }

.sgx-notification-container {
  transform: translateX(100%);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background-color: white;
  box-shadow: 0 2px 6px 0 rgba(89, 89, 89, 0.5); }

.sgx-notification--remove .sgx-notification-container {
  transform: translateX(0%);
  animation-duration: 0.4s;
  animation-name: sgx-notifications-remove;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0s; }

.sgx-notification--entry .sgx-notification-container {
  animation-duration: 0.4s;
  animation-name: sgx-notifications-entry;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.4s; }

.sgx-notification-body {
  flex: 1 1 auto;
  overflow: hidden;
  cursor: pointer;
  padding: 8px 16px; }

.sgx-notification-title {
  color: #bf0052; }

.sgx-notification-actions {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 75px; }

.sgx-notification-actions button {
  border: 1px solid #c7c7c8;
  background-color: #f7f7f7;
  border-radius: 0;
  flex: 1 1 0%;
  color: #6f7072;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background 0.4s ease, color 0.4s ease; }
  .sgx-notification-actions button:hover {
    background: white;
    color: #1d1d1d; }
  .sgx-notification-actions button:focus {
    outline: none; }

.sgx-notification--attached {
  padding: 0; }

.sgx-notification--attached .sgx-notification-container {
  position: relative;
  border-radius: 0;
  background: white;
  transition: background 0.4s ease;
  border-bottom: 1px solid #e7e7e7; }

.sgx-notification--attached:hover .sgx-notification-container {
  background: white; }

.sgx-notification--attached .sgx-notification-actions {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.4s ease;
  background: white; }

.sgx-notification--attached:hover .sgx-notification-actions {
  transform: translateX(0%);
  background: white; }

.sgx-notification--attached .sgx-notification-actions--loading,
.sgx-notification--attached .sgx-notification-actions-confirm,
.sgx-notification--attached .sgx-notification-actions-reject {
  transform: translateX(0%); }

.sgx-notification-actions--loading:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  background: white;
  z-index: 10;
  content: ""; }

.sgx-notification-actions--loading:after {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  display: inline-block;
  transform: translateX(-50%) translateY(-50%);
  icon-font: url(sgx-base-code/svgs/light/spinner-third.svg);
  font-size: 1.8em;
  color: #5f6062;
  animation-duration: 0.4s;
  animation-name: sgx-notifications-rotate-spinner;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear; }

.sgx-notification .sgx-notification-actions-confirm {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #74AC00;
  z-index: 30;
  opacity: 0;
  animation-duration: 0.4s;
  animation-name: sgx-notifications-remove;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-direction: reverse; }

.sgx-notification .sgx-notification-actions-confirm polyline {
  stroke-dasharray: 370;
  stroke-dashoffset: -370;
  animation-duration: 0.4s;
  animation-name: sgx-notifications-svg-check;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-delay: 0.4s; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sgx-notification .sgx-notification-actions-confirm polyline {
      stroke-dashoffset: 0; } }

.sgx-notification .sgx-notification-actions-reject {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #bf0052;
  z-index: 30;
  opacity: 0;
  animation-duration: 0.4s;
  animation-name: sgx-notifications-remove;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-direction: reverse; }

.sgx-notification .sgx-notification-actions-reject path {
  stroke-dasharray: 250;
  stroke-dashoffset: -250;
  animation-duration: 0.4s;
  animation-name: sgx-notifications-svg-cross;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-delay: 0.4s; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .sgx-notification .sgx-notification-actions-reject path {
      stroke-dashoffset: 0; } }

@keyframes sgx-notifications-entry-container {
  0% {
    max-height: 0px; }
  100% {
    max-height: auto; } }

@keyframes sgx-notifications-entry-container-dettached {
  0% {
    max-height: 0px; }
  100% {
    max-height: 100px; } }

@keyframes sgx-notifications-entry {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0%); } }

@keyframes sgx-notifications-remove-container {
  0% {
    max-height: auto; }
  100% {
    max-height: 0px; } }

@keyframes sgx-notifications-remove-container-dettached {
  0% {
    max-height: 100px; }
  100% {
    max-height: 0px; } }

@keyframes sgx-notifications-remove {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes sgx-notifications-rotate-spinner {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg); }
  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg); } }

@keyframes sgx-notifications-svg-check {
  from {
    stroke-dashoffset: -370; }
  to {
    stroke-dashoffset: 0; } }

@keyframes sgx-notifications-svg-cross {
  from {
    stroke-dashoffset: -250; }
  to {
    stroke-dashoffset: 0; } }

sgx-table-list {
  display: block;
  background-color: #f7f7f7;
  text-align: center; }

.sgx-table-show-more-row-button {
  background-color: inherit;
  height: 2rem;
  line-height: 2rem;
  border-top: 1px solid silver;
  text-align: center; }

body[data-type='desktop'] sgx-table-list {
  flex: 1 0 auto;
  transform: translateY(0px);
  background-image: linear-gradient(to bottom, white, white 50%, #f7f7f7 50%, #f7f7f7);
  background-size: 100% 4rem; }

sgx-table-row {
  display: flex;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  transform: translate3d(0, 0, 0); }
  sgx-table-row:last-child {
    border-width: 0; }
  sgx-table-row:nth-child(odd) {
    background-color: white; }
  sgx-table-row:nth-child(even) {
    background-color: #f7f7f7; }
  body[data-type='desktop'] sgx-table-row {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    body[data-type='desktop'] sgx-table-row:hover {
      background: #d9eff4 !important; }
  body:not([data-type='desktop']) sgx-table-row {
    flex-wrap: wrap;
    border-top: 1px solid rgba(0, 0, 0, 0.2); }

.sgx-table-cell {
  position: relative;
  display: inline-block;
  height: 100%;
  padding: 0 0.5em;
  text-overflow: ellipsis;
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  line-height: inherit;
  background: inherit;
  color: rgba(0, 0, 0, 0.87); }
  [data-type="desktop"] .sgx-table-cell {
    flex: 0 0 auto; }
  body:not([data-type='desktop']) .sgx-table-cell {
    border-width: 0;
    margin-top: 0.5em;
    margin-bottom: 1em;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);
    height: auto;
    text-align: left;
    white-space: normal;
    word-break: break-word; }
    body:not([data-type='desktop']) .sgx-table-cell:before {
      content: attr(data-column-label);
      display: block;
      color: #9C9E9F;
      margin-bottom: 4px;
      overflow: hidden; }
  .sgx-table-cell[data-last][data-autowidth="true"] {
    border-right: 0; }
  [data-type="desktop"] .sgx-table-cell[data-frozen="true"] {
    position: sticky;
    z-index: 1;
    background: inherit; }
  [data-type="desktop"] .sgx-table-cell[data-autowidth="true"] {
    flex: 1 0 auto; }

sgx-table-cell-link.sgx-table-cell a {
  color: #0077CC;
  text-decoration: none; }
  sgx-table-cell-link.sgx-table-cell a:visited {
    color: #4099d9; }

.sgx-table-header-element {
  display: inline-block;
  position: relative;
  height: 100%;
  width: calc(100% - 5px);
  padding: 0 1rem;
  overflow: hidden;
  vertical-align: top; }
  .sgx-table-header-element > header {
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .filter-popup-opened > .sgx-table-header-element {
    overflow: visible; }
  [data-filter="false"] > .sgx-table-header-element {
    padding-right: 0; }
  [data-sort="false"] > .sgx-table-header-element {
    padding-left: 0.5em; }
  body[data-type='tablet'] .sgx-table-header-element,
  body[data-type='mobile'] .sgx-table-header-element {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; }

.table-header-filter-popup {
  display: none;
  position: absolute;
  background: white;
  color: rgba(0, 0, 0, 0.87);
  padding: 0.5rem;
  left: 0;
  top: calc(100% + 2px);
  z-index: 10;
  line-height: 1.2rem;
  border-width: 0 1px 1px 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid; }
  .table-header-filter-popup .sgx-input-hint {
    white-space: initial; }
  .table-header-filter-popup.table-header-filter-popup--active {
    left: 0;
    right: auto; }
  .table-header-filter-popup.table-header-filter-popup--flip {
    left: auto;
    right: -5px; }
  .table-header-filter-popup > footer {
    margin-top: 0.5rem;
    text-align: right; }
  .table-header-filter-popup button + button {
    margin-left: 0.5rem; }
  .table-header-filter-popup .button-cancel {
    margin-right: -0.5rem; }
  body[data-type="tablet"] .table-header-filter-popup,
  body[data-type="mobile"] .table-header-filter-popup {
    display: inline-block;
    position: static;
    height: 100%;
    padding: 0;
    background: 0;
    border: none; }
    body[data-type="tablet"] .table-header-filter-popup > header,
    body[data-type="mobile"] .table-header-filter-popup > header {
      display: none; }
    body[data-type="tablet"] .table-header-filter-popup button,
    body[data-type="mobile"] .table-header-filter-popup button {
      display: none; }

sgx-table-cell-number.sgx-table-cell {
  text-align: right; }

sgx-table-toolbar {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  color: rgba(0, 0, 0, 0.6);
  height: 2.5rem;
  line-height: 2.5rem;
  background: white; }
  sgx-table-toolbar .table-action {
    display: block;
    flex: 0 0 auto;
    height: 100%;
    margin: 0 0.5rem;
    position: relative;
    text-align: center;
    color: rgba(0, 0, 0, 0.6); }
    sgx-table-toolbar .table-action:before {
      display: inline-block;
      font-family: fontAwesome;
      margin-right: 0.25rem; }
    sgx-table-toolbar .table-action.filters {
      padding-left: 1.5rem;
      flex: 1 1 auto;
      overflow: hidden; }
      sgx-table-toolbar .table-action.filters:before {
        content: "\f0b0";
        font-family: fontAwesome;
        left: 0.5rem;
        position: absolute;
        top: 0;
        font-size: 12px; }
    sgx-table-toolbar .table-action.settings:hover, sgx-table-toolbar .table-action.download:hover {
      text-decoration: underline;
      cursor: pointer; }
      sgx-table-toolbar .table-action.settings:hover:before, sgx-table-toolbar .table-action.download:hover:before {
        text-decoration: none; }
    sgx-table-toolbar .table-action.settings:before {
      content: "\f013"; }
    sgx-table-toolbar .table-action.download:before {
      content: "\f019"; }
  sgx-table-toolbar .table-filters-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    height: 100%;
    line-height: 1;
    text-align: left;
    overflow: hidden; }
  sgx-table-toolbar .table-filters-panel {
    display: none;
    height: 10rem;
    background: #f7f7f7; }

body[data-type='tablet'] sgx-table-toolbar,
body[data-type='mobile'] sgx-table-toolbar {
  color: rgba(0, 0, 0, 0.6); }
  body[data-type='tablet'] sgx-table-toolbar .table-header-first-bar,
  body[data-type='mobile'] sgx-table-toolbar .table-header-first-bar {
    height: 2rem;
    line-height: 2rem; }
  body[data-type='tablet'] sgx-table-toolbar .table-action,
  body[data-type='mobile'] sgx-table-toolbar .table-action {
    flex: 1 0 33%;
    margin: 0; }
  body[data-type='tablet'] sgx-table-toolbar .table-action-label,
  body[data-type='mobile'] sgx-table-toolbar .table-action-label {
    display: none; }
  body[data-type='tablet'] sgx-table-toolbar .table-action.filters,
  body[data-type='mobile'] sgx-table-toolbar .table-action.filters {
    padding: 0; }
    body[data-type='tablet'] sgx-table-toolbar .table-action.filters:before,
    body[data-type='mobile'] sgx-table-toolbar .table-action.filters:before {
      content: "\f0b0";
      font-family: fontAwesome;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      text-align: center;
      font-size: 16px;
      margin-right: 0; }
    body[data-type='tablet'] sgx-table-toolbar .table-action.filters:hover,
    body[data-type='mobile'] sgx-table-toolbar .table-action.filters:hover {
      background-color: #f7f7f7; }
  body[data-type='tablet'] sgx-table-toolbar .table-action.download,
  body[data-type='mobile'] sgx-table-toolbar .table-action.download {
    box-shadow: -5px 0px 0px -4px rgba(0, 0, 0, 0.2), 5px 0px 0px -4px rgba(0, 0, 0, 0.2); }
  body[data-type='tablet'] sgx-table-toolbar .table-filters-container,
  body[data-type='mobile'] sgx-table-toolbar .table-filters-container {
    display: none; }

sgx-table-toolbar-filter {
  display: flex;
  flex: 0 1 auto;
  height: 1.5rem;
  max-width: 25%;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.34);
  border-style: solid;
  border-radius: 4px;
  overflow: hidden; }
  sgx-table-toolbar-filter + sgx-table-toolbar-filter {
    margin-left: 0.2rem; }
  sgx-table-toolbar-filter > .filter-label {
    flex: 0 0 auto;
    background: rgba(0, 0, 0, 0.34);
    color: #fff;
    padding: 0 0.2rem; }
  sgx-table-toolbar-filter > .filter-value {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 0.2rem;
    background: transparent;
    color: rgba(0, 0, 0, 0.87); }
  sgx-table-toolbar-filter > .filter-close-btn {
    color: rgba(0, 0, 0, 0.87);
    flex: 0 0 auto;
    font-size: 0.75rem;
    padding: 0 0.2rem;
    cursor: pointer;
    opacity: 0.5; }
    sgx-table-toolbar-filter > .filter-close-btn:hover {
      color: rgba(0, 0, 0, 0.87);
      opacity: 1; }
    sgx-table-toolbar-filter > .filter-close-btn:before {
      content: '\f00d';
      font-family: fontAwesome; }

#sgx-table-config-dialog sgx-select-picker {
  height: 100%;
  max-height: none;
  overflow: hidden; }

body[data-type="desktop"] #sgx-table-config-dialog .sgx-select-picker-list {
  min-height: 100%;
  height: 100%; }

body[data-type="desktop"] #sgx-table-config-dialog .sgx-dialog-footer {
  margin: 12px; }

.sgx-table-header-tooltip {
  display: block;
  position: absolute;
  z-index: 10;
  transform: translateY(-200%);
  top: 2rem;
  left: 0.25rem;
  width: calc(100% - 0.5rem);
  padding: 0.25rem;
  min-width: 10rem;
  max-width: 16rem;
  font-size: 0.75rem;
  line-height: 1.5rem;
  white-space: normal;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  border: 1px solid #BCD730;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
  opacity: 0;
  cursor: text; }
  .sgx-table-header-tooltip:empty {
    display: none !important; }
  .sgx-table-header-tooltip:after {
    content: "\f0d8";
    font-family: FontAwesome;
    position: absolute;
    top: -1rem;
    font-size: 1.5rem;
    left: 0.5rem;
    text-shadow: 0px -2px 0px #bdd831;
    color: white;
    line-height: 1; }

body[data-type=desktop] .sgx-table-header-tooltip--flipped {
  left: auto;
  right: 0.25rem; }
  body[data-type=desktop] .sgx-table-header-tooltip--flipped:after {
    left: auto;
    right: 0.5rem; }

body[data-type=desktop] .sgx-table-header-wrapper:not(.filter-popup-opened):hover .sgx-table-header-tooltip {
  transform: translateY(0);
  transition: opacity 1ms ease;
  opacity: 1; }

sgx-table-column-headers-manager {
  display: block;
  flex: 0 0 auto; }
  sgx-table-column-headers-manager .sgx-table-header-container {
    position: relative;
    height: 2rem;
    line-height: 2rem;
    background: #f7f7f7;
    color: rgba(0, 0, 0, 0.87);
    border-style: solid;
    border-width: 1px 0 2px 0;
    border-color: rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    cursor: pointer;
    z-index: 2; }
    [data-type="desktop"] sgx-table-column-headers-manager .sgx-table-header-container {
      display: flex; }
  sgx-table-column-headers-manager .sgx-table-header-overlay {
    display: none;
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;
    height: calc(100% - 2rem);
    background: rgba(0, 0, 0, 0.5);
    opacity: .6;
    z-index: 1; }
  sgx-table-column-headers-manager .sgx-table-header-wrapper {
    background: #f7f7f7;
    color: rgba(0, 0, 0, 0.87);
    display: inline-block;
    height: 100%;
    position: relative;
    z-index: 2; }
    [data-type="desktop"] sgx-table-column-headers-manager .sgx-table-header-wrapper[data-frozen=true] {
      position: sticky;
      z-index: 3; }
    [data-type="desktop"] sgx-table-column-headers-manager .sgx-table-header-wrapper {
      flex: 0 0 auto; }
    [data-type="desktop"] sgx-table-column-headers-manager .sgx-table-header-wrapper[data-autowidth="true"] {
      flex-grow: 1; }
  sgx-table-column-headers-manager .sgx-table-header-filter-button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20px;
    font-family: fontAwesome;
    font-size: 75%;
    color: rgba(0, 0, 0, 0.34);
    text-align: center; }
    sgx-table-column-headers-manager .sgx-table-header-filter-button:before {
      content: "\f0b0"; }
    sgx-table-column-headers-manager .sgx-table-header-filter-button:hover {
      color: rgba(0, 0, 0, 0.6); }
  sgx-table-column-headers-manager [data-filtered="true"] .sgx-table-header-filter-button:before {
    color: rgba(0, 0, 0, 0.87); }
  sgx-table-column-headers-manager .sgx-table-header-sort-button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(100% - 20px);
    z-index: 1;
    font-family: fontAwesome;
    font-size: 75%;
    color: rgba(0, 0, 0, 0.34);
    padding-left: 5px; }
    sgx-table-column-headers-manager .sgx-table-header-sort-button:before {
      content: "\f0dc"; }
    sgx-table-column-headers-manager .sgx-table-header-sort-button:hover {
      color: rgba(0, 0, 0, 0.6); }
  sgx-table-column-headers-manager [data-sorted="asc"] .sgx-table-header-sort-button:before {
    content: "\f0de";
    position: absolute;
    color: rgba(0, 0, 0, 0.87); }
  sgx-table-column-headers-manager [data-sorted="dsc"] .sgx-table-header-sort-button:before {
    content: "\f0dd";
    position: absolute;
    color: rgba(0, 0, 0, 0.87); }
  sgx-table-column-headers-manager [data-label=""][data-filter="false"] .sgx-table-header-sort-button {
    width: 100%;
    text-align: center;
    padding: 0; }
    sgx-table-column-headers-manager [data-label=""][data-filter="false"] .sgx-table-header-sort-button:before {
      position: static; }
  sgx-table-column-headers-manager .sgx-table-header-resizer {
    width: 5px;
    height: 100%;
    display: inline-block;
    cursor: col-resize;
    position: relative;
    border-right: 1px solid rgba(0, 0, 0, 0.2); }
  sgx-table-column-headers-manager [data-last][data-autowidth="true"] > .sgx-table-header-resizer {
    border-right: 0; }
  sgx-table-column-headers-manager [data-resizable="false"] > .sgx-table-header-resizer {
    cursor: default; }

body[data-type='tablet'] sgx-table-column-headers-manager,
body[data-type='mobile'] sgx-table-column-headers-manager {
  display: none;
  width: 100%;
  height: inherit;
  background: white; }
  body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-container,
  body[data-type='mobile'] sgx-table-column-headers-manager .sgx-table-header-container {
    height: inherit;
    line-height: inherit;
    background: #f7f7f7;
    color: black;
    border-width: 0;
    padding: 0.5rem;
    box-shadow: inset 0 0 15px #999; }
    body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-container > footer,
    body[data-type='mobile'] sgx-table-column-headers-manager .sgx-table-header-container > footer {
      padding: 1rem 0 0.5rem;
      text-align: center;
      display: block; }
      body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-container > footer button + button,
      body[data-type='mobile'] sgx-table-column-headers-manager .sgx-table-header-container > footer button + button {
        margin-left: 0.2rem; }
  body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-overlay,
  body[data-type='mobile'] sgx-table-column-headers-manager .sgx-table-header-overlay {
    top: 0; }
  body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-wrapper,
  body[data-type='mobile'] sgx-table-column-headers-manager .sgx-table-header-wrapper {
    display: flex;
    height: auto;
    width: 100% !important;
    padding: 0.5rem 0;
    border: 0;
    border-bottom: 1px solid #b5bdd2; }
    body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-wrapper header,
    body[data-type='mobile'] sgx-table-column-headers-manager .sgx-table-header-wrapper header {
      display: block;
      height: 1rem;
      line-height: 1rem; }
  body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-sort-button,
  body[data-type='mobile'] sgx-table-column-headers-manager .sgx-table-header-sort-button {
    display: none; }
  body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-filter-button,
  body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-resizer,
  body[data-type='mobile'] sgx-table-column-headers-manager .sgx-table-header-filter-button,
  body[data-type='mobile'] sgx-table-column-headers-manager .sgx-table-header-resizer {
    display: none; }

body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-container {
  display: flex;
  flex-wrap: wrap; }
  body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-container > footer {
    width: 100% !important; }

body[data-type='tablet'] sgx-table-column-headers-manager .sgx-table-header-wrapper {
  width: initial !important;
  margin: 1rem;
  flex: 1 1 auto; }

sgx-table-messages {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: white;
  opacity: .9;
  color: rgba(0, 0, 0, 0.87); }
  sgx-table-messages header {
    font-weight: bold; }
  sgx-table-messages p {
    margin: 0; }

sgx-table {
  display: flex;
  flex-direction: column;
  height: 32rem;
  font-family: Lato;
  font-size: 0.76rem;
  position: relative; }
  sgx-table .sgx-table-tabs {
    display: none;
    position: absolute;
    top: -32px;
    width: 100%; }
  sgx-table .table-container {
    position: relative;
    transform: translateZ(0); }
  sgx-table sgx-scroll-shield {
    top: 4.5rem;
    left: 0;
    right: 0;
    bottom: 0; }

sgx-table.sgx-table--with-tabs {
  border-top: 0;
  margin-top: 32px; }
  sgx-table.sgx-table--with-tabs .sgx-table-tabs {
    display: flex; }

body[data-type='desktop'] sgx-table .table-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 0; }

body[data-type='tablet'] sgx-table,
body[data-type='mobile'] sgx-table {
  height: auto;
  font-size: 1rem; }
  body[data-type='tablet'] sgx-table .table-container,
  body[data-type='mobile'] sgx-table .table-container {
    min-height: inherit; }

sgx-input-phone {
  display: inline-block;
  width: auto; }

.sgx-input-phone-input-wrapper {
  display: flex; }
  .sgx-input[readonly] .sgx-input-phone-input-wrapper:after {
    content: attr(data-value);
    display: inline-block;
    box-sizing: border-box;
    height: 36px;
    padding: 8px 12px;
    padding-left: 0;
    color: #1d1d1d; }

.sgx-input-phone-country-selector {
  cursor: pointer;
  position: relative;
  width: 130px;
  flex: 0 0 auto;
  margin-right: 8px; }
  @media (min-width: 768px) {
    .sgx-input-phone-country-selector {
      width: 200px; } }
  .sgx-input-phone-country-selector .sgx-input-control {
    position: relative;
    display: inline-block;
    min-width: none;
    width: 100%; }
  .sgx-input[readonly] .sgx-input-phone-country-selector {
    display: none; }

.sgx-input-phone-number {
  min-width: none;
  flex: 1 1 auto; }
  .sgx-input[readonly] .sgx-input-phone-number {
    display: none; }

.sgx-pagination-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, 0.6); }
  @media (min-width: 768px) {
    .sgx-pagination-top-container {
      align-items: flex-start;
      flex-direction: row; } }
  .sgx-pagination-top-container > div {
    padding: 8px; }
  .sgx-pagination-top-container sgx-input-select {
    width: 100px;
    margin: 0 4px; }

.sgx-pagination-number-records-display {
  flex: 1 1 auto;
  align-self: center; }

.sgx-pagination-page-size {
  display: none; }
  @media (min-width: 768px) {
    .sgx-pagination-page-size {
      display: flex;
      align-items: center;
      justify-content: center; } }

.sgx-pagination-navigator,
.sgx-pagination-page-selector-container {
  display: flex;
  align-items: center;
  justify-content: center; }

.sgx-pagination-navigator {
  padding: 8px 8px; }

.sgx-pagination-page-selector-container {
  padding: 0 8px; }

.sgx-pagination-prev:before {
  padding-right: 8px;
  icon-font: url(sgx-base-code/svgs/light/chevron-left.svg); }

.sgx-pagination-next:after {
  padding-left: 8px;
  icon-font: url(sgx-base-code/svgs/light/chevron-right.svg); }

.sgx-pagination-max-page {
  padding-left: 4px; }

.sgx-pagination--disabled {
  display: none; }

sgx-scroll-shield {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  sgx-scroll-shield * {
    transition: background-color 0.2s ease, color 0.2s ease, opacity 0.2s ease; }
  sgx-scroll-shield.sgx-scroll-shield--scrolling {
    z-index: 100; }
  sgx-scroll-shield:active .table-horizontal-scrolling-wrapper,
  sgx-scroll-shield:active .table-vertical-scrolling-wrapper {
    background: rgba(0, 0, 0, 0.09); }
  sgx-scroll-shield:active [class^=sgx-scroll-shield-button] {
    opacity: 1; }
  sgx-scroll-shield .table-horizontal-scrolling-wrapper,
  sgx-scroll-shield .table-vertical-scrolling-wrapper {
    display: none;
    position: absolute;
    z-index: 100;
    background: 0; }
  sgx-scroll-shield.both-scrollbars:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    width: 12px;
    height: 12px;
    background: rgba(0, 0, 0, 0.2);
    opacity: 0; }
  sgx-scroll-shield.sgx-scroll-shield--near-bar .table-horizontal-scrolling-wrapper,
  sgx-scroll-shield.sgx-scroll-shield--near-bar .table-vertical-scrolling-wrapper {
    background: rgba(0, 0, 0, 0.09); }
  sgx-scroll-shield.sgx-scroll-shield--near-bar [class^=sgx-scroll-shield-button] {
    opacity: 1; }
  sgx-scroll-shield.sgx-scroll-shield--near-bar.both-scrollbars:after {
    opacity: 1; }
  sgx-scroll-shield .table-horizontal-scrolling-wrapper {
    bottom: 0;
    right: 12px;
    left: 0;
    height: 12px; }
  sgx-scroll-shield .table-vertical-scrolling-wrapper {
    width: 12px;
    top: 0;
    right: 0;
    bottom: 12px;
    flex-direction: column; }
  sgx-scroll-shield .horizontal-scrolling-bar-container,
  sgx-scroll-shield .vertical-scrolling-bar-container {
    flex-grow: 1; }
  sgx-scroll-shield [class^=sgx-scroll-shield-button] {
    width: 12px;
    height: 12px;
    font-size: 11px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    background: 0;
    opacity: 0;
    cursor: pointer; }
    :hover > sgx-scroll-shield [class^=sgx-scroll-shield-button] {
      background: rgba(0, 0, 0, 0.09);
      opacity: 1; }
    sgx-scroll-shield [class^=sgx-scroll-shield-button]:hover {
      color: rgba(0, 0, 0, 0.87);
      background: #d9eff4; }
    sgx-scroll-shield [class^=sgx-scroll-shield-button]:active {
      background: #a6dae4; }
    sgx-scroll-shield [class^=sgx-scroll-shield-button]:disabled {
      cursor: default;
      background: 0 !important;
      color: rgba(0, 0, 0, 0.2); }
  sgx-scroll-shield .sgx-scroll-shield-button--left:before {
    icon-font: url(sgx-base-code/svgs/solid/caret-left.svg); }
  sgx-scroll-shield .sgx-scroll-shield-button--right:before {
    icon-font: url(sgx-base-code/svgs/solid/caret-right.svg); }
  sgx-scroll-shield .sgx-scroll-shield-button--up:before {
    icon-font: url(sgx-base-code/svgs/solid/caret-up.svg); }
  sgx-scroll-shield .sgx-scroll-shield-button--down:before {
    icon-font: url(sgx-base-code/svgs/solid/caret-down.svg); }
  sgx-scroll-shield .horizontal-scrolling-bar,
  sgx-scroll-shield .vertical-scrolling-bar {
    background: rgba(0, 0, 0, 0.09); }
    :hover > sgx-scroll-shield .horizontal-scrolling-bar, :hover >
    sgx-scroll-shield .vertical-scrolling-bar {
      background: rgba(0, 0, 0, 0.2); }
    sgx-scroll-shield .horizontal-scrolling-bar:hover,
    sgx-scroll-shield .vertical-scrolling-bar:hover {
      background: rgba(128, 202, 217, 0.8); }
    sgx-scroll-shield .horizontal-scrolling-bar:active,
    sgx-scroll-shield .vertical-scrolling-bar:active {
      background: rgba(0, 148, 179, 0.8); }
  sgx-scroll-shield .horizontal-scrolling-bar {
    height: 100%; }
  sgx-scroll-shield .vertical-scrolling-bar {
    width: 100%; }
  sgx-scroll-shield.only-horizontal-scrollbar .table-horizontal-scrolling-wrapper {
    right: 1px; }
  sgx-scroll-shield.only-horizontal-scrollbar .table-vertical-scrolling-wrapper {
    display: none; }
  sgx-scroll-shield.only-vertical-scrollbar .table-horizontal-scrolling-wrapper {
    display: none; }
  sgx-scroll-shield.only-vertical-scrollbar .table-vertical-scrolling-wrapper {
    bottom: 1px; }
  sgx-scroll-shield.sgx-scroll-shield--touch [class^=sgx-scroll-shield-button], sgx-scroll-shield.sgx-scroll-shield--simple [class^=sgx-scroll-shield-button] {
    display: none !important; }
  sgx-scroll-shield.sgx-scroll-shield--touch .table-vertical-scrolling-wrapper,
  sgx-scroll-shield.sgx-scroll-shield--touch .table-horizontal-scrolling-wrapper, sgx-scroll-shield.sgx-scroll-shield--simple .table-vertical-scrolling-wrapper,
  sgx-scroll-shield.sgx-scroll-shield--simple .table-horizontal-scrolling-wrapper {
    background: 0 !important; }
  sgx-scroll-shield.sgx-scroll-shield--simple .table-horizontal-scrolling-wrapper {
    height: 8px; }
  sgx-scroll-shield.sgx-scroll-shield--simple .table-vertical-scrolling-wrapper {
    width: 8px; }
  sgx-scroll-shield.sgx-scroll-shield--simple.both-scrollbars .table-horizontal-scrolling-wrapper {
    right: 8px; }
  sgx-scroll-shield.sgx-scroll-shield--simple.both-scrollbars .table-vertical-scrolling-wrapper {
    bottom: 8px; }
  sgx-scroll-shield.sgx-scroll-shield--simple.both-scrollbars:after {
    width: 8px;
    height: 8px; }
  sgx-scroll-shield.sgx-scroll-shield--touch .table-horizontal-scrolling-wrapper {
    height: 4px; }
  sgx-scroll-shield.sgx-scroll-shield--touch .table-vertical-scrolling-wrapper {
    width: 4px; }
  sgx-scroll-shield.sgx-scroll-shield--touch.both-scrollbars .table-horizontal-scrolling-wrapper {
    right: 4px; }
  sgx-scroll-shield.sgx-scroll-shield--touch.both-scrollbars .table-vertical-scrolling-wrapper {
    bottom: 4px; }
  sgx-scroll-shield.sgx-scroll-shield--touch.both-scrollbars:after {
    width: 4px;
    height: 4px; }

/* Absolute Centre Status Indicator with Overlay */
sgx-status-indicator {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0.8;
  background: white;
  color: #434343;
  /* Loading */
  /* Empty */
  /* Error */ }
  sgx-status-indicator .sgx-status-indicator-status::before {
    margin-bottom: 4px;
    display: block; }
  sgx-status-indicator:not([status]) .sgx-status-indicator-status::before, sgx-status-indicator[status] .sgx-status-indicator-status::before, sgx-status-indicator[status="loading"] .sgx-status-indicator-status::before {
    icon-font: url("sgx-base-code/svgs/regular/spinner.svg");
    animation: spinner 1500ms infinite cubic-bezier(0.2, 0.5, 0.7, 0.5); }
  sgx-status-indicator[status="empty"] .sgx-status-indicator-status::before {
    icon-font: url("sgx-base-code/svgs/solid/exclamation-circle.svg");
    animation: none; }
  sgx-status-indicator[status="error"] .sgx-status-indicator-status::before {
    icon-font: url("sgx-base-code/svgs/solid/exclamation-triangle.svg");
    animation: none; }
  sgx-status-indicator.sgx-status-indicator--hidden {
    display: none; }

.sgx-status-indicator-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 310px; }
  .sgx-status-indicator-container > * {
    margin-bottom: 8px;
    text-align: center; }

.sgx-status-indicator-title:empty,
.sgx-status-indicator-decription:empty {
  display: none; }

.sgx-status-indicator-status {
  font-size: 28px;
  font-weight: 600; }

.sgx-status-indicator-title {
  font-size: 20px;
  font-weight: 600; }

.sgx-status-indicator-title--large .sgx-status-indicator-title {
  font-size: 28px;
  font-weight: 600; }

.sgx-status-indicator-decription {
  font-size: 16px; }

/* Animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

sgx-list sgx-status-indicator.sgx-list--infinite-loading {
  display: flex;
  top: unset;
  right: 12px; }

sgx-list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #ffffff;
  border: 1px solid #b5bdd2;
  position: relative; }
  sgx-list .list-view {
    position: relative; }
  sgx-list .list-view,
  sgx-list .rows-container {
    height: 100%;
    width: 100%; }
  sgx-list[data-view='infinite'] .rows-container > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  sgx-list[data-view='paged'] .rows-container {
    position: absolute; }
  sgx-list[data-view='paged'] .list-view {
    overflow: hidden; }
  sgx-list[data-view='paged'] sgx-pagination {
    padding: 10px; }
  sgx-list sgx-scroll-shield {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.sgx-list-details {
  position: relative;
  display: flex;
  border: solid 1px rgba(0, 0, 0, 0.2); }
  .sgx-list-details.sgx-list-details--transition .sgx-list-details-details {
    transition: transform 0.5s ease-in-out; }
  .sgx-list-details.sgx-list-details--active .sgx-list-details-details {
    transform: translateX(0); }

.sgx-list-details-list {
  display: flex;
  flex-direction: column;
  flex-basis: 400px;
  border-right: solid 1px rgba(0, 0, 0, 0.2);
  position: relative; }
  @media (max-width: 1023px) {
    .sgx-list-details-list {
      flex-basis: 100%; } }

.sgx-list-details-list-list {
  border: 0;
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  flex: 1 0 auto;
  overscroll-behavior-y: none; }
  .sgx-list-details-list-list .rows-container > *:hover, .sgx-list-details-list-list .rows-container > *:focus {
    background-color: #f2fafb; }
  .sgx-list-details-list-list .rows-container > *[selected], .sgx-list-details-list-list .rows-container > *:active {
    background-color: #d9eff4; }

.sgx-list-details-details {
  position: relative;
  background: #FFFFFF;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  overscroll-behavior-y: none; }
  @media (max-width: 1023px) {
    .sgx-list-details-details {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateX(100%);
      -webkit-overflow-scrolling: touch; }
      .sgx-list-details-details.sgx-list-details-details--bring-to-front {
        z-index: 1000; } }

.sgx-list-details-details-header {
  display: none;
  background: #efefef;
  overflow: hidden;
  padding: 4px;
  flex-basis: 0;
  height: 48px; }
  @media (max-width: 1023px) {
    .sgx-list-details-details-header {
      display: flex;
      flex: 0 0 3rem;
      background: #0B236B;
      color: white; }
      .sgx-list-details-details-header + sgx-status-indicator {
        top: 3rem; } }
  .sgx-list-details-details-header .sgx-list-details-details-header-back {
    padding: 0 20px 4px 0;
    min-width: 45px;
    outline: none; }
  .sgx-list-details-details-header .sgx-list-details-details-header-back--label:before {
    font-family: fontAwesome;
    display: inline-block;
    padding: 0;
    color: white;
    font-size: 1.8rem;
    content: "\f104"; }
  .sgx-list-details-details-header .sgx-list-details-details-header--title {
    padding: 4px 8px;
    margin-right: 40px;
    line-height: 2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    width: calc(100% - 2rem); }

.sgx-list-details-list-toolbar {
  background: white; }

.sgx-list-details-list-toolbar--hidden {
  display: none; }

.sgx-list-details-list-toolbar-row {
  display: flex;
  padding: 8px 12px; }

.sgx-list-details-list-toolbar-row:not(:first-child) {
  border-top: solid 1px rgba(0, 0, 0, 0.2); }

.sgx-list-details-list-toolbar-left,
.sgx-list-details-list-toolbar-right {
  display: flex;
  align-items: center; }

.sgx-list-details-list-toolbar-left {
  justify-content: flex-start; }
  .sgx-list-details-list-toolbar-left button[class^=sgx-data-model-tool-] {
    margin-right: 12px; }

.sgx-list-details-list-toolbar-right {
  justify-content: flex-end; }
  .sgx-list-details-list-toolbar-right button[class^=sgx-data-model-tool-] {
    margin-left: 12px; }

sgx-tooltip {
  display: inline-block;
  position: relative; }

[data-sgx-tooltip-content] {
  display: none; }

.sgx-tooltip-button {
  display: inline-block;
  width: 24px;
  height: 24px;
  min-width: auto;
  padding: 0;
  font-size: 14px;
  color: #6f7072;
  border: 0 !important; }
  .sgx-tooltip-button:after {
    icon-font: url(sgx-base-code/svgs/solid/question-circle.svg); }
  .sgx-tooltip--active .sgx-tooltip-button {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box {
  border-width: 0px;
  border-radius: 5px;
  background-color: #47484a;
  box-shadow: 0 1px 9px 2px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  max-height: 45vh; }
  @media (max-width: 767px) {
    #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box {
      max-width: 95vw; } }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-bottom {
    top: 8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-top {
    top: -8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-right {
    left: 8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-left {
    left: -8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-pinned-left {
    margin-left: 8px; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-pinned-right {
    margin-left: -8px; }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-body {
  color: rgba(255, 255, 255, 0.87); }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body {
  padding: 4px 12px; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body a {
    color: #4da0db; }
    #sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body a:hover {
      color: #0d7ecf; }

.tether-target.sgx-tooltip--active.sgx-tooltip--positioned-target {
  position: relative; }

.tether-target.sgx-tooltip--active:before {
  content: "";
  position: absolute;
  z-index: 1;
  animation: fadeIn .2s ease-out; }

.tether-target.sgx-tooltip--active.tether-target-attached-top.tether-element-attached-bottom:before, .tether-target.sgx-tooltip--active.tether-target-attached-bottom.tether-element-attached-top:before {
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; }

.tether-target.sgx-tooltip--active.tether-target-attached-top.tether-element-attached-bottom:before {
  top: -9px;
  border-top: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-bottom.tether-element-attached-top:before {
  bottom: -7px;
  border-bottom: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-left.tether-element-attached-right:before, .tether-target.sgx-tooltip--active.tether-target-attached-right.tether-element-attached-left:before {
  top: 50%;
  transform: translateY(-50%);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent; }

.tether-target.sgx-tooltip--active.tether-target-attached-left.tether-element-attached-right:before {
  left: -9px;
  border-left: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-right.tether-element-attached-left:before {
  right: -7px;
  border-right: 8px solid #47484a; }

.sgx-context-menu-item {
  display: block;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none; }
  .sgx-context-menu-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .sgx-context-menu-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .sgx-context-menu-item:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #80cad9;
    opacity: 1; }
  .sgx-context-menu-item.sgx-context-menu-item--disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background: transparent !important;
    cursor: default !important; }

.sgx-context-menu-item--icon {
  display: inline-block;
  width: 40px;
  text-align: center;
  margin-left: -12px; }

sgx-header-user {
  display: block;
  cursor: pointer;
  color: #9da7c4; }

.sgx-header-user-icon {
  width: auto;
  text-transform: uppercase; }
  sgx-header-user[auth="true"] .sgx-header-user-icon:after {
    icon-font: url(sgx-base-code/svgs/solid/user-circle.svg); }
  sgx-header-user[auth="false"] .sgx-header-user-icon:after {
    icon-font: url(sgx-base-code/svgs/regular/user-circle.svg); }

.sgx-header-user-name {
  flex: 1 1 auto;
  font-size: 16px;
  font-weight: 300;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.sgx-header-wrapper .sgx-icon, .sgx-header-wrapper .lm_controls .lm_popout, .lm_controls .sgx-header-wrapper .lm_popout, .sgx-header-wrapper .lm_controls .lm_maximise, .lm_controls .sgx-header-wrapper .lm_maximise, .sgx-header-wrapper .lm_controls .lm_close, .lm_controls .sgx-header-wrapper .lm_close, .sgx-header-wrapper .sgx-pagination-prev:before, .sgx-header-wrapper .sgx-pagination-next:after {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: auto;
  font-size: 20px;
  line-height: 40px; }

.sgx-header-toggle-icon.sgx-icon, .lm_controls .sgx-header-toggle-icon.lm_popout, .lm_controls .sgx-header-toggle-icon.lm_maximise, .lm_controls .sgx-header-toggle-icon.lm_close, .sgx-header-toggle-icon.sgx-pagination-prev:before, .sgx-header-toggle-icon.sgx-pagination-next:after {
  font-size: 12px;
  color: #0094B3; }

.sgx-header-footer-container .sgx-header-toggle-icon.sgx-icon, .sgx-header-footer-container .lm_controls .sgx-header-toggle-icon.lm_popout, .lm_controls .sgx-header-footer-container .sgx-header-toggle-icon.lm_popout, .sgx-header-footer-container .lm_controls .sgx-header-toggle-icon.lm_maximise, .lm_controls .sgx-header-footer-container .sgx-header-toggle-icon.lm_maximise, .sgx-header-footer-container .lm_controls .sgx-header-toggle-icon.lm_close, .lm_controls .sgx-header-footer-container .sgx-header-toggle-icon.lm_close, .sgx-header-footer-container .sgx-header-toggle-icon.sgx-pagination-prev:before, .sgx-header-footer-container .sgx-header-toggle-icon.sgx-pagination-next:after {
  line-height: 42px; }

.sgx-icon.sgx-header-toggle-icon:after, .lm_controls .sgx-header-toggle-icon.lm_popout:after, .lm_controls .sgx-header-toggle-icon.lm_maximise:after, .lm_controls .sgx-header-toggle-icon.lm_close:after, .sgx-header-toggle-icon.sgx-pagination-next:after {
  icon-font: url("sgx-base-code/svgs/solid/chevron-double-right.svg");
  transition: transform 0.2s ease;
  transition-delay: 300ms; }

.sgx-icon.sgx-header-toggle-icon--expand:after, .lm_controls .sgx-header-toggle-icon--expand.lm_popout:after, .lm_controls .sgx-header-toggle-icon--expand.lm_maximise:after, .lm_controls .sgx-header-toggle-icon--expand.lm_close:after, .sgx-header-toggle-icon--expand.sgx-pagination-next:after {
  transform: rotate(0); }

.sgx-icon.sgx-header-toggle-icon--collapse:after, .lm_controls .sgx-header-toggle-icon--collapse.lm_popout:after, .lm_controls .sgx-header-toggle-icon--collapse.lm_maximise:after, .lm_controls .sgx-header-toggle-icon--collapse.lm_close:after, .sgx-header-toggle-icon--collapse.sgx-pagination-next:after {
  transform: rotate(180deg); }

.sgx-header-menu-item-title {
  cursor: pointer;
  display: block;
  position: absolute;
  left: 64px;
  top: 50%;
  transform: translateY(-50%);
  width: calc(260px - 64px - 8px);
  font-size: 16px;
  font-weight: 300;
  text-align: left; }

.sgx-header-menu-item-title--brandmark {
  top: 0;
  transform: initial; }

.menu-type--icons .sgx-header-menu-item-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding-left: 16px;
  font-size: .72em;
  height: inherit;
  padding: 0;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  color: #9da7c4;
  letter-spacing: .1px; }
  .menu-type--icons .sgx-header-menu-item-wrapper.sgx-menu-item-active-url {
    color: #f3f4f8;
    background: #485a90; }
  .menu-type--icons .sgx-header-menu-item-wrapper:hover {
    color: #f3f4f8; }
  .menu-type--icons .sgx-header-menu-item-wrapper:focus {
    color: #f3f4f8; }
    .menu-type--icons .sgx-header-menu-item-wrapper:focus .sgx-header-menu-item-title {
      text-decoration: underline; }
  .menu-type--icons .sgx-header-menu-item-wrapper.sgx-button--primary {
    background-color: #0094B3;
    box-shadow: none;
    color: rgba(255, 255, 255, 0.87);
    font-size: 1em; }
    .menu-type--icons .sgx-header-menu-item-wrapper.sgx-button--primary:focus {
      background-color: #80cad9;
      color: rgba(255, 255, 255, 0.87);
      box-shadow: inset 0 0 0 1px transparent; }
    .menu-type--icons .sgx-header-menu-item-wrapper.sgx-button--primary:hover {
      background-color: #80cad9;
      color: rgba(255, 255, 255, 0.87);
      box-shadow: inset 0 0 0 1px transparent; }
    .menu-type--icons .sgx-header-menu-item-wrapper.sgx-button--primary:active {
      background-color: #006f86;
      color: rgba(255, 255, 255, 0.87);
      box-shadow: inset 0 0 0 1px transparent; }
    .menu-type--icons .sgx-header-menu-item-wrapper.sgx-button--primary.sgx-button--selected {
      background-color: #0094B3;
      color: rgba(255, 255, 255, 0.87);
      box-shadow: inset 0 0 0 1px transparent; }
    fieldset[disabled] .menu-type--icons .sgx-header-menu-item-wrapper.sgx-button--primary, .menu-type--icons .sgx-header-menu-item-wrapper.sgx-button--primary.disabled, .menu-type--icons .sgx-header-menu-item-wrapper.sgx-button--primary:disabled, .menu-type--icons .sgx-header-menu-item-wrapper.sgx-button--primary[disabled] {
      cursor: default;
      transition: none;
      color: rgba(0, 0, 0, 0.2) !important;
      background-color: rgba(0, 0, 0, 0.09) !important;
      box-shadow: inset 0 0 0 1px transparent !important;
      outline: 0; }
  .menu-type--icons .sgx-header-menu-item-wrapper.sgx-button--secondary {
    background-color: rgba(0, 0, 0, 0.34);
    color: rgba(255, 255, 255, 0.87);
    font-size: 1em; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .menu-type--icons .sgx-header-menu-item-title.windowsNT6 {
    line-height: 4rem; }
    .menu-type--icons .sgx-header-menu-item-title.windowsNT6 .sgx-icon:before, .menu-type--icons .sgx-header-menu-item-title.windowsNT6 .lm_controls .lm_popout:before, .lm_controls .menu-type--icons .sgx-header-menu-item-title.windowsNT6 .lm_popout:before, .menu-type--icons .sgx-header-menu-item-title.windowsNT6 .lm_controls .lm_maximise:before, .lm_controls .menu-type--icons .sgx-header-menu-item-title.windowsNT6 .lm_maximise:before, .menu-type--icons .sgx-header-menu-item-title.windowsNT6 .lm_controls .lm_close:before, .lm_controls .menu-type--icons .sgx-header-menu-item-title.windowsNT6 .lm_close:before, .menu-type--icons .sgx-header-menu-item-title.windowsNT6 .sgx-pagination-prev:before {
      padding-top: 1.2rem; } }

sgx-header-menu-item {
  display: block;
  font-size: .88em;
  position: relative;
  word-wrap: break-word; }
  sgx-header-menu-item .menu-item-disabled,
  sgx-header-menu-item.menu-item-disabled > *, sgx-header-menu-item.menu-item-disabled {
    color: rgba(255, 255, 255, 0.34) !important;
    cursor: default !important;
    pointer-events: none !important;
    background: 0 !important; }
  sgx-header-menu-item .menu-item-hide, sgx-header-menu-item.menu-item-hide {
    display: none; }
  sgx-header-menu-item .sgx-header-menu-level1-container {
    display: none;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    padding-inline-start: 72px; }
    .sgx-header-menu--list sgx-header-menu-item .sgx-header-menu-level1-container {
      display: block;
      padding-inline-start: 0; }
  @media (max-width: 1023px) {
    sgx-header-menu-item.sub-menu-open .sgx-header-menu-level1-container {
      display: block; } }
  sgx-header-menu-item .sgx-header-menu-level2-title {
    display: block;
    color: #9da7c4;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    position: relative;
    text-align: left;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
    sgx-header-menu-item .sgx-header-menu-level2-title.sgx-menu-item-active-url {
      color: #f3f4f8; }
    sgx-header-menu-item .sgx-header-menu-level2-title:hover {
      color: #f3f4f8; }
    sgx-header-menu-item .sgx-header-menu-level2-title:focus {
      color: #f3f4f8;
      text-decoration: underline; }
  sgx-header-menu-item .sgx-header-menu-level2 {
    list-style: none; }

.sgx-header-wrapper .sgx-icon.sgx-header-menu-item-toggle, .sgx-header-wrapper .lm_controls .sgx-header-menu-item-toggle.lm_popout, .lm_controls .sgx-header-wrapper .sgx-header-menu-item-toggle.lm_popout, .sgx-header-wrapper .lm_controls .sgx-header-menu-item-toggle.lm_maximise, .lm_controls .sgx-header-wrapper .sgx-header-menu-item-toggle.lm_maximise, .sgx-header-wrapper .lm_controls .sgx-header-menu-item-toggle.lm_close, .lm_controls .sgx-header-wrapper .sgx-header-menu-item-toggle.lm_close, .sgx-header-wrapper .sgx-header-menu-item-toggle.sgx-pagination-prev:before, .sgx-header-wrapper .sgx-header-menu-item-toggle.sgx-pagination-next:after {
  display: none; }
  @media (max-width: 1023px) {
    .sgx-header-wrapper .sgx-icon.sgx-header-menu-item-toggle, .sgx-header-wrapper .lm_controls .sgx-header-menu-item-toggle.lm_popout, .lm_controls .sgx-header-wrapper .sgx-header-menu-item-toggle.lm_popout, .sgx-header-wrapper .lm_controls .sgx-header-menu-item-toggle.lm_maximise, .lm_controls .sgx-header-wrapper .sgx-header-menu-item-toggle.lm_maximise, .sgx-header-wrapper .lm_controls .sgx-header-menu-item-toggle.lm_close, .lm_controls .sgx-header-wrapper .sgx-header-menu-item-toggle.lm_close, .sgx-header-wrapper .sgx-header-menu-item-toggle.sgx-pagination-prev:before, .sgx-header-wrapper .sgx-header-menu-item-toggle.sgx-pagination-next:after {
      display: block; } }

.sgx-header-menu-item--collapsible .sgx-header-menu-item-toggle {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 15px;
  color: #9da7c4;
  text-align: center;
  width: auto;
  padding: 0 20px 0 24px; }
  .sgx-header-menu-item--collapsible .sgx-header-menu-item-toggle:hover {
    color: #f3f4f8; }
  .sgx-header-menu-item--collapsible .sgx-header-menu-item-toggle:after {
    display: block;
    icon-font: url("sgx-base-code/svgs/light/chevron-down.svg");
    transition: all 0.2s ease;
    transform: rotate(0); }

.sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-item-toggle:after {
  color: #f3f4f8;
  transform: rotate(180deg); }

#sgx-header-sidebar-revision-info {
  padding: 4px 16px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
  color: #9da7c4; }

.sgx-header-menu-item-wrapper {
  position: relative; }

.sgx-header-menu--list {
  position: relative; }
  .sgx-header-menu--list .sgx-menu-item-active-url {
    background-color: rgba(255, 255, 255, 0.2); }
    .sgx-header-menu--list .sgx-menu-item-active-url .sgx-header-menu-item-title {
      color: #ffffff; }
  .sgx-header-menu--list .sub-link-container > {
    display: none; }
  .sgx-header-menu--list .sub-link-container.sub-menu-open > {
    display: flex; }
  .sgx-header-menu--list .sub-link-container .sgx-header-menu-item--collapsible .sgx-header-menu-item-toggle {
    display: flex; }
  .sgx-header-menu--list .sgx-header-menu-item-title {
    position: static;
    transform: none;
    width: auto;
    overflow: hidden;
    font-weight: 400; }
  .sgx-header-menu--list .sgx-header-menu-item-wrapper {
    display: block;
    padding: 12px 8px 12px 12px;
    text-decoration: none; }
    .sgx-header-menu--list .sgx-header-menu-item-wrapper .sgx-header-menu-item-toggle {
      display: flex;
      position: static;
      width: 48px;
      line-height: normal;
      float: right; }
  .sgx-header-menu--list sgx-header-menu-item .sgx-header-menu-level1-container {
    display: none;
    padding-bottom: 0; }
    .sgx-header-menu--list sgx-header-menu-item .sgx-header-menu-level1-container .sgx-menu-item-active-url {
      background-color: transparent; }
      .sgx-header-menu--list sgx-header-menu-item .sgx-header-menu-level1-container .sgx-menu-item-active-url:focus, .sgx-header-menu--list sgx-header-menu-item .sgx-header-menu-level1-container .sgx-menu-item-active-url:active {
        text-decoration: none; }
  .sgx-header-menu--list sgx-header-menu-item .sgx-header-menu-level2-title {
    padding: 0;
    text-shadow: none;
    font-size: 16px; }
  .sgx-header-menu--list.sub-menu-open .sgx-header-menu-level1-container {
    display: block;
    padding-left: 32px; }
  .sgx-header-menu--list a {
    color: #9da7c4; }
  .sgx-header-menu--list .sgx-header-menu-item--collapsible .sgx-header-menu-item-toggle {
    display: flex;
    width: 48px;
    right: 8px; }
  .sgx-header-menu--list .sub-link-container {
    padding-bottom: 0;
    position: relative;
    padding-left: 8px;
    display: none; }
    .sgx-header-menu--list .sub-link-container .sgx-header-menu-level1-container {
      margin-top: 0;
      padding-bottom: 0; }
    .sgx-header-menu--list .sub-link-container.sub-menu-open {
      display: block; }
  .sgx-header-menu--list .sgx-header-menu-level2 {
    list-style: none;
    position: relative; }
    .sgx-header-menu--list .sgx-header-menu-level2.level-container {
      position: relative; }
      .sgx-header-menu--list .sgx-header-menu-level2.level-container .sgx-header-menu-item-toggle {
        display: flex; }
  .sgx-header-menu--list .sgx-header-menu-level2.sub-menu-open > .sub-link-container {
    display: block; }
  .sgx-header-menu--list .sgx-header-menu-level2 .sub-link-container.sub-menu-open {
    display: none; }
    .sgx-header-menu--list .sgx-header-menu-level2 .sub-link-container.sub-menu-open > .sgx-header-menu-level1-container > .level-container > .sub-link-container {
      display: none; }
  .sgx-header-menu--list .sgx-header-menu-level2.sub-menu-open .sub-link-container.sub-menu-open {
    display: block; }
    .sgx-header-menu--list .sgx-header-menu-level2.sub-menu-open .sub-link-container.sub-menu-open > .sgx-header-menu-level1-container > .level-container > .sub-link-container {
      display: block; }
  .sgx-header-menu--list .sub-link-container > .sgx-header-menu-level1-container > .level-container > .sub-link-container {
    display: none; }
  .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-item-toggle:after {
    display: block;
    transform: rotate(180deg); }
  .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-item-toggle:after {
    transform: rotate(0); }
  .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sgx-header-menu-item-toggle:after {
    transform: rotate(180deg); }
  .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sub-link-container > .sgx-header-menu-item-toggle.sgx-icon:after, .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .lm_controls .sub-link-container > .sgx-header-menu-item-toggle.lm_popout:after, .lm_controls .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sub-link-container > .sgx-header-menu-item-toggle.lm_popout:after, .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .lm_controls .sub-link-container > .sgx-header-menu-item-toggle.lm_maximise:after, .lm_controls .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sub-link-container > .sgx-header-menu-item-toggle.lm_maximise:after, .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .lm_controls .sub-link-container > .sgx-header-menu-item-toggle.lm_close:after, .lm_controls .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sub-link-container > .sgx-header-menu-item-toggle.lm_close:after, .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sub-link-container > .sgx-header-menu-item-toggle.sgx-pagination-next:after {
    transform: rotate(0); }
  .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sub-link-container.sub-menu-open > .sgx-header-menu-item-toggle.sgx-icon:after, .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .lm_controls .sub-link-container.sub-menu-open > .sgx-header-menu-item-toggle.lm_popout:after, .lm_controls .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sub-link-container.sub-menu-open > .sgx-header-menu-item-toggle.lm_popout:after, .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .lm_controls .sub-link-container.sub-menu-open > .sgx-header-menu-item-toggle.lm_maximise:after, .lm_controls .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sub-link-container.sub-menu-open > .sgx-header-menu-item-toggle.lm_maximise:after, .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .lm_controls .sub-link-container.sub-menu-open > .sgx-header-menu-item-toggle.lm_close:after, .lm_controls .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sub-link-container.sub-menu-open > .sgx-header-menu-item-toggle.lm_close:after, .sgx-header-menu--list .sgx-header-menu-item--collapsible.sub-menu-open .sgx-header-menu-level1-container .sgx-header-menu-level2.sub-menu-open .sub-link-container.sub-menu-open > .sgx-header-menu-item-toggle.sgx-pagination-next:after {
    transform: rotate(180deg); }

.sgx-header-sidebar.menu-type--list .sgx-header-logo-brandmark-label-container.sgx-header-menu-item-title {
  z-index: auto; }

.sgx-header-sidebar.menu-type--list .sgx-header-menu-item-wrapper {
  padding-left: 12px; }

.sgx-header-sidebar.menu-type--list .sgx-header-menu-item-title {
  display: flex; }

.sgx-header-menu-item-title {
  display: none; }

.sgx-header--menu-open .sgx-header-menu-item-title {
  display: flex; }

.sgx-header-menu-icon--home:after {
  icon-font: url("sgx-base-code/svgs/light/home.svg"); }

.sgx-header-menu-icon--trades:after {
  icon-font: url("sgx-base-code/svgs/light/chart-line.svg"); }

.sgx-header-menu-icon--reports:after {
  icon-font: url("sgx-base-code/svgs/light/file-alt.svg"); }

.sgx-header-menu-icon--admin:after {
  icon-font: url("sgx-base-code/svgs/light/user-cog.svg"); }

.sgx-header-menu-icon--orders:after {
  icon-font: url("components/sgx-icons/svgs/orders.svg"); }

.sgx-header-menu-icon--settings:after {
  icon-font: url("sgx-base-code/svgs/light/user-circle.svg"); }

.sgx-header-menu-icon--support:after {
  icon-font: url("sgx-base-code/svgs/solid/question-circle.svg"); }

.sgx-menu-icon {
  position: relative;
  width: 3rem;
  height: 3rem;
  cursor: pointer; }
  .sgx-menu-icon > span {
    position: absolute;
    display: block;
    height: 2.5px;
    background-color: white;
    width: 1.5rem;
    transition: background-color 0.15s ease-in;
    will-change: background-color;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
    .sgx-menu-icon > span:after, .sgx-menu-icon > span:before {
      position: absolute;
      display: block;
      left: 0;
      width: 1.5rem;
      height: 2.5px;
      background-color: white;
      content: ""; }
    .sgx-menu-icon > span:before {
      transform: translateY(-8px);
      transition: transform 0.15s ease-in; }
    .sgx-menu-icon > span:after {
      transform: translateY(8px);
      transition: transform 0.15s ease-in;
      will-change: transform; }
  .sgx-menu-icon.sgx-menu-icon--open > span {
    background-color: transparent; }
    .sgx-menu-icon.sgx-menu-icon--open > span:before {
      transform: rotate(45deg); }
    .sgx-menu-icon.sgx-menu-icon--open > span:after {
      transform: rotate(-45deg); }

.sgx-header-logo-link, .sgx-header-logo-brandmark-label-container {
  display: flex; }

.sgx-header-logo-link {
  background: #0b236b; }

.sgx-header-logo-wordmark, .sgx-header-logo-brandmark {
  height: 60px;
  background-size: 60px 17px;
  background-repeat: no-repeat;
  background-position: 50%; }

.sgx-header-logo-wordmark {
  height: 60px;
  width: 64px;
  background-image: url(sgx-logo-wordmark.svg); }

.sgx-header-logo-brandmark {
  width: 20px;
  background-image: url(sgx-logo-brandmark.svg); }

.sgx-header-logo-brandmark-label-container.sgx-header-menu-item-title {
  height: auto;
  top: 0px;
  left: 54px;
  z-index: -1;
  line-height: 53px; }
  @media (max-width: 1023px) {
    .sgx-header-logo-brandmark-label-container.sgx-header-menu-item-title {
      z-index: auto; } }

.menu-type--icons-large .sgx-header-logo-brandmark-label-container.sgx-header-menu-item-title {
  z-index: auto; }

.sgx-header-logo-logomark:not([src]) {
  display: none; }

.sgx-header-logo-label {
  font-size: 18px;
  padding-left: 8px;
  color: #f3f4f8;
  white-space: nowrap;
  text-transform: none;
  line-height: 60px; }

.website-main-layout.sgx-header-layout-target {
  width: calc(100vw - 12rem);
  margin-left: 12rem;
  transition: all .3s; }
  .website-main-layout.sgx-header-layout-target.sgx-header-layout-target--list-menu {
    width: calc(100vw - 260px);
    margin-left: 260px; }
    @media (max-width: 1023px) {
      .website-main-layout.sgx-header-layout-target.sgx-header-layout-target--list-menu {
        width: 100vw;
        margin-left: 0;
        margin-top: 3rem; } }
  .website-main-layout.sgx-header-layout-target.sgx-header-layout-target--icon-menu {
    width: calc(100vw - 64px);
    margin-left: 64px; }
    @media (max-width: 1023px) {
      .website-main-layout.sgx-header-layout-target.sgx-header-layout-target--icon-menu {
        width: 100vw;
        margin-left: 0; } }

sgx-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 3rem;
  width: 48px;
  font-family: Lato, sans-serif;
  font-size: 14px; }
  @media (max-width: 1023px) {
    sgx-header {
      width: 100%;
      background-color: #0B236B; } }
  sgx-header .sgx-header-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    bottom: auto;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.2s ease; }
  sgx-header.sgx-header--menu-open .sgx-header-overlay {
    bottom: 0;
    opacity: 1; }
  sgx-header .sgx-header-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: space-between; }
  sgx-header .sgx-header-logo-container {
    height: 60px; }
  sgx-header .sgx-header-button-container-utility {
    margin-bottom: 20px;
    position: relative; }
    sgx-header .sgx-header-button-container-utility > *, sgx-header .sgx-header-button-container-utility h2, sgx-header .sgx-header-button-container-utility h3 {
      color: #ffffff; }
    sgx-header .sgx-header-button-container-utility--text {
      padding-left: 0;
      padding-right: 0; }
      sgx-header .sgx-header-button-container-utility--text .sgx-header-menu-item-text {
        justify-content: center; }
    sgx-header .sgx-header-button-container-utility .menu-item-container {
      background-color: #ffffff;
      position: absolute;
      width: 95%;
      bottom: -120px;
      right: 2.5%;
      padding: 20px;
      z-index: 1; }
      sgx-header .sgx-header-button-container-utility .menu-item-container.hide {
        display: none; }
      sgx-header .sgx-header-button-container-utility .menu-item-container a {
        padding: 40px 8px;
        display: block;
        font-size: 18px;
        text-decoration: none;
        color: #0B236B; }
        sgx-header .sgx-header-button-container-utility .menu-item-container a.dds {
          background-color: #BDD831; }
        sgx-header .sgx-header-button-container-utility .menu-item-container a.brand {
          background-color: #0B236B;
          color: #ffffff; }
    @media (max-width: 1023px) {
      sgx-header .sgx-header-button-container-utility {
        flex-direction: row; } }
    sgx-header .sgx-header-button-container-utility .menu-icon {
      margin-top: 16px;
      cursor: pointer; }
  sgx-header .sgx-header-menu-container {
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden; }
  sgx-header .sgx-header-menu-container-last {
    margin-bottom: 6px; }
  sgx-header .sgx-header-footer-container {
    display: flex;
    justify-content: center;
    border-top: 1px solid #304481;
    background: rgba(0, 0, 0, 0.34); }
    sgx-header .sgx-header-footer-container .sgx-header-toggle-icon.sgx-icon, sgx-header .sgx-header-footer-container .lm_controls .sgx-header-toggle-icon.lm_popout, .lm_controls sgx-header .sgx-header-footer-container .sgx-header-toggle-icon.lm_popout, sgx-header .sgx-header-footer-container .lm_controls .sgx-header-toggle-icon.lm_maximise, .lm_controls sgx-header .sgx-header-footer-container .sgx-header-toggle-icon.lm_maximise, sgx-header .sgx-header-footer-container .lm_controls .sgx-header-toggle-icon.lm_close, .lm_controls sgx-header .sgx-header-footer-container .sgx-header-toggle-icon.lm_close, sgx-header .sgx-header-footer-container .sgx-header-toggle-icon.sgx-pagination-prev:before, sgx-header .sgx-header-footer-container .sgx-header-toggle-icon.sgx-pagination-next:after {
      width: 64px; }
    @media (max-width: 1023px) {
      sgx-header .sgx-header-footer-container {
        display: none; } }
  sgx-header.sgx-header--menu-open .sgx-header-wrapper .sgx-icon, sgx-header.sgx-header--menu-open .sgx-header-wrapper .lm_controls .lm_popout, .lm_controls sgx-header.sgx-header--menu-open .sgx-header-wrapper .lm_popout, sgx-header.sgx-header--menu-open .sgx-header-wrapper .lm_controls .lm_maximise, .lm_controls sgx-header.sgx-header--menu-open .sgx-header-wrapper .lm_maximise, sgx-header.sgx-header--menu-open .sgx-header-wrapper .lm_controls .lm_close, .lm_controls sgx-header.sgx-header--menu-open .sgx-header-wrapper .lm_close, sgx-header.sgx-header--menu-open .sgx-header-wrapper .sgx-pagination-prev:before, sgx-header.sgx-header--menu-open .sgx-header-wrapper .sgx-pagination-next:after {
    width: 64px; }
  sgx-header.sgx-header--menu-open .sgx-header-footer-container {
    justify-content: flex-end; }
  sgx-header.sgx-header--menu-open .menu-type--icons .sgx-header-menu-item-wrapper {
    flex-direction: row; }
  sgx-header .sgx-header-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    height: 100%;
    width: 12rem;
    background-color: #0b236b;
    align-items: stretch;
    z-index: 1001;
    box-shadow: 3px 0 4px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease, width 0.3s ease; }
    @media (max-width: 1023px) {
      sgx-header .sgx-header-sidebar {
        transform: translateX(-100%); } }
    sgx-header .sgx-header-sidebar.menu-type--icons {
      width: 64px; }
      @media (max-width: 1023px) {
        sgx-header .sgx-header-sidebar.menu-type--icons {
          width: 290px !important; } }
    sgx-header .sgx-header-sidebar.menu-type--icons-large, sgx-header .sgx-header-sidebar.menu-type--list {
      width: 260px; }
    sgx-header .sgx-header-sidebar.sgx-header-sidebar--collapsed {
      width: 3rem; }
  sgx-header .menu-type--icons .sgx-header-menu {
    overflow: visible; }
  sgx-header .sgx-header-menu {
    position: relative;
    height: auto;
    width: 100%;
    overflow: auto; }

sgx-header[data-loaded=false] .sgx-header-button-container-utility,
sgx-header[data-loaded=false] .sgx-header-menu-container,
sgx-header[data-loaded=false] .sgx-header-footer-container {
  visibility: hidden; }

@media (max-width: 1023px) {
  sgx-header .sgx-header-menu-button {
    display: flex; }
  sgx-header .sgx-header-sidebar {
    height: 100%; }
  sgx-header.sgx-header--menu-open .sgx-header-sidebar {
    transform: translateX(0); } }

/**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
.highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0;
  /* #1072 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  font-size: 12px;
  user-select: none; }

.highcharts-root {
  display: block; }

.highcharts-root text {
  stroke-width: 0; }

.highcharts-strong {
  font-weight: bold; }

.highcharts-emphasized {
  font-style: italic; }

.highcharts-anchor {
  cursor: pointer; }

.highcharts-background {
  fill: #ffffff; }

.highcharts-plot-border, .highcharts-plot-background {
  fill: none; }

.highcharts-label-box {
  fill: none; }

.highcharts-button-box {
  fill: inherit; }

.highcharts-tracker-line {
  stroke-linejoin: round;
  stroke: rgba(192, 192, 192, 0.0001);
  stroke-width: 22;
  fill: none; }

.highcharts-tracker-area {
  fill: rgba(192, 192, 192, 0.0001);
  stroke-width: 0; }

/* Titles */
.highcharts-title {
  fill: #333333;
  font-size: 1.5em; }

.highcharts-subtitle {
  fill: #666666; }

/* Axes */
.highcharts-axis-line {
  fill: none;
  stroke: #ccd6eb; }

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0; }

.highcharts-axis-title {
  fill: #666666; }

.highcharts-axis-labels {
  fill: #666666;
  cursor: default;
  font-size: 0.9em; }

.highcharts-grid-line {
  fill: none;
  stroke: #e6e6e6; }

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0px; }

.highcharts-tick {
  stroke: #ccd6eb; }

.highcharts-yaxis .highcharts-tick {
  stroke-width: 0; }

.highcharts-minor-grid-line {
  stroke: #f2f2f2; }

.highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: #cccccc; }

.highcharts-crosshair-category {
  stroke: #ccd6eb;
  stroke-opacity: 0.25; }

/* Credits */
.highcharts-credits {
  cursor: pointer;
  fill: #999999;
  font-size: 0.7em;
  transition: fill 250ms, font-size 250ms; }

.highcharts-credits:hover {
  fill: black;
  font-size: 1em; }

/* Tooltip */
.highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 150ms; }

.highcharts-tooltip text {
  fill: #333333; }

.highcharts-tooltip .highcharts-header {
  font-size: 0.85em; }

.highcharts-tooltip-box {
  stroke-width: 1px;
  fill: #f7f7f7;
  fill-opacity: 0.85; }

.highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7;
  fill-opacity: 0.85; }

div.highcharts-tooltip {
  filter: none; }

.highcharts-selection-marker {
  fill: #335cad;
  fill-opacity: 0.25; }

.highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round; }

.highcharts-empty-series {
  stroke-width: 1px;
  fill: none;
  stroke: #cccccc; }

.highcharts-state-hover .highcharts-graph {
  stroke-width: 3; }

.highcharts-point-inactive {
  opacity: 0.2;
  transition: opacity 50ms;
  /* quick in */ }

.highcharts-series-inactive {
  opacity: 0.2;
  transition: opacity 50ms;
  /* quick in */ }

.highcharts-state-hover path {
  transition: stroke-width 50ms;
  /* quick in */ }

.highcharts-state-normal path {
  transition: stroke-width 250ms;
  /* slow out */ }

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
  transition: opacity 250ms; }

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2; }

/* Series options */
/* Default colors */
.highcharts-color-0 {
  fill: #7cb5ec;
  stroke: #7cb5ec; }

.highcharts-color-1 {
  fill: #434348;
  stroke: #434348; }

.highcharts-color-2 {
  fill: #90ed7d;
  stroke: #90ed7d; }

.highcharts-color-3 {
  fill: #f7a35c;
  stroke: #f7a35c; }

.highcharts-color-4 {
  fill: #8085e9;
  stroke: #8085e9; }

.highcharts-color-5 {
  fill: #f15c80;
  stroke: #f15c80; }

.highcharts-color-6 {
  fill: #e4d354;
  stroke: #e4d354; }

.highcharts-color-7 {
  fill: #2b908f;
  stroke: #2b908f; }

.highcharts-color-8 {
  fill: #f45b5b;
  stroke: #f45b5b; }

.highcharts-color-9 {
  fill: #91e8e1;
  stroke: #91e8e1; }

.highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0; }

.highcharts-markers {
  stroke-width: 1px;
  stroke: #ffffff; }

.highcharts-a11y-markers-hidden .highcharts-point:not(.highcharts-point-hover):not(.highcharts-a11y-marker-visible),
.highcharts-a11y-marker-hidden {
  opacity: 0; }

.highcharts-point {
  stroke-width: 1px; }

.highcharts-dense-data .highcharts-point {
  stroke-width: 0; }

.highcharts-data-label {
  font-size: 0.9em;
  font-weight: bold; }

.highcharts-data-label-box {
  fill: none;
  stroke-width: 0; }

.highcharts-data-label text, text.highcharts-data-label {
  fill: #333333; }

.highcharts-data-label-connector {
  fill: none; }

.highcharts-data-label-hidden {
  pointer-events: none; }

.highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0; }

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.highcharts-markers .highcharts-point-select {
  fill: #cccccc;
  stroke: #000000; }

.highcharts-column-series rect.highcharts-point {
  stroke: #ffffff; }

.highcharts-column-series .highcharts-point {
  transition: fill-opacity 250ms; }

.highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms; }

.highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff; }

.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms; }

.highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff; }

.highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms; }

.highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit; }

.highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #ffffff; }

.highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms; }

.highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit; }

.highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0; }

.highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: #e6e6e6;
  transition: stroke 250ms, fill 250ms, fill-opacity 250ms; }

.highcharts-treemap-series .highcharts-point-hover {
  stroke: #999999;
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms; }

.highcharts-treemap-series .highcharts-above-level {
  display: none; }

.highcharts-treemap-series .highcharts-internal-node {
  fill: none; }

.highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer; }

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75; }

.highcharts-vector-series .highcharts-point {
  fill: none;
  stroke-width: 2px; }

.highcharts-windbarb-series .highcharts-point {
  fill: none;
  stroke-width: 2px; }

.highcharts-lollipop-stem {
  stroke: #000000; }

.highcharts-focus-border {
  fill: none;
  stroke-width: 2px; }

.highcharts-legend-item-hidden .highcharts-focus-border {
  fill: none !important; }

/* Legend */
.highcharts-legend-box {
  fill: none;
  stroke-width: 0; }

.highcharts-legend-item > text {
  fill: #333333;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  stroke-width: 0; }

.highcharts-legend-item:hover text {
  fill: #000000; }

.highcharts-legend-item-hidden * {
  fill: #cccccc !important;
  stroke: #cccccc !important;
  transition: fill 250ms; }

.highcharts-legend-nav-active {
  fill: #003399;
  cursor: pointer; }

.highcharts-legend-nav-inactive {
  fill: #cccccc; }

circle.highcharts-legend-nav-active, circle.highcharts-legend-nav-inactive {
  /* tracker */
  fill: rgba(192, 192, 192, 0.0001); }

.highcharts-legend-title-box {
  fill: none;
  stroke-width: 0; }

/* Bubble legend */
.highcharts-bubble-legend-symbol {
  stroke-width: 2;
  fill-opacity: 0.5; }

.highcharts-bubble-legend-connectors {
  stroke-width: 1; }

.highcharts-bubble-legend-labels {
  fill: #333333; }

/* Loading */
.highcharts-loading {
  position: absolute;
  background-color: #ffffff;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 250ms; }

.highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition: opacity 250ms, height 250ms step-end; }

.highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%; }

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band, .highcharts-pane {
  fill: #000000;
  fill-opacity: 0.05; }

.highcharts-plot-line {
  fill: none;
  stroke: #999999;
  stroke-width: 1px; }

/* Highcharts More and modules */
.highcharts-boxplot-box {
  fill: #ffffff; }

.highcharts-boxplot-median {
  stroke-width: 2px; }

.highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5; }

.highcharts-errorbar-series .highcharts-point {
  stroke: #000000; }

.highcharts-gauge-series .highcharts-data-label-box {
  stroke: #cccccc;
  stroke-width: 1px; }

.highcharts-gauge-series .highcharts-dial {
  fill: #000000;
  stroke-width: 0; }

.highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0; }

.highcharts-waterfall-series .highcharts-graph {
  stroke: #333333;
  stroke-dasharray: 1, 3; }

.highcharts-sankey-series .highcharts-point {
  stroke-width: 0; }

.highcharts-sankey-series .highcharts-link {
  transition: fill 250ms, fill-opacity 250ms;
  fill-opacity: 0.5; }

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  transition: fill 50ms, fill-opacity 50ms;
  fill-opacity: 1; }

.highcharts-venn-series .highcharts-point {
  fill-opacity: 0.75;
  stroke: #cccccc;
  transition: stroke 250ms, fill-opacity 250ms; }

.highcharts-venn-series .highcharts-point-hover {
  fill-opacity: 1;
  stroke: #cccccc; }

/* Highstock */
.highcharts-navigator-mask-outside {
  fill-opacity: 0; }

.highcharts-navigator-mask-inside {
  fill: #6685c2;
  /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize; }

.highcharts-navigator-outline {
  stroke: #cccccc;
  fill: none; }

.highcharts-navigator-handle {
  stroke: #cccccc;
  fill: #f2f2f2;
  cursor: ew-resize; }

.highcharts-navigator-series {
  fill: #335cad;
  stroke: #335cad; }

.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px; }

.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05; }

.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0; }

.highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #e6e6e6; }

.highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: #999999; }

.highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0; }

.highcharts-scrollbar-thumb {
  fill: #cccccc;
  stroke: #cccccc;
  stroke-width: 1px; }

.highcharts-scrollbar-button {
  fill: #e6e6e6;
  stroke: #cccccc;
  stroke-width: 1px; }

.highcharts-scrollbar-arrow {
  fill: #666666; }

.highcharts-scrollbar-rifles {
  stroke: #666666;
  stroke-width: 1px; }

.highcharts-scrollbar-track {
  fill: #f2f2f2;
  stroke: #f2f2f2;
  stroke-width: 1px; }

.highcharts-button {
  fill: #f7f7f7;
  stroke: #cccccc;
  cursor: default;
  stroke-width: 1px;
  transition: fill 250ms; }

.highcharts-button text {
  fill: #333333; }

.highcharts-button-hover {
  transition: fill 0ms;
  fill: #e6e6e6;
  stroke: #cccccc; }

.highcharts-button-hover text {
  fill: #333333; }

.highcharts-button-pressed {
  font-weight: bold;
  fill: #e6ebf5;
  stroke: #cccccc; }

.highcharts-button-pressed text {
  fill: #333333;
  font-weight: bold; }

.highcharts-button-disabled text {
  fill: #333333; }

.highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0px; }

.highcharts-range-label rect {
  fill: none; }

.highcharts-range-label text {
  fill: #666666; }

.highcharts-range-input rect {
  fill: none; }

.highcharts-range-input text {
  fill: #333333; }

.highcharts-range-input {
  stroke-width: 1px;
  stroke: #cccccc; }

input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px;
  /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em;
  /* #4798 */ }

.highcharts-crosshair-label text {
  fill: #ffffff;
  font-size: 1.1em; }

.highcharts-crosshair-label .highcharts-label-box {
  fill: inherit; }

.highcharts-candlestick-series .highcharts-point {
  stroke: #000000;
  stroke-width: 1px; }

.highcharts-candlestick-series .highcharts-point-up {
  fill: #ffffff; }

.highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px; }

.highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: #999999;
  fill: #ffffff;
  transition: fill 250ms; }

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: #000000;
  fill: #ccd6eb; }

.highcharts-flags-series .highcharts-point text {
  fill: #000000;
  font-size: 0.9em;
  font-weight: bold; }

/* Highmaps */
.highcharts-map-series .highcharts-point {
  transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  stroke: #cccccc; }

.highcharts-map-series .highcharts-point-hover {
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
  stroke-width: 2px; }

.highcharts-mapline-series .highcharts-point {
  fill: none; }

.highcharts-heatmap-series .highcharts-point {
  stroke-width: 0; }

.highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center; }

.highcharts-coloraxis {
  stroke-width: 0; }

.highcharts-coloraxis-marker {
  fill: #999999; }

.highcharts-null-point {
  fill: #f7f7f7; }

/* 3d charts */
.highcharts-3d-frame {
  fill: transparent; }

/* Exporting module */
.highcharts-contextbutton {
  fill: #ffffff;
  /* needed to capture hover */
  stroke: none;
  stroke-linecap: round; }

.highcharts-contextbutton:hover {
  fill: #e6e6e6;
  stroke: #e6e6e6; }

.highcharts-button-symbol {
  stroke: #666666;
  stroke-width: 3px; }

.highcharts-menu {
  border: 1px solid #999999;
  background: #ffffff;
  padding: 5px 0;
  box-shadow: 3px 3px 10px #888; }

.highcharts-menu-item {
  padding: 0.5em 1em;
  background: none;
  color: #333333;
  cursor: pointer;
  transition: background 250ms, color 250ms; }

.highcharts-menu-item:hover {
  background: #335cad;
  color: #ffffff; }

/* Drilldown module */
.highcharts-drilldown-point {
  cursor: pointer; }

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: #003399;
  font-weight: bold;
  text-decoration: underline; }

/* No-data module */
.highcharts-no-data text {
  font-weight: bold;
  font-size: 12px;
  fill: #666666; }

/* Drag-panes module */
.highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: black;
  stroke-width: 2px; }

/* Bullet type series */
.highcharts-bullet-target {
  stroke-width: 0; }

/* Lineargauge type series */
.highcharts-lineargauge-target {
  stroke-width: 1px;
  stroke: #333333; }

.highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: #333333; }

/* Annotations module */
.highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: #000000;
  fill: #000000;
  fill-opacity: 0.75; }

.highcharts-annotation-label text {
  fill: #e6e6e6; }

/* Gantt */
.highcharts-treegrid-node-collapsed, .highcharts-treegrid-node-expanded {
  cursor: pointer; }

.highcharts-point-connecting-path {
  fill: none; }

.highcharts-grid-axis .highcharts-tick {
  stroke-width: 1px; }

.highcharts-grid-axis .highcharts-axis-line {
  stroke-width: 1px; }

sgx-chart {
  display: block; }

.highcharts-yaxis-grid .highcharts-grid-line {
  stroke: #d7d7d8; }

.highcharts-axis.sgx-chart-yaxis-colour {
  fill: #1d1d1d;
  stroke: transparent; }

.highcharts-axis.sgx-chart-yaxis-colour text {
  fill: #1d1d1d; }

.highcharts-axis.sgx-chart-yaxis-colour .highcharts-axis-line {
  stroke: transparent; }

.highcharts-background {
  fill: transparent; }

.highcharts-loading {
  background-color: transparent; }

.highcharts-loading-inner {
  color: #1d1d1d; }

.highcharts-axis-labels {
  color: #6f7072;
  fill: #6f7072; }

/* STYLED MODE ENABLED */
.highcharts-xaxis .highcharts-tick {
  stroke: #d7d7d8; }

.highcharts-tooltip-box {
  stroke: transparent; }

/* add class 'sgx-chart-series-volume or price' to the className property of your chart config */
.highcharts-tooltip .sgx-chart-series-volume,
.highcharts-column-series.sgx-chart-series-volume rect.highcharts-point {
  fill: #BDD831;
  stroke: #BDD831; }

.highcharts-tooltip .sgx-chart-series-price,
.highcharts-line-series.sgx-chart-series-price {
  fill: #0094B3;
  stroke: #0094B3; }

sgx-content-table {
  display: block;
  position: relative; }

.sgx-content-table-scroll-container {
  overflow: auto;
  position: relative;
  max-width: 100%; }
  .sgx-content-table-scroll-container > table {
    min-width: 600px; }
  .sgx-content-table-scroll-container.sgx-content-table-scroll-container--fixed-height {
    max-height: 500px; }

.sgx-content-table-fixed-column {
  position: absolute;
  top: 0;
  left: -1px;
  z-index: 10;
  overflow: hidden; }
  .sgx-content-table-fixed-column th {
    border-right: 1px solid #d7d7d8;
    padding-right: 19px; }

.sgx-content-table-fixed-column-header {
  position: relative;
  overflow: hidden;
  z-index: 10; }

.sgx-content-table-fixed-column-body {
  position: relative;
  z-index: 1; }

.sgx-content-table {
  width: 100%;
  border-collapse: collapse;
  color: #1d1d1d; }
  .sgx-content-table caption {
    caption-side: bottom;
    text-align: left;
    padding-top: 10px; }
  .sgx-content-table tr {
    background-color: white;
    border-bottom: 1px solid #d7d7d8; }
  .sgx-content-table thead th {
    vertical-align: bottom; }
  .sgx-content-table thead tr {
    border-bottom-width: 2px; }
  .sgx-content-table.sgx-content-table--vertical-align-top tbody {
    vertical-align: top; }
  .sgx-content-table tbody tr:hover {
    background-color: #e7e7e7; }
  .sgx-content-table th {
    text-align: left;
    font-weight: bold; }
  .sgx-content-table th, .sgx-content-table td {
    padding: 10px 15px; }
  @media (min-width: 1280px) {
    .sgx-content-table caption {
      padding-top: 12px; }
    .sgx-content-table thead th {
      padding: 12px 20px; }
    .sgx-content-table th, .sgx-content-table td {
      padding: 15px 20px; } }
  .sgx-content-table .sgx-content-table-change--down {
    color: #BF0052; }
  .sgx-content-table .sgx-content-table-change--up {
    color: #BCD730; }
  .sgx-content-table.sgx-content-table--wrap-words th, .sgx-content-table.sgx-content-table--wrap-words td {
    word-wrap: break-word;
    word-break: break-word;
    -ms-word-break: break-word; }
  .sgx-content-table.sgx-content-table--no-header {
    border-top: 1px solid #d7d7d8; }
  .sgx-content-table.sgx-content-table--equal-column-width {
    table-layout: fixed; }
  .sgx-content-table.sgx-content-table--sticky-first-column th:first-child {
    position: sticky;
    left: 0;
    background: inherit;
    z-index: 10; }
    .sgx-content-table.sgx-content-table--sticky-first-column th:first-child:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      background: #d7d7d8;
      right: 0;
      top: 0; }
  .sgx-content-table.sgx-content-table--sticky-header thead th {
    position: sticky;
    top: 0;
    background: inherit; }
    .sgx-content-table.sgx-content-table--sticky-header thead th:first-child {
      z-index: 20; }
    .sgx-content-table.sgx-content-table--sticky-header thead th:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: #d7d7d8;
      left: 0;
      bottom: -2px; }
  .sgx-content-table.sgx-content-table--condensed caption {
    padding-top: 8px; }
  .sgx-content-table.sgx-content-table--condensed th, .sgx-content-table.sgx-content-table--condensed td {
    padding: 8px 10px; }
  @media (min-width: 1280px) {
    .sgx-content-table.sgx-content-table--condensed caption {
      padding-top: 10px; }
    .sgx-content-table.sgx-content-table--condensed thead th {
      padding: 10px 15px; }
    .sgx-content-table.sgx-content-table--condensed th, .sgx-content-table.sgx-content-table--condensed td {
      padding: 10px 15px; } }

/*
*  TODO: Remove this override once the styles in sgx-table have been updated to remove visited colors
*/
sgx-table-cell-link.sgx-table-cell a:visited {
  color: #0B236B; }

sgx-announcements {
  display: block; }

.sgx-announcements-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  color: #1d1d1d; }

.sgx-announcements-list-item {
  display: flex;
  align-items: baseline;
  padding: 8px 0 8px 12px;
  border-bottom: 1px solid #d7d7d8; }
  .sgx-announcements-list-item:before {
    content: '';
    font-size: 8px;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 50%;
    color: #FFFFFF;
    flex-shrink: 0; }
  .sgx-announcements-list-item--success:before {
    icon-font: url("@sgx/sgx-base-code/svgs/solid/check.svg");
    background: #74AC00; }
  .sgx-announcements-list-item--info:before {
    icon-font: url("@sgx/sgx-base-code/svgs/solid/info.svg");
    background: #0077CC; }
  .sgx-announcements-list-item--warning:before {
    icon-font: url("@sgx/sgx-base-code/svgs/solid/exclamation.svg");
    background: #FFCC00; }
  .sgx-announcements-list-item--error:before {
    icon-font: url("@sgx/sgx-base-code/svgs/solid/exclamation.svg");
    background: #FF0000; }

.sgx-announcements-list-item.sgx-icon--before:before, .lm_header .lm_tab .sgx-announcements-list-item.lm_close_tab:before {
  margin-right: 8px;
  line-height: 16px; }

/* Absolute Centre Loader with Overlay */
sgx-loader {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 0.8;
  background: white;
  color: #6f7072;
  /* Spinner */ }
  sgx-loader:before {
    margin-bottom: 4px;
    icon-font: url(sgx-base-code/svgs/regular/spinner.svg);
    display: block;
    animation: spinner 1500ms infinite cubic-bezier(0.2, 0.5, 0.7, 0.5); }
  sgx-loader.sgx-loader--medium {
    font-size: 30px; }
  sgx-loader.sgx-loader--large {
    font-size: 40px; }
  sgx-loader.sgx-loader--hidden {
    display: none; }

/* Animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* SHARED */
sgx-tabs {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  sgx-tabs .sgx-tabs-scroll-wrapper {
    flex: 0 1 auto;
    height: 100%;
    position: relative;
    overflow: hidden;
    -webkit-overflow-scrolling: touch; }
  sgx-tabs .sgx-tabs-container {
    display: flex;
    height: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    position: relative;
    white-space: nowrap;
    min-width: 100%;
    margin: 0;
    padding: 0;
    list-style: none; }
  sgx-tabs .sgx-tab {
    position: relative;
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow-y: hidden; }
  sgx-tabs .sgx-tab-link {
    color: inherit;
    text-decoration: none; }

/* PAGE TABS & CONTENT TABS */
sgx-tabs[data-type="page"],
sgx-tabs[data-type="content"] {
  line-height: 2; }
  sgx-tabs[data-type="page"] .sgx-tab,
  sgx-tabs[data-type="content"] .sgx-tab {
    min-width: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 20px;
    align-items: flex-start; }
    @media (min-width: 600px) {
      sgx-tabs[data-type="page"] .sgx-tab,
      sgx-tabs[data-type="content"] .sgx-tab {
        margin-left: 32px; } }
  sgx-tabs[data-type="page"] .sgx-tab:hover,
  sgx-tabs[data-type="content"] .sgx-tab:hover {
    color: rgba(0, 0, 0, 0.87); }
  sgx-tabs[data-type="page"] .sgx-tab:after,
  sgx-tabs[data-type="content"] .sgx-tab:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: #BCD730;
    transform: translateY(150%);
    transition: transform 0.3s ease, background-color 0.3s ease; }
  sgx-tabs[data-type="page"] .sgx-tab:first-of-type,
  sgx-tabs[data-type="content"] .sgx-tab:first-of-type {
    margin-left: 8px; }
    @media (min-width: 600px) {
      sgx-tabs[data-type="page"] .sgx-tab:first-of-type,
      sgx-tabs[data-type="content"] .sgx-tab:first-of-type {
        margin-left: 12px; } }
  sgx-tabs[data-type="page"] .sgx-tab.sgx-tab--active,
  sgx-tabs[data-type="content"] .sgx-tab.sgx-tab--active {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    cursor: default; }
    sgx-tabs[data-type="page"] .sgx-tab.sgx-tab--active:after,
    sgx-tabs[data-type="content"] .sgx-tab.sgx-tab--active:after {
      transform: translateY(0);
      background: #BCD730; }
  sgx-tabs[data-type="page"].sgx-tabs--editable .sgx-tabs-arrow-down,
  sgx-tabs[data-type="content"].sgx-tabs--editable .sgx-tabs-arrow-down {
    margin-right: -8px; }
  sgx-tabs[data-type="page"].sgx-tabs--editable .sgx-tab-btn-add,
  sgx-tabs[data-type="content"].sgx-tabs--editable .sgx-tab-btn-add {
    margin-left: 8px; }

/* PAGE TABS */
sgx-tabs[data-type="page"] {
  font-size: 16px; }
  sgx-tabs[data-type="page"] .sgx-tab:after {
    height: 4px; }

/* CONTENT TABS */
sgx-tabs[data-type="content"] {
  font-size: 14px;
  box-shadow: inset 0px -1px 0 0px rgba(0, 0, 0, 0.09); }
  sgx-tabs[data-type="content"] .sgx-tab:after {
    height: 3px; }

/* STATE TABS */
sgx-tabs[data-type="state"] {
  color: rgba(0, 0, 0, 0.6);
  background-color: #f7f7f7; }
  sgx-tabs[data-type="state"] .sgx-tab {
    padding: 4px 8px; }
    @media (min-width: 600px) {
      sgx-tabs[data-type="state"] .sgx-tab {
        padding: 4px 12px; } }
  sgx-tabs[data-type="state"] .sgx-tab:hover {
    color: rgba(0, 0, 0, 0.87); }
  sgx-tabs[data-type="state"] .sgx-tab.sgx-tab--active {
    color: rgba(255, 255, 255, 0.87);
    background-color: #0094B3; }
  sgx-tabs[data-type="state"] .sgx-tab-btn-ellipsis-v {
    color: rgba(255, 255, 255, 0.6); }
  sgx-tabs[data-type="state"] .sgx-tab-btn-ellipsis-v:hover {
    color: rgba(255, 255, 255, 0.87); }
  sgx-tabs[data-type="state"] .sgx-tabs-arrow-left {
    box-shadow: 10px 0 10px -4px #f7f7f7; }
  sgx-tabs[data-type="state"] .sgx-tabs-arrow-right {
    box-shadow: -10px 0 10px -4px #f7f7f7; }

#sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-box {
  border-width: 0px;
  border-radius: 5px;
  background-color: #47484a;
  box-shadow: 0 1px 9px 2px rgba(0, 0, 0, 0.2);
  max-height: 45vh; }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-box:before {
    content: "";
    position: absolute;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent; }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-box.tether-element-attached-top {
    margin-top: 8px; }
    #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-box.tether-element-attached-top:before {
      top: -8px;
      border-bottom: 8px solid #47484a; }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-box.tether-element-attached-bottom {
    margin-bottom: 8px; }
    #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-box.tether-element-attached-bottom:before {
      bottom: -8px;
      border-top: 8px solid #47484a; }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-box.tether-element-attached-right:before {
    right: 4px; }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-box.tether-element-attached-left:before {
    left: 4px; }

#sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body {
  text-align: left; }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body > .menu-item {
    color: rgba(255, 255, 255, 0.87);
    padding: 8px 12px;
    cursor: pointer; }
    #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body > .menu-item:first-child {
      border-radius: 4px 4px 0 0; }
    #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body > .menu-item:last-child {
      border-radius: 0 0 4px 4px; }
    #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body > .menu-item:hover {
      color: rgba(0, 0, 0, 0.87);
      background-color: #80cad9;
      opacity: 1; }
    #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body > .menu-item a {
      color: rgba(255, 255, 255, 0.87);
      text-decoration: none; }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body > .menu-item--disabled {
    color: rgba(0, 0, 0, 0.2);
    cursor: default; }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body .menu-item--edit,
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body .menu-item--delete,
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body .menu-item--clone {
    margin-right: 8px; }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body .menu-item--edit:before {
    icon-font: url(sgx-base-code/svgs/solid/pencil.svg); }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body .menu-item--clone:before {
    icon-font: url(sgx-base-code/svgs/solid/clone.svg); }
  #sgx-tabs-context-menu-dialog.sgx-dialog--attached .sgx-dialog-body .menu-item--delete:before {
    icon-font: url(sgx-base-code/svgs/solid/times.svg); }

/* ICONS */
.sgx-tab-btn-ellipsis-v,
.sgx-tabs-arrow-right,
.sgx-tabs-arrow-left,
.sgx-tabs-arrow-down {
  display: none; }

.sgx-tab-btn-add,
.sgx-tab-btn-ellipsis-v,
.sgx-tabs-arrow-right,
.sgx-tabs-arrow-left,
.sgx-tabs-arrow-down {
  position: relative;
  z-index: 1;
  flex: 0 0 auto;
  padding: 0 8px;
  cursor: pointer;
  background: 0;
  border: 0;
  outline: 0;
  color: rgba(0, 0, 0, 0.2); }
  .sgx-tab-btn-add:hover, .sgx-tab-btn-add:focus,
  .sgx-tab-btn-ellipsis-v:hover,
  .sgx-tab-btn-ellipsis-v:focus,
  .sgx-tabs-arrow-right:hover,
  .sgx-tabs-arrow-right:focus,
  .sgx-tabs-arrow-left:hover,
  .sgx-tabs-arrow-left:focus,
  .sgx-tabs-arrow-down:hover,
  .sgx-tabs-arrow-down:focus {
    color: rgba(0, 0, 0, 0.6); }
  .sgx-tab-btn-add:active,
  .sgx-tab-btn-ellipsis-v:active,
  .sgx-tabs-arrow-right:active,
  .sgx-tabs-arrow-left:active,
  .sgx-tabs-arrow-down:active {
    color: rgba(0, 0, 0, 0.87); }
  .sgx-tab-btn-add:disabled,
  .sgx-tab-btn-ellipsis-v:disabled,
  .sgx-tabs-arrow-right:disabled,
  .sgx-tabs-arrow-left:disabled,
  .sgx-tabs-arrow-down:disabled {
    color: rgba(0, 0, 0, 0.09) !important;
    cursor: default !important;
    box-shadow: none !important; }

.sgx-tab-btn-add:before {
  icon-font: url(sgx-base-code/svgs/solid/plus.svg); }

.sgx-tab-btn-ellipsis-v:after {
  icon-font: url(sgx-base-code/svgs/regular/ellipsis-v.svg); }

.sgx-tabs-arrow-left:after {
  icon-font: url(sgx-base-code/svgs/solid/chevron-left.svg); }

.sgx-tabs-arrow-right:after {
  icon-font: url(sgx-base-code/svgs/solid/chevron-right.svg); }

.sgx-tabs-arrow-down:after {
  icon-font: url(sgx-base-code/svgs/solid/chevron-down.svg); }

.sgx-tabs-arrow-left {
  align-self: stretch;
  box-shadow: 10px 0 10px -4px rgba(0, 0, 0, 0.2); }

.sgx-tabs-arrow-right {
  align-self: stretch;
  box-shadow: -10px 0 10px -4px rgba(0, 0, 0, 0.2); }

.sgx-tab-btn-ellipsis-v {
  width: 20px;
  margin-left: -8px;
  text-align: right;
  padding: 0; }
  .sgx-tab--active > .sgx-tab-btn-ellipsis-v {
    display: inline-block; }

.sgx-tab-btn-add {
  display: none;
  align-self: center; }
  .sgx-tabs--editable .sgx-tab-btn-add {
    display: block; }

/* DIALOGUES */
#sgx-tabs-add-dialog .sgx-dialog-body,
#sgx-tabs-edit-dialog .sgx-dialog-body {
  padding: 1rem; }

#sgx-tabs-add-dialog .sgx-input-control,
#sgx-tabs-edit-dialog .sgx-input-control {
  min-width: 0; }

sgx-tooltip {
  display: inline-block;
  position: relative; }

[data-sgx-tooltip-content] {
  display: none; }

.sgx-tooltip-button {
  display: inline-block;
  width: 24px;
  height: 24px;
  min-width: auto;
  padding: 0;
  font-size: 14px;
  color: #6f7072;
  border: 0 !important; }
  .sgx-tooltip-button:after {
    icon-font: url(sgx-base-code/svgs/solid/question-circle.svg); }
  .sgx-tooltip--active .sgx-tooltip-button {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box {
  border-width: 0px;
  border-radius: 5px;
  background-color: #47484a;
  box-shadow: 0 1px 9px 2px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  max-height: 45vh; }
  @media (max-width: 767px) {
    #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box {
      max-width: 95vw; } }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-bottom {
    top: 8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-top {
    top: -8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-right {
    left: 8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-left {
    left: -8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-pinned-left {
    margin-left: 8px; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-pinned-right {
    margin-left: -8px; }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-body {
  color: rgba(255, 255, 255, 0.87); }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body {
  padding: 4px 12px; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body a {
    color: #4da0db; }
    #sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body a:hover {
      color: #0d7ecf; }

.tether-target.sgx-tooltip--active.sgx-tooltip--positioned-target {
  position: relative; }

.tether-target.sgx-tooltip--active:before {
  content: "";
  position: absolute;
  z-index: 1;
  animation: fadeIn .2s ease-out; }

.tether-target.sgx-tooltip--active.tether-target-attached-top.tether-element-attached-bottom:before, .tether-target.sgx-tooltip--active.tether-target-attached-bottom.tether-element-attached-top:before {
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; }

.tether-target.sgx-tooltip--active.tether-target-attached-top.tether-element-attached-bottom:before {
  top: -9px;
  border-top: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-bottom.tether-element-attached-top:before {
  bottom: -7px;
  border-bottom: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-left.tether-element-attached-right:before, .tether-target.sgx-tooltip--active.tether-target-attached-right.tether-element-attached-left:before {
  top: 50%;
  transform: translateY(-50%);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent; }

.tether-target.sgx-tooltip--active.tether-target-attached-left.tether-element-attached-right:before {
  left: -9px;
  border-left: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-right.tether-element-attached-left:before {
  right: -7px;
  border-right: 8px solid #47484a; }

.sgx-context-menu-item {
  display: block;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none; }
  .sgx-context-menu-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }
  .sgx-context-menu-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .sgx-context-menu-item:hover {
    color: rgba(0, 0, 0, 0.87);
    background-color: #80cad9;
    opacity: 1; }
  .sgx-context-menu-item.sgx-context-menu-item--disabled {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.2) !important;
    background: transparent !important;
    cursor: default !important; }

.sgx-context-menu-item--icon {
  display: inline-block;
  width: 40px;
  text-align: center;
  margin-left: -12px; }

sgx-tooltip {
  display: inline-block;
  position: relative; }

[data-sgx-tooltip-content] {
  display: none; }

.sgx-tooltip-button {
  display: inline-block;
  width: 24px;
  height: 24px;
  min-width: auto;
  padding: 0;
  font-size: 14px;
  color: #6f7072;
  border: 0 !important; }
  .sgx-tooltip-button:after {
    icon-font: url(sgx-base-code/svgs/solid/question-circle.svg); }
  .sgx-tooltip--active .sgx-tooltip-button {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: inset 0 0 0 1px transparent; }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box {
  border-width: 0px;
  border-radius: 5px;
  background-color: #47484a;
  box-shadow: 0 1px 9px 2px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  max-height: 45vh; }
  @media (max-width: 767px) {
    #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box {
      max-width: 95vw; } }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-bottom {
    top: 8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-top {
    top: -8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-right {
    left: 8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-target-attached-left {
    left: -8px !important; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-pinned-left {
    margin-left: 8px; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-box.tether-pinned-right {
    margin-left: -8px; }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-dialog-body {
  color: rgba(255, 255, 255, 0.87); }

#sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body {
  padding: 4px 12px; }
  #sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body a {
    color: #4da0db; }
    #sgx-tooltip-dialog.sgx-dialog--attached .sgx-tooltip-hint-body a:hover {
      color: #0d7ecf; }

.tether-target.sgx-tooltip--active.sgx-tooltip--positioned-target {
  position: relative; }

.tether-target.sgx-tooltip--active:before {
  content: "";
  position: absolute;
  z-index: 1;
  animation: fadeIn .2s ease-out; }

.tether-target.sgx-tooltip--active.tether-target-attached-top.tether-element-attached-bottom:before, .tether-target.sgx-tooltip--active.tether-target-attached-bottom.tether-element-attached-top:before {
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent; }

.tether-target.sgx-tooltip--active.tether-target-attached-top.tether-element-attached-bottom:before {
  top: -9px;
  border-top: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-bottom.tether-element-attached-top:before {
  bottom: -7px;
  border-bottom: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-left.tether-element-attached-right:before, .tether-target.sgx-tooltip--active.tether-target-attached-right.tether-element-attached-left:before {
  top: 50%;
  transform: translateY(-50%);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent; }

.tether-target.sgx-tooltip--active.tether-target-attached-left.tether-element-attached-right:before {
  left: -9px;
  border-left: 8px solid #47484a; }

.tether-target.sgx-tooltip--active.tether-target-attached-right.tether-element-attached-left:before {
  right: -7px;
  border-right: 8px solid #47484a; }

sgx-lite-youtube {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer; }

/* gradient */
sgx-lite-youtube::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
  background-position: top;
  background-repeat: repeat-x;
  height: 60px;
  padding-bottom: 50px;
  width: 100%;
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1); }

/* responsive iframe with a 16:9 aspect ratio
  thanks https://css-tricks.com/responsive-iframes/
*/
sgx-lite-youtube::after {
  content: "";
  display: block;
  padding-bottom: calc(100% / (16 / 9)); }

sgx-lite-youtube > iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0; }

/* play button */
sgx-lite-youtube > .lty-playbtn {
  width: 68px;
  height: 48px;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  z-index: 1; }

sgx-lite-youtube > .lty-playbtn .yt-btn {
  transition: fill 0.1s cubic-bezier(0, 0, 0.2, 1); }

sgx-lite-youtube:hover > .lty-playbtn .yt-btn {
  fill: #f00; }

/* Post-click styles */
sgx-lite-youtube.lyt-activated {
  cursor: unset; }

sgx-lite-youtube.lyt-activated::before,
sgx-lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none; }

sgx-page-loader {
  display: block;
  font-size: 1rem;
  width: 5em;
  height: 5em;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #f4f4f4, #f4f4f4 50%, #e9e9e9 50%, #e9e9e9);
  background-size: 2em; }
  sgx-page-loader:after {
    content: attr(data-content);
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 0.5em;
    font-size: 1.2em;
    color: #222222; }

.sgx-page-loader-line {
  height: 1em;
  animation-duration: 2s;
  animation-iteration-count: infinite; }
  .sgx-page-loader-line:nth-of-type(odd) {
    background: #BDD831; }
  .sgx-page-loader-line:nth-of-type(even) {
    background: #0B236B; }
  .sgx-page-loader-line:nth-of-type(1) {
    animation-name: sgx-page-loader-1; }
  .sgx-page-loader-line:nth-of-type(2) {
    animation-name: sgx-page-loader-2; }
  .sgx-page-loader-line:nth-of-type(3) {
    animation-name: sgx-page-loader-3; }
  .sgx-page-loader-line:nth-of-type(4) {
    animation-name: sgx-page-loader-4; }
  .sgx-page-loader-line:nth-of-type(5) {
    animation-name: sgx-page-loader-5; }

@keyframes sgx-page-loader-1 {
  0%, 20% {
    transform: translateX(-100%); }
  70% {
    transform: translateX(0); } }

@keyframes sgx-page-loader-2 {
  0%, 15% {
    transform: translateX(100%); }
  65% {
    transform: translateX(0); } }

@keyframes sgx-page-loader-3 {
  0%, 10% {
    transform: translateX(-100%); }
  60% {
    transform: translateX(0); } }

@keyframes sgx-page-loader-4 {
  0%, 5% {
    transform: translateX(100%); }
  55% {
    transform: translateX(0); } }

@keyframes sgx-page-loader-5 {
  0% {
    transform: translateX(-100%); }
  50% {
    transform: translateX(0); } }

sgx-quick-search {
  cursor: pointer; }

#sgx-quick-search-dialog {
  overflow: hidden; }
  #sgx-quick-search-dialog .sgx-dialog-overlay {
    overflow: auto;
    height: 100%; }
  #sgx-quick-search-dialog .sgx-button--icon-search {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center; }
  #sgx-quick-search-dialog .sgx-dialog-box {
    border: 0;
    min-width: 630px;
    box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.15);
    padding: 0; }
  #sgx-quick-search-dialog .sgx-input-control {
    height: 3.7rem;
    padding: 0.8rem;
    border-top: 0;
    border-bottom: 1px solid #ccc; }
  #sgx-quick-search-dialog sgx-input-select {
    width: 100%; }
  #sgx-quick-search-dialog sgx-input-text .sgx-input-message {
    padding: 10px 0;
    margin-left: -90px; }
  #sgx-quick-search-dialog sgx-input-text .sgx-input--has-error .sgx-input-control {
    outline: none;
    border-color: #b7b7b7; }

[data-resolution="small"] #sgx-quick-search-dialog .sgx-dialog-box {
  min-width: auto; }

[data-resolution="small"] #sgx-quick-search-dialog sgx-input-text .sgx-input-message {
  margin-left: 10px; }

.website-header-button--search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 40px; }

.sgx-text-small-body {
  font-size: 12px;
  font-weight: normal; }

.sgx-text-small-title {
  font-size: 14px;
  font-weight: 600; }

.sgx-text-small-message {
  font-size: 10px;
  font-weight: normal; }

.sgx-notifications-panel-header-container {
  padding: 8px;
  justify-content: flex-start; }

.sgx-hide-mute-button {
  display: none; }

sgx-header-notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 64px;
  height: 40px; }
  sgx-header-notifications.sgx-header-notifications--count:after {
    content: attr(data-count);
    position: absolute;
    top: 1px;
    right: 12px;
    background: #FF0000;
    border-radius: 50%;
    padding: 4px 6px;
    color: white;
    font-size: 0.7rem; }

.sgx-header-notifications-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(89, 89, 89, 0.5); }
  .sgx-header-notifications-overlay--hidden {
    display: none; }

#notifications-detached,
#notifications-attached {
  position: fixed;
  top: 0;
  z-index: 3000; }

#notifications-detached {
  right: 0;
  padding: 0 20px;
  height: auto; }

#notifications-attached {
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.4s ease; }
  #notifications-attached.active {
    transform: translateX(0%); }

.sgx-notifications-panel--detached {
  width: 450px !important; }
  .sgx-notifications-panel--detached .sgx-notification-body {
    padding: 8px; }

.sgx-notifications-panel--attached {
  width: 390px;
  box-shadow: 2px 0 15px rgba(89, 89, 89, 0.5);
  background-color: white; }

.sgx-notification--detached .sgx-notification-container--order {
  border-left: 2px solid #F79232; }

.sgx-notification--detached .sgx-notification-container--deal {
  border-left: 2px solid #0077cc; }

.sgx-notifications-panel-body--attached {
  overflow-x: hidden; }

.sgx-notifications-template {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  height: 100%; }
  .sgx-notifications-template-title {
    font-family: Lato;
    display: flex;
    width: 100%;
    align-items: center; }
  .sgx-notifications-template .sgx-notification-title {
    color: #6f7072;
    font-weight: normal;
    width: 100%;
    margin-left: 4px;
    margin-top: 2.5px; }
  .sgx-notifications-template .sgx-notifications-template-body {
    color: #1d1d1d; }
  .sgx-notifications-template .sgx-notification-title-tag {
    text-transform: uppercase;
    flex: 0 0 42px;
    height: 15px;
    border-radius: 1px;
    color: white;
    text-align: center;
    line-height: 15px;
    font-size: 11px; }
    .sgx-notifications-template .sgx-notification-title-tag--order {
      background: #F79232; }
    .sgx-notifications-template .sgx-notification-title-tag--deal {
      background: #0077cc; }
  .sgx-notifications-template-date {
    opacity: 0.8;
    font-size: 11px;
    color: #6f7072;
    font-weight: normal;
    text-align: right;
    text-transform: none;
    width: auto;
    line-height: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .sgx-notifications-template-body {
    color: #1d1d1d;
    margin-top: 4px; }
  .sgx-notifications-template-sup-title {
    margin: 4px 0;
    color: #6f7072; }
  .sgx-notifications-template-sub-title {
    color: #6f7072; }

body[data-type="mobile"] #notifications-detached {
  padding: 0;
  left: 10px;
  width: calc(100% - 20px); }
  body[data-type="mobile"] #notifications-detached .sgx-notifications-panel {
    width: auto !important; }

sgx-input-time-picker {
  display: inline-block;
  margin-top: 3px; }

.table-decorator-flash {
  position: relative;
  animation: flash 1s; }
  .table-decorator-flash:after {
    content: '';
    animation-name: flash;
    animation-duration: 1s;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0; }

@keyframes iefix {
  from, to {
    transform: translateZ(0); } }

@keyframes flash {
  10% {
    opacity: 0.5; } }

sgx-titan-loader .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 105;
  background-color: rgba(0, 0, 0, 0.1);
  /*dim the background*/
  font-size: 1rem; }
  sgx-titan-loader .overlay:after {
    content: attr(data-content);
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    margin: 0 auto;
    text-align: center; }
  sgx-titan-loader .overlay .sgx-titan-loader--icon {
    display: inline-block;
    width: 100%;
    position: absolute;
    left: 0;
    top: 46.5%;
    right: 0;
    margin: 0 auto;
    text-align: center; }
    sgx-titan-loader .overlay .sgx-titan-loader--icon:after {
      content: "\f1ce";
      font-family: fontAwesome; }

.sgx-titan-loader--spin {
  animation: spin 2s infinite linear; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

[data-alert-type='error'] {
  background: #f2dede; }

[data-alert-type='information'] {
  background: #d9edf7; }

[data-alert-type='success'] {
  background: #dff0d8; }

[data-alert-type='warning'] {
  background: #fcf8e3; }

sgx-status-bar {
  height: 3rem;
  bottom: 0;
  left: 4rem;
  right: 0;
  z-index: 9999;
  transition: transform .3s; }
  sgx-status-bar .sgx-status-bar-wrapper {
    border-radius: 2px;
    margin: 0;
    padding: 0;
    transition: all 0.18s ease-out;
    transform: translate3d(0px, 0px, 0px);
    will-change: transform; }
    sgx-status-bar .sgx-status-bar-wrapper[data-alert-type='error'], sgx-status-bar .sgx-status-bar-wrapper[data-alert-type='information'], sgx-status-bar .sgx-status-bar-wrapper[data-alert-type='success'], sgx-status-bar .sgx-status-bar-wrapper[data-alert-type='warning'] {
      padding: 1rem .8rem; }
    sgx-status-bar .sgx-status-bar-wrapper.sgx-status-bar-sticky {
      position: fixed;
      margin: 0;
      bottom: 0;
      border-radius: 0;
      left: 4rem;
      right: 0; }
      sgx-status-bar .sgx-status-bar-wrapper.sgx-status-bar-sticky .sgx-status-bar-close-btn {
        right: .7rem; }
  sgx-status-bar .sgx-status-bar-message {
    font-size: .95em;
    text-transform: initial; }
  sgx-status-bar .sgx-status-bar-close-btn {
    cursor: pointer;
    opacity: .55;
    position: absolute;
    right: .8rem;
    top: 1rem; }
    sgx-status-bar .sgx-status-bar-close-btn:hover, sgx-status-bar .sgx-status-bar-close-btn:active {
      opacity: 1; }
    sgx-status-bar .sgx-status-bar-close-btn.sgx-status-bar-close-btn--hidden {
      display: none; }
    sgx-status-bar .sgx-status-bar-close-btn span {
      font-size: .85em;
      text-indent: -9999em;
      position: absolute; }
    sgx-status-bar .sgx-status-bar-close-btn:after {
      font-family: 'fontAwesome';
      content: '\f00d';
      display: block; }

body[data-type='mobile'] sgx-status-bar .sgx-status-bar-wrapper.sgx-status-bar-sticky,
body[data-type='tablet'] sgx-status-bar .sgx-status-bar-wrapper.sgx-status-bar-sticky,
body[data-type='desktop'][data-resolution='small'] sgx-status-bar .sgx-status-bar-wrapper.sgx-status-bar-sticky {
  left: 0; }

#order-history-dialog {
  min-height: 28rem; }
  #order-history-dialog h2 {
    width: 100%;
    text-align: center; }
  #order-history-dialog .details-content {
    max-width: 100%;
    display: block;
    padding: 20px;
    width: 60vw; }
    #order-history-dialog .details-content sgx-table {
      height: 50vh;
      max-width: 100%; }
  #order-history-dialog .wait-message {
    text-align: center;
    display: block; }

sgx-product-selector .sgx-product-selector-wrapper {
  width: 100%; }

sgx-product-selector .sgx-product-selector-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%; }

sgx-product-selector .sgx-product-selector-list-item {
  margin: 0.5rem;
  text-align: center;
  cursor: pointer;
  min-width: 14rem;
  max-width: 14rem;
  min-height: 9rem;
  border-radius: 5px;
  background-color: #efefef;
  padding: 1rem; }
  sgx-product-selector .sgx-product-selector-list-item .sgx-product-selector-list-item-image {
    margin-top: 1rem;
    margin-left: 0;
    margin-right: 0;
    color: #222222; }
    sgx-product-selector .sgx-product-selector-list-item .sgx-product-selector-list-item-image .sgx-product-selector-list-item-image-icon:before {
      font-size: 2rem;
      content: '\f0ac';
      font-family: fontTotc, fontAwesome; }
  sgx-product-selector .sgx-product-selector-list-item > div {
    padding: 1.5rem 1rem 0rem 1rem; }
  sgx-product-selector .sgx-product-selector-list-item p {
    font-size: 15px;
    color: #222222; }
  sgx-product-selector .sgx-product-selector-list-item img {
    width: 4rem; }

sgx-product-selector .sgx-product-selector-list-item--active {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #0094B3; }
  sgx-product-selector .sgx-product-selector-list-item--active p {
    color: #ffffff; }
  sgx-product-selector .sgx-product-selector-list-item--active .sgx-product-selector-list-item-image {
    color: #ffffff; }

sgx-product-selector .sgx-product-selector-list-item--selected {
  background-color: white;
  pointer-events: none;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  sgx-product-selector .sgx-product-selector-list-item--selected > div {
    opacity: 0.6; }

.sgx-product-selector-input-select {
  width: 100%; }
  .sgx-product-selector-input-select sgx-list {
    height: 100%; }

sgx-contract-selector {
  display: block;
  height: 100%; }
  sgx-contract-selector .sgx-contract-selector-container {
    display: flex;
    border-top: 0;
    width: 100%;
    height: 100%; }
  sgx-contract-selector .sgx-contract-selector-container-child {
    font-size: 13px;
    text-transform: capitalize;
    background: #e7e7e7;
    color: #222222;
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
    margin: 0 .3rem; }
    sgx-contract-selector .sgx-contract-selector-container-child .sgx-contract-selector-fragment-title {
      font-size: 16px;
      margin: .4rem;
      width: 100%; }
    sgx-contract-selector .sgx-contract-selector-container-child .sgx-contract-selector-dynamic-update {
      width: 100%;
      margin: .4rem 0 .4rem .8rem; }
    sgx-contract-selector .sgx-contract-selector-container-child sgx-input-select {
      width: 100%; }
    sgx-contract-selector .sgx-contract-selector-container-child .horizontal-scrolling-bar,
    sgx-contract-selector .sgx-contract-selector-container-child .vertical-scrolling-bar {
      background: #5F6062; }
      sgx-contract-selector .sgx-contract-selector-container-child .horizontal-scrolling-bar:hover,
      sgx-contract-selector .sgx-contract-selector-container-child .vertical-scrolling-bar:hover {
        background: #BDD831; }
      sgx-contract-selector .sgx-contract-selector-container-child .horizontal-scrolling-bar:active,
      sgx-contract-selector .sgx-contract-selector-container-child .vertical-scrolling-bar:active {
        background: #aac22c; }
  sgx-contract-selector .sgx-contract-selector-container-group-title {
    margin: 1.5rem 0; }
  .sgx-contract-selector-container-group .sgx-input-label sgx-contract-selector {
    font-size: 13px; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  sgx-contract-selector .sgx-contract-selector-container sgx-input-checkbox-toggle-list {
    width: 100%; } }

@supports (-ms-accelerator: true) {
  sgx-contract-selector .sgx-contract-selector-container sgx-input-checkbox-toggle-list {
    width: 100%; } }

.sgx-contract-selector-container-child .sgx-input-checkbox-label {
  font-size: 0.85rem; }

sgx-table-cell-action-menu {
  cursor: pointer; }
  sgx-table-cell-action-menu .sgx-table-cell-menu-kebab-icon {
    visibility: hidden;
    position: absolute;
    left: 0;
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
    sgx-table-cell-action-menu .sgx-table-cell-menu-kebab-icon svg {
      pointer-events: none; }
    sgx-table-cell-action-menu .sgx-table-cell-menu-kebab-icon.visible {
      visibility: visible; }
  sgx-table-cell-action-menu .sgx-table-cell-menu-text {
    margin-left: 0.5rem; }
  sgx-table-cell-action-menu:hover .sgx-table-cell-menu-kebab-icon {
    visibility: visible; }

sgx-table sgx-table-row:hover sgx-table-cell-action-menu .sgx-table-cell-menu-kebab-icon {
  visibility: visible; }

.sgx-table-action-menu-cell-triangle {
  position: absolute;
  left: -9px; }
  .sgx-table-action-menu-cell-triangle:before {
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    border-right: 10px solid rgba(0, 0, 0, 0.1);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; }
  .sgx-table-action-menu-cell-triangle:after {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    border-right: 9px solid #ffffff;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent; }

.sgx-table-cell-action-menu-line {
  height: 1px;
  background: #efefef;
  opacity: 0.07; }

#order-cancellation-dialog .sgx-dialog-box {
  min-width: 572px;
  min-height: 350px; }

#order-cancellation-dialog [data-dialog="title"] {
  font-size: 1.1rem;
  font-weight: bold;
  color: #222222; }

#order-cancellation-dialog .order-cancellation-dialog-body-single-order,
#order-cancellation-dialog .order-cancellation-dialog-body-all-orders {
  display: none; }

#order-cancellation-dialog .sgx-dialog-body {
  color: #222222;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  display: flex; }
  #order-cancellation-dialog .sgx-dialog-body p {
    font-size: 16px;
    margin: 1.5rem 0; }

#order-cancellation-dialog .order-cancellation-dialog-text {
  width: 320px; }

#order-cancellation-dialog .order-cancellation-dialog-text-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 2rem; }

#order-cancellation-dialog .order-cancellation-dialog-icon {
  text-align: center;
  width: 40%;
  margin-top: 3rem; }
  #order-cancellation-dialog .order-cancellation-dialog-icon:before {
    content: "\f06a";
    font-family: fontAwesome;
    color: #ff0042;
    font-size: 6rem; }

sgx-modal-confirm {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;
  background: white;
  color: #6f7072; }
  sgx-modal-confirm .sgx-modal-confirm-title {
    text-align: center;
    margin-bottom: 15px; }
  sgx-modal-confirm .sgx-modal-confirm-button-confirm,
  sgx-modal-confirm .sgx-modal-confirm-button-cancel {
    padding: 0 15px;
    margin: 0 15px; }
  sgx-modal-confirm.sgx-modal-confirm--hidden {
    display: none; }

sgx-text-dropdown {
  color: #222; }
  sgx-text-dropdown .sgx-text-dropdown-header-container {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between; }
    sgx-text-dropdown .sgx-text-dropdown-header-container .sgx-text-dropdown-title-container {
      width: 100%; }
    sgx-text-dropdown .sgx-text-dropdown-header-container p {
      cursor: pointer;
      width: 100%; }
      sgx-text-dropdown .sgx-text-dropdown-header-container p.sgx-text-dropdown-list-container--toggle-closed:before, sgx-text-dropdown .sgx-text-dropdown-header-container p.sgx-text-dropdown-list-container--toggle-open:before {
        font-family: "fontTotc";
        margin-right: 0.5rem;
        font-size: 0.75em;
        position: relative;
        top: -1px; }
      sgx-text-dropdown .sgx-text-dropdown-header-container p.sgx-text-dropdown-list-container--toggle-closed:before {
        content: "\e827"; }
      sgx-text-dropdown .sgx-text-dropdown-header-container p.sgx-text-dropdown-list-container--toggle-open:before {
        content: "\e826"; }
  sgx-text-dropdown .sgx-text-dropdown-toggle-container {
    padding-top: 6px; }
  sgx-text-dropdown .sgx-text-dropdown-list {
    display: none;
    font-size: 0.8rem; }
    sgx-text-dropdown .sgx-text-dropdown-list li {
      margin: 0.5rem; }
  sgx-text-dropdown .sgx-text-dropdown-list-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 80%;
    margin-left: 1.6em; }

.sgx-alert-dialog {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center; }
  .sgx-alert-dialog .sgx-dialog-box {
    position: relative; }
    body[data-type="mobile"] .sgx-alert-dialog .sgx-dialog-box {
      margin: 10px; }
  .sgx-alert-dialog--hidden {
    display: none; }
  .sgx-alert-dialog-status-message {
    font-size: 0.9rem;
    padding: 0.3rem 1rem;
    background: #FF0000;
    line-height: 1.2rem;
    opacity: 1;
    height: auto;
    transition: opacity 0.5s ease-out;
    color: #222222; }
    .sgx-alert-dialog-status-message--hidden {
      display: none; }

.sgx-alert-dialog-close {
  position: absolute;
  top: 16px;
  right: 12px;
  cursor: pointer;
  color: #222222; }

.sgx-alert-dialog-footer {
  margin-top: 0;
  flex-direction: row;
  justify-content: flex-end; }

.sgx-alert-dialog-body {
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center; }

.sgx-alert-dialog-content {
  flex: 2 2 auto;
  color: #222222; }

.sgx-alert-dialog-subtitle {
  padding: 20px 40px 0px;
  color: #222222; }

.sgx-alert-dialog-icon {
  color: #b1ca2d;
  text-align: right;
  border: 1px solid #b1ca2d;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 70px; }
  body[data-type="mobile"] .sgx-alert-dialog-icon {
    margin-right: 0px; }

.sgx-alert-dialog-icon--success {
  color: #bdd832;
  border-color: #bdd832; }

.sgx-alert-dialog-icon--error {
  color: #FF0000;
  border-color: #FF0000; }

.sgx-alert-dialog button:focus {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color; }

.alert-dialog-form-title {
  padding: 12px 0px 20px 0px;
  display: block;
  color: #6d6d6d; }

.alert-dialog-form {
  padding: 0px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  font-size: 14px; }

.alert-dialog-form-item {
  display: inline-block;
  min-height: 40px;
  width: 500px;
  padding: 0 20px 9px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #6d6d6d; }

.alert-dialog-form-label {
  width: 200px;
  text-align: right;
  margin: auto 20px auto 0; }

.alert-dialog-form-control-container {
  display: inline-block;
  width: 300px;
  text-align: left;
  margin: auto 0; }
  .alert-dialog-form-control-container .sgx-input-number-wrapper {
    width: 200px; }
  .alert-dialog-form-control-container sgx-input-text {
    width: 200px; }
  .alert-dialog-form-control-container sgx-input-select {
    width: 200px; }

.alert-dialog-form-detail-title {
  justify-self: flex-start;
  padding-right: 20px;
  flex: 1 1 auto;
  color: #b0b2b3;
  line-height: 1.2em;
  font-size: 12px; }

.alert-dialog-form-detail-description {
  justify-self: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #222222;
  line-height: 1.2em;
  flex: 1 1 auto; }

.confirm-reject-content-subtitle {
  padding: 12px 0px 20px 0px;
  display: block;
  width: 700px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.confirm-reject-content {
  display: flex;
  justify-content: left;
  align-items: flex-start; }

.confirm-reject-content-details {
  display: flex;
  height: auto; }

.confirm-reject-dropdown-item {
  font-size: 0.7rem;
  color: #878787; }

.confirm-reject-container {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  min-width: 420px; }

.confirm-reject-container-tabs {
  min-width: 200px;
  margin-right: 1rem; }

.confirm-reject-details {
  flex: 1 1 auto; }

.confirm-reject-content-detail-title {
  justify-self: flex-start;
  padding-right: 20px;
  flex: 1 1 30%;
  color: #878787;
  line-height: 1.2em;
  font-size: 12px;
  margin: 0.5rem 0;
  width: 30%;
  text-align: right; }
  [data-dropdown='true'] .confirm-reject-content-detail-title {
    align-self: start; }

.confirm-reject-content-leg-price-title {
  width: 100%;
  text-align: left; }

.confirm-reject-content-leg-price-input-1, .confirm-reject-content-leg-price-input-2 {
  width: 200px;
  font-size: 12px; }

.confirm-reject-content-leg-price-input-2 {
  margin-left: 1rem; }

.confirm-reject-content-leg-price-input-1 .sgx-input-label,
.confirm-reject-content-leg-price-input-2 .sgx-input-label {
  font-size: 12px; }

.confirm-reject-content-detail-description {
  justify-self: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #222222;
  line-height: 1.2em;
  flex: 1 1 70%;
  margin: 0.5rem 0;
  width: 70%; }

.confirm-reject-content-leg1-price-note {
  font-size: 12px;
  margin: 0.5rem 0;
  color: rgba(0, 0, 0, 0.6);
  width: 90%; }

.confirm-reject-dropdown-list-container {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 64px;
  width: 70%; }

.confirm-reject-content-detail-item-trader-select {
  margin-top: 1rem; }
  .confirm-reject-content-detail-item-trader-select sgx-input-select {
    margin-right: 0.5rem; }

.confirm-reject-content-detail-item {
  display: flex;
  align-items: flex-end; }
  .confirm-reject-content-detail-item[data-dropdown='true'][data-is-expanded='true'] {
    position: relative; }
    .confirm-reject-content-detail-item[data-dropdown='true'][data-is-expanded='true'] .confirm-reject-content-detail-title {
      position: absolute; }
    .confirm-reject-content-detail-item[data-dropdown='true'][data-is-expanded='true'] .confirm-reject-content-detail-description {
      position: absolute;
      top: 0px;
      left: 30%;
      margin-left: 5px; }
      .confirm-reject-content-detail-item[data-dropdown='true'][data-is-expanded='true'] .confirm-reject-content-detail-description ul {
        padding-top: 2px;
        padding-bottom: 4px; }

.confirm-reject-content-detail-custom-leg-item {
  align-items: start; }

.confirm-reject-tabs-container {
  position: relative;
  width: 100%;
  height: 200px;
  min-width: 260px; }

.confirm-reject-textarea--buyer,
.confirm-reject-textarea--seller {
  width: 100%;
  height: 200px;
  max-width: 100%;
  max-height: 200px;
  min-width: 260px;
  min-height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  padding: 14px;
  background: #b5bdd2;
  font-family: lato;
  color: #050505;
  transition: opacity 0.4s ease; }

.confirm-reject-textarea--show {
  pointer-events: all;
  opacity: 1; }

.confirm-reject-reject-container {
  padding: 40px;
  display: flex;
  justify-content: center; }

.confirm-reject-reject-icon,
.confirm-reject-reject-text {
  flex: 1 1 auto;
  vertical-align: middle; }

.confirm-reject-reject-icon {
  text-align: center; }
  .confirm-reject-reject-icon:before {
    content: '\f06a';
    font-family: fontAwesome;
    color: #FF0000;
    font-size: 6rem; }

.confirm-reject-dropdown-title {
  cursor: pointer;
  width: 35%; }
  .confirm-reject-dropdown-title:after {
    float: right;
    font-family: 'fontTotc';
    content: '\e827'; }
  .confirm-reject-dropdown-title[data-is-expanded='true']:after {
    content: '\e826'; }

.sgx-confirm-reject-alert-dialog .sgx-alert-dialog-content {
  overflow: auto;
  height: 400px; }

.confirm-reject-content-multileg-note {
  margin-right: 0.5rem; }

sgx-confirm-reject-traders-content .sgx-confirm-reject-traders-content {
  display: flex;
  margin-top: 1rem; }

sgx-confirm-reject-traders-content .sgx-confirm-reject-traders-content-buyer-account,
sgx-confirm-reject-traders-content .sgx-confirm-reject-traders-content-seller-account {
  width: 200px;
  margin: 0.5rem; }

sgx-confirm-reject-traders-content sgx-input-select {
  margin-top: 0.5rem; }

sgx-confirm-reject-traders-content .sgx-confirm-reject-traders-content div:first-child {
  margin-left: 0; }

sgx-confirm-reject-traders-content .sgx-input-label {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.sgx-backend-error-modal-content {
  width: 20rem;
  color: #222222;
  min-width: 500px;
  max-width: 500px; }

.sgx-backend-error-dialog .sgx-alert-dialog-container .sgx-alert-dialog-body .sgx-backend-error-modal-content-body i:before {
  color: #FF0000;
  icon-font: url("sgx-base-code/svgs/solid/times-circle.svg");
  margin-right: 8px;
  vertical-align: text-bottom; }

.sgx-backend-error-dialog .sgx-alert-dialog-container .sgx-alert-dialog-body {
  padding-left: 0px;
  padding-right: 0px;
  line-height: 1.4em; }

body:not([data-type='desktop']) .sgx-backend-error-modal-content {
  min-width: 80%;
  max-width: 400px; }

sgx-table-row.suspend-indicator--bid .sgx-table-cell[data-column-id="bidId"],
sgx-table-row.suspend-indicator--bid .sgx-table-cell[data-column-id="bidQuantity"],
sgx-table-row.suspend-indicator--bid .sgx-table-cell[data-column-id="bidPrice"] {
  color: #6f7072;
  background-color: #e7e7e7;
  border-top: solid 1px #d7d7d8;
  border-bottom: solid 1px #d7d7d8; }

sgx-table-row.suspend-indicator--ask .sgx-table-cell[data-column-id="askId"],
sgx-table-row.suspend-indicator--ask .sgx-table-cell[data-column-id="askQuantity"],
sgx-table-row.suspend-indicator--ask .sgx-table-cell[data-column-id="askPrice"] {
  color: #6f7072;
  background-color: #e7e7e7;
  border-top: solid 1px #d7d7d8;
  border-bottom: solid 1px #d7d7d8; }

sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="askId"],
sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="askQuantity"],
sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="askPrice"],
sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="bidId"],
sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="bidQuantity"],
sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="bidPrice"] {
  color: #6f7072;
  background-color: #e7e7e7;
  border-top: solid 1px #d7d7d8;
  border-bottom: solid 1px #d7d7d8; }

body[data-type="desktop"] sgx-table-row.book-styles:nth-of-type(odd) {
  background: #fff; }
  body[data-type="desktop"] sgx-table-row.book-styles:nth-of-type(odd):hover {
    background: #fff !important; }

body[data-type="desktop"] sgx-table-row.book-styles:nth-of-type(even) {
  background: #f5f5f5; }
  body[data-type="desktop"] sgx-table-row.book-styles:nth-of-type(even):hover {
    background: #f5f5f5 !important; }

sgx-order-book-grid-view .sgx-table-cell {
  text-align: left; }

sgx-order-book-grid-view [data-column-id="bidPrice"] {
  text-align: right; }

.book-styles {
  cursor: pointer; }
  .book-styles--bid--hover [data-column-id^="bid"] {
    background: rgba(12, 35, 107, 0.07); }
  .book-styles--ask--hover [data-column-id^="ask"] {
    background: rgba(12, 35, 107, 0.07); }
  .book-styles--edit-bid-indicator, .book-styles[data-bid-owner="true"] [data-column-id*="bidPrice"]:before, .book-styles[data-ask-owner="true"] [data-column-id*="askPrice"]:before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    background: rgba(12, 35, 107, 0.07);
    border-radius: 30px 0px 0px 30px;
    z-index: -1; }
  .book-styles[data-ask-owner="true"] [data-column-id*="askPrice"]:before {
    border-radius: 0px 30px 30px 0px; }
  .book-styles[data-bid-order-type="0"] [data-column-id*="bid"] {
    color: #222222; }
  .book-styles[data-bid-order-type="1"] [data-column-id="bidPrice"]:after {
    content: '\f00c';
    font-family: fontAwesome;
    position: absolute;
    color: #bdd831;
    font-size: 0.7rem;
    left: 5px; }
  .book-styles[data-bid-order-type="2"] [data-column-id*="bid"] {
    color: #ffcc00; }
  .book-styles[data-bid-fill-type="0"] [data-column-id="bidQuantity"]:after {
    content: '\f023';
    font-family: fontAwesome;
    position: absolute;
    font-size: 0.7rem;
    right: 5px; }
  .book-styles[data-ask-order-type="0"] [data-column-id*="ask"] {
    color: #222222; }
  .book-styles[data-ask-order-type="1"] [data-column-id="askPrice"]:after {
    content: '\f00c';
    font-family: fontAwesome;
    position: absolute;
    right: 5px;
    font-size: 0.7rem;
    color: #bdd831; }
  .book-styles[data-ask-order-type="2"] [data-column-id*="ask"] {
    color: #ffcc00; }
  .book-styles[data-ask-fill-type="0"] [data-column-id="askQuantity"]:after {
    content: '\f023';
    font-family: fontAwesome;
    position: absolute;
    font-size: 0.7rem;
    right: 5px; }
  .book-styles[data-bid-auto-confirm="true"] [data-column-id="bidId"]:after {
    content: '\f0e7';
    font-family: fontAwesome;
    position: absolute;
    font-size: 0.7rem;
    right: 5px;
    color: #1d1d1d; }
  .book-styles[data-ask-auto-confirm="true"] [data-column-id="askId"]:after {
    content: '\f0e7';
    font-family: fontAwesome;
    position: absolute;
    font-size: 0.7rem;
    right: 5px;
    color: #1d1d1d; }
  .book-styles--down-triangle, .book-styles--up-triangle, .book-styles[data-last-price-color="up"] [data-column-id="lastPrice"]:after, .list-book-styles[data-last-price-color="up"] [data-column-id="lastPrice"]:after, .book-styles[data-last-price-color="down"] [data-column-id="lastPrice"]:after, .list-book-styles .book-styles--up-triangle, .list-book-styles[data-last-price-color="down"] [data-column-id="lastPrice"]:after {
    top: 12px;
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor; }
  .book-styles--up-triangle, .book-styles[data-last-price-color="up"] [data-column-id="lastPrice"]:after, .list-book-styles[data-last-price-color="up"] [data-column-id="lastPrice"]:after {
    border-top: 0;
    border-bottom: 5px solid currentColor; }
  .book-styles--price-up-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-price-up-flash; }
  .book-styles--price-down-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-price-down-flash; }
  .book-styles--total-volume-up-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-price-up-flash; }
  .book-styles--total-volume-down-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-price-down-flash; }
  .book-styles--neutral-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-neutral-flash; }

@keyframes book-styles-price-up-flash {
  50% {
    background: #72af00;
    color: white; } }

@keyframes book-styles-price-down-flash {
  50% {
    background: #f30643;
    color: white; } }

@keyframes book-styles-neutral-flash {
  50% {
    background: #c0d730;
    color: white; } }
  .book-styles[data-last-price-color="up"] [data-column-id="lastPrice"] {
    color: #72af00; }
    .book-styles[data-last-price-color="up"] [data-column-id="lastPrice"]:after {
      right: 5px; }
  .book-styles[data-last-price-color="down"] [data-column-id="lastPrice"] {
    color: #f30643; }
    .book-styles[data-last-price-color="down"] [data-column-id="lastPrice"]:after {
      right: 5px; }
  .book-styles[data-bid-order-is-bait="true"] [data-column-id^="bid"] {
    color: #66ade0; }
  .book-styles[data-ask-order-is-bait="true"] [data-column-id^="ask"] {
    color: #66ade0; }

sgx-table-row.suspend-indicator--bid .sgx-table-cell[data-column-id="bidId"],
sgx-table-row.suspend-indicator--bid .sgx-table-cell[data-column-id="bidQuantity"],
sgx-table-row.suspend-indicator--bid .sgx-table-cell[data-column-id="bidPrice"] {
  color: #6f7072;
  background-color: #e7e7e7;
  border-top: solid 1px #d7d7d8;
  border-bottom: solid 1px #d7d7d8; }

sgx-table-row.suspend-indicator--ask .sgx-table-cell[data-column-id="askId"],
sgx-table-row.suspend-indicator--ask .sgx-table-cell[data-column-id="askQuantity"],
sgx-table-row.suspend-indicator--ask .sgx-table-cell[data-column-id="askPrice"] {
  color: #6f7072;
  background-color: #e7e7e7;
  border-top: solid 1px #d7d7d8;
  border-bottom: solid 1px #d7d7d8; }

sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="askId"],
sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="askQuantity"],
sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="askPrice"],
sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="bidId"],
sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="bidQuantity"],
sgx-table-row.suspend-indicator--all .sgx-table-cell[data-column-id="bidPrice"] {
  color: #6f7072;
  background-color: #e7e7e7;
  border-top: solid 1px #d7d7d8;
  border-bottom: solid 1px #d7d7d8; }

sgx-order-book-filter-select {
  position: relative;
  display: flex;
  font-size: 0.85rem; }
  sgx-order-book-filter-select .sgx-input-checkbox-label {
    margin-bottom: 0px !important; }

#order-book-filter-precheck-toggle,
#order-book-filter-auto-conf-toggle {
  margin-right: 15px; }

#order-book-filter-ID-select-container {
  cursor: pointer;
  margin: auto 0 auto 0;
  height: 22px;
  width: 40px;
  border-radius: 11px;
  color: #222222;
  display: inline-flex;
  border: 1px solid black;
  align-items: center;
  justify-content: space-between;
  padding: 0 6px;
  position: relative;
  z-index: 1000;
  transition: border-color 0.4s ease; }
  #order-book-filter-ID-select-container:hover, #order-book-filter-ID-select-container:focus {
    border-color: #fff; }

.order-book-filter-select-number {
  margin: auto 0 auto 0;
  font-size: 12px; }

.order-book-filter-select-popup {
  top: calc(100%);
  right: 0;
  display: none;
  width: 290px;
  position: absolute;
  background: #f5f5f5;
  border-radius: 5px;
  color: #222222;
  padding: 0.5rem;
  z-index: 110;
  line-height: 1.2rem;
  box-shadow: 1px 0px 5px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #ffffff; }
  .order-book-filter-select-popup footer {
    text-align: right; }
    .order-book-filter-select-popup footer button {
      font-size: 0.8rem; }
  .order-book-filter-select-popup sgx-input-select {
    width: 100%; }
  .order-book-filter-select-popup .button-clear {
    margin-right: 0.5rem; }
  .order-book-filter-select-popup .sgx-input-select-filter-wrapper {
    margin-bottom: 0.5rem; }

.order-book-filter-select--isFiltering svg {
  opacity: 0.3;
  cursor: default; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #order-book-filter-ID-select-container {
    padding-left: 0.3rem; }
  .order-book-filter-select-number {
    margin-left: 0.2rem;
    margin-top: 0.3rem; } }

sgx-order-book-grid-view {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex: auto;
  margin: 0 2px 0 0;
  flex-direction: column; }
  sgx-order-book-grid-view:last-child {
    margin: 0;
    border-right: 0; }
  sgx-order-book-grid-view svg {
    pointer-events: none; }
  sgx-order-book-grid-view .sgx-order-book-grid-view-topbar {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 0 0 auto;
    height: 30px; }
    sgx-order-book-grid-view .sgx-order-book-grid-view-topbar .sgx-order-book-grid-view-topbar-controls {
      text-align: right;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end; }
      sgx-order-book-grid-view .sgx-order-book-grid-view-topbar .sgx-order-book-grid-view-topbar-controls .sgx-tool-settings {
        width: 70px;
        text-align: left;
        display: inline-block;
        padding-left: 4px;
        font: normal 13px/1.1 Lato,HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif; }
    sgx-order-book-grid-view .sgx-order-book-grid-view-topbar .sgx-order-book-grid-view-topbar-controls-session-timer {
      font-size: 0.8rem;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.4); }
      sgx-order-book-grid-view .sgx-order-book-grid-view-topbar .sgx-order-book-grid-view-topbar-controls-session-timer span {
        color: #e60000; }
    sgx-order-book-grid-view .sgx-order-book-grid-view-topbar .sgx-order-book-grid-view-product-meta {
      display: flex;
      width: 100%;
      align-items: center;
      flex-basis: 60rem; }
  sgx-order-book-grid-view[data-browser='ie'] .sgx-order-book-grid-view-topbar-controls sgx-order-book-filter-select sgx-input-toggle .sgx-input-toggle-centrebox {
    flex: 0 0 auto; }
  sgx-order-book-grid-view[data-browser='ie'] .sgx-order-book-grid-view-topbar-controls sgx-order-book-filter-select sgx-input-toggle .sgx-input-toggle-sidebox {
    flex: 0 0 auto; }
  sgx-order-book-grid-view .sgx-order-book-grid-view-topbar-product-name-content {
    max-width: 206px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle; }
  sgx-order-book-grid-view .sgx-order-book-grid-view-topbar-product-name {
    color: #222222;
    font-size: 0.8rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    white-space: nowrap; }
    sgx-order-book-grid-view .sgx-order-book-grid-view-topbar-product-name .sgx-flag {
      display: inlnie-block;
      text-align: center;
      padding: 2px;
      margin-left: 4px;
      width: 17px;
      height: 15px;
      background-color: #0077CC;
      font-size: 12px;
      color: #FFFFFF; }
      sgx-order-book-grid-view .sgx-order-book-grid-view-topbar-product-name .sgx-flag:first-child {
        margin-left: 8px; }
  sgx-order-book-grid-view .sgx-widget-order-book-grid-view-container {
    display: flex; }
  sgx-order-book-grid-view sgx-table {
    flex: 1 1 auto; }
    sgx-order-book-grid-view sgx-table [data-column-id="contract"] {
      display: none; }
    sgx-order-book-grid-view sgx-table .sgx-table-header-wrapper[data-id="contract"] {
      display: none; }
    sgx-order-book-grid-view sgx-table .table-container {
      border-bottom: 0; }
    sgx-order-book-grid-view sgx-table:first-child .table-container {
      border-left: 0; }
    sgx-order-book-grid-view sgx-table:last-child .table-container {
      border-right: 0; }
    sgx-order-book-grid-view sgx-table sgx-table-toolbar {
      display: none; }
    sgx-order-book-grid-view sgx-table [data-locked="true"] {
      font-style: italic; }
  sgx-order-book-grid-view .sgx-table-cell-inline-edit {
    padding-left: 0px;
    padding-right: 0px !important; }
    sgx-order-book-grid-view .sgx-table-cell-inline-edit sgx-input-number {
      width: 100%;
      height: 32px; }
      sgx-order-book-grid-view .sgx-table-cell-inline-edit sgx-input-number.sgx-input--has-error .sgx-input-control {
        outline: 1px solid red;
        border: 0px; }
    sgx-order-book-grid-view .sgx-table-cell-inline-edit .sgx-input-control {
      height: 32px;
      font-size: 13px;
      padding-left: 5%;
      padding-right: 20%;
      padding-bottom: 4px; }
    sgx-order-book-grid-view .sgx-table-cell-inline-edit .sgx-input-number-up {
      width: 10%;
      right: 3px; }
    sgx-order-book-grid-view .sgx-table-cell-inline-edit .sgx-input-number-down {
      width: 10%;
      bottom: 5px;
      right: 3px; }

sgx-kebab-menu-list {
  color: #222222; }
  sgx-kebab-menu-list .sgx-kebab-menu-container {
    min-width: 180px; }
  sgx-kebab-menu-list ul {
    list-style: none;
    padding: 0.5rem 0;
    margin: 0; }
  sgx-kebab-menu-list .sgx-kebab-menu-list-item {
    padding: 0.1rem 0;
    cursor: pointer; }
    sgx-kebab-menu-list .sgx-kebab-menu-list-item:hover {
      background-color: #eef2fd;
      color: #222222; }
    sgx-kebab-menu-list .sgx-kebab-menu-list-item > div {
      padding-left: 1rem;
      font-size: 14px; }
      sgx-kebab-menu-list .sgx-kebab-menu-list-item > div:before {
        font-size: 0.8rem;
        margin: 0.5rem; }

sgx-kebab-menu {
  cursor: pointer;
  color: #222222; }
  sgx-kebab-menu.sgx-kebab-menu-container {
    padding: 0 0.5rem;
    color: #222; }
  sgx-kebab-menu.sgx-kebab-menu-container--show {
    opacity: 1; }

#sgx-kebab-menu-dialog .sgx-dialog-box {
  border-radius: 5px; }

#sgx-kebab-menu-dialog.is-visible {
  animation: fadeIn 0.5s; }

#sgx-kebab-menu-dialog .sgx-kebab-menu-triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.1);
  content: "";
  margin-bottom: 10px;
  top: -9px;
  right: 14px; }
  #sgx-kebab-menu-dialog .sgx-kebab-menu-triangle > div:before {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 12px solid #ccc;
    content: "";
    margin-bottom: 10px;
    top: -2px;
    right: -8px;
    z-index: -1; }

.sgx-header-lang {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 48px;
  font-size: 16px;
  cursor: pointer; }

sgx-readonly-text {
  display: block; }
  sgx-readonly-text .sgx-readonly-text-title {
    color: #6f7072;
    font-size: 14px; }
  sgx-readonly-text .sgx-readonly-text-content {
    margin-top: 4px;
    font-size: 16px;
    color: #1d1d1d;
    text-transform: capitalize; }

#sgx-change-password-dialog .sgx-dialog-body {
  padding: 20px; }

sgx-alert-bar[data-status=neutral] .sgx-alert-bar-accent {
  background-color: rgba(0, 0, 0, 0.6); }

sgx-alert-bar[data-status=neutral] .sgx-alert-bar-icon::before {
  icon-font: url(sgx-base-code/svgs/regular/info-circle.svg);
  color: rgba(0, 0, 0, 0.6); }

sgx-alert-bar[data-status=informational] .sgx-alert-bar-accent {
  background-color: #0077CC; }

sgx-alert-bar[data-status=informational] .sgx-alert-bar-icon::before {
  icon-font: url(sgx-base-code/svgs/solid/info-circle.svg);
  color: #0077CC; }

sgx-alert-bar[data-status=success] .sgx-alert-bar-accent {
  background-color: #74AC00; }

sgx-alert-bar[data-status=success] .sgx-alert-bar-icon::before {
  icon-font: url(sgx-base-code/svgs/solid/check-circle.svg);
  color: #74AC00; }

sgx-alert-bar[data-status=warning] .sgx-alert-bar-accent {
  background-color: #FFCC00; }

sgx-alert-bar[data-status=warning] .sgx-alert-bar-icon::before {
  icon-font: url(sgx-base-code/svgs/solid/exclamation-circle.svg);
  color: #FFCC00; }

sgx-alert-bar[data-status=alert] .sgx-alert-bar-accent {
  background-color: #e60000; }

sgx-alert-bar[data-status=alert] .sgx-alert-bar-icon::before {
  icon-font: url(sgx-base-code/svgs/solid/exclamation-circle.svg);
  color: #e60000; }

sgx-alert-bar[data-status=error] .sgx-alert-bar-accent {
  background-color: #e60000; }

sgx-alert-bar[data-status=error] .sgx-alert-bar-icon::before {
  icon-font: url(sgx-base-code/svgs/solid/times-circle.svg);
  color: #e60000; }

sgx-alert-bar {
  display: flex; }

sgx-alert-bar.sgx-alert-bar--hide {
  display: none; }

sgx-alert-bar[data-background=true] {
  background-color: rgba(0, 0, 0, 0.09); }

sgx-alert-bar[data-background=true] > .sgx-alert-bar-accent {
  width: 4px;
  align-self: stretch;
  flex-shrink: 0; }

sgx-alert-bar .sgx-alert-bar-icon {
  margin: 8px;
  line-height: 1.2; }

sgx-alert-bar p.sgx-alert-bar-text {
  padding: unset;
  color: rgba(0, 0, 0, 0.87);
  margin: 8px 8px 8px 0;
  flex-grow: 1; }

sgx-alert-bar:not([data-static])[data-position=top] {
  top: 0px;
  left: 0px;
  z-index: 2000;
  position: absolute;
  width: 100%; }

sgx-alert-bar:not([data-static])[data-position=bottom] {
  bottom: 0px;
  left: 0px;
  z-index: 2000;
  position: absolute;
  width: 100%; }

sgx-add-remove-select {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between; }
  sgx-add-remove-select sgx-input-select {
    width: 400px; }

.sgx-add-remove-select-add-button {
  margin-bottom: 12px;
  line-height: 36px; }

.sgx-add-remove-select-remove-button {
  line-height: 36px; }

.sgx-add-remove-select-controls {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 120px;
  margin-left: 12px;
  margin-right: 12px; }

sgx-dialog-body {
  display: block;
  padding: 1rem; }
  sgx-dialog-body .sgx-dialog-body-title {
    color: #1d1d1d;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 50px; }
  sgx-dialog-body .sgx-dialog-body-content {
    display: block;
    padding: 60px;
    padding-top: 50px;
    font-size: 24px;
    line-height: 32px;
    color: #6f7072; }
  sgx-dialog-body .sgx-dialog-body-content-actions {
    margin-top: 45px; }
    sgx-dialog-body .sgx-dialog-body-content-actions button:first-child {
      height: 44px;
      width: 220px; }
    sgx-dialog-body .sgx-dialog-body-content-actions button {
      font-weight: 600;
      font-size: 18px; }

sgx-prices-card {
  display: block;
  color: #1d1d1d;
  /*value and caret*/
  /* change caret/change color based on price change */
  /* change font/caret size based on size level e.g. sm, md, lg */ }
  sgx-prices-card .sgx-price-card-value {
    font-size: 1em;
    line-height: 1.4em;
    font-weight: 400; }
  sgx-prices-card .sgx-price-card-value:before,
  sgx-prices-card .sgx-price-card-value:after {
    font-size: 1.3em;
    line-height: 1.4em;
    font-family: fontAwesome;
    vertical-align: middle; }
  sgx-prices-card .sgx-price-card-value:before {
    padding-right: .3em; }
  sgx-prices-card .sgx-price-card-value:after {
    padding-left: .3em; }
  sgx-prices-card .sgx-price-card-change {
    font-size: .7em;
    line-height: 1.4em; }
  sgx-prices-card.sgx-price-card--up.sgx-price-card--before .sgx-price-card-value:before,
  sgx-prices-card.sgx-price-card--up.sgx-price-card--after .sgx-price-card-value:after {
    content: '\f0d8'; }
  sgx-prices-card.sgx-price-card--up .sgx-price-card-value:before,
  sgx-prices-card.sgx-price-card--up .sgx-price-card-value:after,
  sgx-prices-card.sgx-price-card--up .sgx-price-card-change {
    color: #74ad00; }
  sgx-prices-card.sgx-price-card--down.sgx-price-card--before .sgx-price-card-value:before,
  sgx-prices-card.sgx-price-card--down.sgx-price-card--after .sgx-price-card-value:after {
    content: '\f0d7'; }
  sgx-prices-card.sgx-price-card--down .sgx-price-card-value:before,
  sgx-prices-card.sgx-price-card--down .sgx-price-card-value:after,
  sgx-prices-card.sgx-price-card--down .sgx-price-card-change {
    color: #bf0052; }
  sgx-prices-card.sgx-price-card--md {
    font-size: 24px; }

sgx-table-cell-checkbox sgx-input-checkbox {
  margin-top: 10px; }
  sgx-table-cell-checkbox sgx-input-checkbox .sgx-input-checkbox-label {
    font-size: 0.8rem; }

sgx-table-cell-checkbox.sgx-table-cell {
  text-overflow: unset; }

sgx-list-adaptive-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #222222;
  white-space: nowrap;
  border-bottom: solid 1px rgba(0, 0, 0, 0.09);
  border-left: solid 1px rgba(0, 0, 0, 0.09);
  padding-top: 8px;
  padding-bottom: 8px; }
  sgx-list-adaptive-row .sgx-list-cell {
    display: inline-block;
    position: relative;
    height: 100%;
    padding: 0 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    text-align: right;
    font-size: 12px; }
    sgx-list-adaptive-row .sgx-list-cell[data-column-id='totalVolume'], sgx-list-adaptive-row .sgx-list-cell[data-column-id='lastPrice'] {
      padding-right: 16px;
      padding-left: 0px; }
  sgx-list-adaptive-row .sgx-list-composite-main-detail-cell,
  sgx-list-adaptive-row .sgx-list-composite-detail-cell {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6); }
  sgx-list-adaptive-row .sgx-list-composite-content-cell {
    font-size: 14px; }
  sgx-list-adaptive-row .sgx-list-composite-cell[detail-mode='false'] {
    grid-template-columns: 0 0 100%;
    grid-template-rows: 1fr; }
    sgx-list-adaptive-row .sgx-list-composite-cell[detail-mode='false'] .sgx-list-composite-content-cell {
      grid-row: 1/1;
      grid-column: 3/4; }
    sgx-list-adaptive-row .sgx-list-composite-cell[detail-mode='false'] .sgx-list-composite-main-detail-cell {
      grid-row: 1/1;
      grid-column: 2/3;
      visibility: hidden; }
    sgx-list-adaptive-row .sgx-list-composite-cell[detail-mode='false'] .sgx-list-composite-detail-cell {
      grid-row: 1/1;
      grid-column: 1/2;
      visibility: hidden; }
    sgx-list-adaptive-row .sgx-list-composite-cell[detail-mode='false'][cell-align='right'] {
      text-align: right; }
    sgx-list-adaptive-row .sgx-list-composite-cell[detail-mode='false'][cell-align='left'] {
      text-align: left; }
  sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell {
    flex: 1 1 50%; }
  sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] {
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 1fr 1fr;
    text-align: right;
    line-height: normal; }
    sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-content-cell {
      grid-row: 1/2;
      grid-column: 1/3; }
    sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-main-detail-cell {
      grid-row: 2/3;
      grid-column: 2/3;
      position: relative; }
      sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-main-detail-cell span {
        position: absolute;
        right: 0px;
        bottom: 0px; }
    sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-detail-cell {
      grid-row: 2/3;
      grid-column: 1/2;
      position: relative; }
      sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-detail-cell span {
        position: absolute;
        bottom: 0px;
        right: 0px; }
  sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] {
    grid-template-columns: 2fr 3fr;
    grid-template-rows: 1fr 1fr;
    text-align: left;
    line-height: normal; }
    sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-content-cell {
      grid-row: 1/2;
      grid-column: 1/3; }
    sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-main-detail-cell {
      grid-row: 2/3;
      grid-column: 1/2;
      position: relative; }
      sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-main-detail-cell span {
        position: absolute;
        left: 0px;
        bottom: 0px; }
    sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-detail-cell {
      grid-row: 2/3;
      grid-column: 2/3;
      position: relative; }
      sgx-list-adaptive-row[adaptive-mode='mobile'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-detail-cell span {
        position: absolute;
        bottom: 0px;
        left: 0px; }
  sgx-list-adaptive-row[adaptive-mode='mobile-landscape'] .sgx-list-cell, sgx-list-adaptive-row[adaptive-mode='complete'] .sgx-list-cell {
    flex: 1 1 15%; }
  sgx-list-adaptive-row[adaptive-mode='mobile-landscape'] .sgx-list-composite-cell, sgx-list-adaptive-row[adaptive-mode='complete'] .sgx-list-composite-cell {
    flex: 1 1 35%; }
  sgx-list-adaptive-row[adaptive-mode='mobile-landscape'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'], sgx-list-adaptive-row[adaptive-mode='complete'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] {
    grid-template-columns: 4fr 8fr 1fr 4fr 1fr 6fr;
    grid-template-rows: 1fr;
    text-align: right; }
    sgx-list-adaptive-row[adaptive-mode='mobile-landscape'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-content-cell, sgx-list-adaptive-row[adaptive-mode='complete'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-content-cell {
      grid-row: 1/1;
      grid-column: 6/7; }
    sgx-list-adaptive-row[adaptive-mode='mobile-landscape'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-main-detail-cell, sgx-list-adaptive-row[adaptive-mode='complete'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-main-detail-cell {
      grid-row: 1/1;
      grid-column: 4/5; }
    sgx-list-adaptive-row[adaptive-mode='mobile-landscape'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-detail-cell, sgx-list-adaptive-row[adaptive-mode='complete'] .sgx-list-composite-cell[detail-mode='true'][cell-align='right'] .sgx-list-composite-detail-cell {
      grid-row: 1/1;
      grid-column: 2/3; }
  sgx-list-adaptive-row[adaptive-mode='mobile-landscape'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'], sgx-list-adaptive-row[adaptive-mode='complete'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] {
    grid-template-columns: 6fr 1fr 4fr 1fr 8fr 4fr;
    grid-template-rows: 1fr;
    text-align: left; }
    sgx-list-adaptive-row[adaptive-mode='mobile-landscape'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-content-cell, sgx-list-adaptive-row[adaptive-mode='complete'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-content-cell {
      grid-row: 1/1;
      grid-column: 1/2; }
    sgx-list-adaptive-row[adaptive-mode='mobile-landscape'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-main-detail-cell, sgx-list-adaptive-row[adaptive-mode='complete'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-main-detail-cell {
      grid-row: 1/1;
      grid-column: 3/4; }
    sgx-list-adaptive-row[adaptive-mode='mobile-landscape'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-detail-cell, sgx-list-adaptive-row[adaptive-mode='complete'] .sgx-list-composite-cell[detail-mode='true'][cell-align='left'] .sgx-list-composite-detail-cell {
      grid-row: 1/1;
      grid-column: 5/6; }

sgx-list-adaptive-header {
  display: flex;
  position: relative;
  background: #efefef;
  color: #222222;
  min-height: 2rem;
  max-height: 2rem;
  line-height: 2rem;
  border-top: solid 1px transparent;
  border-left: solid 1px rgba(0, 0, 0, 0.09);
  border-top: solid 1px rgba(0, 0, 0, 0.09);
  border-bottom: solid 1px rgba(0, 0, 0, 0.09);
  white-space: nowrap;
  cursor: pointer;
  z-index: 2; }
  sgx-list-adaptive-header[data-adaptive-mode='mobile'][data-detail-mode='true'] {
    min-height: calc(2 * 2rem - 14px);
    max-height: calc(2 * 2rem - 14px); }
  sgx-list-adaptive-header .sgx-list-adaptive-header-cell:nth-of-type(1) {
    border-left: 0px; }
  sgx-list-adaptive-header .sgx-list-adaptive-header-cell {
    border-left: solid 1px rgba(0, 0, 0, 0.09);
    flex: 1 1 auto;
    font-size: 14px;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    text-align: right; }
    sgx-list-adaptive-header .sgx-list-adaptive-header-cell[data-column-id='totalVolume'], sgx-list-adaptive-header .sgx-list-adaptive-header-cell[data-column-id='lastPrice'] {
      padding-right: 16px;
      padding-left: 0px; }
  sgx-list-adaptive-header .sgx-list-adaptive-header-main-detail-cell,
  sgx-list-adaptive-header .sgx-list-adaptive-header-detail-cell,
  sgx-list-adaptive-header .sgx-list-adaptive-header-seperator-cell {
    font-size: 12px; }
  sgx-list-adaptive-header .sgx-list-adaptive-header-composite-cell {
    padding-right: 0px;
    display: grid; }
    sgx-list-adaptive-header .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] {
      text-align: right; }
    sgx-list-adaptive-header .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] {
      text-align: left; }
  sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell {
    flex: 1 1 50%; }
  sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] {
    grid-template-columns: 2fr 4fr 4px 50px 8px;
    grid-template-rows: 1fr 1fr;
    line-height: normal;
    padding: calc(0.5rem - 2px) 0px;
    margin-right: 0px; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-content-cell {
      grid-row: 1/2;
      grid-column: 1/5; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-main-detail-cell {
      grid-row: 2/3;
      grid-column: 4/5;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      position: relative; }
      sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-main-detail-cell header {
        position: absolute;
        right: 0px; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-seperator-cell {
      grid-row: 2/3;
      grid-column: 3/4;
      text-align: right;
      color: rgba(0, 0, 0, 0.6);
      padding-right: 4px; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-detail-cell {
      grid-row: 2/3;
      grid-column: 2/3;
      color: rgba(0, 0, 0, 0.6);
      padding-right: 4px; }
  sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] {
    grid-template-columns: 8px 50px 4px 4fr 2fr;
    grid-template-rows: 1fr 1fr;
    line-height: normal;
    padding: calc(0.5rem - 2px) 0px; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-content-cell {
      grid-row: 1/2;
      grid-column: 2/6; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-main-detail-cell {
      grid-row: 2/3;
      grid-column: 2/3;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      position: relative; }
      sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-main-detail-cell header {
        position: absolute;
        left: 0px; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-seperator-cell {
      grid-row: 2/3;
      grid-column: 3/4;
      text-align: left;
      color: rgba(0, 0, 0, 0.6);
      margin-left: 2px; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-detail-cell {
      grid-row: 2/3;
      grid-column: 4/5;
      color: rgba(0, 0, 0, 0.6);
      padding-left: 4px; }
  sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-cell, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-cell {
    flex: 1 1 15%; }
  sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell {
    padding-left: 0px;
    padding-right: 0px;
    flex: 1 1 35%; }
  sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'], sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] {
    grid-template-columns: 4fr 2fr 10px 3fr 2fr 8px;
    grid-template-rows: 1fr; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-content-cell, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-content-cell {
      grid-row: 1/1;
      grid-column: 5/6; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-main-detail-cell, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-main-detail-cell {
      grid-row: 1/1;
      grid-column: 4/5;
      color: rgba(0, 0, 0, 0.6);
      display: flex; }
      sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-main-detail-cell:after, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-main-detail-cell:after {
        content: '|';
        width: 10px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6); }
    sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-seperator-cell, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-seperator-cell {
      grid-row: 1/1;
      grid-column: 3/4;
      text-align: center;
      color: rgba(0, 0, 0, 0.6); }
    sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-detail-cell, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] .sgx-list-adaptive-header-detail-cell {
      grid-row: 1/1;
      grid-column: 2/3;
      color: rgba(0, 0, 0, 0.6); }
  sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'], sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] {
    grid-template-columns: 8px 2fr 3fr 10px 2fr 4fr;
    grid-template-rows: 1fr; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-content-cell, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-content-cell {
      grid-row: 1/1;
      grid-column: 2/3; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-main-detail-cell, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-main-detail-cell {
      grid-row: 1/1;
      grid-column: 3/4;
      color: rgba(0, 0, 0, 0.6);
      display: flex; }
      sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-main-detail-cell:before, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-main-detail-cell:before {
        content: '|';
        width: 10px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6); }
    sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-seperator-cell, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-seperator-cell {
      grid-row: 1/1;
      grid-column: 4/5;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      padding-left: 4px; }
    sgx-list-adaptive-header[data-adaptive-mode='mobile-landscape'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-detail-cell, sgx-list-adaptive-header[data-adaptive-mode='complete'] .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] .sgx-list-adaptive-header-detail-cell {
      grid-row: 1/1;
      grid-column: 5/6;
      color: rgba(0, 0, 0, 0.6);
      padding-right: 4px; }
  sgx-list-adaptive-header[data-detail-mode='false'] .sgx-list-adaptive-header-main-detail-cell {
    visibility: hidden; }
  sgx-list-adaptive-header[data-detail-mode='false'] .sgx-list-adaptive-header-seperator-cell {
    visibility: hidden; }
  sgx-list-adaptive-header[data-detail-mode='false'] .sgx-list-adaptive-header-detail-cell {
    visibility: hidden; }

.precheck-icon, .list-book-styles[data-bid-order-type="1"][adaptive-mode="mobile"] [data-column-id="bidPrice"][detail-mode='true']:after, .list-book-styles[data-bid-order-type="1"][adaptive-mode="mobile"] [data-column-id="bidPrice"][detail-mode='false']:after, .list-book-styles[data-bid-order-type="1"][adaptive-mode='mobile-landscape'] [data-column-id="bidPrice"]:after, .list-book-styles[data-bid-order-type="1"][adaptive-mode='complete'] [data-column-id="bidPrice"]:after, .list-book-styles[data-ask-order-type="1"][adaptive-mode="mobile"] [data-column-id="askPrice"][detail-mode='true']:after, .list-book-styles[data-ask-order-type="1"] [data-column-id="askPrice"][detail-mode='false']:after, .list-book-styles[data-ask-order-type="1"][adaptive-mode='mobile-landscape'] [data-column-id="askPrice"]:after, .list-book-styles[data-ask-order-type="1"][adaptive-mode='complete'] [data-column-id="askPrice"]:after {
  content: '\f00c';
  font-family: fontAwesome;
  position: absolute;
  color: #74AC00;
  font-size: 0.7rem;
  line-height: normal; }

.lock-icon, .lock-icon-after:after, .list-book-styles[data-ask-fill-type="0"][adaptive-mode="mobile"] [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span:after, .list-book-styles[data-ask-fill-type="0"][adaptive-mode='mobile-landscape'] [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span:after, .list-book-styles[data-ask-fill-type="0"][adaptive-mode='complete'] [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span:after, .lock-icon-before:before, .list-book-styles[data-bid-fill-type="0"][adaptive-mode="mobile"] [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span:before, .list-book-styles[data-bid-fill-type="0"][adaptive-mode='mobile-landscape'] [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span:before, .list-book-styles[data-bid-fill-type="0"][adaptive-mode='complete'] [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span:before {
  content: '\f023';
  font-family: fontAwesome;
  position: absolute;
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.4);
  line-height: normal; }

.auto-confirm-icon, .auto-confirm-icon-after:after, .list-book-styles[data-bid-auto-confirm="true"] [data-column-id="bidId"] span:after, .auto-confirm-icon-before:before, .list-book-styles[data-ask-auto-confirm="true"] [data-column-id="askId"] span:before {
  content: '\f0e7';
  font-family: fontAwesome;
  position: absolute;
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.4);
  line-height: normal; }

.lock-icon-after, .list-book-styles[data-ask-fill-type="0"][adaptive-mode="mobile"] [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span, .list-book-styles[data-ask-fill-type="0"][adaptive-mode='mobile-landscape'] [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span, .list-book-styles[data-ask-fill-type="0"][adaptive-mode='complete'] [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span {
  margin-right: 4px; }
  .lock-icon-after:after, .list-book-styles[data-ask-fill-type="0"][adaptive-mode="mobile"] [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span:after, .list-book-styles[data-ask-fill-type="0"][adaptive-mode='mobile-landscape'] [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span:after, .list-book-styles[data-ask-fill-type="0"][adaptive-mode='complete'] [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span:after {
    margin-left: 4px;
    position: relative; }

.lock-icon-before, .list-book-styles[data-bid-fill-type="0"][adaptive-mode="mobile"] [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span, .list-book-styles[data-bid-fill-type="0"][adaptive-mode='mobile-landscape'] [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span, .list-book-styles[data-bid-fill-type="0"][adaptive-mode='complete'] [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span {
  margin-left: 4px; }
  .lock-icon-before:before, .list-book-styles[data-bid-fill-type="0"][adaptive-mode="mobile"] [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span:before, .list-book-styles[data-bid-fill-type="0"][adaptive-mode='mobile-landscape'] [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span:before, .list-book-styles[data-bid-fill-type="0"][adaptive-mode='complete'] [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span:before {
    margin-right: 4px;
    position: relative; }

.auto-confirm-icon-after, .list-book-styles[data-bid-auto-confirm="true"] [data-column-id="bidId"] span {
  margin-right: 4px; }
  .auto-confirm-icon-after:after, .list-book-styles[data-bid-auto-confirm="true"] [data-column-id="bidId"] span:after {
    margin-left: 4px;
    position: relative; }

.auto-confirm-icon-before, .list-book-styles[data-ask-auto-confirm="true"] [data-column-id="askId"] span {
  margin-right: 4px; }
  .auto-confirm-icon-before:before, .list-book-styles[data-ask-auto-confirm="true"] [data-column-id="askId"] span:before {
    margin-right: 4px;
    position: relative; }

.list-book-styles[data-bid-owner="true"] .order-book-mobile-bid-editable-highlight {
  position: absolute;
  width: 50%;
  top: 0;
  left: 0;
  height: 100%;
  content: "";
  background: #e7e7e7;
  border-radius: 30px 0 0 30px;
  z-index: -1; }

.list-book-styles[adaptive-mode='mobile-landscape'][data-bid-owner="true"] .order-book-mobile-bid-editable-highlight, .list-book-styles[adaptive-mode='complete'][data-bid-owner="true"] .order-book-mobile-bid-editable-highlight {
  width: calc((100% - 15% * 2) / 2); }

.list-book-styles[data-bid-order-type="1"][adaptive-mode="mobile"] [data-column-id="bidPrice"][detail-mode='true']:after {
  left: 8px;
  top: 0px; }

.list-book-styles[data-bid-order-type="1"][adaptive-mode="mobile"] [data-column-id="bidPrice"][detail-mode='false']:after {
  left: 8px;
  top: 10px; }

.list-book-styles[data-bid-order-type="1"][adaptive-mode='mobile-landscape'] [data-column-id="bidPrice"]:after, .list-book-styles[data-bid-order-type="1"][adaptive-mode='complete'] [data-column-id="bidPrice"]:after {
  left: 8px;
  top: 10px; }

.list-book-styles[data-ask-owner="true"] .order-book-mobile-ask-editable-highlight {
  position: absolute;
  width: 50%;
  top: 0;
  left: 50%;
  height: 100%;
  content: "";
  background: #e7e7e7;
  border-radius: 0 30px 30px 0;
  z-index: -1; }

.list-book-styles[adaptive-mode='mobile-landscape'][data-ask-owner="true"] .order-book-mobile-ask-editable-highlight, .list-book-styles[adaptive-mode='complete'][data-ask-owner="true"] .order-book-mobile-ask-editable-highlight {
  width: calc((100% - 15% * 2) / 2);
  left: calc((100% - 15% * 2) / 2); }

.list-book-styles[data-ask-order-type="1"][adaptive-mode="mobile"] [data-column-id="askPrice"][detail-mode='true']:after {
  right: 8px;
  top: 0px; }

.list-book-styles[data-ask-order-type="1"] [data-column-id="askPrice"][detail-mode='false']:after {
  right: 8px;
  top: 10px; }

.list-book-styles[data-ask-order-type="1"][adaptive-mode='mobile-landscape'] [data-column-id="askPrice"]:after, .list-book-styles[data-ask-order-type="1"][adaptive-mode='complete'] [data-column-id="askPrice"]:after {
  right: 8px;
  top: 10px; }

.list-book-styles .book-styles--down-triangle, .list-book-styles[data-last-price-color="up"] [data-column-id="lastPrice"]:after, .list-book-styles .book-styles[data-last-price-color="down"] [data-column-id="lastPrice"]:after, .book-styles[data-last-price-color="down"] .list-book-styles [data-column-id="lastPrice"]:after, .list-book-styles .book-styles--up-triangle, .list-book-styles .book-styles[data-last-price-color="up"] [data-column-id="lastPrice"]:after, .book-styles[data-last-price-color="up"] .list-book-styles [data-column-id="lastPrice"]:after, .list-book-styles[data-last-price-color="down"] [data-column-id="lastPrice"]:after {
  top: 12px;
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor; }

.list-book-styles .book-styles--up-triangle, .list-book-styles .book-styles[data-last-price-color="up"] [data-column-id="lastPrice"]:after, .book-styles[data-last-price-color="up"] .list-book-styles [data-column-id="lastPrice"]:after, .list-book-styles[data-last-price-color="up"] [data-column-id="lastPrice"]:after {
  border-top: 0;
  border-bottom: 5px solid currentColor; }

.list-book-styles .book-styles--price-up-flash {
  animation-duration: 1.2s;
  animation-name: book-styles-price-up-flash; }

.list-book-styles .book-styles--price-down-flash {
  animation-duration: 1.2s;
  animation-name: book-styles-price-down-flash; }

.list-book-styles .book-styles--neutral-flash {
  animation-duration: 1.2s;
  animation-name: book-styles-neutral-flash; }

@keyframes book-styles-price-up-flash {
  50% {
    background: #72af00;
    color: white; } }

@keyframes book-styles-price-down-flash {
  50% {
    background: #f30643;
    color: white; } }

@keyframes book-styles-neutral-flash {
  50% {
    background: #c0d730;
    color: white; } }

.list-book-styles[data-last-price-color="up"] [data-column-id="lastPrice"] {
  color: #72af00; }
  .list-book-styles[data-last-price-color="up"] [data-column-id="lastPrice"]:after {
    right: 4px; }

.list-book-styles[data-last-price-color="down"] [data-column-id="lastPrice"] {
  color: #f30643; }
  .list-book-styles[data-last-price-color="down"] [data-column-id="lastPrice"]:after {
    right: 4px; }

.list-book-styles[data-bid-order-is-bait="true"] [data-column-id^="bid"] {
  color: #66ade0; }

.list-book-styles[data-ask-order-is-bait="true"] [data-column-id^="ask"] {
  color: #66ade0; }

sgx-list-simple-row {
  display: block; }
  sgx-list-simple-row:first-child .sgx-simple-row {
    border-top: solid 1px rgba(0, 0, 0, 0.09);
    margin-top: 20px; }
  sgx-list-simple-row .sgx-simple-row {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.09);
    height: 100%; }
  sgx-list-simple-row .sgx-simple-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.87); }
  sgx-list-simple-row .sgx-simple-id {
    margin-right: 12px; }

sgx-card-widget-wrapper {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 12px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); }
  @media (max-width: 599px) {
    sgx-card-widget-wrapper {
      padding: 12px;
      padding-top: 20px; } }
  sgx-card-widget-wrapper .sgx-card-widget-header {
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between; }
    sgx-card-widget-wrapper .sgx-card-widget-header .sgx-card-widget-kebab {
      cursor: pointer;
      padding: 0 4px;
      font-size: 20px; }
      sgx-card-widget-wrapper .sgx-card-widget-header .sgx-card-widget-kebab:after {
        icon-font: url("sgx-base-code/svgs/light/ellipsis-v.svg");
        color: rgba(0, 0, 0, 0.6); }
  sgx-card-widget-wrapper .sgx-card-widget-title {
    display: flex;
    width: 100%; }
    sgx-card-widget-wrapper .sgx-card-widget-title .sgx-card-widget-title-clickable-link {
      height: calc(27px + 2 * 4px); }
    sgx-card-widget-wrapper .sgx-card-widget-title img,
    sgx-card-widget-wrapper .sgx-card-widget-title .sgx-card-title-category {
      align-self: center; }
    sgx-card-widget-wrapper .sgx-card-widget-title img {
      margin: 4px 8px 4px 0;
      width: 54px;
      height: 27px; }
  sgx-card-widget-wrapper .sgx-card-empty-message {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center; }
  sgx-card-widget-wrapper sgx-status-indicator {
    z-index: 998; }

.sgx-context-menu-item .sgx-category-selection-icon:before {
  icon-font: url("sgx-base-code/svgs/light/edit.svg"); }

sgx-button-radio-select {
  display: flex;
  overflow: auto;
  height: auto; }

.sgx-radio-button-select-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: min-content;
  align-self: baseline;
  margin-bottom: 24px;
  width: 100%; }
  .sgx-radio-button-select-container .sgx-button {
    width: 160px;
    height: 46px;
    margin: 8px 0; }
    [data-type="mobile"] .sgx-radio-button-select-container .sgx-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      width: calc((100% -12px) / 2);
      margin-bottom: 16px; }
      [data-type="mobile"] .sgx-radio-button-select-container .sgx-button :nth-child(odd) {
        margin-right: 12px; }
  .sgx-radio-button-select-container .sgx-button--place-holder {
    opacity: 0;
    cursor: default; }

.sgx-mobile-market-view {
  position: fixed;
  top: 50px;
  width: 100%;
  height: 100%;
  z-index: 10000; }

.sgx-card.sgx-market-view-select-card {
  display: flex;
  flex-direction: column;
  padding-bottom: 46px; }

.sgx-market-view-select-dialog .sgx-dialog-body {
  padding: 0 12px; }

.sgx-market-view-select-dialog .sgx-dialog-box {
  display: flex;
  flex-direction: column;
  width: 764px; }

.sgx-dialog-header .sgx-market-view-select-header {
  margin-left: 12px;
  font-size: 24px; }

[data-type="mobile"] .sgx-market-view-select-header {
  font-weight: 600;
  color: #1d1d1d;
  font-size: 20px;
  margin-left: 0;
  margin-bottom: 12px; }

.sgx-market-view-select-subheader {
  font-weight: 600;
  color: #6f7072;
  margin-bottom: 32px;
  margin-top: 12px; }
  [data-type="mobile"] .sgx-market-view-select-subheader {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 0; }

.sgx-market-view-select-submit {
  float: right;
  margin-right: 0;
  margin-bottom: 12px;
  width: 150px;
  height: 40px; }
  [data-type="mobile"] .sgx-market-view-select-submit {
    width: 90px;
    float: none;
    align-self: flex-end;
    margin-top: 4px; }

.sgx-market-view-select-btn-container {
  display: flex;
  justify-content: flex-end; }

sgx-list-news-row {
  display: block; }
  sgx-list-news-row .sgx-news-row {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.09); }
  sgx-list-news-row .sgx-news-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  sgx-list-news-row .sgx-news-title a {
    color: rgba(0, 0, 0, 0.87); }
    sgx-list-news-row .sgx-news-title a:hover {
      text-decoration: underline; }
  sgx-list-news-row .sgx-news-flag-featured {
    display: inline-block;
    width: 60px;
    height: 14px;
    padding-left: 2px;
    position: relative;
    vertical-align: middle;
    line-height: 16px;
    background-color: #4da0db;
    color: rgba(255, 255, 255, 0.87); }
  sgx-list-news-row .sgx-news-others {
    padding-top: 4px;
    color: rgba(0, 0, 0, 0.6); }
    sgx-list-news-row .sgx-news-others span {
      display: inline-block;
      padding-left: 8px; }
      sgx-list-news-row .sgx-news-others span:first-child {
        padding-left: 0px; }
  sgx-list-news-row .sgx-news-time {
    padding-left: 8px; }

sgx-list-publications-row .sgx-publications-row {
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.09);
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1023px) {
    sgx-list-publications-row .sgx-publications-row {
      flex-direction: column; } }
  sgx-list-publications-row .sgx-publications-row .sgx-publications-title {
    display: flex;
    align-items: center;
    width: calc(100% - 100px);
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-width: 1023px) {
      sgx-list-publications-row .sgx-publications-row .sgx-publications-title {
        width: 100%; } }
    sgx-list-publications-row .sgx-publications-row .sgx-publications-title a {
      width: 100%;
      height: 1.5em;
      line-height: 1.5em;
      display: block;
      color: rgba(0, 0, 0, 0.87);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
      sgx-list-publications-row .sgx-publications-row .sgx-publications-title a:hover, sgx-list-publications-row .sgx-publications-row .sgx-publications-title a:active {
        text-decoration: underline; }
    sgx-list-publications-row .sgx-publications-row .sgx-publications-title .sgx-publications-flag-featured {
      width: 60px;
      height: 14px;
      padding-left: 2px;
      position: relative;
      vertical-align: middle;
      white-space: nowrap;
      display: inline-block;
      color: rgba(255, 255, 255, 0.87);
      background-color: #4da0db; }
  sgx-list-publications-row .sgx-publications-row .sgx-publications-date {
    text-align: right;
    color: rgba(0, 0, 0, 0.6);
    width: 100px;
    vertical-align: middle; }
    @media (max-width: 1023px) {
      sgx-list-publications-row .sgx-publications-row .sgx-publications-date {
        text-align: left; } }

sgx-list-jobs-row {
  display: block; }
  sgx-list-jobs-row .sgx-jobs-row {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.09); }
  sgx-list-jobs-row .sgx-jobs-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  sgx-list-jobs-row .sgx-jobs-title a {
    color: rgba(0, 0, 0, 0.87); }
    sgx-list-jobs-row .sgx-jobs-title a:hover {
      text-decoration: underline; }
  sgx-list-jobs-row .sgx-jobs-others {
    padding-top: 4px;
    color: rgba(0, 0, 0, 0.6); }
    sgx-list-jobs-row .sgx-jobs-others span {
      display: inline-block;
      padding-left: 8px; }
      sgx-list-jobs-row .sgx-jobs-others span:first-child {
        padding-left: 0px; }
  sgx-list-jobs-row .sgx-jobs-time {
    padding-left: 8px; }

sgx-export-settings {
  display: flex; }
  sgx-export-settings sgx-input-select {
    margin-right: 1rem; }

sgx-right-drawer {
  width: 320px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: #efefef;
  box-shadow: calc(-1 * 2px) 0 15px rgba(89, 89, 89, 0.4);
  transition: right 0.4s ease, background-color 0.4s ease; }
  sgx-right-drawer > *:not(.sgx-right-drawer-edge-button) {
    transition: transform 0.4s ease; }
  sgx-right-drawer[data-visible="false"] {
    right: calc(20px - 320px); }
  sgx-right-drawer[data-visible="false"] > *:not(.sgx-right-drawer-edge-button) {
    transform: translate(20px); }
  sgx-right-drawer[data-visible="false"]:hover {
    background-color: white; }
  sgx-right-drawer[data-active-color="true"] {
    background-color: #d2d5e0; }

.sgx-right-drawer-tab-container {
  height: 100%;
  width: calc(20px - 320px); }

.sgx-right-drawer-edge-button {
  position: absolute;
  top: calc(50% - 44px/ 2);
  left: -20px;
  width: 20px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  background-color: #0094B3;
  border-radius: 2px 0 0 2px;
  z-index: 101; }
  .sgx-right-drawer-edge-button:before {
    color: white;
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/solid/chevron-double-right.svg"); }
  .sgx-right-drawer-edge-button:hover {
    cursor: pointer; }
  [data-visible="false"] .sgx-right-drawer-edge-button:before {
    icon-font: url("sgx-base-code/svgs/solid/chevron-double-left.svg"); }

sgx-tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%; }

.sgx-tab-container-tabs {
  width: 100%;
  justify-content: center;
  padding-right: 12px;
  padding-top: 8px; }

sgx-tab-container [data-visible="false"] {
  display: none; }

sgx-progress-bar {
  display: block; }

.sgx-progress {
  width: 100%;
  height: 30px;
  appearance: none;
  border: none; }

.sgx-progress-bar-header {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px; }

.sgx-progress-bar-caption {
  color: rgba(0, 0, 0, 0.34); }

.sgx-progress::-webkit-progress-bar {
  background-color: rgba(0, 0, 0, 0.09); }

.sgx-progress::-webkit-progress-value {
  background-color: #0077CC; }

sgx-titan-toolbar {
  position: relative; }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end; }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper div {
  height: 30px;
  line-height: 30px; }

.sgx-titan-toolbar .toolbuttons-right.sgx-titan-toolbar-wrapper {
  justify-content: flex-end; }

.sgx-titan-toolbar .toolbuttons-left.sgx-titan-toolbar-wrapper {
  justify-content: flex-start; }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-toolbar-button {
  position: relative;
  text-align: center;
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.6); }
  sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-toolbar-button:hover {
    cursor: pointer; }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-toolbar-button svg {
  width: 14px;
  height: 14px; }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-toolbar-button.sgx-notification-icon:before {
  right: -30%;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  position: absolute;
  display: inline-block;
  background-color: #0094B3;
  font-size: 9px;
  color: #ffffff;
  line-height: 1.8em;
  content: attr(data-message); }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-toolbar-button.active {
  color: #0094B3; }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-toolbar-button span {
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  padding-left: 4px;
  vertical-align: middle; }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-titan-toolbar-hint-icon:hover ~ .sgx-input-hint {
  visibility: visible; }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-input-hint {
  top: 50%;
  z-index: 10;
  padding: 8px;
  min-width: 200px;
  max-width: 200px;
  min-height: 18px;
  max-height: 50px;
  display: block;
  color: #f7f7f7;
  visibility: hidden;
  border-radius: 2px;
  position: absolute;
  background: #212222;
  right: calc(100% + 10px);
  transform: translateY(-60%);
  font: normal 14px/1.3 Lato,HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.25);
  box-sizing: content-box; }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-input-hint:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-left-color: #212222;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%); }

sgx-titan-toolbar .sgx-titan-toolbar-wrapper .sgx-input-hint.active {
  visibility: visible;
  min-width: 350px;
  max-width: 350px; }

.sgx-consent-banner {
  position: fixed;
  display: block;
  width: 100%;
  padding: 20px 0;
  left: 0;
  bottom: 0;
  background-color: #0B236B;
  z-index: 10000; }
  .sgx-consent-banner--hidden {
    display: none; }

.sgx-consent-banner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%; }
  @media (max-width: 599px) {
    .sgx-consent-banner-wrapper {
      align-items: initial;
      flex-direction: column; } }

.sgx-consent-banner-content {
  line-height: 1.2rem;
  padding-right: 1rem; }
  @media (max-width: 599px) {
    .sgx-consent-banner-content {
      padding-right: 0;
      padding-bottom: 1rem;
      max-width: 100%; } }

.sgx-consent-banner-acceptance-button {
  flex: 0 0 auto; }

.sgx-consent-banner-content,
.sgx-consent-banner-link {
  color: white; }

sgx-user-profile-content {
  display: block;
  /**
   * Common styles
   */
  /**
   * Title + Views Buttons
   */
  /**
   * Views
   */ }
  sgx-user-profile-content .sgx-user-profile-content--hidden {
    display: none !important; }
  sgx-user-profile-content sgx-input-text,
  sgx-user-profile-content sgx-readonly-text {
    margin-top: 20px; }
  sgx-user-profile-content sgx-input-checkbox {
    display: block !important; }
  sgx-user-profile-content sgx-form button {
    margin-top: 20px;
    float: right; }
  sgx-user-profile-content .sgx-user-profile-content-header .sgx-user-profile-content-title,
  sgx-user-profile-content .sgx-user-profile-content-header .sgx-user-profile-view-buttons {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
    position: relative;
    z-index: 0; }
  sgx-user-profile-content .sgx-user-profile-content-header .sgx-user-profile-view-buttons button::before {
    font-family: FontAwesome;
    margin-right: 4px; }
  sgx-user-profile-content .sgx-user-profile-content-header .sgx-user-profile-view-buttons .sgx-user-profile-content-view-edit-button::before {
    content: "\F040"; }
  sgx-user-profile-content sgx-user-profile-content-view-edit sgx-input-checkbox,
  sgx-user-profile-content sgx-user-profile-content-view-edit sgx-input-select {
    margin-top: 20px; }
  sgx-user-profile-content sgx-user-profile-content-view-edit .sgx-user-profile-content-edit-buttons {
    margin-top: 20px;
    float: right; }
  sgx-user-profile-content sgx-readonly-text[data-config-key="emailAddress"] .sgx-readonly-text-content,
  sgx-user-profile-content sgx-readonly-text[data-config-key="firstName"] .sgx-readonly-text-content,
  sgx-user-profile-content sgx-readonly-text[data-config-key="lastName"] .sgx-readonly-text-content {
    text-transform: none; }

sgx-empty-image-placeholders .sgx-empty-image-placeholders {
  top: 50%;
  left: 50%;
  height: auto;
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%); }

sgx-empty-image-placeholders .sgx-empty-image-placeholders .sgx-empty-image-placeholders-title {
  font-size: 16px;
  margin-top: 12px; }

sgx-empty-image-placeholders .sgx-empty-image-placeholders .sgx-empty-image-placeholders-desc {
  font-size: 12px;
  padding-top: 4px; }

sgx-empty-image-placeholders .sgx-empty-image-placeholders .sgx-empty-image-placeholders-image.metalAlerts {
  content: url("../static/images/emptyMetalAlerts.png"); }

sgx-empty-image-placeholders .sgx-empty-image-placeholders .sgx-empty-image-placeholders-image.metalsData {
  content: url("../static/images/emptyMetalsData.png"); }

sgx-empty-image-placeholders .sgx-empty-image-placeholders .sgx-empty-image-placeholders-image.chartData {
  content: url("../static/images/emptyChartsData.png"); }

#sgx-community-change-password-dialog .sgx-dialog-box {
  padding: 32px 20px 20px 20px; }

#sgx-community-change-password-dialog .sgx-dialog-header {
  padding-bottom: 20px; }
  #sgx-community-change-password-dialog .sgx-dialog-header h2 {
    font-size: 24px; }

#sgx-community-change-password-dialog form {
  display: flex;
  flex-direction: column; }
  #sgx-community-change-password-dialog form > * {
    margin: 8px 0;
    width: 100%; }
  #sgx-community-change-password-dialog form .sgx-community-change-password-btn-container {
    text-align: right; }

#sgx-community-change-password-dialog .sgx-dialog-footer {
  display: none; }

#sgx-community-change-password-dialog sgx-status-indicator {
  background-color: #f7f7f7;
  padding: 20px;
  line-height: 1.25; }
  #sgx-community-change-password-dialog sgx-status-indicator .sgx-community-change-password-indicator-button {
    margin-top: 8px; }

body[data-type="desktop"] #sgx-community-change-password-dialog .sgx-dialog-box,
body[data-type="tablet"] #sgx-community-change-password-dialog .sgx-dialog-box {
  min-width: 350px; }

sgx-platts-landing-page {
  width: 100%;
  height: 100vh;
  display: flex;
  margin: 64px auto 0px auto;
  min-height: 100vh;
  max-height: 100vh;
  justify-content: stretch; }

sgx-platts-landing-page main.sgx-card {
  height: auto;
  position: relative; }

sgx-platts-landing-page .sgx-platts-landing-page-container {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 20px;
  width: 740px; }

body[data-type="mobile"] sgx-platts-landing-page .sgx-platts-landing-page-container {
  width: 100%; }

sgx-platts-landing-page .sgx-platts-landing-page-page-logo {
  top: 10px;
  left: 10px;
  width: 80px;
  height: 45px;
  display: block;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat; }

sgx-platts-landing-page .sgx-platts-landing-page-content .sgx-platts-landing-page-image {
  width: 662px;
  height: 326px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 20px auto 0px;
  background-position: center center; }

.sgx-platts-landing-page-header h2.sgx-platts-landing-page-title {
  padding: 0;
  margin: 0px;
  color: rgba(0, 0, 0, 0.87); }

.sgx-platts-landing-page-container .sgx-platts-landing-page-desc {
  padding: 20px 0 0 0; }

.sgx-platts-landing-page-container .sgx-platts-landing-page-desc p {
  text-align: left;
  line-height: 24px;
  padding: 0 0 8px 0;
  margin: 0px;
  color: rgba(0, 0, 0, 0.6); }

.sgx-platts-landing-page-container .sgx-platts-landing-page-desc span {
  display: block;
  width: 100%;
  color: rgba(0, 0, 0, 0.6); }

.sgx-platts-landing-page-container .sgx-platts-landing-page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.sgx-platts-landing-page-container .sgx-platts-landing-page-content sgx-alert-bar {
  margin-top: 20px; }

sgx-platts-landing-page sgx-alert-bar.sgx-platts-landing-page-alert-bar.sgx-alert-bar--show {
  top: unset;
  transition: unset; }

.sgx-platts-landing-page-container .sgx-section {
  margin-top: 32px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(34, 34, 34, 0.2); }

.sgx-platts-landing-page-container .sgx-platts-landing-page-controls {
  display: flex;
  padding: 20px 0px 0px;
  justify-content: flex-end; }

.template-platts-terms-and-conditions-wrapper {
  height: 100%; }

.template-platts-terms-and-conditions-wrapper .template-platts-terms-and-conditions {
  height: 100%; }

sgx-platts-tnc-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: stretch; }

sgx-platts-tnc-page main.sgx-card {
  height: 80%;
  padding: 20px;
  position: relative;
  margin-top: 10%; }

.sgx-platts-tnc-page-main .sgx-platts-tnc-page-content-container {
  height: calc(100% - 81px); }

sgx-platts-tnc-page .sgx-platts-tnc-page-wrapper {
  width: 1092px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  height: calc(100% - 64px); }

body[data-type="mobile"] sgx-platts-tnc-page .sgx-platts-tnc-page-wrapper {
  width: 100%; }

sgx-platts-tnc-page .sgx-platts-tnc-page-page-logo {
  clear: both;
  display: block;
  top: 10px;
  left: 10px;
  width: 80px;
  height: 45px;
  display: block;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat; }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content sgx-alert-bar {
  margin-top: 20px; }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder {
  height: 100%;
  overflow-y: scroll;
  padding: 20px 0 0 0; }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder p {
  margin: 0px;
  padding: 0 0 8px 0;
  padding-top: 1.2em;
  color: rgba(0, 0, 0, 0.6); }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder ol {
  color: rgba(0, 0, 0, 0.6); }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder ol li {
  padding-top: 1.2em;
  color: rgba(0, 0, 0, 0.6);
  list-style: decimal;
  list-style-position: inside; }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder ol li p {
  text-align: justify;
  display: inline; }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder > ol > li > p::before {
  content: '\00a0\00a0\00a0'; }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder ol li ul {
  padding-left: 20px; }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-content-holder ol li ul li {
  list-style: lower-alpha;
  list-style-position: inside; }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-controls button {
  margin-left: 12px; }

.sgx-platts-tnc-page-wrapper .sgx-section {
  margin-top: 32px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(34, 34, 34, 0.2); }

.sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-controls {
  display: flex;
  padding: 20px 0px 0px;
  justify-content: flex-end; }

.sgx-platts-tnc-page-header h2.sgx-platts-tnc-page-title {
  color: rgba(0, 0, 0, 0.6); }

sgx-platts-market-data-chart {
  float: left;
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 20px 12px 12px 12px;
  box-sizing: border-box; }

sgx-platts-market-data-chart .sgx-platts-market-data-chart-wrapper {
  height: 100%;
  min-height: 100%; }

sgx-platts-market-data-chart .sgx-platts-market-data-chart-wrapper .sgx-platts-market-data-chart-inner-wrapper {
  height: 100%;
  min-height: 100%; }

sgx-platts-market-data-chart .sgx-platts-market-data-chart-container {
  height: 100%;
  min-height: 100%; }

sgx-platts-market-data-chart .sgx-platts-market-data-chart-container sgx-chart {
  height: 100%;
  min-height: 100%; }

sgx-platts-market-data-chart .highcharts-input-group .highcharts-range-input text {
  font-weight: 500;
  fill: rgba(0, 0, 0, 0.87) !important;
  color: rgba(0, 0, 0, 0.87);
  background-color: #3b3b3b; }

sgx-platts-market-data-chart .highcharts-input-group .highcharts-range-label text {
  font-weight: 500;
  fill: rgba(0, 0, 0, 0.87) !important;
  color: rgba(0, 0, 0, 0.87);
  background-color: #3b3b3b; }

sgx-platts-market-data-chart .highcharts-input-group .highcharts-range-label:first-child {
  display: none; }

.highcharts-range-selector-group .highcharts-range-selector-buttons > text {
  display: none; }

sgx-platts-market-data-chart .highcharts-range-selector-group {
  display: none; }

sgx-platts-market-data-chart .highcharts-navigator-mask-inside {
  fill: rgba(0, 0, 0, 0.6);
  fill-opacity: .25;
  stroke: rgba(0, 0, 0, 0.09); }

sgx-platts-market-data-chart .highcharts-navigator-outline {
  stroke: rgba(0, 0, 0, 0.09); }

sgx-platts-market-data-chart .highcharts-navigator-mask-outside {
  stroke: rgba(0, 0, 0, 0.09); }

sgx-platts-market-data-chart .highcharts-navigator-xaxis .highcharts-grid-line {
  stroke: rgba(0, 0, 0, 0.09); }

sgx-platts-market-data-table {
  height: 100%;
  min-height: 100%; }

sgx-platts-market-data-table .sgx-platts-market-data-table-wrapper {
  height: 100%;
  min-height: 100%; }

sgx-platts-market-data-table .sgx-platts-market-data-table-wrapper .sgx-platts-market-data-table-container {
  height: 100%;
  min-height: 100%; }

sgx-platts-market-data-table .sgx-platts-market-data-table-container sgx-table {
  height: 100%;
  min-height: 100%; }

sgx-platts-market-data-table sgx-table-cell-market-data .sgx-table-cell-row-group .sgx-cell-col {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 60px;
  text-align: right;
  padding-right: 8px;
  box-sizing: border-box;
  max-width: 33.33%;
  min-width: 33.33%; }

sgx-platts-market-data-table sgx-table-cell-market-data .sgx-table-cell-row-group .sgx-cell-col:not(:last-child) {
  border-right: solid 1px rgba(0, 0, 0, 0.2); }

sgx-platts-market-data-table sgx-table sgx-table-column-headers-manager sgx-table-header-text header {
  line-height: 50px; }

sgx-platts-market-data-table sgx-table-toolbar {
  display: none; }

sgx-platts-market-data-table sgx-table sgx-scroll-shield {
  top: 50px; }

sgx-platts-market-data-table sgx-table-column-headers-manager .sgx-table-header-container sgx-table-header-text:first-child {
  text-align: left; }

sgx-platts-market-data-table sgx-table-column-headers-manager .sgx-table-header-container sgx-table-header-text:last-child {
  text-align: left; }

sgx-platts-market-data-table [data-sort=false] > sgx-table-header-market-data.sgx-table-header-element {
  padding-left: 0px;
  width: 100%;
  border-right: solid 1px rgba(0, 0, 0, 0.2); }

sgx-platts-market-data-table sgx-table sgx-table-column-headers-manager .sgx-table-header-container {
  height: 52px; }

sgx-platts-market-data-table sgx-table sgx-table-column-headers-manager sgx-table-header-market-data + .sgx-table-header-resizer {
  display: none; }

sgx-platts-market-data-table sgx-table sgx-table-column-headers-manager .sgx-table-header-market-data {
  border-right: solid 1px rgba(0, 0, 0, 0.2); }

sgx-table-header-market-data .sgx-header-row-group .sgx-row-group:first-child {
  text-align: left;
  text-indent: 8px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2); }

sgx-table-header-market-data .sgx-header-row-group .sgx-row-group .sgx-col {
  text-align: left;
  text-indent: 8px; }
  sgx-table-header-market-data .sgx-header-row-group .sgx-row-group .sgx-col:not(:last-child) {
    border-right: solid 1px rgba(0, 0, 0, 0.2); }

.sgx-table-header-wrapper .sgx-header-row-group {
  display: flex;
  flex-direction: column;
  justify-content: left; }

.sgx-row-group {
  height: 23px;
  min-height: 23px;
  max-height: 23px;
  line-height: 23px;
  display: flex;
  overflow-y: hidden;
  text-align: left;
  text-indent: 8px;
  flex-direction: row;
  justify-content: space-around; }

.sgx-row-group .sgx-col {
  flex: 1; }

.sgx-row-group.sgx-multi-colspan {
  width: 100%;
  justify-content: left; }

sgx-table-header-market-data .sgx-header-row-group {
  height: 49px;
  min-height: 49px;
  max-height: 49px;
  line-height: 49px; }

sgx-platts-market-data-table sgx-table-row sgx-table-cell-market-data {
  padding-left: 0px !important;
  padding-right: 0px !important; }

sgx-table-cell-market-data .sgx-table-cell-row-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center; }

sgx-platts-market-symbol-data-table {
  height: 100%;
  position: relative; }

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper {
  height: 100%; }

sgx-platts-market-symbol-data-table sgx-table sgx-table-row sgx-table-cell-checkbox[data-column-id='symbolstatus'] {
  max-width: 30px; }

sgx-platts-market-symbol-data-table sgx-table-toolbar {
  display: none; }

sgx-platts-market-symbol-data-table sgx-table-column-headers-manager .sgx-table-header-container .sgx-table-header-wrapper[data-id='symbolstatus'] {
  max-width: 30px; }

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-container {
  height: 100%;
  position: relative; }

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-container sgx-table {
  height: calc(100% - 25px);
  text-align: left; }

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-container .sgx-market-symbol-data-table-footer {
  height: 25px;
  text-align: left;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px; }

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer a {
  text-decoration: none;
  color: #0094B3; }

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer a:not(:first-child):after {
  content: '|';
  padding-left: 8px;
  padding-right: 8px;
  color: #0094B3; }

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer a:last-child:after {
  content: '';
  padding-right: unset; }

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer .sgx-input-hint-icon {
  position: static;
  padding-left: 0px; }

sgx-platts-market-symbol-data-table sgx-table sgx-scroll-shield {
  top: 32px;
  height: calc(100% - 32px); }

sgx-platts-market-symbol-data-table .sgx-market-symbol-data-table-footer .sgx-input-hint {
  top: 0;
  left: 0;
  color: #a7a8a9;
  min-width: 286px;
  max-width: 286px; }

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer .sgx-input-hint:after {
  left: 1px; }

sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer .sgx-input-hint-icon:hover ~ .sgx-input-hint,
sgx-platts-market-symbol-data-table .sgx-platts-market-symbol-data-table-wrapper .sgx-market-symbol-data-table-footer .sgx-input-hint-icon:hover .sgx-input-hint {
  visibility: visible; }

platts-admin-enquiry-blotter sgx-table {
  height: calc(100% - 64px); }

platts-admin-enquiry-blotter .platts-admin-enquiry-blotter-title {
  font-size: 20px;
  font-family: Lato, sans-serif;
  color: #1d1d1d;
  padding-bottom: 20px; }

platts-admin-enquiry-blotter sgx-state-tabs {
  background: none;
  padding-left: 16px; }

sgx-partner-image-container {
  display: block;
  width: 100%;
  height: 100%; }
  sgx-partner-image-container .sgx-partner-image-container {
    width: 100%;
    height: 100%;
    overflow-y: auto; }

platts-admin-subscription-blotter sgx-table {
  height: calc(100% - 64px); }

platts-admin-subscription-blotter .platts-admin-subscription-blotter-title {
  font-size: 20px;
  font-family: Lato, sans-serif;
  color: #1d1d1d;
  padding-bottom: 20px; }

platts-admin-subscription-blotter sgx-state-tabs {
  background: none;
  padding-left: 16px; }

sgx-partner-iframe-container {
  display: block;
  width: 100%;
  height: 100%; }
  sgx-partner-iframe-container .sgx-partner-iframe-container {
    width: 100%;
    height: 100%;
    overflow-y: auto; }
    sgx-partner-iframe-container .sgx-partner-iframe-container .sgx-partner-iframe {
      width: 100%; }

.template-platts-page-copyright-section {
  padding-top: 20px; }

sgx-platts-copyrights .sgx-platts-page-copyrights-footer {
  text-align: center;
  color: rgba(0, 0, 0, 0.34); }

sgx-platts-copyrights .sgx-platts-page-copyrights-footer .sgx-button-copyright {
  padding: auto 10px;
  color: #0094B3;
  padding: 5px 5px 0px 5px !important; }

sgx-platts-copyrights .sgx-platts-page-copyrights-footer .sgx-button-copyright[class*=sgx-button] {
  padding: 5px 5px 0px 5px !important;
  vertical-align: bottom; }

sgx-platts-copyrights .sgx-platts-page-copyrights-footer .sgx-button-copyright[class*=sgx-button]:focus,
sgx-platts-copyrights .sgx-platts-page-copyrights-footer .sgx-button-copyright[class*=sgx-button]:hover {
  background-color: transparent;
  border: none;
  outline-color: none; }

sgx-alert-dialog.sgx-platts-copyright-dialog .sgx-alert-dialog-content {
  max-width: 640px;
  line-height: 1.3em;
  color: rgba(0, 0, 0, 0.6); }

sgx-alert-dialog.sgx-platts-copyright-dialog .sgx-alert-dialog-header {
  font-size: 25px; }

sgx-alert-dialog.sgx-platts-copyright-dialog .sgx-alert-dialog-header img {
  height: 40px;
  vertical-align: bottom; }

.sgx-platts-historical-data-settings-container {
  width: 600px;
  max-width: 600px;
  min-width: 600px;
  padding-top: 20px;
  padding-bottom: 20px; }

.sgx-platts-historical-data-settings-container .sgx-historical-section-title,
.sgx-platts-historical-data-settings-container .sgx-span-label {
  color: rgba(0, 0, 0, 0.87); }

.sgx-platts-historical-data-settings-container .sgx-platts-section-container .form-row {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 1 auto; }

.sgx-platts-historical-data-settings-container .sgx-platts-section-container .form-row .radio-column {
  flex-basis: 40px; }

.sgx-platts-historical-data-settings-container .sgx-platts-section-container .form-row .radio-column {
  flex-basis: auto; }

.sgx-platts-historical-data-settings-container .sgx-platts-section-container .settings-option {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 0 auto; }

.sgx-platts-historical-data-settings-container .sgx-platts-section-container .settings-option .trading-days-back-count {
  width: 109px; }

.sgx-platts-historical-data-settings-container .sgx-platts-section-container .settings-option .sgx-date-back-input {
  width: 200px; }

.form-column {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start; }

.form-column .settings-option {
  height: 40px;
  flex-basis: 100%;
  padding-top: 12px; }

.form-column .settings-option span.sgx-span-label {
  height: 30px;
  padding: 10px 12px;
  display: inline-block; }

.form-column .settings-option span.sgx-span-label.extra-pad {
  padding-left: 24px; }

.sgx-platts-historical-data-settings-container .form-column .settings-spread-option {
  height: 52px;
  max-height: 52px;
  min-height: 52px; }

.sgx-platts-historical-data-settings-container sgx-input-radio-list.sgx-input fieldset label {
  display: block;
  height: 35px;
  margin-top: 12px;
  padding-top: 10px; }

.sgx-platts-historical-data-settings-container .form-column .settings-option sgx-input-date {
  width: 200px; }

.sgx-platts-historical-data-settings-container .settings-option.settings-spread-option sgx-input-select {
  width: 215px;
  height: 40px; }

.sgx-platts-historical-data-settings-container .sgx-platts-section-spread-selection .settings-spread-option .sgx-span-label {
  padding-left: 24px;
  padding-right: 24px; }

.sgx-platts-historical-data-settings-container .sgx-platts-section-spread-selection {
  padding-top: 12px; }

sgx-platts-historical-data-settings .sgx-platts-historical-data-settings button {
  margin-left: 20px; }

sgx-widget-flexible-layout,
sgx-platts-widget-flexible-layout {
  display: block;
  margin-top: 8px;
  height: 100%; }
  sgx-widget-flexible-layout .lm_header,
  sgx-platts-widget-flexible-layout .lm_header {
    z-index: 999; }
  @media (max-width: 599px) {
    sgx-widget-flexible-layout,
    sgx-platts-widget-flexible-layout {
      margin-top: 4px; } }
  sgx-widget-flexible-layout sgx-card-widget-wrapper,
  sgx-platts-widget-flexible-layout sgx-card-widget-wrapper {
    height: 100%; }

.sgx-widget-order-book-contract-view-container {
  box-shadow: 1px 0 1px 0px rgba(0, 0, 0, 0.1);
  z-index: 100; }

.sgx-contract-view {
  display: flex;
  flex-direction: column;
  min-width: 121px;
  background: #efefef;
  color: #222222;
  font-family: Lato;
  font-size: 13px;
  height: 100%; }

.sgx-contract-view-row-wrapper {
  display: flex;
  flex-direction: column; }
  .sgx-contract-view-row-wrapper .sgx-contract-view-strike-price {
    display: none; }
    .sgx-contract-view-row-wrapper .sgx-contract-view-strike-price[data-display-strike='true'] {
      display: block; }
  .sgx-contract-view-row-wrapper .sgx-contract-view-row:nth-of-type(2) {
    display: none; }
    .sgx-contract-view-row-wrapper .sgx-contract-view-row:nth-of-type(2)[data-display-details='true'] {
      display: flex; }

.sgx-contract-view-row {
  display: flex;
  flex-direction: row;
  min-height: 25px; }
  .sgx-contract-view-detail .sgx-contract-view-row-wrapper .sgx-contract-view-row {
    min-height: 16px; }
  .sgx-contract-view-detail .sgx-contract-view-row-wrapper .sgx-contract-view-row:nth-child(2) {
    padding-top: 0px;
    padding-bottom: 2px; }

.sgx-contract-view-text {
  min-width: auto; }
  .sgx-contract-view-detail .sgx-contract-view-row .sgx-contract-view-text {
    min-width: auto;
    margin-right: 8px;
    max-width: 110px;
    background-color: transparent; }

.sgx-contract-view-strike-price {
  color: rgba(0, 0, 0, 0.4);
  background-color: transparent; }

.sgx-contract-view-detail .sgx-contract-view-row .sgx-contract-view-last-price {
  padding-right: 8px;
  color: rgba(0, 0, 0, 0.4);
  background-color: transparent; }

.sgx-contract-view-detail .sgx-contract-view-row .sgx-contract-view-total-volume {
  color: rgba(0, 0, 0, 0.4);
  background-color: transparent; }

.sgx-contract-view-header {
  flex: none;
  height: 3.85rem;
  background-color: #efefef;
  padding-top: calc(2.45rem - 2px);
  padding-left: 12px;
  padding-right: 5px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-bottom: solid 2px #b6bdd0;
  align-items: flex-end;
  padding-bottom: 0.5rem;
  font-size: 14px; }
  .sgx-contract-view-header:hover .sgx-contract-view-header-plus {
    cursor: pointer;
    visibility: visible;
    opacity: 1; }
  .sgx-contract-view-detail .sgx-contract-view-header {
    padding: calc(1.45rem - 2px) 5px 0.5rem 12px; }

.sgx-contract-view-header-text {
  width: 100%; }
  .sgx-contract-view-detail .sgx-contract-view-header-text {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around; }

.sgx-contract-view-header-text .sgx-last-price {
  font-size: 12px;
  padding-right: 8px;
  color: rgba(0, 0, 0, 0.4); }

.sgx-contract-view-header-text .sgx-contract {
  padding-right: 8px; }

.sgx-contract-view-header-text .sgx-t-volume {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px; }

.sgx-contract-view-header-text .sgx-t-volume:before {
  margin-right: 4px;
  content: '|'; }

.sgx-contract-view-header-text .sgx-strike-price {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px; }

.sgx-contract-view-header-text .sgx-strike-price:before {
  margin-right: 4px;
  content: '|'; }

.sgx-contract-view-header-plus:before {
  font-size: 0.8rem; }

.sgx-contract-view-list {
  background-image: linear-gradient(180deg, #f9f9f9, #f9f9f9 50%, #efefef 0, #efefef);
  background-size: 100% 4rem; }
  .sgx-contract-view-detail .sgx-contract-view-list {
    background-size: 100% 6rem; }

.sgx-contract-view-list-container {
  overflow: hidden;
  flex: 1 1 auto;
  display: block;
  height: 100%; }

.sgx-contract-view-item {
  width: 100%;
  color: #222222;
  height: 32px; }
  .sgx-contract-view-item[data-visible-contract='false'] {
    display: none; }

.sgx-contract-view-item--wrapper {
  padding: 8px 0px 0 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  height: 32px; }

.sgx-contract-view-icon {
  display: none;
  color: #222;
  transition: transform 0.4s ease;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-top: -3px; }
  .sgx-contract-view-icon svg {
    pointer-events: none; }
  .sgx-contract-view-icon:hover {
    cursor: pointer; }

.sgx-contract-view-collapsed-icon {
  display: flex;
  transform: rotate(0deg); }

.sgx-contract-view-expanded-icon {
  display: flex;
  transform: rotate(90deg); }

sgx-widget-order-book {
  height: 100%;
  padding: 12px;
  background: #efefef;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  border-radius: 3px; }
  sgx-widget-order-book .sgx-widget-order-book-grid-view-wrapper {
    border: 1px solid #b6bdd0;
    overflow: hidden;
    display: flex;
    height: 100%; }
  sgx-widget-order-book .sgx-widget-order-book-grid-view-container {
    display: none;
    position: relative;
    margin: 1px;
    height: calc(100% - 2px);
    width: 96%; }
    sgx-widget-order-book .sgx-widget-order-book-grid-view-container[data-horizontal="true"] {
      padding-bottom: 12px; }
    sgx-widget-order-book .sgx-widget-order-book-grid-view-container[data-vertical="true"] {
      padding-right: 12px; }
    sgx-widget-order-book .sgx-widget-order-book-grid-view-container .sgx-widget-order-book-contract-view-container {
      position: relative;
      flex: 0 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100; }
      sgx-widget-order-book .sgx-widget-order-book-grid-view-container .sgx-widget-order-book-contract-view-container:before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        height: 100%;
        left: calc(100% - 15px);
        width: 15px;
        box-shadow: 4px 0px 12px 0px rgba(0, 0, 0, 0.25);
        pointer-events: none; }
    sgx-widget-order-book .sgx-widget-order-book-grid-view-container sgx-scroll-shield {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      sgx-widget-order-book .sgx-widget-order-book-grid-view-container sgx-scroll-shield .table-horizontal-scrolling-wrapper {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-left: 0; }
      sgx-widget-order-book .sgx-widget-order-book-grid-view-container sgx-scroll-shield .table-vertical-scrolling-wrapper {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-top: 0; }
      sgx-widget-order-book .sgx-widget-order-book-grid-view-container sgx-scroll-shield.no-scrollbars .table-vertical-scrolling-wrapper,
      sgx-widget-order-book .sgx-widget-order-book-grid-view-container sgx-scroll-shield.only-horizontal-scrollbar .table-vertical-scrolling-wrapper {
        width: 0;
        border-left: 0;
        bottom: 0; }
      sgx-widget-order-book .sgx-widget-order-book-grid-view-container sgx-scroll-shield.only-horizontal-scrollbar .table-horizontal-scrolling-wrapper {
        right: 0; }
      sgx-widget-order-book .sgx-widget-order-book-grid-view-container sgx-scroll-shield.only-vertical-scrollbar .table-vertical-scrolling-wrapper {
        bottom: 0; }
    sgx-widget-order-book .sgx-widget-order-book-grid-view-container .sgx-widget-order-book-grid-view-container-wrapper {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      height: 100%;
      z-index: 1; }
      sgx-widget-order-book .sgx-widget-order-book-grid-view-container .sgx-widget-order-book-grid-view-container-wrapper sgx-table {
        width: 100%;
        height: calc(100% - 30px); }
        sgx-widget-order-book .sgx-widget-order-book-grid-view-container .sgx-widget-order-book-grid-view-container-wrapper sgx-table .table-container {
          border-bottom: 0;
          border-right: 0; }
        sgx-widget-order-book .sgx-widget-order-book-grid-view-container .sgx-widget-order-book-grid-view-container-wrapper sgx-table:first-child .table-container {
          border-left: 0; }
        sgx-widget-order-book .sgx-widget-order-book-grid-view-container .sgx-widget-order-book-grid-view-container-wrapper sgx-table sgx-table-toolbar {
          display: none; }
  sgx-widget-order-book .sgx-widget-order-book-add-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #efefef;
    transition: color 0.4s ease;
    cursor: pointer;
    color: #6a7082; }
    sgx-widget-order-book .sgx-widget-order-book-add-product:hover {
      color: #222222; }
    sgx-widget-order-book .sgx-widget-order-book-add-product.sgx-widget-order-book-add-product--added {
      width: 4%;
      background: #efefef; }
      sgx-widget-order-book .sgx-widget-order-book-add-product.sgx-widget-order-book-add-product--added svg {
        width: 16px;
        height: 16px; }
    sgx-widget-order-book .sgx-widget-order-book-add-product .sgx-widget-order-book-add-product-icon {
      padding: 12px 16px; }
    sgx-widget-order-book .sgx-widget-order-book-add-product .sgx-widget-order-book-add-product-icon-text {
      font-size: 1.8rem;
      font-weight: 100;
      position: relative; }
  sgx-widget-order-book .sgx-table-header-wrapper[data-id="bidPrice"] {
    text-align: right; }

sgx-routing-group-view {
  padding: 0 12px; }
  sgx-routing-group-view > h1 {
    margin-top: 1.2em; }
  sgx-routing-group-view h1 {
    color: black;
    font-size: 1em;
    margin-bottom: 0.5em;
    text-transform: capitalize; }
  sgx-routing-group-view #route-to-internal {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    margin: 0.8rem 0; }
    sgx-routing-group-view #route-to-internal sgx-input-checkbox-toggle {
      margin-bottom: 0.5em; }
      sgx-routing-group-view #route-to-internal sgx-input-checkbox-toggle .sgx-input-label {
        font-size: 1em; }
  sgx-routing-group-view #route-to-companies {
    margin-top: 1em; }
    sgx-routing-group-view #route-to-companies h1 {
      margin: 1em 0; }
    sgx-routing-group-view #route-to-companies .sgx-input-label {
      display: inline-block;
      font-size: 0.9em;
      margin-right: 0;
      margin-bottom: 0.5rem;
      margin-top: auto;
      overflow: hidden;
      padding-right: 0px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 175px; }
  sgx-routing-group-view #route-to-brokers,
  sgx-routing-group-view #route-to-brokers-header,
  sgx-routing-group-view #route-to-brokers-fcm,
  sgx-routing-group-view #route-to-fcms-header {
    display: block; }
    sgx-routing-group-view #route-to-brokers.route-to-brokers--hidden,
    sgx-routing-group-view #route-to-brokers-header.route-to-brokers--hidden,
    sgx-routing-group-view #route-to-brokers-fcm.route-to-brokers--hidden,
    sgx-routing-group-view #route-to-fcms-header.route-to-brokers--hidden {
      display: none; }
  sgx-routing-group-view #route-to-companies,
  sgx-routing-group-view #route-to-brokers-header,
  sgx-routing-group-view #route-to-fcms-header {
    border-top: 1px solid rgba(0, 0, 0, 0.3); }
  sgx-routing-group-view #route-to-brokers-header,
  sgx-routing-group-view #route-to-fcms-header {
    padding-top: 1em; }
  sgx-routing-group-view sgx-input-checkbox-toggle {
    text-align: right; }
    sgx-routing-group-view sgx-input-checkbox-toggle.sgx-input-checkbox-toggle--checked .sgx-input-checkbox-toggle-circle {
      background-color: #fff; }
    sgx-routing-group-view sgx-input-checkbox-toggle .sgx-input-checkbox-toggle-pill {
      background-color: #abadb5; }
    sgx-routing-group-view sgx-input-checkbox-toggle .sgx-input-checkbox-toggle-circle {
      background-color: #e7e8ea; }
  sgx-routing-group-view[browser="ie"] .sgx-text-dropdown-header-container {
    padding-left: 0px !important; }

#route-to-groups {
  width: 78%; }
  #route-to-groups .sgx-text-dropdown-list {
    margin-left: 1.2em; }
  #route-to-groups sgx-text-dropdown .sgx-text-dropdown-header-container {
    flex-direction: row-reverse;
    position: relative; }
  #route-to-groups sgx-text-dropdown .sgx-text-dropdown-list-container--toggle-closed,
  #route-to-groups sgx-text-dropdown .sgx-text-dropdown-list-container--toggle-open {
    margin-top: 3px;
    margin-left: -1px; }
    #route-to-groups sgx-text-dropdown .sgx-text-dropdown-list-container--toggle-closed:before,
    #route-to-groups sgx-text-dropdown .sgx-text-dropdown-list-container--toggle-open:before {
      position: absolute;
      top: 8px;
      right: 10px; }
  #route-to-groups sgx-text-dropdown .sgx-text-dropdown-header-container p {
    margin-right: 0;
    overflow: hidden;
    padding-right: 0px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 175px; }

.sgx-route-group-items .sgx-input-checkbox-toggle-container .sgx-input-control {
  width: 95%;
  height: 1.5rem;
  padding: 0; }

.sgx-route-group-link-to-userprofile-container {
  display: flex;
  align-items: center; }
  .sgx-route-group-link-to-userprofile-container svg {
    color: #BDD831;
    padding-right: 5px;
    width: 1.3rem;
    height: 1.3rem; }

.sgx-route-group-link-to-userprofile {
  padding-top: 6px;
  height: 1.5rem;
  text-decoration: none;
  color: inherit; }
  .sgx-route-group-link-to-userprofile:hover {
    text-decoration: underline; }

.sgx-widget-routing-view-status-message {
  position: relative;
  font-size: 0.9rem;
  padding: 0.3rem 1rem;
  background: #fff5cc;
  line-height: 1.2rem;
  opacity: 1;
  height: 50px;
  transition: opacity 0.5s ease-out, height 0.5s ease-out, padding 0.5s ease-out;
  color: black; }
  .sgx-widget-routing-view-status-message--hidden {
    padding: 0 1rem;
    height: 0;
    opacity: 0; }
  .sgx-widget-routing-view-status-message .sgx-widget-routing-view-status-message-close-button {
    position: absolute;
    top: 50%;
    right: 1rem;
    content: "\F00D";
    font-family: FontAwesome;
    color: black; }

.sgx-route-group-update-anim {
  animation-duration: 0.7s;
  animation-name: sgx-route-group-update-keyframes; }

.sgx-route-group-add-anim {
  animation-duration: 0.7s;
  animation-name: sgx-route-group-add-keyframes;
  animation-timing-function: ease-out; }

.sgx-route-group-delete-anim {
  animation-duration: 0.7s;
  animation-name: sgx-route-group-add-keyframes;
  animation-direction: reverse;
  animation-timing-function: ease-in; }

@keyframes sgx-route-group-update-keyframes {
  50% {
    background: #BDD831;
    color: white; } }

@keyframes sgx-route-group-add-keyframes {
  from {
    opacity: 0;
    margin-left: 300px; }
  to {
    opacity: 1;
    margin-left: 0px; } }

sgx-order-ticket-view form {
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  flex-direction: column; }
  sgx-order-ticket-view form > * {
    padding-bottom: 1rem; }
  sgx-order-ticket-view form > :first-child {
    margin-bottom: 1rem;
    position: relative;
    left: -15px;
    width: calc(100% + 30px); }

.sgx-order-submission-widget {
  height: 100%;
  color: #222;
  width: 320px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  z-index: 100; }
  .sgx-order-submission-widget .sgx-dynamic-form-row {
    padding: 0.5rem 1rem; }
  .sgx-order-submission-widget .sgx-dynamic-form-row > * {
    width: 100%; }
  .sgx-order-submission-widget-view-wrapper {
    flex: 1 0 auto;
    margin: 0;
    overflow: hidden;
    position: relative; }
  .sgx-order-submission-widget-order-ticket-view .sgx-order-submission-order-toggle-direction {
    padding: 0 0 0.5rem 0;
    line-height: 2rem;
    background-color: transparent; }
    .sgx-order-submission-widget-order-ticket-view .sgx-order-submission-order-toggle-direction .sgx-input-toggle-gui-wrapper {
      padding: 0.5rem 0; }
    .sgx-order-submission-widget-order-ticket-view .sgx-order-submission-order-toggle-direction--buy {
      transition: background-color 0.4s linear; }
    .sgx-order-submission-widget-order-ticket-view .sgx-order-submission-order-toggle-direction--sell {
      transition: background-color 0.4s linear; }
  .sgx-order-submission-widget-order-ticket-view, .sgx-order-submission-widget-routing-group-view {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(0);
    transition: transform 0.4s ease, background 0.4s ease;
    background: #efefef; }
  .sgx-order-submission-widget[data-current-view="order"] .sgx-order-submission-widget-routing-group-view,
  .sgx-order-submission-widget[data-current-view="routing"] .sgx-order-submission-widget-order-ticket-view {
    transform: translateX(100%);
    transition-delay: 0.4s;
    z-index: 0; }
  .sgx-order-submission-widget-btn-clear, .sgx-order-submission-widget-btn-submit {
    padding: 1rem; }
  .sgx-order-submission-widget-view-controls {
    transition: background 0.4s ease;
    background-color: #efefef;
    box-shadow: 3px 0px 9px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    z-index: 3; }
  .sgx-mobile-order-submission-widget .sgx-order-submission-widget-view-controls {
    background: #ffffff; }
  .sgx-order-submission-widget-input-validity--hidden {
    display: none; }
  .sgx-order-submission-widget-switch-view {
    display: none;
    color: #222;
    border-color: #222; }
    .sgx-order-submission-widget-switch-view--show {
      display: block; }
  .sgx-order-submission-widget-button-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    padding-top: 1rem; }
  .sgx-order-submission-widget-input {
    width: 100%; }

.sgx-order-submission-widget-focus sgx-order-ticket-view,
.sgx-order-submission-widget-focus sgx-routing-group-view,
.sgx-order-submission-widget-focus .sgx-order-submission-widget-header-container,
.sgx-order-submission-widget-focus .sgx-order-submission-widget-view-controls {
  background-color: #d2d5e0; }

.sgx-order-submission-widget-focus.sgx-mobile-order-submission-widget sgx-order-ticket-view,
.sgx-order-submission-widget-focus.sgx-mobile-order-submission-widget sgx-routing-group-view,
.sgx-order-submission-widget-focus.sgx-mobile-order-submission-widget .sgx-order-submission-widget-header-container,
.sgx-order-submission-widget-focus.sgx-mobile-order-submission-widget .sgx-order-submission-widget-view-controls {
  background-color: #ffffff; }

.sgx-order-submission-widget-focus .sgx-order-submission-widget-panel-button:before {
  background-color: #0094B3; }

.sgx-order-submission-widget-focus [name="sgx-order-ticket-toggle-direction"] {
  transition: background 0.4s ease;
  background-color: #bf0000; }

.sgx-order-submission-widget-focus [name="sgx-order-ticket-toggle-direction"][data-isleft="true"] {
  background-color: #74ad00; }

.sgx-order-submission-widget-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  background: #efefef;
  transition: padding-left 0.4s, background-color 0.4s; }

.sgx-order-submission-widget-header-container {
  text-align: center;
  color: #222;
  background-color: #efefef;
  transition: background-color 0.4s;
  padding: 1rem;
  z-index: 3; }

sgx-order-ticket-view .sgx-dynamic-form-row:first-of-type {
  padding: 0; }
  sgx-order-ticket-view .sgx-dynamic-form-row:first-of-type .sgx-input-toggle-gui-wrapper {
    padding: 0;
    height: 50px; }

.sgx-order-submission-auto-routing-flag {
  height: 30px;
  padding: 0.25rem;
  width: 100%;
  text-align: center;
  line-height: 18px;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: #1287cc; }
  .sgx-order-submission-auto-routing-flag span {
    color: #fff;
    font-size: 11px; }

.sgx-auto-routing--hide {
  display: none; }

.sgx-icons-tick:before {
  font-size: 15px;
  margin-right: 8px;
  icon-font: url("sgx-base-code/svgs/solid/check-circle.svg"); }

#sgx-order-submission-select-fill-type .sgx-input-hint-icon:hover + .sgx-input-hint {
  white-space: pre-line; }

.sgx-widget-order-submissions-status-message-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .sgx-widget-order-submissions-status-message-wrapper[data-type="warning"] {
    color: white;
    background: #FF0000; }
  .sgx-widget-order-submissions-status-message-wrapper[data-type="note"] {
    color: black;
    background: #FFCC00; }

.sgx-widget-order-submissions-status-message {
  display: inline-block;
  font-size: 0.9rem;
  padding: 0.3rem 1rem;
  line-height: 1.2rem;
  opacity: 1;
  height: 50px;
  transition: opacity 0.5s ease-out, height 0.5s ease-out, padding 0.5s ease-out; }

.sgx-widget-order-submissions-status-message--hidden {
  padding: 0 1rem;
  height: 0;
  opacity: 0; }

.sgx-widget-order-submissions-status-message-close-icon {
  height: 100%;
  width: 3rem;
  display: inline-block;
  text-align: center; }
  .sgx-widget-order-submissions-status-message-close-icon:after {
    color: darkgrey;
    content: "\F00D";
    font-family: FontAwesome; }
  .sgx-widget-order-submissions-status-message-close-icon:hover {
    cursor: pointer; }
  [data-closeable="false"] > .sgx-widget-order-submissions-status-message-close-icon {
    width: 0;
    height: 0;
    opacity: 0; }

sgx-widget-order-blotter-bulk-actions.table-action {
  display: flex;
  margin: 0; }
  sgx-widget-order-blotter-bulk-actions.table-action:hover {
    text-decoration: none; }

sgx-widget-order-blotter-bulk-actions .table-action {
  cursor: pointer; }
  sgx-widget-order-blotter-bulk-actions .table-action .sgx-table-toolbar-icon {
    margin-top: 2px;
    margin-right: 4px; }
  sgx-widget-order-blotter-bulk-actions .table-action:hover {
    text-decoration: underline; }
  sgx-widget-order-blotter-bulk-actions .table-action:after {
    content: attr(count);
    position: absolute;
    z-index: 100;
    top: 1.5px;
    right: -10px;
    font-size: 10px;
    background: #BDD831;
    color: #222222;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 50%;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1); }

sgx-widget-order-blotter-bulk-actions .sgx-widget-order-blotter-bulk-actions--inactive {
  color: #ccc;
  opacity: 0.8;
  pointer-events: none;
  cursor: default; }

body[data-type=tablet] sgx-widget-order-blotter-bulk-actions {
  display: none; }

sgx-widget-bait-order-blotter,
sgx-widget-order-blotter {
  height: 100%;
  padding: 12px;
  background: #efefef;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  border-radius: 3px; }
  sgx-widget-bait-order-blotter sgx-table,
  sgx-widget-order-blotter sgx-table {
    margin: 0;
    height: calc(100% - 32px); }
  sgx-widget-bait-order-blotter sgx-table,
  sgx-widget-bait-order-blotter sgx-state-tabs,
  sgx-widget-order-blotter sgx-table,
  sgx-widget-order-blotter sgx-state-tabs {
    background-color: #efefef; }
  sgx-widget-bait-order-blotter sgx-state-tabs,
  sgx-widget-order-blotter sgx-state-tabs {
    padding-left: 1rem; }
  sgx-widget-bait-order-blotter .table-action-disabled,
  sgx-widget-order-blotter .table-action-disabled {
    pointer-events: none;
    opacity: 0.8;
    color: #ccc;
    text-decoration: none; }
    sgx-widget-bait-order-blotter .table-action-disabled:hover,
    sgx-widget-order-blotter .table-action-disabled:hover {
      text-decoration: none; }

sgx-widget-derivatives-prices-chart {
  display: block;
  position: relative;
  /* WIDGET-SCOPE OVERWRITE */
  /* TODO: REMOVE WHEN DDS2 STATE TABS IS READY TO BE IMPLEMENTED IN TITAN */ }
  sgx-widget-derivatives-prices-chart .sgx-product-price-chart-header > div {
    display: flex;
    align-items: center;
    padding-bottom: 15px; }
  sgx-widget-derivatives-prices-chart .sgx-product-price-chart-header + div .sgx-price-card {
    margin-bottom: 15px; }
  sgx-widget-derivatives-prices-chart .sgx-product-price-chart-main-content.sgx-product-price-chart-main-content--hidden {
    display: none; }
  sgx-widget-derivatives-prices-chart .widget-derivatives-prices-and-chart-contract-month-dropdown-container.widget-derivatives-prices-and-chart-contract-month-dropdown-container--hidden {
    display: none; }
  sgx-widget-derivatives-prices-chart .sgx-product-price-chart-table {
    table-layout: fixed;
    margin-bottom: 20px; }
    @media (min-width: 1024px) {
      sgx-widget-derivatives-prices-chart .sgx-product-price-chart-table {
        margin-bottom: 0; } }
  sgx-widget-derivatives-prices-chart .sgx-product-price-chart-container {
    position: relative; }
    sgx-widget-derivatives-prices-chart .sgx-product-price-chart-container sgx-chart {
      height: 300px; }
  sgx-widget-derivatives-prices-chart .sgx-product-price-chart-wrapper {
    position: relative; }
  sgx-widget-derivatives-prices-chart .sgx-product-price-chart-prices-wrapper--hide, sgx-widget-derivatives-prices-chart .sgx-product-price-chart-wrapper--hide {
    display: none; }
  sgx-widget-derivatives-prices-chart .widget-derivatives-prices-and-chart-dropdown {
    width: 100%; }
  sgx-widget-derivatives-prices-chart .widget-derivatives-prices-and-chart-contract-month-dropdown-container {
    margin-left: auto; }
  sgx-widget-derivatives-prices-chart .sgx-product-price-chart-symbol-dropd {
    margin-left: auto; }
  sgx-widget-derivatives-prices-chart .sgx-product-price-chart-symbol-dropd-title {
    font-size: 13px;
    padding-bottom: 5px; }
  sgx-widget-derivatives-prices-chart .sgx-product-price-chart-date {
    color: #6f7072;
    float: right; }
  sgx-widget-derivatives-prices-chart sgx-state-tabs {
    background-color: white;
    box-shadow: inset 0 -1px 0 0 #d7d7d8; }

sgx-widget-account-aliases {
  display: block;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 32px calc(108px + 32px); }
  sgx-widget-account-aliases sgx-table {
    font-size: 14px; }
  sgx-widget-account-aliases sgx-table-row {
    position: relative; }
  sgx-widget-account-aliases sgx-table-list {
    background-size: 100% 70px !important; }

.sgx-widget-account-aliases-row-highlight {
  background: #dbdee9 !important; }

.sgx-widget-account-aliases-sub-header {
  color: #6f7072;
  font-size: 14px;
  margin-bottom: 16px; }

.sgx-widget-account-aliases-controls {
  position: absolute;
  display: none;
  right: 0px; }

.sgx-widget-account-aliases-edit-control {
  display: inline;
  margin-right: 20px; }
  .sgx-widget-account-aliases-edit-control:before {
    icon-font: url(sgx-base-code/svgs/regular/pencil-alt.svg);
    padding-right: 4px; }
  .sgx-widget-account-aliases-edit-control:hover {
    cursor: pointer; }

.sgx-widget-account-aliases-delete-control {
  display: inline;
  margin-right: 20px; }
  .sgx-widget-account-aliases-delete-control:before {
    icon-font: url(sgx-base-code/svgs/regular/trash-alt.svg);
    padding-right: 4px; }
  .sgx-widget-account-aliases-delete-control:hover {
    cursor: pointer; }
  .sgx-widget-account-aliases-delete-control[data-visible='false'] {
    display: none; }

.sgx-widget-account-aliases-table {
  height: 60vh; }
  .sgx-widget-account-aliases-table sgx-table-row:hover .sgx-widget-account-aliases-controls {
    display: flex; }

#sgx-account-alias-edit-dialog .sgx-dialog-body,
#sgx-account-alias-edit-dialog .sgx-dialog-footer,
#sgx-account-alias-edit-dialog .sgx-dialog-box {
  background-color: #f7f7f7; }

#sgx-account-alias-edit-dialog .sgx-dialog-box {
  min-width: 432px;
  padding: 24px 20px 20px 20px; }

#sgx-account-alias-edit-dialog sgx-input-text {
  width: 100%; }

#sgx-account-alias-edit-dialog .sgx-dialog-footer {
  padding-bottom: 0px;
  padding-right: 0px; }

.sgx-account-alias-dialog-header {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px; }

sgx-widget-routing-groups-editor {
  display: block;
  background-color: white;
  height: 85vh;
  padding: 32px calc(108px + 32px);
  overflow-y: auto; }

.routing-groups-editor-header {
  position: relative;
  width: calc(100% - (108px + 32px)/2); }

.routing-groups-editor-add-button {
  position: absolute;
  right: 0px;
  bottom: 0px;
  line-height: 36px; }

.routing-groups-editor-title {
  color: #1d1d1d;
  font-weight: semibold;
  margin-bottom: 24px; }

.routing-groups-editor-companies-cell {
  position: relative; }

.routing-groups-editor-companies-text {
  position: absolute;
  left: 0px;
  top: 0px; }

.routing-groups-editor-row-controls {
  display: none;
  position: absolute;
  height: 20px;
  right: 0px;
  top: 0px;
  margin: 0;
  padding: 0; }

.routing-groups-editor-edit-control {
  display: inline;
  padding-left: 8px;
  padding-right: 8px; }
  .routing-groups-editor-edit-control:before {
    icon-font: url(sgx-base-code/svgs/regular/pencil-alt.svg);
    padding-right: 4px; }
  .routing-groups-editor-edit-control:hover {
    cursor: pointer; }

.routing-groups-editor-delete-control {
  display: inline;
  padding-left: 8px;
  padding-right: 16px; }
  .routing-groups-editor-delete-control:before {
    icon-font: url(sgx-base-code/svgs/regular/trash-alt.svg);
    padding-right: 4px; }
  .routing-groups-editor-delete-control:hover {
    cursor: pointer; }

#routing-groups-editor-content-table {
  width: calc(100% - (108px + 32px)/2); }
  #routing-groups-editor-content-table table {
    outline: 1px solid #d7d7d8; }
  #routing-groups-editor-content-table td {
    white-space: pre; }
  #routing-groups-editor-content-table tr td:first-child {
    width: 290px; }
  #routing-groups-editor-content-table tr:hover .routing-groups-editor-row-controls {
    display: flex; }

.routing-groups-editor-name-input {
  width: 400px;
  margin-bottom: 20px; }

.routing-groups-editor-dialog-contents {
  padding: 20px 20px;
  color: #1d1d1d; }

sgx-widget-mobile-order-book {
  display: flex; }
  sgx-widget-mobile-order-book sgx-contract-view {
    flex: 1 1 30%;
    font-size: 12px;
    background-color: inherit !important; }
    sgx-widget-mobile-order-book sgx-contract-view .sgx-t-volume,
    sgx-widget-mobile-order-book sgx-contract-view .sgx-last-price,
    sgx-widget-mobile-order-book sgx-contract-view .sgx-strike-price {
      color: rgba(0, 0, 0, 0.6) !important; }
    sgx-widget-mobile-order-book sgx-contract-view .sgx-last-price {
      padding-right: 4px !important; }
    sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-list {
      background-image: none; }
    sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-item {
      height: 50px;
      min-height: initial;
      padding-top: 8px;
      padding-left: 8px;
      padding-bottom: 8px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.09); }
    sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-icon {
      height: 37px;
      position: relative; }
      sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-icon svg {
        position: absolute;
        right: 8px;
        width: 5px;
        height: 10px; }
    sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-row-wrapper {
      width: 100%; }
      sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-row-wrapper .sgx-contract-view-text {
        display: inline-block;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px !important;
        white-space: nowrap;
        font-size: 12px; }
      sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-row-wrapper .sgx-contract-view-strike-price {
        font-size: 12px;
        white-space: nowrap;
        padding-left: 8px;
        color: rgba(0, 0, 0, 0.6); }
      sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-row-wrapper .sgx-contract-view-last-price {
        flex: 1 1 52.25px;
        font-size: 12px;
        white-space: nowrap;
        min-width: 52.25px;
        max-width: 52.25px; }
        sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-row-wrapper .sgx-contract-view-last-price .sgx-contract-view-last-price-label {
          font-size: 10px; }
        sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-row-wrapper .sgx-contract-view-last-price .sgx-contract-view-last-price-value {
          color: rgba(0, 0, 0, 0.6); }
      sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-row-wrapper .sgx-contract-view-total-volume {
        flex: 1 1 auto;
        font-size: 12px;
        white-space: nowrap; }
        sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-row-wrapper .sgx-contract-view-total-volume .sgx-contract-view-total-volume-label {
          font-size: 10px; }
        sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-row-wrapper .sgx-contract-view-total-volume .sgx-contract-view-total-volume-value {
          color: rgba(0, 0, 0, 0.6); }
    sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-item--wrapper {
      min-height: calc(50px - 2 * 8px);
      max-height: calc(50px - 2 * 8px);
      flex-wrap: nowrap;
      padding-left: 0px;
      padding-top: 0px; }
      sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-item--wrapper[data-last-price-color="up"] .sgx-contract-view-last-price-value {
        color: #72af00; }
      sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-item--wrapper[data-last-price-color="down"] .sgx-contract-view-last-price-value {
        color: #f30643; }
    sgx-widget-mobile-order-book sgx-contract-view .sgx-contract-view-header {
      padding-left: 8px;
      background-color: #efefef;
      border-top: solid 1px rgba(0, 0, 0, 0.09);
      border-bottom: solid 1px rgba(0, 0, 0, 0.09); }
    sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="true"][data-landscape-view="false"] .sgx-contract-view-icon {
      height: 15px; }
    sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="true"][data-landscape-view="false"] .sgx-contract-view-row-wrapper .sgx-contract-view-row:nth-of-type(1) {
      margin-top: -2px; }
    sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="true"][data-landscape-view="false"] .sgx-contract-view-row-wrapper .sgx-contract-view-row:nth-of-type(2) {
      padding-top: 4px; }
    sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="true"][data-landscape-view="false"] .sgx-contract-view-strike-price {
      padding-top: 1px; }
    sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="true"] .sgx-contract-view-header {
      padding: 0.5rem 0.5rem 0.5rem 8px;
      height: calc(2 * 2rem - 14px); }
    sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="true"] .sgx-contract-view-header-row {
      height: 2rem;
      white-space: nowrap; }
    sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="true"][data-landscape-view="true"] .sgx-contract-view-item--wrapper, sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="false"] .sgx-contract-view-item--wrapper {
      line-height: calc(50px - 2 * 8px); }
    sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="true"][data-landscape-view="true"] .sgx-contract-view-header, sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="false"] .sgx-contract-view-header {
      height: 2rem;
      padding: 0 0 0 8px; }
      sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="true"][data-landscape-view="true"] .sgx-contract-view-header .sgx-contract-view-header-row .sgx-contract, sgx-widget-mobile-order-book sgx-contract-view[data-detail-mode="false"] .sgx-contract-view-header .sgx-contract-view-header-row .sgx-contract {
        line-height: 2rem;
        white-space: nowrap; }
  sgx-widget-mobile-order-book .mobile-order-book-orders {
    flex: 2 2 70%;
    display: flex;
    flex-direction: column; }
    sgx-widget-mobile-order-book .mobile-order-book-orders sgx-list {
      height: 100%;
      border-top: 0px;
      border-bottom: 0px;
      border-left: 0px;
      border-left-color: rgba(0, 0, 0, 0.09);
      border-right-color: transparent;
      background-color: inherit; }

sgx-widget-news {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  min-height: 0; }
  sgx-widget-news sgx-list {
    display: flex;
    flex-grow: 1;
    border: none;
    padding: 0px; }
    sgx-widget-news sgx-list .rows-container {
      border-top: solid 1px rgba(0, 0, 0, 0.09); }
  sgx-widget-news .sgx-widget-news-wrapper {
    display: flex;
    flex-grow: 1;
    width: 100%; }
  sgx-widget-news .sgx-widget-news-footer {
    padding-top: 12px;
    display: none; }

.sgx-widget-performance-tracker-header-description,
.sgx-widget-performance-tracker-progress-bar {
  margin-bottom: 48px; }

.sgx-widget-performance-tracker-header-title {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px; }

.sgx-widget-performance-tracker-header-description {
  color: rgba(0, 0, 0, 0.6); }

.sgx-widget-performance-tracker-caption {
  color: rgba(0, 0, 0, 0.6); }

.sgx-widget-performance-tracker-separator {
  border: solid 1px rgba(0, 0, 0, 0.09); }

.sgx-widget-performance-tracker-disclaimer {
  color: rgba(0, 0, 0, 0.6); }

#performance-tracker-accounts-list {
  display: flex;
  flex-grow: 1;
  border: none;
  padding: 0px; }

sgx-widget-publications {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  min-height: 0; }
  sgx-widget-publications sgx-list {
    display: flex;
    flex-grow: 1;
    border: none;
    padding: 0px; }
    sgx-widget-publications sgx-list .rows-container {
      border-top: solid 1px rgba(0, 0, 0, 0.09); }
  sgx-widget-publications .sgx-widget-publications-wrapper {
    display: flex;
    flex-grow: 1;
    width: 100%; }
  sgx-widget-publications .sgx-widget-publications-footer {
    padding-top: 12px;
    display: none; }

sgx-widget-jobs {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  min-height: 0; }
  sgx-widget-jobs sgx-list {
    display: flex;
    flex-grow: 1;
    border: none;
    padding: 0px; }
    sgx-widget-jobs sgx-list .rows-container {
      border-top: solid 1px rgba(0, 0, 0, 0.09); }
  sgx-widget-jobs .sgx-widget-jobs-wrapper {
    display: flex;
    flex-grow: 1;
    width: 100%; }
  sgx-widget-jobs .sgx-widget-jobs-footer {
    padding-top: 12px;
    display: none; }

sgx-widget-idb-ranking {
  display: flex;
  flex-direction: column; }
  sgx-widget-idb-ranking .sgx-widget-idb-ranking-product {
    display: flex;
    flex-direction: row;
    height: 36px; }
    sgx-widget-idb-ranking .sgx-widget-idb-ranking-product sgx-input-select input {
      text-align: left !important; }
    sgx-widget-idb-ranking .sgx-widget-idb-ranking-product .sgx-widget-idb-ranking-product-period {
      line-height: 36px;
      margin-left: 12px;
      color: rgba(0, 0, 0, 0.6); }
  sgx-widget-idb-ranking .sgx-widget-idb-ranking-list-wrapper {
    height: calc(100% - 36px - 28px); }
  sgx-widget-idb-ranking[data-adaptive-mode='mobile'] .sgx-widget-idb-ranking-list-wrapper {
    height: calc(100% - 2 * 36px - 28px); }
  sgx-widget-idb-ranking[data-adaptive-mode='mobile'] .sgx-widget-idb-ranking-product {
    flex-direction: column;
    height: calc(2 * 36px); }
  sgx-widget-idb-ranking sgx-list-simple-row:nth-of-type(1) {
    border-top: solid 1px rgba(0, 0, 0, 0.09); }
  sgx-widget-idb-ranking .sgx-widget-idb-ranking-list-footer {
    padding-top: 12px;
    text-align: right;
    height: 28px; }
    sgx-widget-idb-ranking .sgx-widget-idb-ranking-list-footer span {
      color: rgba(0, 0, 0, 0.6);
      margin-right: 8px; }
  sgx-widget-idb-ranking sgx-list {
    border: none;
    height: 100%; }

sgx-widget-announcements {
  display: block;
  font-size: 12px;
  position: relative; }
  sgx-widget-announcements[data-expanded="false"] .sgx-announcements-list-item:nth-child(n + 2) {
    display: none; }
  sgx-widget-announcements[data-expanded="true"] .sgx-announcements-list-item {
    display: flex; }
  sgx-widget-announcements .sgx-announcements-list-item {
    padding-right: 32px; }

.sgx-widget-announcements-expander {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  cursor: pointer; }
  [data-expanded="false"] .sgx-widget-announcements-expander:after {
    icon-font: url("@sgx/sgx-base-code/svgs/solid/chevron-down.svg"); }
  [data-expanded="true"] .sgx-widget-announcements-expander:after {
    icon-font: url("@sgx/sgx-base-code/svgs/solid/chevron-up.svg"); }

sgx-widget-announcements p,
.sgx-widget-announcements-expander p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87); }

sgx-widget-side-panel,
sgx-platts-widget-side-panel {
  display: flex;
  flex-direction: column;
  width: 320px;
  position: fixed;
  top: 0;
  background: white;
  box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.4);
  height: 100%;
  z-index: 1000;
  padding: 20px 12px;
  right: -320px;
  opacity: 0;
  transition: right 0.5s ease-in-out;
  overflow-y: auto; }
  sgx-widget-side-panel.sgx-widget-side-panel--active,
  sgx-platts-widget-side-panel.sgx-widget-side-panel--active {
    display: block;
    right: 0;
    opacity: 1;
    transition: right 0.5s ease-in-out; }
  sgx-widget-side-panel .sgx-side-panel-toggle-btn,
  sgx-platts-widget-side-panel .sgx-side-panel-toggle-btn {
    bottom: 32px;
    left: 12px;
    width: 100%; }
  sgx-widget-side-panel .sgx-widget-side-panel-title,
  sgx-platts-widget-side-panel .sgx-widget-side-panel-title {
    color: rgba(0, 0, 0, 0.87); }
  sgx-widget-side-panel .sgx-widget-side-panel-content-wrapper,
  sgx-platts-widget-side-panel .sgx-widget-side-panel-content-wrapper {
    display: block;
    margin-top: 20px; }
    sgx-widget-side-panel .sgx-widget-side-panel-content-wrapper .sgx-flex-widget-panel-item,
    sgx-platts-widget-side-panel .sgx-widget-side-panel-content-wrapper .sgx-flex-widget-panel-item {
      display: flex;
      height: 35px;
      line-height: 35px;
      background: #f7f7f7;
      border-radius: 2px;
      margin: 4px 0;
      padding-left: 4px;
      color: rgba(0, 0, 0, 0.87);
      text-transform: capitalize;
      position: relative;
      cursor: move; }
      sgx-widget-side-panel .sgx-widget-side-panel-content-wrapper .sgx-flex-widget-panel-item:after,
      sgx-platts-widget-side-panel .sgx-widget-side-panel-content-wrapper .sgx-flex-widget-panel-item:after {
        icon-font: url("@sgx/sgx-base-code/svgs/solid/bars.svg");
        position: absolute;
        right: 10px;
        top: 10px;
        color: rgba(0, 0, 0, 0.34); }
      sgx-widget-side-panel .sgx-widget-side-panel-content-wrapper .sgx-flex-widget-panel-item img,
      sgx-platts-widget-side-panel .sgx-widget-side-panel-content-wrapper .sgx-flex-widget-panel-item img {
        margin: 4px 8px 4px 0;
        width: 54px;
        height: 27px; }
    sgx-widget-side-panel .sgx-widget-side-panel-content-wrapper .sgx-flex-widget-panel-item-partner-icon,
    sgx-platts-widget-side-panel .sgx-widget-side-panel-content-wrapper .sgx-flex-widget-panel-item-partner-icon {
      height: 100%; }

sgx-mobile-widget-order-submission.sgx-order-submission-widget {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute; }

sgx-mobile-widget-order-submission #route-to-groups sgx-text-dropdown .sgx-text-dropdown-header-container p {
  padding: 0px; }
  sgx-mobile-widget-order-submission #route-to-groups sgx-text-dropdown .sgx-text-dropdown-header-container p:before {
    top: 6px; }

sgx-mobile-widget-order-submission #route-to-groups {
  width: 100%; }

sgx-mobile-widget-order-submission sgx-input-checkbox.sgx-route-tc.sgx-route-group-items,
sgx-mobile-widget-order-submission sgx-input-checkbox.sgx-route-br.sgx-route-group-items {
  display: block; }

sgx-mobile-widget-order-submission .sgx-order-submission-widget-wrapper {
  position: relative; }

sgx-mobile-widget-order-submission .sgx-order-submission-widget-view-controls {
  width: 100%; }

sgx-mobile-widget-order-submission .sgx-dynamic-form-row:last-child {
  margin-bottom: 120px; }

sgx-mobile-widget-order-submission .sgx-order-submission-close-button {
  display: inline-block;
  width: 32px;
  height: 32px;
  position: absolute;
  right: 4px;
  top: 4px;
  border: none;
  background-color: transparent; }

sgx-mobile-widget-order-submission .sgx-order-submission-close-button:before {
  font-size: 16px;
  icon-font: url("sgx-base-code/svgs/solid/times.svg"); }

sgx-mobile-widget-order-submission .sgx-order-submission-widget-header-container h3.sgx-order-submission-widget-header {
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  padding-left: 8px;
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffffff; }

sgx-mobile-widget-order-submission .mobile-menu-header {
  background-color: #ffffff; }

sgx-widget-treemap {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: -15px; }
  sgx-widget-treemap .hightcharts-container {
    display: flex;
    justify-content: center;
    flex-direction: column; }
  sgx-widget-treemap .sgx-widget-treemap-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    position: relative; }
    sgx-widget-treemap .sgx-widget-treemap-wrapper .sgx-widget-treemap-inner-wrapper {
      height: 100%;
      width: 100%; }
      sgx-widget-treemap .sgx-widget-treemap-wrapper .sgx-widget-treemap-inner-wrapper--hide {
        display: none; }
      sgx-widget-treemap .sgx-widget-treemap-wrapper .sgx-widget-treemap-inner-wrapper .sgx-treemap-sub-title {
        color: rgba(0, 0, 0, 0.6);
        padding-bottom: 12px;
        line-height: 12px; }
  sgx-widget-treemap .sgx-treemap-content-container {
    height: calc(100% - 80px);
    position: relative; }
  sgx-widget-treemap .sgx-widget-treemap-wrapper .highcharts-legend .highcharts-legend-title text tspan {
    fill: rgba(0, 0, 0, 0.6); }
  sgx-widget-treemap .sgx-widget-treemap-footer {
    display: flex;
    height: 50px;
    width: 100%;
    flex-direction: row;
    padding-top: 12px; }
    sgx-widget-treemap .sgx-widget-treemap-footer .sgx-widget-treemap-legend {
      width: 50%; }
      sgx-widget-treemap .sgx-widget-treemap-footer .sgx-widget-treemap-legend .sgx-widget-treemap-legend-title {
        padding-top: 8px;
        color: rgba(0, 0, 0, 0.6); }
      sgx-widget-treemap .sgx-widget-treemap-footer .sgx-widget-treemap-legend .sgx-widget-treemap-color-axis {
        display: flex;
        flex-direction: row;
        margin-top: 4px; }
        sgx-widget-treemap .sgx-widget-treemap-footer .sgx-widget-treemap-legend .sgx-widget-treemap-color-axis .sgx-widget-treemap-color-axis-suffix,
        sgx-widget-treemap .sgx-widget-treemap-footer .sgx-widget-treemap-legend .sgx-widget-treemap-color-axis .sgx-widget-treemap-color-axis-prefix {
          width: 30px;
          text-align: left;
          line-height: 10px; }
        sgx-widget-treemap .sgx-widget-treemap-footer .sgx-widget-treemap-legend .sgx-widget-treemap-color-axis .sgx-widget-treemap-color-axis-suffix {
          text-align: right; }
        sgx-widget-treemap .sgx-widget-treemap-footer .sgx-widget-treemap-legend .sgx-widget-treemap-color-axis .sgx-widget-treemap-color-axis-gradient {
          width: 220px;
          height: 8px;
          background: linear-gradient(90deg, #9c0824 0%, #9c0824 9.1%, #b41f27 9.1%, #b41f27 18.2%, #cc312b 18.2%, #cc312b 27.3%, #e86753 27.3%, #e86753 36.4%, #fcb4a5 36.4%, #fcb4a5 45.5%, white 45.5%, white 54.5%, #b9d7b7 54.5%, #b9d7b7 63.6%, #74af72 63.6%, #74af72 72.7%, #428f49 72.7%, #428f49 81.8%, #297839 81.8%, #297839 90.9%, #09622a 90.9%, #09622a 100%); }
    sgx-widget-treemap .sgx-widget-treemap-footer .sgx-widget-treemap-timestamp {
      width: 50%;
      text-align: right;
      color: rgba(0, 0, 0, 0.6); }

sgx-widget-treemap .sgx-treemap-content-container sgx-chart {
  width: 100%;
  height: 100%; }

.sgx-widget-performance-tracker-header-description,
.sgx-widget-performance-tracker-progress-bar {
  margin-bottom: 48px; }

.sgx-widget-performance-tracker-header-title {
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 4px; }

.sgx-widget-performance-tracker-header-description {
  color: rgba(0, 0, 0, 0.6); }

.sgx-widget-performance-tracker-caption {
  color: rgba(0, 0, 0, 0.6); }

.sgx-widget-performance-tracker-separator {
  border: solid 1px rgba(0, 0, 0, 0.09); }

.sgx-widget-performance-tracker-disclaimer {
  color: rgba(0, 0, 0, 0.6); }

#performance-tracker-accounts-list {
  display: flex;
  flex-grow: 1;
  border: none;
  padding: 0px; }

sgx-widget-advert .sgx-widget-advert-content-text,
sgx-widget-advert .sgx-widget-advert-content-image,
sgx-widget-advert .sgx-widget-advert-content-video {
  max-width: 100%;
  position: relative; }
  sgx-widget-advert .sgx-widget-advert-content-text::after,
  sgx-widget-advert .sgx-widget-advert-content-image::after,
  sgx-widget-advert .sgx-widget-advert-content-video::after {
    display: block;
    content: '';
    padding-bottom: 50%; }

sgx-widget-advert .sgx-widget-advert-header-wrapper {
  height: calc(5rem + 8px); }

sgx-widget-advert .sgx-widget-advert-header {
  overflow: hidden;
  padding: 0 8px 8px 8px;
  display: block;
  max-height: 5rem;
  height: 5rem; }
  sgx-widget-advert .sgx-widget-advert-header [class*=text-h] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  sgx-widget-advert .sgx-widget-advert-header > *:not([class*=text-h]) {
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: block;
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3rem; }

sgx-widget-advert .sgx-widget-advert-content-image .sgx-widget-advert-content-image-content {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

sgx-widget-advert .sgx-widget-advert-content-image .sgx-widget-advert-content-image-link {
  display: block;
  text-decoration: none;
  height: 100%; }

sgx-widget-advert .sgx-widget-advert-content-image-content {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

sgx-widget-advert .sgx-widget-advert-content-text-content {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 8px 8px 8px;
  margin-top: -8px; }

sgx-widget-rich-text > * {
  font-size: 14px;
  margin: 0.4rem 0 0.4rem .4rem;
  line-height: 1.25rem; }

sgx-widget-rich-text [class*=text-h] {
  font-size: 18px;
  font-weight: 600;
  margin-top: 0.8rem; }
  sgx-widget-rich-text [class*=text-h] a:not(:hover):not(:focus):not(:active) {
    color: unset; }

sgx-widget-rich-text p.text-caption {
  color: rgba(0, 0, 0, 0.6); }

sgx-widget-rich-text a {
  text-decoration: none; }
  sgx-widget-rich-text a:hover, sgx-widget-rich-text a:focus, sgx-widget-rich-text a:active {
    text-decoration: underline; }

sgx-widget-partner-metrics .sgx-widget-partner-metrics-description {
  display: block;
  margin-bottom: 20px;
  font-size: 14px; }
  sgx-widget-partner-metrics .sgx-widget-partner-metrics-description > :first-child,
  sgx-widget-partner-metrics .sgx-widget-partner-metrics-description > :first-child > :first-child {
    margin-top: 0; }
  sgx-widget-partner-metrics .sgx-widget-partner-metrics-description > :last-child,
  sgx-widget-partner-metrics .sgx-widget-partner-metrics-description > :last-child > :last-child {
    margin-bottom: 0; }
  sgx-widget-partner-metrics .sgx-widget-partner-metrics-description > *,
  sgx-widget-partner-metrics .sgx-widget-partner-metrics-description > * > * {
    margin-left: 0; }

sgx-widget-partner-metrics .sgx-widget-partner-metrics-tabs {
  margin-bottom: 20px; }
  sgx-widget-partner-metrics .sgx-widget-partner-metrics-tabs .sgx-tabs-container :first-child {
    margin-left: 0; }

sgx-widget-partner-metrics .sgx-table-header-wrapper > .sgx-table-header-element,
sgx-widget-partner-metrics .sgx-table-cell {
  padding-left: 12px;
  padding-right: 12px; }

sgx-widget-partner-metrics .sgx-widget-partner-metrics-table-wrapper {
  position: relative; }

sgx-widget-partner-metrics-table {
  position: relative; }
  sgx-widget-partner-metrics-table sgx-table-toolbar {
    display: none; }
  sgx-widget-partner-metrics-table sgx-scroll-shield {
    top: 2rem; }
  sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container {
    position: relative; }
    sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-content {
      height: 100%; }
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-content .sgx-widget-partner-metrics-table-table {
        height: 100%; }
    sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom {
      margin-top: 20px;
      display: flex; }
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom .sgx-widget-partner-metrics-table-footer,
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom .sgx-widget-partner-metrics-table-footer p,
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom .sgx-widget-partner-metrics-table-timestamp {
        font-size: 12px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.6);
        flex: 0 1 50%; }
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom .sgx-widget-partner-metrics-table-footer > :first-child,
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom .sgx-widget-partner-metrics-table-footer > :first-child > :first-child {
        margin-top: 0; }
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom .sgx-widget-partner-metrics-table-footer > :last-child,
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom .sgx-widget-partner-metrics-table-footer > :last-child > :last-child {
        margin-bottom: 0; }
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom .sgx-widget-partner-metrics-table-footer > *,
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom .sgx-widget-partner-metrics-table-footer > * > * {
        margin-left: 0; }
      sgx-widget-partner-metrics-table .sgx-widget-partner-metrics-table-container .sgx-widget-partner-metrics-table-bottom .sgx-widget-partner-metrics-table-timestamp {
        text-align: right; }

sgx-widget-community-admin-user-blotter .sgx-widget-community-admin-user-blotter-title h2 {
  font-size: 20px;
  font-family: Lato, sans-serif;
  color: #1d1d1d; }

sgx-widget-community-admin-user-blotter .sgx-widget-community-admin-user-blotter-title {
  padding-bottom: 20px; }

sgx-widget-community-admin-user-blotter sgx-table sgx-state-tabs {
  background: none;
  padding-left: 16px; }

sgx-widget-community-admin-user-blotter sgx-table {
  height: calc(100% - 64px); }

sgx-alert-dialog.sgx-user-blotter-config-dialog--desktop .sgx-alert-dialog-container {
  min-width: 600px;
  max-width: 600px; }

sgx-alert-dialog.sgx-user-blotter-config-dialog--mobile .sgx-alert-dialog-container {
  min-width: 90%;
  max-width: 400px; }

sgx-alert-dialog.sgx-user-blotter-config-dialog--mobile .sgx-user-blotter-confirmation-content {
  max-width: 350px; }

sgx-alert-dialog.sgx-user-blotter-config-dialog--success .sgx-alert-dialog-content h2 i:before {
  margin-right: 8px;
  vertical-align: baseline;
  color: #bdd832;
  icon-font: url("sgx-base-code/svgs/solid/check-circle.svg"); }

sgx-alert-dialog .sgx-user-blotter-confirmation-content .sgx-user-blotter-action-reason-input .sgx-input-textarea-input {
  width: 100%;
  height: 150px;
  max-height: 150px;
  margin-top: 16px; }

sgx-alert-dialog .sgx-user-blotter-confirmation-content .sgx-user-blotter-action-reason-input {
  width: 100%; }

sgx-alert-dialog.sgx-user-blotter-config-dialog .sgx-alert-dialog-body {
  padding-left: 0px;
  padding-right: 0px; }

.sgx-user-blotter-config-dialog--error .sgx-alert-dialog-content .sgx-user-blotter-confirmation-content i:before {
  margin-right: 8px;
  vertical-align: baseline;
  color: #FF0000;
  icon-font: url("sgx-base-code/svgs/solid/times-circle.svg"); }

.sgx-user-blotter-confirmation-content sgx-input-textarea.sgx-user-blotter-action-reason-input textarea {
  resize: none; }

body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter .sgx-widget-community-admin-user-blotter-title {
  display: none; }

body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table sgx-table-toolbar .table-action.filters {
  display: none; }

body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table .table-container sgx-table-column-headers-manager .sgx-table-header-container {
  height: auto; }

body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table sgx-table-list sgx-table-row {
  height: auto;
  align-content: flex-start; }

body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table sgx-table-list sgx-table-row sgx-table-cell-action-menu .sgx-table-cell-menu-text {
  margin-left: 0px; }

body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table sgx-table-list sgx-table-row .sgx-table-cell {
  color: #222222; }

body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table .table-container {
  height: calc(100vh - 200px);
  overflow-y: auto; }

body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table .table-container .sgx-table-show-more-row-button {
  background-color: white;
  color: #222222; }

body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table sgx-table-cell-action-menu .sgx-table-cell-menu-kebab-icon {
  left: 0px;
  top: -10px;
  width: 10px;
  height: 40px; }
  body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table sgx-table-cell-action-menu .sgx-table-cell-menu-kebab-icon svg {
    fill-opacity: 100;
    fill: #999; }

body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table sgx-state-tabs {
  padding-left: 0px; }
  body:not([data-type='desktop']) sgx-widget-community-admin-user-blotter sgx-table sgx-state-tabs .sgx-state-tabs-scroll-wrapper {
    overflow-x: hidden !important; }

sgx-widget-community-admin-user-blotter sgx-table-cell-text[data-column-id="jobRole"], sgx-widget-community-admin-user-blotter sgx-table-cell-text[data-column-id="companyType"], sgx-widget-community-admin-user-blotter sgx-table-cell-text[data-column-id="country"], sgx-widget-community-admin-user-blotter sgx-table-cell-text[data-column-id="status"] {
  text-transform: capitalize; }

sgx-platts-widget-metals-alert-row {
  display: block;
  max-height: 52px; }
  sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container {
    text-align: left;
    padding: 8px 12px; }
    sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container > *:not(:last-child) {
      margin-bottom: 8px; }
    sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container .sgx-platts-widget-metals-alert-row-headline {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 4px;
      max-width: 386px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container .sgx-platts-widget-metals-alert-row-metadata {
      position: relative;
      font-size: 12px;
      line-height: 16px;
      margin-right: 8px;
      color: #777777; }
      sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container .sgx-platts-widget-metals-alert-row-metadata > :first-child {
        display: inline-block; }
        sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container .sgx-platts-widget-metals-alert-row-metadata > :first-child > * {
          margin-right: 12px; }
      sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container .sgx-platts-widget-metals-alert-row-metadata > :first-child {
        background: -webkit-linear-gradient(0.25turn, #6c6c6c, 100%, transparent);
        background: linear-gradient(0.25turn, #6c6c6c, 100%, transparent);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        overflow: hidden;
        white-space: nowrap; }
      sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container .sgx-platts-widget-metals-alert-row-metadata.sgx-platts-widget-metals-alert-row-flash > :first-child {
        background: -webkit-linear-gradient(0.25turn, #6c6c6c, 85%, transparent);
        background: linear-gradient(0.25turn, #6c6c6c, 85%, transparent);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent; }
      sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container .sgx-platts-widget-metals-alert-row-metadata.sgx-platts-widget-metals-alert-row-flash > :last-child {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 1px 4px;
        font-size: 9px;
        font-weight: 200; }
    sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container .sgx-platts-widget-metals-alert-row-flash-tag {
      height: 15px;
      border-radius: 1px;
      background-color: #0077CC;
      color: #fff;
      text-align: center;
      line-height: 15px;
      font-size: 11px; }
    sgx-platts-widget-metals-alert-row .sgx-platts-widget-metals-alert-row-container .sgx-platts-widget-metals-alert-row-flash-tag--hidden {
      display: none; }
  sgx-platts-widget-metals-alert-row.sgx-platts-widget-metals-alert-row--selected {
    background-color: #d9eff4; }

sgx-platts-widget-metals-alert-view {
  text-align: left; }
  sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-header {
    border-bottom: 1px solid rgba(127, 127, 127, 0.2);
    padding: 12px; }
    sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-header .sgx-platts-widget-metals-alert-view-headline {
      font-size: 16px;
      font-weight: 700;
      padding: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-header .sgx-platts-widget-metals-alert-view-metadata {
      position: relative;
      font-size: 12px;
      line-height: 16px;
      color: #777777; }
      sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-header .sgx-platts-widget-metals-alert-view-metadata > :first-child {
        display: inline-block; }
        sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-header .sgx-platts-widget-metals-alert-view-metadata > :first-child > * {
          padding: 4px; }
      sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-header .sgx-platts-widget-metals-alert-view-metadata .sgx-platts-widget-metals-alert-view-source {
        font-weight: 800; }
      sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-header .sgx-platts-widget-metals-alert-view-metadata.sgx-platts-widget-metals-alert-view-flash > :last-child {
        display: inline-block;
        position: absolute;
        right: 0;
        padding: 1px 4px;
        font-size: 9px;
        font-weight: 200; }
    sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-header .sgx-platts-widget-metals-alert-view-flash-tag {
      height: 15px;
      border-radius: 1px;
      background-color: #0077CC;
      text-align: center;
      line-height: 15px;
      font-size: 11px;
      color: #ffffff; }
    sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-header .sgx-platts-widget-metals-alert-view-flash-tag--hidden {
      display: none; }
  sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-html {
    padding: 8px; }
    sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-html sgx-widget-rich-text {
      white-space: pre-wrap;
      font-family: Consolas, Menlo, monospace; }
    sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-html .sgx-platts-widget-metals-alert-view-banner {
      text-align: center;
      margin-top: 40px;
      display: inline-block;
      width: 100%; }

#sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-box {
  padding: 20px; }
  #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-box .sgx-platts-widget-metal-alert-filters-dialog-buttons-container {
    text-align: right;
    margin-top: 40px; }
    #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-box .sgx-platts-widget-metal-alert-filters-dialog-buttons-container button {
      margin: 0;
      margin-left: 20px; }
  #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-box .sgx-dialog-footer {
    display: none; }

body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container {
  margin: 16px;
  color: #222222; }
  body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form,
  body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row,
  body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > * {
    border: 8px inset transparent;
    border-collapse: collapse;
    border-spacing: 0;
    line-height: 40px; }
  body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > *:not(:last-child) {
      margin-right: 16px; }
    body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > * .sgx-filter-form-cell-inner {
      width: 80px; }
    body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > *.sgx-filter-form-cell-label .sgx-filter-form-cell-inner {
      text-align: right;
      width: 130px; }
    body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > *.sgx-filter-form-cell-operation .sgx-filter-form-cell-inner {
      width: 100px; }
      body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > *.sgx-filter-form-cell-operation .sgx-filter-form-cell-inner input[type='text'] {
        background: transparent;
        border: none;
        text-align: right; }
    body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > *.sgx-filter-form-cell-input .sgx-filter-form-cell-input-container > * {
      width: 310px; }
    body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > *.sgx-filter-form-cell-input .sgx-filter-form-cell-input-container > sgx-input-number {
      width: 155px; }
    body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > *.sgx-filter-form-cell-input .sgx-filter-form-cell-input-hint {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.6);
      margin: 0; }
      body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > *.sgx-filter-form-cell-input .sgx-filter-form-cell-input-hint .sgx-filter-form-cell-input-hint-inner {
        margin-top: -4px;
        margin-bottom: -8px; }
    body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row > * .sgx-filter-form-cell-input-options fieldset > label {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 0; }
  body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-platts-widget-metal-alert-filters-dialog-input-keyword .sgx-filter-form-cell-operation,
  body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-platts-widget-metal-alert-filters-dialog-input-pCategory .sgx-filter-form-cell-operation,
  body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-platts-widget-metal-alert-filters-dialog-input-symbol .sgx-filter-form-cell-operation,
  body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-platts-widget-metal-alert-filters-dialog-input-page .sgx-filter-form-cell-operation,
  body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-platts-widget-metal-alert-filters-dialog-input-daysBack .sgx-filter-form-cell-operation,
  body[data-type='desktop'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-platts-widget-metal-alert-filters-dialog-input-maxHeadlines .sgx-filter-form-cell-operation {
    text-align: right;
    padding-right: 12px; }

body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container {
  margin: 16px;
  color: #222222; }
  body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form,
  body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form > *,
  body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form > * > * {
    display: block; }
  body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.2); }
  body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 12px;
    margin: 0 -16px; }
    body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row .sgx-filter-form-cell-relation {
      display: none; }
    body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row .sgx-filter-form-cell-operation {
      margin-top: 16px; }
    body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row .sgx-filter-form-cell-input {
      margin-top: 8px; }
      body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row .sgx-filter-form-cell-input > * > * {
        width: 100%; }
      body[data-type='mobile'] #sgx-platts-widget-metal-alert-filters-dialog .sgx-dialog-body .sgx-platts-widget-metal-alert-filters-dialog-inputs-container .sgx-filter-form-row .sgx-filter-form-cell-input .sgx-filter-form-cell-input-hint {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        margin: 0;
        margin-top: 8px; }

sgx-platts-widget-metals-alert {
  width: 100%;
  height: 100%;
  text-align: left; }
  sgx-platts-widget-metals-alert .sgx-platts-widget-metals-alert-filters-wrapper-top {
    position: absoulte;
    width: 100%;
    height: 100%; }
  sgx-platts-widget-metals-alert sgx-empty-image-placeholders {
    text-align: center; }
  sgx-platts-widget-metals-alert sgx-list-details {
    border: none !important;
    width: 100%;
    height: 100%; }
    sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list sgx-status-indicator {
      background: white; }
    sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-right-icon {
      cursor: pointer; }
      sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-right-icon > * {
        margin-left: 8px; }
    sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-right-icon-filter {
      font-size: 14px;
      position: relative; }
      sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-right-icon-filter:after {
        icon-font: url(sgx-base-code/svgs/regular/sliders-v.svg); }
      sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-right-icon-filter .sgx-list-details-list-toolbar-right-icon-filter-badge {
        font-family: "Lato", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
        color: white;
        border-radius: 100%;
        position: absolute;
        right: -10px;
        top: -6px;
        width: 12px;
        height: 12px;
        background-color: #0094B3;
        padding: 2px;
        font-size: 10px;
        text-align: center; }
      sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-right-icon-filter .sgx-list-details-list-toolbar-right-icon-filter-badge--empty {
        display: none; }
    sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-row:last-child {
      height: 29px;
      color: #ffffff;
      background-color: #666666;
      font-size: 12px;
      font-weight: 300; }
      sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-row:last-child > * > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-row:last-child > :first-child > * {
        margin-right: 16px; }
  sgx-platts-widget-metals-alert .sgx-platts-widget-metals-alert-row-container:not(:first-child) {
    display: none; }

body[data-type='desktop'] sgx-platts-widget-metals-alert .sgx-platts-widget-metals-alert-input-source-initial {
  padding: 8px 12px; }

body[data-type='desktop'] sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-row:last-child > :first-child > * {
  max-width: 120px; }

body[data-type='desktop'] sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list-toolbar-row:last-child > :last-child > * {
  max-width: 210px; }

body[data-type='desktop'] sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-list {
  min-width: 400px; }

body[data-type='desktop'] sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-details {
  flex: 1;
  position: relative; }
  body[data-type='desktop'] sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-details sgx-platts-widget-metals-alert-view {
    height: 100%;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0; }
    body[data-type='desktop'] sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-details sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-container {
      height: 100%; }
      body[data-type='desktop'] sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-details sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-container > * {
        overflow: auto; }
      body[data-type='desktop'] sgx-platts-widget-metals-alert sgx-list-details .sgx-list-details-details sgx-platts-widget-metals-alert-view .sgx-platts-widget-metals-alert-view-container .sgx-platts-widget-metals-alert-view-html {
        height: calc(100% - 65px); }

sgx-platts-widget-metals-data {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative; }

sgx-platts-widget-metals-data .sgx-platts-widget-metals-data-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

sgx-platts-widget-metals-data .sgx-metal-widget-symbol-wrapper {
  height: 100%;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

sgx-platts-widget-metals-data .sgx-metal-widget-switcher-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

sgx-platts-widget-metals-data .sgx-platts-widget-metals-data-wrapper.sgx-wrapper-big .sgx-metal-widget-switcher-wrapper {
  min-width: 600px;
  max-width: 600px; }

sgx-platts-widget-metals-data .sgx-platts-widget-metals-data-wrapper.sgx-wrapper-big .sgx-metal-widget-symbol-wrapper {
  width: calc(100% - 600px); }

sgx-platts-widget-metals-data .sgx-platts-widget-metals-data-wrapper.sgx-wrapper-small .sgx-metal-widget-switcher-wrapper,
sgx-platts-widget-metals-data .sgx-platts-widget-metals-data-wrapper.sgx-wrapper-small .sgx-metal-widget-symbol-wrapper {
  width: 50%;
  max-width: 50%; }

sgx-platts-widget-metals-data .sgx-metal-widget-category-selector {
  width: 100%;
  height: 36px;
  max-height: 36px;
  margin-bottom: 12px;
  text-align: left; }

sgx-platts-widget-metals-data .sgx-metal-widget-category-selector sgx-input-select {
  width: 300px; }

sgx-platts-widget-metals-data sgx-titan-toolbar {
  float: right;
  margin-top: 8px;
  display: inline-block; }

@media screen and (min-width: 1440px) {
  sgx-platts-widget-metals-data .sgx-platts-widget-metals-data-wrapper.sgx-wrapper-normal .sgx-metal-widget-switcher-wrapper {
    min-width: 600px;
    max-width: 600px; }
  sgx-platts-widget-metals-data .sgx-platts-widget-metals-data-wrapper.sgx-wrapper-normal .sgx-metal-widget-symbol-wrapper {
    width: calc(100% - 600px); } }

@media screen and (max-width: 1440px) {
  sgx-platts-widget-metals-data .sgx-platts-widget-metals-data-wrapper.sgx-wrapper-normal .sgx-metal-widget-switcher-wrapper {
    width: 50%;
    max-width: 50%; }
  sgx-platts-widget-metals-data .sgx-platts-widget-metals-data-wrapper.sgx-wrapper-normal .sgx-metal-widget-symbol-wrapper {
    width: 50%;
    max-width: 50%; } }

sgx-widget-platts-subscription {
  width: 100%;
  height: 100vh;
  display: flex;
  margin: 64px auto 0px auto;
  min-height: 100vh;
  max-height: 100vh;
  justify-content: stretch; }

sgx-widget-platts-subscription main {
  position: relative; }

sgx-widget-platts-subscription a.sgx-link-text-content {
  display: contents; }

sgx-widget-platts-subscription sgx-dynamic-form-v2 textarea[name="comments"] {
  resize: none;
  height: 100px; }

.sgx-widget-platts-subscription-container {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 12px; }

body[data-type="mobile"] .sgx-widget-platts-subscription-container {
  width: 100%; }

body[data-type="tablet"] .sgx-widget-platts-subscription-container,
body[data-type="desktop"] .sgx-widget-platts-subscription-container {
  width: 680px; }

.sgx-widget-platts-subscription-container .sgx-dynamic-form {
  width: 100%; }

.sgx-widget-platts-subscription-container .sgx-form {
  width: 100%; }

.sgx-widget-platts-subscription-container .sgx-widget-platts-subscription-page-logo {
  top: 10px;
  left: 10px;
  width: 80px;
  height: 45px;
  display: block;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat; }

.sgx-widget-platts-subscription-container .sgx-widget-platts-subscription-header h2.sgx-widget-platts-subscription-page-title {
  margin: 0px;
  padding: 0;
  color: rgba(0, 0, 0, 0.87); }

.sgx-widget-platts-subscription-container .sgx-widget-platts-subscription-desc {
  padding: 32px 0 0 0; }

.sgx-widget-platts-subscription-container .sgx-widget-platts-subscription-desc p {
  text-align: left;
  line-height: 24px;
  padding: 0 0 8px 0;
  margin: 0px;
  color: rgba(0, 0, 0, 0.6); }

.sgx-widget-platts-subscription-container .sgx-widget-platts-subscription-desc span {
  display: block;
  width: 100%;
  color: rgba(0, 0, 0, 0.6); }

.sgx-widget-platts-subscription-container .sgx-widget-platts-subscription-form-wrapper {
  display: flex;
  justify-content: row;
  width: 100%; }

.sgx-widget-platts-subscription-container .sgx-section {
  margin-top: 32px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(34, 34, 34, 0.2); }
  .sgx-widget-platts-subscription-container .sgx-section sgx-input-email[name='emailAddress'][readonly] input[type='email'] {
    height: 36px;
    padding: 8px 12px;
    color: #1d1d1d;
    background: #f7f7f7;
    border-color: #9fa0a1; }

body[data-type="mobile"] .sgx-widget-platts-subscription-container .sgx-section > * {
  width: 327px;
  margin-top: 20px; }

body[data-type="tablet"] .sgx-widget-platts-subscription-container .sgx-section > *,
body[data-type="desktop"] .sgx-widget-platts-subscription-container .sgx-section > * {
  width: 440px;
  margin-top: 20px; }

body[data-type="mobile"] .sgx-widget-platts-subscription-container .sgx-section {
  padding: 32px 12px; }

body[data-type="tablet"] .sgx-widget-platts-subscription-container .sgx-section,
body[data-type="desktop"] .sgx-widget-platts-subscription-container .sgx-section {
  padding: 32px 108px; }

.sgx-widget-platts-subscription-container a {
  color: #0094b3;
  text-decoration: none; }

.sgx-widget-platts-subscription-container a:hover {
  text-decoration: underline; }

.sgx-widget-platts-subscription-form-wrapper .sgx-section h2 {
  margin: 0px;
  color: rgba(0, 0, 0, 0.87); }

.sgx-widget-platts-subscription-form-wrapper .sgx-section h2:before {
  margin-top: 0px; }

.sgx-widget-platts-subscription-form-wrapper .sgx-section-policy h2 {
  margin-bottom: 32px; }

.sgx-message-dialog .sgx-alert-dialog-content h2 {
  color: rgba(0, 0, 0, 0.87); }

.sgx-message-dialog .sgx-alert-dialog-content h2 i {
  padding-right: 12px; }

.sgx-message-dialog--success .sgx-alert-dialog-content h2 i:before {
  icon-font: url("sgx-base-code/svgs/solid/check-circle.svg");
  color: #bdd832; }

.sgx-message-dialog--error .sgx-alert-dialog-content h2 i:before {
  icon-font: url("sgx-base-code/svgs/solid/times-circle.svg");
  color: #FF0000; }

.sgx-widget-platts-subscription-success-dialog .sgx-alert-dialog-info-message p {
  color: rgba(0, 0, 0, 0.6); }

.sgx-widget-platts-subscription-container .sgx-widget-platts-subscription-form-wrapper .sgx-section-policy .sgx-input-checkbox-label {
  align-items: flex-start; }

.sgx-widget-platts-subscription-container .sgx-widget-platts-subscription-form-controls {
  display: flex;
  padding: 32px 0px;
  justify-content: flex-end; }

.sgx-platts-subscription-dialog .sgx-alert-dialog-info-message {
  padding-top: 12px; }

sgx-widget-platts-subscription .sgx-alert-dialog-body {
  max-width: 600px;
  min-width: 600px;
  padding-left: 0px;
  padding-right: 0px; }

@media (min-width: 1024px) {
  .sgx-dialog-header.sgx-alert-dialog-header {
    margin-bottom: 0px; } }

sgx-widget-generic-partner {
  height: 100%;
  position: relative; }
  sgx-widget-generic-partner .sgx-widget-generic-partner-wrapper {
    display: block;
    width: 100%;
    height: 100%; }
    sgx-widget-generic-partner .sgx-widget-generic-partner-wrapper .sgx-widget-generic-partner-inner-wrapper {
      width: 100%;
      height: 100%; }
      sgx-widget-generic-partner .sgx-widget-generic-partner-wrapper .sgx-widget-generic-partner-inner-wrapper--hide {
        display: none; }

sgx-widget-platts-historical {
  height: 100%;
  min-height: 100%;
  position: relative; }

.sgx-widget-platts-historical {
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column; }

.sgx-widget-platts-historical .sgx-platts-header-container {
  width: 100%;
  min-height: 30px;
  max-height: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px; }

.sgx-widget-platts-historical .sgx-platts-header-container .header-section-left {
  text-align: left; }

.sgx-widget-platts-historical .sgx-platts-header-container .header-section-left {
  text-align: left; }

.sgx-widget-platts-historical .sgx-platts-view-container {
  width: 100%;
  overflow-y: hidden;
  box-sizing: border-box;
  height: calc(100% - 50px);
  min-height: calc(100% - 50px); }

.sgx-widget-platts-historical .sgx-platts-view-container .code-block-container {
  height: 100%;
  position: relative; }

.sgx-widget-platts-historical .sgx-platts-view-container .code-block-container:not(.active) {
  display: none; }

sgx-widget-platts-historical sgx-tabs[data-type='state'] {
  background-color: transparent;
  height: 30px; }

sgx-widget-platts-historical .sgx-platts-header-container sgx-tabs .sgx-tabs-scroll-wrapper {
  background-color: #f7f7f7; }

.sgx-platts-expanded-view-modal .sgx-dialog-box .sgx-platts-dialog-content {
  height: calc(100% - 30px); }

.sgx-platts-expanded-view-modal .sgx-dialog-box .sgx-platts-dialog-content sgx-chart {
  height: 100%; }

.sgx-platts-expanded-view-modal .sgx-market-view-select-header {
  width: 100%; }

sgx-widget-platts-historical sgx-tabs {
  height: 28px; }

sgx-widget-platts-historical sgx-tabs .sgx-tabs-scroll-wrapper {
  border-radius: 2px;
  line-height: 28px; }

sgx-widget-platts-historical sgx-tabs .sgx-tabs-scroll-wrapper ul.sgx-tabs-container {
  border-radius: 2px;
  line-height: 28px; }

sgx-widget-platts-historical sgx-tabs .sgx-tabs-scroll-wrapper ul.sgx-tabs-container li.sgx-tab {
  border-radius: 2px;
  line-height: 28px; }

html {
  height: 100%;
  font-family: "Lato", sans-serif;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OTJBQkFGRUZFOTIyMTFFMEJDRDNEQzkxOTVGOTNBODAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OTJBQkFGRjBFOTIyMTFFMEJDRDNEQzkxOTVGOTNBODAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo5MkFCQUZFREU5MjIxMUUwQkNEM0RDOTE5NUY5M0E4MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo5MkFCQUZFRUU5MjIxMUUwQkNEM0RDOTE5NUY5M0E4MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtrV+I8AAAAQSURBVHjaYvj//z8DQIABAAj8Av7bok0WAAAAAElFTkSuQmCC',sizingMethod=crop);
  zoom: 1;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-text-size-adjust: 100%; }

body {
  height: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  background: #ffffff; }

#page-container {
  height: 100%; }

body::-webkit-scrollbar {
  display: none; }

sgx-flexible-layout .lm_header .lm_tabs {
  width: 100%; }

.website-main-layout {
  display: flex;
  align-items: stretch;
  height: 100%; }

.website-main-container {
  flex: 1 1 auto;
  width: 100%;
  height: 100%; }

.website-main-content {
  opacity: 1;
  transition: opacity 0.1s ease;
  height: 100%; }
  .router-transition-in-progress .website-main-content {
    opacity: 0;
    pointer-events: none; }

#main-container {
  background: #f7f7f7; }

.template {
  background: #f7f7f7;
  height: 100%;
  width: 100%;
  display: block;
  position: relative; }

#page-tabs {
  margin: 0.5rem 0; }

.website-main-background {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
  transition: opacity 0.5s ease;
  opacity: 0;
  text-align: center;
  font-size: 1rem; }
  .router-transition-in-progress .website-main-background {
    opacity: 1;
    transition-delay: 1s;
    z-index: 100; }

.website-main-loader {
  opacity: 0;
  animation-play-state: paused;
  transition: opacity 0.75s; }
  .router-transition-in-progress .website-main-loader {
    opacity: 1;
    animation-play-state: running;
    transition-delay: 1.5s; }

.website-overflow-wrapper {
  overflow-x: auto;
  font-size: 0; }

.website-overflow-content {
  display: inline-block;
  font-size: 1rem;
  min-width: 100%; }

.website-component-hide {
  display: none !important; }

.website-component-hidden {
  visibility: hidden !important; }

body[data-type="mobile"] .website-main-background {
  font-size: 0.8rem; }

#sgx-table-config-dialog {
  font-size: 0.76rem;
  z-index: 10000; }

#sgx-select-dialog {
  font-size: 0.76rem;
  z-index: 100000 !important; }

.table-header-filter-popup-input {
  font-size: 0.9rem;
  width: 100%; }

::selection {
  background: #ddd;
  color: #222222;
  opacity: 1; }

.sgx-card-mobile,
.sgx-card {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 12px;
  background: white;
  margin-bottom: 12px;
  height: 100%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); }
  .sgx-card-mobile .sgx-card-title,
  .sgx-card .sgx-card-title {
    color: #1d1d1d;
    font-size: 20px;
    margin-bottom: 12px; }
  .sgx-card-mobile .sgx-card-subtitle,
  .sgx-card .sgx-card-subtitle {
    color: #6f7072;
    font-size: 14px;
    margin-bottom: 20px; }

.sgx-card-mobile {
  padding: 0px;
  margin-bottom: 4px; }
  .sgx-card-mobile .sgx-card-title {
    font-size: 16px;
    margin-bottom: 4px; }
  .sgx-card-mobile .sgx-card-subtitle {
    font-size: 12px;
    margin-bottom: 12px; }

#sgx-quick-search-dialog .sgx-dialog-overlay,
body[data-analytics-category="Orders"] .sgx-dialog--centered .sgx-dialog-overlay {
  background: rgba(0, 0, 0, 0.2); }

.sgx-header-help-menu {
  width: 64px;
  height: 40px;
  text-decoration: none;
  color: #9da7c4; }
  .sgx-header-help-menu i {
    font-size: 20px; }

body[data-type="mobile"] .sgx-card {
  padding-left: 12px;
  padding-right: 12px; }

body[data-type="tablet"] .sgx-card {
  padding-left: 16px;
  padding-right: 16px; }

.unsupported-browser {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  background: #0b236b;
  z-index: 200;
  line-height: 1.4; }
  .unsupported-browser .sgx-logo {
    width: 128px; }

#unknown-error .unsupported-browser-content {
  height: auto;
  padding-bottom: 4rem; }

#unknown-error .contact-us {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0; }

.unsupported-browser-content {
  position: absolute;
  background: #ffffff;
  width: 600px;
  height: 425px;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  padding: 32px;
  top: 50%;
  margin-top: -212.5px;
  left: 50%;
  margin-left: -300px; }

.unsupported-browser-title {
  font-size: 125%;
  margin: 16px 0;
  font-weight: 600;
  color: #5f6062; }

.browser-detail {
  text-align: center;
  margin: 16px 0; }

.browser-type {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 100px;
  padding: 10px;
  text-decoration: none;
  color: #5f6062; }
  .browser-type span {
    display: block;
    margin-top: 10px; }

.contact-us {
  text-align: center; }

body.no-browser-support .unsupported-browser {
  display: block; }

body.no-browser-support sgx-menu {
  display: none; }

body[data-type='mobile'] .unsupported-browser-content {
  position: relative;
  width: auto;
  height: auto;
  min-height: 100%;
  top: auto;
  left: auto;
  margin-top: auto;
  margin-left: auto; }

body[data-type='mobile'] .browser-type {
  width: 49%; }

.page-tabs {
  font-size: 88%;
  background: #fff;
  border-bottom: 4px solid #f5f5f5;
  margin: 0 1rem;
  display: block; }
  .page-tabs .sgx-tabmenu-link {
    background: transparent; }

body[data-type="tablet"] .page-tabs {
  padding: 0 1rem; }

.page-table-title {
  position: relative;
  font-size: 1rem;
  line-height: 2.5rem;
  border: 1px solid #ddd;
  color: #0B236B;
  background: #f5f5f5 no-repeat;
  background-position: 0.5rem center;
  background-size: 1.5rem;
  padding-left: 2.5rem; }
  body[data-type=mobile] .page-table-title {
    font-size: 0.9rem;
    line-height: 2rem; }

.page-table {
  min-width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc; }
  body[data-type=mobile] .page-table {
    font-size: 0.9rem; }
  .page-table td, .page-table th {
    padding: 0.5rem;
    vertical-align: middle; }
  .page-table th {
    color: white;
    background: #0B236B;
    border-right: 1px solid black;
    border-bottom: 1px solid black; }
  .page-table td {
    color: #5F6062;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
  .page-table tr:nth-child(even) td {
    background: #f5f5f5; }
  .page-table tr:nth-child(odd) td {
    background: #fff; }
  .page-table tr:hover td {
    background-color: rgba(0, 148, 179, 0.1); }

.page-table--prices {
  font-size: 0.875rem; }
  .page-table--prices th, .page-table--prices td {
    text-align: right; }
  .page-table--prices th:first-child,
  .page-table--prices td:first-child {
    text-align: left; }
  body[data-type=mobile] .page-table--prices {
    font-size: 0.8rem; }
  .page-table--prices td.table-decorator-flash {
    position: static !important;
    animation: flash_shadow 3s; }
    .page-table--prices td.table-decorator-flash:after {
      display: none !important; }

@keyframes flash_shadow {
  20% {
    box-shadow: inset 0 0 1.5rem 0px currentColor; } }

.pages-content {
  color: #5F6062;
  font-family: Lato, sans-serif;
  line-height: 1.4;
  text-align: left;
  padding: 12px 20px; }
  .pages-content h3 {
    color: #5F6062;
    font-size: 125%;
    font-weight: bold; }
  .pages-content p {
    padding: 1rem 0; }
  .pages-content img {
    height: auto;
    max-width: 100%; }

body[data-type='tablet'] .pages-content {
  padding: 2rem 1rem; }

body[data-type='mobile'] .pages-content {
  position: relative;
  padding: 1rem;
  height: 100%; }

/* feed item */
.page-feed-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 1rem 10% 0;
  line-height: 1.4; }
  .page-feed-container sgx-feed {
    flex: 1;
    margin: 0 2% 0 0; }
    .page-feed-container sgx-feed:last-child {
      margin: 0; }

body[data-type='tablet'] .page-feed-container {
  padding: 1rem 1rem 0; }

body[data-type='mobile'] .page-feed-container {
  flex-direction: column;
  padding: 1rem 1rem 0; }
  body[data-type='mobile'] .page-feed-container sgx-feed {
    margin: 0.5rem 0; }
    body[data-type='mobile'] .page-feed-container sgx-feed:last-child {
      margin-bottom: 0; }

template-error-page .sgx-card-error {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

template-error-page .sgx-card-error-title {
  font-size: 28px;
  text-align: center;
  padding-bottom: 20px;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: solid 2px rgba(0, 0, 0, 0.34); }

template-error-page .sgx-card-error-desc {
  font-size: 16px;
  text-align: center;
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.87); }

/* page header  */
.page-header {
  background: #efefef; }

.page-header-banner {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 220px; }

.page-header-carousel {
  width: 100%;
  height: auto; }

.page-title {
  line-height: 1.4;
  font-size: 156%;
  font-weight: 400;
  color: #0B236B;
  padding: 1rem 10%; }

.page-title--margin {
  padding-top: 2rem;
  padding-bottom: 2rem; }

body[data-type='tablet'] .page-title {
  padding: 1rem; }

body[data-type='tablet'] .page-title--margin {
  padding-top: 2rem;
  padding-bottom: 2rem; }

body[data-type='mobile'] .page-header-banner,
body[data-type='mobile'] .page-header-carousel {
  display: none; }

body[data-type='mobile'] .page-title {
  padding: 0.5rem 1rem;
  font-size: 120%; }

body[data-type='mobile'] .page-title--margin {
  background: #F5F5F5; }

template-iframe {
  height: inherit;
  overflow: hidden;
  display: none; }
  template-iframe iframe {
    position: relative;
    border: 0;
    width: 100%;
    height: 100%; }

template-nlt-page #nlt-table {
  padding-top: 12px; }
  template-nlt-page #nlt-table sgx-table {
    height: calc(100vh - 160px); }

template-nlt-aggregation-page #nlt-table {
  padding-top: 12px; }
  template-nlt-aggregation-page #nlt-table sgx-table {
    height: calc(100vh - 160px); }

body[data-type="desktop"] template-nlt-aggregation-page,
body[data-type="desktop"] template-nlt-page {
  padding: 0 1rem;
  overflow: hidden; }

body[data-type="mobile"] template-nlt-aggregation-page,
body[data-type="mobile"] template-nlt-page {
  padding: 0 0.5rem; }

body template-trading-page {
  height: 100%;
  font-family: Lato;
  width: 100%;
  display: flex; }

body .sgx-flexible-layout-container {
  height: 100%;
  width: 100%;
  display: block;
  overflow: hidden;
  padding: 1rem;
  padding-right: 2rem; }

body sgx-flexible-layout {
  height: 100%;
  width: 100%;
  display: block; }
  body sgx-flexible-layout sgx-flexible-layout-add-tab {
    background: #f5f5f5;
    transition: color 0.4s ease;
    color: #6b6f80; }
    body sgx-flexible-layout sgx-flexible-layout-add-tab:hover {
      color: #000; }
  body sgx-flexible-layout .sgx-flexible-layout-add-tab-icon:before {
    content: ""; }

body #product-contract-selection-modal-dialog .sgx-dialog-box {
  width: 903px;
  height: 628px; }

body #product-contract-selection-modal-dialog [data-dialog="body"] {
  overflow-x: hidden;
  overflow-y: auto; }
  body #product-contract-selection-modal-dialog [data-dialog="body"] .sgx-product-selector-title {
    color: #222222;
    padding: 1.3rem 0;
    margin-bottom: 0.9rem; }
  body #product-contract-selection-modal-dialog [data-dialog="body"] sgx-product-selector {
    display: flex;
    align-items: center;
    justify-content: center; }

.dashboard-faq-dialog-image-en,
.dashboard-faq-dialog-image-cn {
  width: 100%; }

.dashboard-faq-dialog-title {
  color: rgba(0, 0, 0, 0.87); }

body[data-type="mobile"] template-dashboard-page .lm_content[data-widget="sgx-widget-derivatives-prices-chart"] sgx-card-widget-wrapper,
body[data-type="tablet"] template-dashboard-page .lm_content[data-widget="sgx-widget-derivatives-prices-chart"] sgx-card-widget-wrapper {
  overflow-y: auto; }

template-dashboard-page {
  height: 100%;
  background-color: #f7f7f7;
  overflow: auto;
  position: relative; }
  template-dashboard-page .template-dashboard-add-widget-btn {
    position: absolute;
    top: 0;
    right: 20px;
    margin-top: 12px; }
    template-dashboard-page .template-dashboard-add-widget-btn:before {
      icon-font: url("@sgx/sgx-base-code/svgs/light/plus.svg"); }
  template-dashboard-page .template-dashboard-page-greeting-section {
    padding: 12px 0; }
    body[data-type="mobile"] template-dashboard-page .template-dashboard-page-greeting-section {
      display: none; }
  template-dashboard-page .template-dashboard-page-widgets-section {
    height: 100%; }
    body[data-type="mobile"] template-dashboard-page .template-dashboard-page-widgets-section {
      padding-top: 12px; }
    template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-container {
      margin: 8px 0; }
      template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-container > * {
        overflow: hidden;
        padding: 0; }
    @media (min-width: 768px) {
      template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 8px;
        row-gap: 8px; } }
    @media (max-width: 1023px) {
      template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-container {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 20px;
        grid-auto-flow: column;
        grid-auto-columns: calc(40% - 2 * 8px);
        overflow-x: auto;
        margin-left: -28px;
        margin-right: -28px;
        padding-bottom: 8px;
        margin-bottom: -4px; }
        template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-container::before, template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-container::after {
          content: '';
          width: 20px; } }
    @media (max-width: 767px) {
      template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-container {
        grid-auto-columns: calc(50% - 2 * 8px); } }
    @media (max-width: 599px) {
      template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-container {
        grid-auto-columns: calc(75% - 2 * 8px); } }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-container {
        margin-right: -12px; }
      template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-wrapper {
        display: inline-block;
        width: calc(33% - 5.33333px);
        vertical-align: top;
        margin-right: 8px; } }

@media all and (-ms-high-contrast: none) and (max-width: 1023px), (-ms-high-contrast: active) and (max-width: 1023px) {
  template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-container {
    margin-left: 0;
    margin-right: -8px;
    padding-bottom: 12px; }
  template-dashboard-page .template-dashboard-page-widgets-section .sgx-dashboard-advert-wrapper {
    width: calc(33% - 5.33333px); } }
  template-dashboard-page .template-dashboard-page-title {
    color: #1d1d1d; }
  template-dashboard-page .template-dashboard-page-dashboard-container {
    padding: 0 20px;
    height: auto; }
  template-dashboard-page .template-dashboard-page-dashboard-container.sgx-flexible-layout-container {
    padding-bottom: 20px; }

template-platts-page {
  height: 100%;
  background-color: #f7f7f7;
  overflow: auto;
  position: relative; }
  template-platts-page .template-platts-add-widget-btn {
    position: absolute;
    top: 0;
    right: 20px;
    margin-top: 12px; }
    template-platts-page .template-platts-add-widget-btn:before {
      icon-font: url("@sgx/sgx-base-code/svgs/light/plus.svg"); }
  template-platts-page .template-platts-page-container {
    padding: 0 20px;
    height: 100%;
    padding-bottom: 20px;
    overflow-y: auto; }
  template-platts-page .template-platts-page-header-section {
    padding: 12px 0; }
    template-platts-page .template-platts-page-header-section .template-platts-page-title {
      color: #1d1d1d; }
  template-platts-page .template-platts-page-widgets-section {
    height: 100%; }
    body[data-type="mobile"] template-platts-page .template-platts-page-widgets-section {
      padding-top: 12px; }
  template-platts-page .sgx-platt-item-hide {
    display: none; }
  template-platts-page .sgx-card-widget-kebab {
    cursor: pointer;
    padding: 0 4px;
    font-size: 20px;
    font-family: icon-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-rendering: auto;
    line-height: 1;
    vertical-align: -.125em; }
    template-platts-page .sgx-card-widget-kebab::after {
      content: '\F153';
      color: rgba(255, 255, 255, 0.6); }

template-community-user-page {
  width: 100%;
  height: 100%;
  font-family: Lato;
  display: flex;
  box-sizing: border-box;
  padding: 0px 1rem;
  overflow: hidden; }

template-community-user-page .sgx-card {
  height: calc(100vh - 60px); }

body[data-type='mobile'] template-community-user-page {
  padding: 0px; }

template-platts-admin-enquiry-page {
  width: 100%;
  height: 100%;
  font-family: Lato;
  display: flex;
  box-sizing: border-box;
  padding: 0px 1rem;
  overflow: hidden; }

template-platts-admin-enquiry-page .sgx-card {
  height: calc(100vh - 60px); }

body[data-type='mobile'] template-platts-admin-enquiry-page {
  padding: 0px; }

template-platts-admin-subscription-page {
  width: 100%;
  height: 100%;
  font-family: Lato;
  display: flex;
  box-sizing: border-box;
  padding: 0px 1rem;
  overflow: hidden; }

template-platts-admin-subscription-page .sgx-card {
  height: calc(100vh - 60px); }

body[data-type='mobile'] template-platts-admin-subscription-page {
  padding: 0px; }

template-settings-page {
  height: 100%; }
  template-settings-page .pages-content {
    display: flex;
    height: calc(100% - 45px);
    overflow-y: auto; }
  template-settings-page .sgx-card {
    position: relative;
    padding-left: 20px;
    padding-right: 20px; }
  template-settings-page .sgx-card:last-child {
    margin-left: 20px; }
  template-settings-page .sgx-user-settings-userId .sgx-readonly-text-content {
    text-transform: none; }
  template-settings-page .sgx-user-settings-email .sgx-readonly-text-content {
    text-transform: lowercase; }
  template-settings-page .checkbox-group-container sgx-input-checkbox:not(:first-child) {
    margin-left: 20px; }

body[data-type="mobile"] template-settings-page .pages-content {
  flex-wrap: wrap; }

body[data-type="mobile"] template-settings-page .sgx-card:last-child {
  margin-left: 0; }

body[data-type="tablet"] template-settings-page .pages-content {
  padding-top: 0; }

template-community-settings-page {
  height: 100%; }
  template-community-settings-page .pages-content {
    display: flex;
    height: calc(100% - 45px);
    overflow-y: auto; }
  template-community-settings-page .sgx-card {
    position: relative;
    padding-left: 20px;
    padding-right: 20px; }
  template-community-settings-page .sgx-card:last-child {
    margin-left: 20px; }
  template-community-settings-page .sgx-user-profile-content-chk-mailing-list .sgx-input-label {
    color: #6f7072;
    font-size: 14px; }
  template-community-settings-page .sgx-user-profile-content-parent--hidden {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0 !important;
    padding: 0 !important; }
  template-community-settings-page .sgx-user-profile-content-parent--view sgx-user-profile-content {
    width: 50%; }

body[data-type="mobile"] template-community-settings-page .pages-content {
  flex-wrap: wrap; }

body[data-type="mobile"] template-community-settings-page .sgx-card:last-child {
  margin-left: 0; }

body[data-type="mobile"] template-community-settings-page .sgx-user-profile-content-parent--view sgx-user-profile-content {
  width: 100%; }

body[data-type="tablet"] template-community-settings-page .pages-content {
  padding-top: 0; }

template-account-alias-page .pages-content {
  display: flex;
  height: calc(100vh - 45px); }

template-support-page {
  display: block;
  height: 100%;
  overflow-y: auto; }
  template-support-page .template-support-page-wrapper {
    padding-left: calc(108px + 32px);
    padding-right: calc(108px + 32px); }
  template-support-page sgx-card-widget-wrapper {
    box-shadow: none;
    padding: 0px;
    padding-bottom: 16px; }
    @media screen and (min-width: 992px) {
      template-support-page sgx-card-widget-wrapper sgx-widget-media {
        width: 500px; } }
  template-support-page ul {
    list-style-type: disc;
    list-style-position: inside; }
    template-support-page ul li {
      padding-left: 12px; }
  template-support-page p,
  template-support-page li,
  template-support-page .support-page-strongger-text {
    color: #6f7072;
    font-size: 14px; }
  template-support-page strong {
    color: #1d1d1d;
    font-weight: 600;
    font-size: 16px; }
  template-support-page a {
    text-decoration: underline; }
  template-support-page .support-page-strongger-text {
    font-weight: 700; }
  template-support-page .pages-content p {
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px; }
  template-support-page .pages-content h3,
  template-support-page .pages-content h4,
  template-support-page .pages-content h5 {
    color: #1d1d1d;
    font-weight: 600;
    font-size: 20px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px; }
    template-support-page .pages-content h3 strong,
    template-support-page .pages-content h4 strong,
    template-support-page .pages-content h5 strong {
      color: #1d1d1d;
      font-weight: 600;
      font-size: 20px; }
  template-support-page[data-is-mobile='true'] .template-support-page-wrapper {
    height: auto; }
  template-support-page[data-is-mobile='true'] ul li {
    padding-left: 0px; }
  template-support-page[data-is-mobile='true'] a {
    word-break: break-all; }

template-automated-workflows-page {
  padding: 0 20px; }
  template-automated-workflows-page .template-automated-workflows {
    padding: 0 32px; }
  template-automated-workflows-page .template-automated-workflows-wrapper {
    width: auto; }
  template-automated-workflows-page sgx-table {
    height: 400px; }
    template-automated-workflows-page sgx-table sgx-table-row[data-row-id="0"] {
      background: #e7e7e7;
      border: #c7c7c8; }
  template-automated-workflows-page .template-automated-workflows-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    background: #f7f7f7;
    border: 1px solid #c7c7c8;
    margin-bottom: 8px;
    padding: 8px; }
  template-automated-workflows-page .template-automated-workflows-panel-description {
    font-size: 14px;
    color: #6f7072; }
    template-automated-workflows-page .template-automated-workflows-panel-description p {
      margin-bottom: 8px; }
  template-automated-workflows-page .template-automated-workflows-panel-description-indent {
    margin-left: 12px; }
  template-automated-workflows-page .template-automated-workflows-information-icon:before {
    font-size: 14px;
    margin-right: 8px;
    color: #0077cc;
    icon-font: url("@sgx/sgx-base-code/svgs/regular/info-circle.svg"); }
  template-automated-workflows-page .template-automated-workflows-information {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 8px 12px;
    font-size: 14px;
    color: #565658;
    background: #e6f1fa; }
  template-automated-workflows-page .template-automated-workflows-save {
    display: flex;
    justify-content: flex-end; }
  template-automated-workflows-page button.template-automated-workflows-save-btn {
    width: 240px;
    height: 50px;
    margin-top: 20px;
    font-size: 16px; }

template-mobile-trading-page {
  height: 100%;
  width: 100%;
  font-family: Lato;
  display: flex;
  flex-direction: column; }
  template-mobile-trading-page .pages-content {
    height: 100%;
    padding-top: 0px !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0px !important;
    padding-right: 0px !important; }
  template-mobile-trading-page .mobile-menu-header {
    font-size: 16px;
    font-weight: bold;
    padding-top: 12px;
    padding-left: 8px;
    padding-bottom: 4px;
    color: rgba(0, 0, 0, 0.87);
    background-color: #efefef; }
  template-mobile-trading-page .mobile-order-book-tabs-content {
    width: 100%; }
    template-mobile-trading-page .mobile-order-book-tabs-content .sgx-tabs-container {
      padding-left: 8px; }
      template-mobile-trading-page .mobile-order-book-tabs-content .sgx-tabs-container .sgx-tab:nth-of-type(1) {
        margin-left: 2px; }
  template-mobile-trading-page .mobile-order-book {
    height: 100%;
    padding-top: 4px; }
  template-mobile-trading-page .mobile-order-book-product {
    display: flex; }
    template-mobile-trading-page .mobile-order-book-product sgx-input-select {
      flex: 1 1 60%;
      font-size: 14px;
      min-width: 210px;
      max-width: 230px; }
      template-mobile-trading-page .mobile-order-book-product sgx-input-select input {
        font-size: 14px;
        text-align: left !important;
        padding-left: 8px; }
    template-mobile-trading-page .mobile-order-book-product .mobile-order-book-detail-view {
      flex: 2 2 40%;
      position: relative; }
      template-mobile-trading-page .mobile-order-book-product .mobile-order-book-detail-view span {
        position: absolute;
        right: 0px;
        cursor: pointer;
        color: #222222; }
        template-mobile-trading-page .mobile-order-book-product .mobile-order-book-detail-view span:before {
          position: absolute;
          right: 0.5rem;
          line-height: 36px;
          icon-font: url("@sgx/sgx-base-code/svgs/regular/list-alt.svg");
          font-size: 18px; }
  template-mobile-trading-page .mobile-order-book-no-settings-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  template-mobile-trading-page .mobile-order-book-no-settings-icon {
    margin-top: 72px;
    margin-bottom: 32px; }
    template-mobile-trading-page .mobile-order-book-no-settings-icon:before {
      icon-font: url(sgx-base-code/svgs/regular/cogs.svg);
      font-size: 140px;
      color: #e7e7e7; }
  template-mobile-trading-page .mobile-order-book-no-settings-text {
    width: 60%;
    text-align: center;
    color: #6f7072; }

template-not-mobile-ready-page {
  display: block;
  text-align: center;
  height: 100%;
  overflow-y: auto; }
  template-not-mobile-ready-page .not-mobile-ready-template-page {
    display: flex;
    align-items: flex-start;
    height: 100%;
    margin-bottom: 0px;
    box-shadow: none; }
  template-not-mobile-ready-page .not-mobile-ready-template {
    padding: 2rem;
    width: 100%; }
  template-not-mobile-ready-page .not-mobile-ready-title {
    font-weight: 400;
    font-size: 28px;
    margin-top: 1rem;
    color: #6f7072; }
    template-not-mobile-ready-page .not-mobile-ready-title p:last-child {
      margin-top: 0.8rem;
      margin-bottom: 1rem; }
  template-not-mobile-ready-page .not-mobile-ready-sub-title {
    font-size: 16px;
    color: #6f7072; }
  template-not-mobile-ready-page .sgx-icon-coming-soon {
    display: flex;
    justify-content: center;
    color: #e7e7e7; }
    template-not-mobile-ready-page .sgx-icon-coming-soon:before {
      icon-font: url("@sgx/sgx-base-code/svgs/light/rocket.svg");
      font-size: 140px; }

template-configure-client-permissions-page {
  padding: 0 20px; }
  template-configure-client-permissions-page .template-configure-client-permissions {
    padding: 0 32px; }
  template-configure-client-permissions-page .template-configure-client-permissions-wrapper {
    width: auto; }
  template-configure-client-permissions-page sgx-table {
    height: 400px; }
    template-configure-client-permissions-page sgx-table sgx-table-row[data-row-id="0"] {
      background: #e7e7e7;
      border: #c7c7c8; }
  template-configure-client-permissions-page .template-configure-client-permissions-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 600;
    background: #f7f7f7;
    border: 1px solid #c7c7c8;
    margin-bottom: 8px;
    padding: 8px; }
  template-configure-client-permissions-page .template-configure-client-permissions-panel-description {
    font-size: 14px;
    color: #6f7072; }
    template-configure-client-permissions-page .template-configure-client-permissions-panel-description p {
      margin-bottom: 8px; }
  template-configure-client-permissions-page .template-configure-client-permissions-panel-description-indent {
    margin-left: 12px; }
  template-configure-client-permissions-page .template-configure-client-permissions-information-icon:before {
    font-size: 14px;
    margin-right: 8px;
    color: #0077cc;
    icon-font: url("@sgx/sgx-base-code/svgs/regular/info-circle.svg"); }
  template-configure-client-permissions-page .template-configure-client-permissions-information {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 8px 12px;
    font-size: 14px;
    color: #565658;
    background: #e6f1fa; }
  template-configure-client-permissions-page .template-configure-client-permissions-save {
    display: flex;
    justify-content: flex-end; }
  template-configure-client-permissions-page button.template-configure-client-permissions-save-btn {
    width: 240px;
    height: 50px;
    margin-top: 20px;
    font-size: 16px; }

template-orders-report-page {
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #f7f7f7; }
  template-orders-report-page .template-orders-report-container {
    height: 100%;
    padding: 32px 108px; }
  template-orders-report-page .template-orders-report-page-inner-wrapper {
    padding-left: 32px;
    color: black;
    width: max-content; }
    template-orders-report-page .template-orders-report-page-inner-wrapper .template-orders-report-page-title {
      padding-bottom: 8px;
      font-size: 20px; }
    template-orders-report-page .template-orders-report-page-inner-wrapper .template-orders-report-page-sub-title {
      padding-bottom: 20px;
      font-size: 14px;
      color: #393a3b; }
    template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container {
      display: flex;
      flex-direction: row; }
      template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container .date-picker-separator {
        padding-left: 12px;
        padding-right: 12px; }
      template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label {
        display: flex;
        flex-direction: row;
        align-items: center; }
        template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label .sgx-input-label {
          color: black; }
        template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label input {
          width: 324px; }
        template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label span {
          padding-right: 12px; }
        template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label .sgx-icon::after, template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label .lm_controls .lm_popout::after, .lm_controls template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label .lm_popout::after, template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label .lm_controls .lm_maximise::after, .lm_controls template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label .lm_maximise::after, template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label .lm_controls .lm_close::after, .lm_controls template-orders-report-page .template-orders-report-page-inner-wrapper .date-selection-container sgx-input-date label .lm_close::after {
          padding-right: 12px; }
    template-orders-report-page .template-orders-report-page-inner-wrapper .info-message {
      font-size: 12px;
      color: #393a3b;
      padding-top: 8px;
      padding-left: 44px; }
    template-orders-report-page .template-orders-report-page-inner-wrapper .error-message, template-orders-report-page .template-orders-report-page-inner-wrapper .empty-date-error-message {
      font-size: 16px;
      padding-top: 16px;
      color: #e60000; }
      template-orders-report-page .template-orders-report-page-inner-wrapper .error-message.hide, template-orders-report-page .template-orders-report-page-inner-wrapper .empty-date-error-message.hide {
        display: none; }
      template-orders-report-page .template-orders-report-page-inner-wrapper .error-message.show, template-orders-report-page .template-orders-report-page-inner-wrapper .empty-date-error-message.show {
        display: block; }
    template-orders-report-page .template-orders-report-page-inner-wrapper .sgx-orders-report-btn-submit {
      margin-left: 16px; }
    template-orders-report-page .template-orders-report-page-inner-wrapper .orders-report-alert-bar {
      margin-bottom: 8px; }

.table-action:before, .table-action:after {
  color: #1d1d1d; }

.table-action .sgx-table-toolbar-icon {
  color: #1d1d1d; }

#page-container {
  height: 100%; }

#website-header {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  height: 2.9rem;
  z-index: 1000; }

body[data-type="mobile"] {
  position: fixed; }

.sgx-dialog-header {
  margin-bottom: 0px; }

.lm_content {
  background-color: #efefef; }

.sgx-dialog-button-dismiss,
.sgx-dialog-footer .sgx-button--link,
sgx-state-tabs-dialog-body {
  color: #222222; }

sgx-table-toolbar-filter > .filter-label {
  background: #184e7f !important; }

.lm_header .lm_tab {
  border: none; }

.lm_tabdropdown_list .lm_tab {
  right: 5px !important;
  top: 5px !important;
  line-height: 2.2rem !important; }

.lm_header .lm_tabdropdown_list .lm_tab .lm_title {
  height: 100%; }

sgx-table-toolbar,
.table-filters-container {
  background-color: #efefef !important; }

sgx-table-toolbar > span:before,
sgx-table-toolbar span:before {
  color: #b5bdd2; }

sgx-table-toolbar .table-action-label {
  color: #0B236B;
  margin-top: 1px; }

.sgx-flexible-layout-kebab-menu-container {
  color: #222222; }

.lm_header .lm_tab .lm_title {
  margin: 0;
  display: inline-block; }

.lm_header .lm_tab .lm_close_tab {
  background-image: none; }
  .lm_header .lm_tab .lm_close_tab:before {
    icon-font: url("sgx-base-code/svgs/solid/times.svg");
    color: rgba(0, 0, 0, 0.6); }

.lm_header .lm_controls .lm_close {
  display: none !important; }

.sgx-notifications-panel-footer-button {
  line-height: 0.6em; }

.sgx-notifications-panel--attached .sgx-notifications-panel-body-outer {
  overflow-y: auto; }

.sgx-notifications-panel {
  width: 400px; }

.sgx-input-checkbox-toggle,
.sgx-input-toggle {
  margin-bottom: 0;
  text-align: right; }
  .sgx-input-checkbox-toggle--checked .sgx-input-toggle-circle,
  .sgx-input-checkbox-toggle--checked .sgx-input-checkbox-toggle-circle,
  .sgx-input-toggle--checked .sgx-input-toggle-circle,
  .sgx-input-toggle--checked .sgx-input-checkbox-toggle-circle {
    background-color: #fff; }
  .sgx-input-checkbox-toggle-pill,
  .sgx-input-toggle-pill {
    background-color: #abadb5; }
  .sgx-input-checkbox-toggle-circle,
  .sgx-input-toggle-circle {
    background-color: #e7e8ea; }
  .sgx-input-checkbox-toggle[disabled],
  .sgx-input-toggle[disabled] {
    color: #fff;
    cursor: not-allowed; }

sgx-input-checkbox-toggle[disabled] *,
sgx-input-toggle[disabled] * {
  color: #fff !important;
  cursor: not-allowed; }

.lm_header .lm_controls .lm_tabdropdown:before {
  color: #222222 !important; }

.lm_header .lm_tabdropdown_list {
  background: #ffffff;
  color: #222222; }

.lm_header .lm_tabdropdown_list .lm_tab {
  padding: 0 0 0 1rem;
  height: 2.2rem;
  position: relative;
  top: -3px; }

.sgx-header-wrapper .sgx-header-menu-icon--platts.sgx-icon, .sgx-header-wrapper .lm_controls .sgx-header-menu-icon--platts.lm_popout, .lm_controls .sgx-header-wrapper .sgx-header-menu-icon--platts.lm_popout, .sgx-header-wrapper .lm_controls .sgx-header-menu-icon--platts.lm_maximise, .lm_controls .sgx-header-wrapper .sgx-header-menu-icon--platts.lm_maximise, .sgx-header-wrapper .lm_controls .sgx-header-menu-icon--platts.lm_close, .lm_controls .sgx-header-wrapper .sgx-header-menu-icon--platts.lm_close, .sgx-header-wrapper .sgx-header-menu-icon--platts.sgx-pagination-prev:before, .sgx-header-wrapper .sgx-header-menu-icon--platts.sgx-pagination-next:after {
  background-image: url("../static/images/platts_header_icon.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 28px 28px;
  margin-top: 4px;
  text-align: center;
  height: 32px;
  width: 32px;
  font-size: 28px;
  display: block; }

.sgx-header-menu-icon--orders::after {
  font-size: 28px; }

.sgx-header-menu-icon--admin::after {
  font-size: 25px; }

.sgx-header-menu-icon--home:after {
  font-size: 28px; }

.sgx-header-menu-icon--trades:after {
  font-size: 24px; }

.sgx-header-menu-icon--reports:after {
  font-size: 26px; }

#sgx-header-sidebar-revision-info-env {
  font-size: 10px; }

.sgx-header-menu-icon--admin:after {
  font-size: 25px; }

.sgx-header-menu-icon--support:after {
  font-size: 25px; }

.sgx-header-menu-item-wrapper {
  margin-bottom: 0.3rem; }

@media (max-width: 1023px) {
  .website-main-layout {
    margin-top: 48px; }
  .website-main-layout {
    height: calc(100% - 48px); } }

#sgx-table-config-dialog {
  z-index: 1002 !important; }

sgx-order-book-filter-select sgx-input-checkbox {
  margin-top: auto;
  margin-bottom: auto; }

sgx-order-book-filter-select .sgx-input-label {
  font-size: 13px; }

sgx-order-book-filter-select .sgx-input-label:before {
  margin-bottom: 3px; }

@media (max-width: 399px) {
  .sgx-notifications-panel {
    width: 100%; }
  sgx-notifications {
    width: 100%; } }

sgx-widget-news sgx-list,
sgx-widget-publications sgx-list,
sgx-widget-jobs sgx-list,
#performance-tracker-dialog sgx-list {
  background: transparent; }

sgx-status-indicator {
  opacity: 1;
  background: transparent;
  color: rgba(0, 0, 0, 0.6); }

sgx-input-checkbox .sgx-input-control {
  z-index: -1; }

#sgx-select-dialog {
  z-index: 2000; }

.lm_header .lm_controls > li {
  opacity: 1; }

sgx-status-indicator[status="success"] .sgx-status-indicator-status::before {
  icon-font: url(sgx-base-code/svgs/solid/check-circle.svg);
  color: #74AC00;
  animation: none; }

sgx-status-indicator[status="success-grey"] .sgx-status-indicator-status::before {
  icon-font: url(sgx-base-code/svgs/solid/check-square.svg);
  animation: none; }

sgx-status-indicator.sgx-status-indicator-server-down {
  opacity: 1;
  min-height: 150px; }

.warning-dialog .sgx-dialog-box {
  width: 960px;
  height: 450px;
  padding: 0; }

.warning-dialog .sgx-dialog-overlay {
  background: rgba(0, 0, 0, 0.7); }

.warning-dialog [data-dialog="title"] {
  height: 60px;
  padding: 0 !important;
  font-weight: 600;
  color: #fff;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 20px; }
  .warning-dialog [data-dialog="title"] span:before {
    icon-font: url("@sgx/sgx-base-code/svgs/solid/exclamation-circle.svg");
    margin-left: 30px;
    font-size: 28px;
    color: #fff; }
  .warning-dialog [data-dialog="title"] p {
    display: inline-block; }

@keyframes blink {
  0% {
    background-color: #303031; }
  50% {
    background-color: #ffcc00; }
  100% {
    background-color: #303031; } }

.sgx-auto-logout-dialog [data-dialog="title"] {
  animation: blink 1.2s infinite none; }

.sgx-shield-dialog[data-type="session-affected"] [data-dialog="title"] {
  background: #ffcc00; }

.sgx-shield-dialog[data-type="session-disconnected"] [data-dialog="title"] {
  background: #ff3333; }

.sgx-filter-form {
  display: table; }
  .sgx-filter-form .sgx-filter-form-row {
    display: table-row; }
    .sgx-filter-form .sgx-filter-form-row > * {
      display: table-cell; }

sgx-accordion.sgx-accordion--minimal .sgx-accordion-header,
sgx-accordion.sgx-accordion--minimal .sgx-accordion-container {
  background: transparent; }

sgx-accordion.sgx-accordion--minimal .sgx-accordion-header:after {
  icon-font: url("sgx-base-code/svgs/regular/chevron-down.svg"); }

sgx-accordion.sgx-accordion--minimal .sgx-accordion-item--open .sgx-accordion-header:after {
  icon-font: url("sgx-base-code/svgs/regular/chevron-up.svg"); }

sgx-accordion.sgx-accordion--minimal .sgx-accordion-item {
  margin-bottom: 20px;
  border-bottom: none; }

sgx-accordion.sgx-accordion--minimal .sgx-accordion-header {
  color: rgba(0, 0, 0, 0.87);
  padding: 0 8px 4px 0; }

sgx-accordion.sgx-accordion--minimal .sgx-accordion-item--open .sgx-accordion-header {
  font-weight: unset; }

sgx-accordion.sgx-accordion--minimal .sgx-accordion-container {
  padding: 0; }

sgx-accordion.sgx-accordion--minimal .sgx-accordion-header:after,
sgx-accordion.sgx-accordion--minimal .sgx-accordion-item--open .sgx-accordion-header:after {
  color: rgba(0, 0, 0, 0.6); }

sgx-accordion.sgx-accordion--minimal .sgx-accordion-expandAll-btn,
sgx-accordion.sgx-accordion--minimal .sgx-accordion-expandAll-icon {
  display: none; }
