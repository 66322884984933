$sgx-confirmation-dialog-icon--success: $ui-state-success !default;
$sgx-confirmation-dialog-icon--error: $ui-state-error !default;

sgx-widget-platts-subscription {
  width: 100%;
  height: 100vh;
  display: flex;
  margin: $space-16 auto 0px auto;
  min-height: 100vh;
  max-height: 100vh;
  justify-content: stretch;
}

main {
  sgx-widget-platts-subscription & {
    position: relative;
  }
}

a.sgx-link-text-content {
  sgx-widget-platts-subscription & {
    display: contents;
  }
}

textarea[name="comments"] {
  sgx-widget-platts-subscription sgx-dynamic-form-v2 & {
    resize: none;
    height: 100px;
  }
}

.sgx-widget-platts-subscription-container {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 $space-3;
}

.sgx-widget-platts-subscription-container {
  body[data-type="mobile"] & {
    width: 100%;
  }
  body[data-type="tablet"] &,
  body[data-type="desktop"] & {
    width: 680px;
  }
}

.sgx-dynamic-form {
  .sgx-widget-platts-subscription-container & {
    width: 100%;
  }
}

.sgx-form {
  .sgx-widget-platts-subscription-container & {
    width: 100%;
  }
}

.sgx-widget-platts-subscription-page-logo {
  .sgx-widget-platts-subscription-container & {
    top: 10px;
    left: 10px;
    width: 80px;
    height: 45px;
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

h2.sgx-widget-platts-subscription-page-title {
  .sgx-widget-platts-subscription-container .sgx-widget-platts-subscription-header & {
    margin: 0px;
    padding: 0;
    color: $sgx-theme-v2-high-emp-default;
  }
}

.sgx-widget-platts-subscription-desc {
  .sgx-widget-platts-subscription-container & {
    padding: $space-8 0 0 0;
  }
  .sgx-widget-platts-subscription-container & p {
    text-align: left;
    line-height: 24px;
    padding: 0 0 $space-2 0;
    margin: 0px;
    color: $sgx-theme-v2-medium-emp-default;
  }
  .sgx-widget-platts-subscription-container & span {
    display: block;
    width: 100%;
    color: $sgx-theme-v2-medium-emp-default;
  }
}

.sgx-widget-platts-subscription-form-wrapper {
  .sgx-widget-platts-subscription-container & {
    display: flex;
    justify-content: row;
    width: 100%;
  }
}

.sgx-section {
  .sgx-widget-platts-subscription-container & {
    margin-top: $space-8;
    width: 100%;
    background-color: $ui-bg;
    box-shadow: 0 1px 4px $sgx-card-shadow-color;

    sgx-input-email[name='emailAddress'][readonly] input[type='email'] {
      height: $space-9;
      padding: $space-2 $space-3;
      color: map-get($sgx-input-colors, input-text);
      background: map-get($sgx-input-colors, input-background);
      border-color: map-get($sgx-input-state-line, default);
    }
  }



  body[data-type="mobile"] .sgx-widget-platts-subscription-container & > * {
    width: 327px;
    margin-top: $space-5;
  }

  body[data-type="tablet"] .sgx-widget-platts-subscription-container & > *,
  body[data-type="desktop"] .sgx-widget-platts-subscription-container & > * {
    width: 440px;
    margin-top: $space-5;
  }

  body[data-type="mobile"] .sgx-widget-platts-subscription-container & {
    padding: $space-8 $space-3;
  }

  body[data-type="tablet"] .sgx-widget-platts-subscription-container &,
  body[data-type="desktop"] .sgx-widget-platts-subscription-container & {
    padding: $space-8 108px;
  }
}

.sgx-widget-platts-subscription-container {
  a {
    color: $sgx-blue-light;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}

h2 {
  .sgx-widget-platts-subscription-form-wrapper .sgx-section & {
    margin: 0px;
    color: $sgx-theme-v2-high-emp-default;
    @extend .sgx-base-text-title-16;
  }

  .sgx-widget-platts-subscription-form-wrapper .sgx-section &:before {
    margin-top: 0px;
  }

  .sgx-widget-platts-subscription-form-wrapper .sgx-section-policy & {
    margin-bottom: $space-8;
  }
}

h2.sgx-alert-dialog-title {
  .sgx-message-dialog & {
    @extend .sgx-base-text-title-24;
  }
}

h2 {
  .sgx-message-dialog .sgx-alert-dialog-content & {
    @extend .sgx-base-text-title-20;
    color: $sgx-theme-v2-high-emp-default;
  }
  .sgx-message-dialog .sgx-alert-dialog-content & i:before {
    @extend .sgx-base-text-title-24;
  }
  .sgx-message-dialog .sgx-alert-dialog-content & i {
    padding-right: $space-3;
  }
  .sgx-message-dialog--success .sgx-alert-dialog-content & i:before {
    icon-font: url("sgx-base-code/svgs/solid/check-circle.svg");
    color: $sgx-confirmation-dialog-icon--success;
  }
  .sgx-message-dialog--error .sgx-alert-dialog-content & i:before {
    icon-font: url("sgx-base-code/svgs/solid/times-circle.svg");
    color: $sgx-confirmation-dialog-icon--error;
  }
}

.sgx-alert-dialog-info-message {
  .sgx-widget-platts-subscription-success-dialog & p {
    color: $sgx-theme-v2-medium-emp-default;
  }
}

// overrides
.sgx-input-checkbox-label {
  .sgx-widget-platts-subscription-container .sgx-widget-platts-subscription-form-wrapper .sgx-section-policy & {
    align-items: flex-start;
  }
}

.sgx-widget-platts-subscription-form-controls {
  .sgx-widget-platts-subscription-container & {
    display: flex;
    padding: $space-8 0px;
    justify-content: flex-end;
  }
}

.sgx-alert-dialog-info-message {
  .sgx-platts-subscription-dialog & {
    padding-top: $space-3;
  }
}

.sgx-alert-dialog-body {
  sgx-widget-platts-subscription & {
    max-width: 600px;
    min-width: 600px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 1024px) {
  .sgx-dialog-header.sgx-alert-dialog-header {
    margin-bottom: 0px;
  }
}
