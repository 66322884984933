$bulk-actions-badge: $ui-text-default !default;

sgx-widget-order-blotter-bulk-actions {
  &.table-action {
    display: flex;
    margin: 0;
    &:hover {
      text-decoration: none;
    }
  }

  .table-action {
    .sgx-table-toolbar-icon {
      margin-top: 2px;
      margin-right: 4px;
    }
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:after {
      content: attr(count);
      position: absolute;
      z-index: 100;
      top: 1.5px;
      right: -10px;
      font-size: 10px;
      background: $sgx-green;
      color: $bulk-actions-badge;
      width: 18px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      border-radius: 50%;
      box-shadow: 0 0 1px $ui-shadow;
    }
  }

  .sgx-widget-order-blotter-bulk-actions--inactive {
    color: #ccc;
    opacity: 0.8;
    pointer-events: none;
    cursor: default;
  }
}

body[data-type=tablet] sgx-widget-order-blotter-bulk-actions {
  display: none;
}
