@font-face {
  font-family: 'fontTotc';
  src: url('../static/fonts/fontotc.woff2') format('woff2'),
       url('../static/fonts/fontotc.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: fontTotc, fontAwesome;
  font-size: 200%;
  font-style: normal;
  font-weight: normal;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  line-height: 1em; // fix height
  text-align: center;

  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}

// header
.icon-totc-alert:before { content: '\e802'; }
.icon-totc-search:before { content: '\f81a'; }

// products
.icon-totc-coking-coal:before { content: '\e82e' !important; }
.icon-totc-cvf:before { content: '\e830' !important; }
.icon-totc-cwf:before { content: '\e80d' !important; }
.icon-totc-iron-ore:before { content: '\e824' !important; }
.icon-totc-m42f:before { content: "\f06d" !important; }

.icon-totc-buy:before { content: '\e820'; }
.icon-totc-cancel-trade:before { content: '\e80b'; }
.icon-totc-cancel:before { content: '\e80b'; }
.icon-totc-customise:before { content: '\F013'; }
.icon-totc-add:before { content: '\e80b'; transform: rotateZ(45deg); }
.icon-totc-clone:before { content: '\e831'; }
.icon-totc-configure:before { content: '\e82d'; }
.icon-totc-confirm:before { content: '\f80e'; }
.icon-totc-copy:before { content: '\f80f'; }
.icon-totc-customize:before { content: '\e834'; }
.icon-totc-download:before { content: '\e823'; }
.icon-totc-edit:before { content: '\f811'; }
.icon-totc-filters:before { content: '\f812'; }
.icon-totc-history:before { content: '\e832'; }
.icon-totc-options:before { content: '\e81b'; }
.icon-totc-price-down:before { content: '\e999'; }
.icon-totc-price-up:before { content: '\f816'; }
.icon-totc-resume-trade:before { content: '\f818'; }
.icon-totc-route:before { content: '\f819'; }
.icon-totc-sell:before { content: '\e822'; }
.icon-totc-suspend-trade:before { content: '\f81c'; }
.icon-totc-system:before { content: '\e821'; }
.icon-totc-view-deal:before { content: '\e81e'; }
.icon-totc-chevron-down:before { content: '\e826'; } /* '' */
.icon-totc-chevron-right:before { content: '\e827'; } /* '' */
.icon-totc-chevron-left:before { content: '\e828'; } /* '' */
.icon-totc-chevron-up:before { content: '\e829'; } /* '' */

// Action menu items

.action-menu-item-aggress {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/handshake-alt.svg");
  }
}

.action-menu-item-copy-details {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/copy.svg");
  }
}

.action-menu-item-history {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/history.svg");
  }
}

.action-menu-item-edit {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/pencil-alt.svg");
  }
}

.action-menu-item-user-edit {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/user-edit.svg");
  }
}

.action-menu-item-reject {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/times.svg");
  }
}

.action-menu-item-accept {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/check.svg");
  }
}

.action-menu-item-resume, .action-menu-item-start {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/play.svg");
  }
}

.action-menu-item-delete {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/trash-alt.svg");
  }
}

.action-menu-item-cancel {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/times.svg");
  }
}

.action-menu-item-suspend {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/pause.svg");
  }
}

.action-menu-item-clone {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/clone.svg");
  }
}

.action-menu-item-view-deal {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/eye.svg");
  }
}

.action-menu-item-route {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/route.svg");
  }
}

.action-menu-item-confirm-reject {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/check-square.svg");
  }
}

.action-menu-item-clipboard-check {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/clipboard-check.svg");
  }
}


.action-menu-item-match {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/handshake-alt.svg");
  }
}

.action-menu-item-resend-link {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/reply.svg");
  }
}

.action-menu-item-activate {
  &:before {
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/regular/caret-circle-right.svg");
  }
}

/** sgx-base-code based common icons **/
.sgx-icon {
  &--spinner-third {
    &:after {
      vertical-align: 0; // otherwise, it mucks up the animations... ends up being wonky and not positioned properly
      icon-font: url("@sgx/sgx-base-code/svgs/regular/spinner-third.svg");
    }
  }

  &--spinner-first {
    &:after {
      vertical-align: 0; // otherwise, it mucks up the animations... ends up being wonky and not positioned properly
      icon-font: url("@sgx/sgx-base-code/svgs/regular/spinner.svg");
    }
  }

  &--spinner {
    animation: spin 0.6s infinite linear;
  }
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
