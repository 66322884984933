@import "./subcomponents/sgx-routing-group-view/sgx-routing-group-view.scss";

$sgx-order-submission-widget-panel-width: 320px;

// new styles
sgx-order-ticket-view form {
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  flex-direction: column;

  > * {
    padding-bottom: 1rem;
  }

  > :first-child {
    margin-bottom: 1rem;
    position: relative;
    left: -15px;
    width: calc(100% + 30px);
  }
}


.sgx-order-submission-widget {
  height: 100%;
  color: $sgx-order-submission-color;
  width: $sgx-order-submission-widget-panel-width;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  z-index: 100;

  & .sgx-dynamic-form-row {
    padding: 0.5rem 1rem;
  }

  & .sgx-dynamic-form-row > * {
    width: 100%;
  }
  &-view-wrapper {
    flex: 1 0 auto;
    margin: 0;
    overflow: hidden;
    position: relative;
  }
  &-order-ticket-view {
    .sgx-order-submission-order-toggle-direction {
      padding: 0 0 0.5rem 0;
      line-height: 2rem;
      background-color: transparent;
      .sgx-input-toggle-gui-wrapper {
        padding: 0.5rem 0;
      }
      &--buy {
        transition: background-color 0.4s linear;
      }
      &--sell {
        transition: background-color 0.4s linear;
      }
    }
  }
  &-order-ticket-view,
  &-routing-group-view {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(0);
    transition: transform 0.4s ease, background 0.4s ease;
    background: $sgx-right-drawer-bg;
  }
  .sgx-order-submission-widget[data-current-view="order"] &-routing-group-view,
  .sgx-order-submission-widget[data-current-view="routing"] &-order-ticket-view {
    transform: translateX(100%);
    transition-delay: 0.4s;
    z-index: 0;
  }
  &-btn-clear,
  &-btn-submit {
    padding: 1rem;
  }
  &-view-controls {
    transition: background 0.4s ease;
    background-color: $sgx-right-drawer-bg;
    box-shadow: 3px 0px 9px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    z-index: 3;
  }
  // Mobile View
  .sgx-mobile-order-submission-widget &-view-controls {
    background: $ui-bg;
  }
  &-input-validity {
    &--hidden {
      display: none;
    }
  }
  &-switch-view {
    display: none;
    color: $sgx-order-submission-color;
    border-color: $sgx-order-submission-color;
    &--show {
      display: block;
    }
  }
  &-button-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    padding-top: 1rem;
  }
  &-input {
    width: 100%;
  }
}

.sgx-order-submission-widget-focus {
  & sgx-order-ticket-view,
  & sgx-routing-group-view,
  & .sgx-order-submission-widget-header-container,
  & .sgx-order-submission-widget-view-controls {
    background-color: $sgx-right-drawer-bg-active;
  }
  &.sgx-mobile-order-submission-widget sgx-order-ticket-view,
  &.sgx-mobile-order-submission-widget sgx-routing-group-view,
  &.sgx-mobile-order-submission-widget .sgx-order-submission-widget-header-container,
  &.sgx-mobile-order-submission-widget .sgx-order-submission-widget-view-controls {
    background-color: $ui-bg;
  }
  & .sgx-order-submission-widget-panel-button:before {
    background-color: $v2-sgx-light-blue;
  }
  & [name="sgx-order-ticket-toggle-direction"] {
    transition: background 0.4s ease;
    background-color: #bf0000;
  }
  & [name="sgx-order-ticket-toggle-direction"][data-isleft="true"] {
    background-color: #74ad00;
  }
}

.sgx-order-submission-widget-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  background: $sgx-right-drawer-bg;
  transition: padding-left 0.4s, background-color 0.4s;
}

.sgx-order-submission-widget-header-container {
  text-align: center;
  color: $sgx-order-submission-color;
  background-color: $sgx-right-drawer-bg;
  transition: background-color 0.4s;
  padding: 1rem;
  z-index: 3;
}

// make direction toggle fill full width of widget
sgx-order-ticket-view .sgx-dynamic-form-row:first-of-type {
  padding: 0;
  & .sgx-input-toggle-gui-wrapper {
    padding: 0;
    height: 50px;
  }
}

.sgx-order-submission-auto-routing-flag {
  height: 30px;
  padding: 0.25rem;
  width: 100%;
  text-align: center;
  line-height: 18px;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: #1287cc;

  span {
    color: #fff;
    font-size: 11px;
  }
}

.sgx-auto-routing--hide {
  display: none;
}

.sgx-icons-tick:before {
  font-size: 15px;
  margin-right: $space-2;
  icon-font: url("sgx-base-code/svgs/solid/check-circle.svg");
}

#sgx-order-submission-select-fill-type {
  .sgx-input-hint-icon:hover + .sgx-input-hint {
    white-space: pre-line;
  }
}

.sgx-widget-order-submissions-status-message-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &[data-type="warning"] {
    color: white;
    background: $sgx-theme-functional-01;
  }
  &[data-type="note"] {
    color: black;
    background: $v2-sgx-yellow;
  }
}

.sgx-widget-order-submissions-status-message {
  display: inline-block;
  font-size: 0.9rem;
  padding: 0.3rem 1rem;
  line-height: 1.2rem;
  opacity: 1;
  height: 50px;
  transition: opacity 0.5s ease-out, height 0.5s ease-out, padding 0.5s ease-out;
}

.sgx-widget-order-submissions-status-message--hidden {
  padding: 0 1rem;
  height: 0;
  opacity: 0;
}

.sgx-widget-order-submissions-status-message-close-icon {
  height: 100%;
  width: 3rem;
  display: inline-block;
  text-align: center;

  &:after {
    color: darkgrey;
    content: "\F00D";
    font-family: FontAwesome;
  }

  &:hover {
    cursor: pointer;
  }

  [data-closeable="false"] > & {
    width: 0;
    height: 0;
    opacity: 0;
  }
}
