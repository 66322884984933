sgx-widget-news {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  min-height: 0;

  sgx-list {
    display: flex;
    flex-grow: 1;
    border: none;
    padding: 0px;

    .rows-container {
      border-top: solid 1px $sgx-theme-v2-lowest-emp-default;
    }
  }

  .sgx-widget-news-wrapper {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }

  .sgx-widget-news-footer {
    padding-top: $space-3;
    display: none;
  }
}
