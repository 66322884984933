$edit-icon:url(sgx-base-code/svgs/regular/pencil-alt.svg);
$delete-icon:url(sgx-base-code/svgs/regular/trash-alt.svg);
sgx-widget-routing-groups-editor {
  display: block;
  background-color: $sgx-card-background;
  height: 85vh;
  padding: $space-8 calc(#{$space-27} + #{$space-8});
  overflow-y: auto;
}

.routing-groups-editor-header {
  position: relative;
  width: calc(100% - (#{$space-27} + #{$space-8})/2);
}

.routing-groups-editor-add-button {
  position: absolute;
  right: 0px;
  bottom: 0px;
  line-height: $space-9;
}

.routing-groups-editor-title {
  color: $sgx-card-header-text;
  font-weight: semibold;
  margin-bottom: $space-6;
}

.routing-groups-editor-companies-cell {
  position: relative;
}

.routing-groups-editor-companies-text {
  position: absolute;
  left: 0px;
  top: 0px;
}

.routing-groups-editor-row-controls {
  display: none;
  position: absolute;
  height: 20px;
  right: 0px;
  top: 0px;
  margin: 0;
  padding: 0;
}

.routing-groups-editor-edit-control {
  display: inline;
  padding-left: $space-2;
  padding-right: $space-2;
  &:before {
    icon-font: $edit-icon;
    padding-right: $space-1;
  }
  &:hover {
    cursor: pointer;
  }
}

.routing-groups-editor-delete-control {
  display: inline;
  padding-left: $space-2;
  padding-right: $space-4;
  &:before {
    icon-font: $delete-icon;
    padding-right: $space-1;
  }
  &:hover {
    cursor: pointer;
  }
}

#routing-groups-editor-content-table {
  width: calc(100% - (#{$space-27} + #{$space-8})/2);
  & table {
    outline: $sgx-content-table-border;
  }
  & td {
    white-space: pre;
  }
  & tr td:first-child {
    width: 290px;
  }
  & tr:hover .routing-groups-editor-row-controls {
    display: flex;
  }
}

.routing-groups-editor-name-input {
  width: 400px;
  margin-bottom: $space-5;
}

//TODO: is this handled by newer version of sgx dialog?
.routing-groups-editor-dialog-contents {
  padding: $space-5 $space-5;
  color: $sgx-form-input-text
}
