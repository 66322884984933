$sgx-header-height: 50px;

.sgx-mobile-market-view {
  position: fixed;
  top: $sgx-header-height;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.sgx-card.sgx-market-view-select-card {
  display: flex;
  flex-direction: column;
  padding-bottom: 46px;
}

.sgx-market-view-select-dialog {
  & .sgx-dialog-body {
    padding: 0 $space-3
  }

  & .sgx-dialog-box {
    display: flex;
    flex-direction: column;
    width: 764px;
  }
}

.sgx-market-view-select-header {
  .sgx-dialog-header & {
    margin-left: $space-3;
    font-size: 24px;
  }

  [data-type="mobile"] & {
    font-weight: 600;
    color: $sgx-market-view-select-header-color;
    font-size: 20px;
    margin-left: 0;
    margin-bottom: $space-3;
  }
}

.sgx-market-view-select-subheader {
  font-weight: 600;
  color: $sgx-market-view-select-subheader-color;
  margin-bottom: $space-8;
  margin-top: $space-3;

  [data-type="mobile"] & {
    font-size: 16px;
    margin-bottom: $space-5;
    margin-top: 0;
  }
}

.sgx-market-view-select-submit {
  float: right;
  margin-right: 0;
  margin-bottom: $space-3;
  width: 150px;
  height: 40px;

  [data-type="mobile"] & {
    width: 90px;
    float: none;
    align-self: flex-end;
    // design shows $space-5, bottom margin on above buttons is $space-4
    margin-top: $space-1;
  }
}

.sgx-market-view-select-btn-container {
  display: flex;
  justify-content: flex-end;
}
