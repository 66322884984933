sgx-list-simple-row {
  display: block;

  &:first-child .sgx-simple-row {
    border-top: solid 1px $sgx-theme-v2-lowest-emp-default;
    margin-top: $space-5;
  }

  .sgx-simple-row {
    padding-top: $space-2;
    padding-bottom: $space-2;
    border-bottom: solid 1px $sgx-theme-v2-lowest-emp-default;
    height: 100%;
  }

  .sgx-simple-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $sgx-theme-v2-high-emp-default;
  }

  .sgx-simple-id {
    margin-right: $space-3;
  }
}
