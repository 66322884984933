$sgx-right-drawer-width: 320px;
$sgx-right-drawer-protrusion-width: 20px;

$sgx-right-drawer-edge-button-color: theme-color($sgx-theme-ui-01, tint 100%, tint 100%);
$sgx-right-drawer-edge-hover-color: theme-color($sgx-theme-ui-01, tint 100%, shade 25%);

$sgx-right-drawer-edge-button-height: 44px;
$sgx-right-drawer-edge-button-width: 20px;
$sgx-right-drawer-edge-hidden-width: 20px;

sgx-right-drawer {
  width: $sgx-right-drawer-width;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: $sgx-right-drawer-bg;
  box-shadow: $sgx-shadow-left;
  transition: right 0.4s ease, background-color 0.4s ease;

  & > *:not(.sgx-right-drawer-edge-button) {
    transition: transform 0.4s ease;
  }

  &[data-visible="false"] {
    right: calc(#{$sgx-right-drawer-protrusion-width} - #{$sgx-right-drawer-width});
  }

  &[data-visible="false"] > *:not(.sgx-right-drawer-edge-button) {
    transform: translate($sgx-right-drawer-protrusion-width);
  }

  &[data-visible="false"]:hover {
    background-color: $sgx-right-drawer-edge-hover-color;
  }

  &[data-active-color="true"] {
    background-color: $sgx-right-drawer-bg-active;
  }
}

.sgx-right-drawer-tab-container {
  height: 100%;
  width: calc(#{$sgx-right-drawer-protrusion-width} - #{$sgx-right-drawer-width});
}

.sgx-right-drawer-edge-button {
  position: absolute;
  top: calc(50% - #{$sgx-right-drawer-edge-button-height}/ 2);
  left: -20px;
  width: $sgx-right-drawer-edge-button-width;
  height: $sgx-right-drawer-edge-button-height;
  line-height: $sgx-right-drawer-edge-button-height;
  text-align: center;
  background-color: $v2-sgx-light-blue;
  border-radius: 2px 0 0 2px;
  z-index: 101;

  &:before {
    color: $sgx-right-drawer-edge-button-color;
    font-size: 14px;
    icon-font: url("sgx-base-code/svgs/solid/chevron-double-right.svg");
  }

  &:hover {
    cursor: pointer;
  }

  [data-visible="false"] &:before {
    icon-font: url("sgx-base-code/svgs/solid/chevron-double-left.svg");
  }
}
