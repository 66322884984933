sgx-add-remove-select {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  & sgx-input-select {
    width: 400px;
  }
}

.sgx-add-remove-select-add-button {
  margin-bottom: $space-3;
  line-height: $space-9;
}

.sgx-add-remove-select-remove-button {
  line-height: $space-9;
}

.sgx-add-remove-select-controls {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 120px;
  margin-left: $space-3;
  margin-right: $space-3;
}