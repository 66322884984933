$sgx-modal-confirm-background: theme-color($sgx-theme-ui-06);
$sgx-modal-confirm-colour: theme-color($sgx-theme-ui-01, tint 10%, tint 45%);

sgx-modal-confirm {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1;
  background: $sgx-modal-confirm-background;
  color: $sgx-modal-confirm-colour;

  .sgx-modal-confirm-title {
    text-align: center;
    margin-bottom: 15px;
  }

  .sgx-modal-confirm-button-confirm,
  .sgx-modal-confirm-button-cancel {
    padding: 0 15px;
    margin: 0 15px;
  }

  &.sgx-modal-confirm--hidden {
    display: none;
  }
}
