sgx-widget-flexible-layout,
sgx-platts-widget-flexible-layout {
  display: block;
  margin-top: $space-2;
  height: 100%;

  .lm_header {
    z-index: 999;
  }

  @include media-breakpoint-down(xxs) {
    margin-top: $space-1;
  }

  sgx-card-widget-wrapper {
    height: 100%;
  }
}
