$sgx-confirm-reject-error: $ui-state-error !default;
$confirm-reject-text-color: #222222 !default;
$confirm-reject-grey-color: #878787 !default;
$confirm-reject-tab-color: #050505 !default;

.confirm-reject-content-subtitle {
  padding: 12px 0px 20px 0px;
  display: block;
  width: 700px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.confirm-reject-content {
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.confirm-reject-content-details {
  display: flex;
  height: auto;
  // padding-top: $space-5;
}

.confirm-reject-dropdown-item {
  font-size: 0.7rem;
  color: $confirm-reject-grey-color;
}

.confirm-reject-container {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  min-width: 420px;
}

.confirm-reject-container-tabs {
  min-width: 200px;
  margin-right: 1rem;
}

.confirm-reject-details {
  flex: 1 1 auto;
}

.confirm-reject-content-detail-title {
  justify-self: flex-start;
  padding-right: 20px;
  flex: 1 1 30%;
  color: $confirm-reject-grey-color;
  line-height: 1.2em;
  font-size: 12px;
  margin: 0.5rem 0;
  width: 30%;
  text-align: right;

  [data-dropdown='true'] & {
    align-self: start;
  }
}

.confirm-reject-content-leg-price-title {
  width: 100%;
  text-align: left;
}

.confirm-reject-content-leg-price-input-1, .confirm-reject-content-leg-price-input-2 {
  width: 200px;
  font-size: $space-3;
}

.confirm-reject-content-leg-price-input-2 {
  margin-left: 1rem;
}

.sgx-input-label {
  .confirm-reject-content-leg-price-input-1 &,
  .confirm-reject-content-leg-price-input-2 & {
    font-size: 12px;
  }
}

.confirm-reject-content-detail-description {
  justify-self: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: $confirm-reject-text-color;
  line-height: 1.2em;
  flex: 1 1 70%;
  margin: 0.5rem 0;
  width: 70%;
}

.confirm-reject-content-leg1-price-note {
  font-size: 12px;
  margin: 0.5rem 0;
  color: $sgx-theme-v2-medium-emp-default;
  width: 90%;
}

.confirm-reject-dropdown-list-container {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: $space-16;
  width: 70%;
}

.confirm-reject-content-detail-item-trader-select {
  margin-top: 1rem;

  sgx-input-select {
    margin-right: 0.5rem;
  }
}

.confirm-reject-content-detail-item {
  display: flex;
  align-items: flex-end;

  &[data-dropdown='true'][data-is-expanded='true'] {
    position: relative;

    .confirm-reject-content-detail-title {
      position: absolute;
    }

    .confirm-reject-content-detail-description {
      position: absolute;
      top: 0px;
      left: 30%;
      margin-left: 5px;

      ul {
        padding-top: 2px;
        padding-bottom: $space-1;
      }
    }
  }
}

.confirm-reject-content-detail-custom-leg-item {
  align-items: start;
}

.confirm-reject-tabs-container {
  position: relative;
  width: 100%;
  height: 200px;
  min-width: 260px;
}

.confirm-reject-textarea--buyer,
.confirm-reject-textarea--seller {
  width: 100%;
  height: 200px;
  max-width: 100%;
  max-height: 200px;
  min-width: 260px;
  min-height: 200px;

  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  padding: 14px;
  background: $cloudy-blue;
  font-family: lato;
  color: $confirm-reject-tab-color;

  transition: opacity 0.4s ease;
}

.confirm-reject-textarea--show {
  pointer-events: all;
  opacity: 1;
}

.confirm-reject-reject-container {
  padding: 40px;
  display: flex;
  justify-content: center;
}

.confirm-reject-reject-icon,
.confirm-reject-reject-text {
  flex: 1 1 auto;
  vertical-align: middle;
}

.confirm-reject-reject-icon {
  text-align: center;

  &:before {
    content: '\f06a';
    font-family: fontAwesome;
    color: $sgx-confirm-reject-error;
    font-size: 6rem;
  }
}

.confirm-reject-dropdown-title {
  cursor: pointer;
  width: 35%;
  &:after {
    float: right;
    font-family: 'fontTotc';
    content: '\e827';
  }
  &[data-is-expanded='true']:after {
    content: '\e826';
  }
}


.sgx-confirm-reject-alert-dialog .sgx-alert-dialog-content {
  overflow: auto;
  height: 400px;
}


.confirm-reject-content-multileg-note {
  margin-right: 0.5rem;
}
