$page-content-padding: $space-3 $space-5 !default;
$page-content-tablet-padding: 2rem 1rem !default;
$page-content-mobile-padding: 1rem !default;

$page-content-font-family: Lato, sans-serif !default;
$page-content-line-height: 1.4 !default;
$page-content-text-align: left !default;

$page-content-h3-font-size: 125% !default;
$page-content-h3-font-color: $sgx-dark-grey !default;
$page-content-h3-font-weight: bold !default;

.pages-content {
	color: $sgx-dark-grey;
	font-family: $page-content-font-family;
	line-height: $page-content-line-height;
	text-align: $page-content-text-align;
	padding: $page-content-padding;

	h3 {
		color: $page-content-h3-font-color;
		font-size: $page-content-h3-font-size;
		font-weight: $page-content-h3-font-weight;
	}

	p {
		padding: 1rem 0;
	}

	img {
		height: auto;
		max-width: 100%;
	}
}

body[data-type='tablet'] {
	.pages-content {
		padding: $page-content-tablet-padding;
	}
}

body[data-type='mobile'] {
	.pages-content {
		position: relative;
    padding: $page-content-mobile-padding;
    height: 100%;
	}
}
