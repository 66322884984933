sgx-table-cell-market-data {
  sgx-platts-market-data-table sgx-table-row & {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.sgx-table-cell-row-group {
  sgx-table-cell-market-data & {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
  }
}

