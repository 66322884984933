$book-styles-white: $ui-item-row !default;
$book-styles-up: #72af00 !default;
$book-styles-down: #f30643 !default;
$book-styles-neutral-flash: #c0d730 !default;
$book-styles-bait-order-color: theme-color($v2-blue, tint 40%, tint 40%);

// minus width of tvol and last columns, which are 15% width each using flex 
$editable-highlight-width-landscape: calc((100% - 15% * 2) / 2);

.precheck-icon {
  content: '\f00c';
  font-family: fontAwesome;
  position: absolute;
  color: $sgx-theme-functional-02;
  font-size: 0.7rem;
  line-height: normal;
}

.lock-icon {
  content: '\f023';
  font-family: fontAwesome;
  position: absolute;
  font-size: 0.7rem;
  color: $ui-text-default-grayed;
  line-height: normal;
}

.auto-confirm-icon {
  content: '\f0e7';
  font-family: fontAwesome;
  position: absolute;
  font-size: 0.7rem;
  color: $ui-text-default-grayed;
  line-height: normal;
}

.lock-icon-after {
  margin-right: $space-1;

  &:after {
    @extend .lock-icon;
    margin-left: $space-1;
    position: relative;

  }
}

.lock-icon-before {
  margin-left: $space-1;

  &:before {
    @extend .lock-icon;
    margin-right: $space-1;
    position: relative;
  }
}

.auto-confirm-icon-after {
  margin-right: $space-1;

  &:after {
    @extend .auto-confirm-icon;
    margin-left: $space-1;
    position: relative;
  }
}

.auto-confirm-icon-before {
  margin-right: $space-1;

  &:before {
    @extend .auto-confirm-icon;
    margin-right: $space-1;
    position: relative;
  }
}


.list-book-styles {

  // bid side styles

  &[data-bid-owner="true"] .order-book-mobile-bid-editable-highlight {
    position: absolute;
    width: 50%;
    top: 0;
    left: 0;
    height: 100%;
    content: "";
    background: $sgx-mobile-order-book-editable-highlight-color;
    border-radius: 30px 0 0 30px;
    z-index: -1;
  }

  &[adaptive-mode='mobile-landscape'],
  &[adaptive-mode='complete'] {
    &[data-bid-owner="true"] .order-book-mobile-bid-editable-highlight {
      width: $editable-highlight-width-landscape;
    }
  }

  &[data-bid-auto-confirm="true"] {
    [data-column-id="bidId"] span {
      @extend .auto-confirm-icon-after;
    }
  }

  &[data-ask-auto-confirm="true"] {
    [data-column-id="askId"] span {
      @extend .auto-confirm-icon-before;
    }
  }

  &[data-bid-order-type="1"] {
    &[adaptive-mode="mobile"] {
      [data-column-id="bidPrice"][detail-mode='true'] {
        &:after {
          @extend .precheck-icon;
          left: $space-2;
          top: 0px;
        }
      }

      [data-column-id="bidPrice"][detail-mode='false'] {
        &:after {
          @extend .precheck-icon;
          left: $space-2;
          top: 10px;
        }
      }
    }

    &[adaptive-mode='mobile-landscape'],
    &[adaptive-mode='complete'] {
      [data-column-id="bidPrice"] {
        &:after {
          @extend .precheck-icon;
          left: $space-2;
          top: 10px;
        }
      }
    }
  }

  &[data-bid-fill-type="0"] {
    &[adaptive-mode="mobile"] {
      [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span {
        @extend .lock-icon-before;
      }
    }

    &[adaptive-mode='mobile-landscape'],
    &[adaptive-mode='complete'] {
      [data-column-id="bidPrice"][detail-mode='true'] [data-column-id="bidQuantity"] span {
        @extend .lock-icon-before;
      }
    }
  }

  // ask side styles

  &[data-ask-owner="true"] .order-book-mobile-ask-editable-highlight {
    position: absolute;
    width: 50%;
    top: 0;
    left: 50%;
    height: 100%;
    content: "";
    background: $sgx-mobile-order-book-editable-highlight-color;
    border-radius: 0 30px 30px 0;
    z-index: -1;
  }

  &[adaptive-mode='mobile-landscape'],
  &[adaptive-mode='complete'] {
    &[data-ask-owner="true"] .order-book-mobile-ask-editable-highlight {
      width: $editable-highlight-width-landscape;
      left: $editable-highlight-width-landscape;
    }
  }

  &[data-ask-order-type="1"] {
    &[adaptive-mode="mobile"] {
      [data-column-id="askPrice"][detail-mode='true'] {
        &:after {
          @extend .precheck-icon;
          right: $space-2;
          top: 0px;
        }
      }
    }

    [data-column-id="askPrice"][detail-mode='false'] {
      &:after {
        @extend .precheck-icon;
        right: $space-2;
        top: 10px;
      }
    }

    &[adaptive-mode='mobile-landscape'],
    &[adaptive-mode='complete'] {
      [data-column-id="askPrice"] {
        &:after {
          @extend .precheck-icon;
          right: $space-2;
          top: 10px;
        }
      }
    }
  }

  &[data-ask-fill-type="0"] {
    &[adaptive-mode="mobile"] {
      [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span {
        @extend .lock-icon-after;
      }
    }

    &[adaptive-mode='mobile-landscape'],
    &[adaptive-mode='complete'] {
      [data-column-id="askPrice"][detail-mode='true'] [data-column-id="askQuantity"] span {
        @extend .lock-icon-after;
      }
    }
  }

  .book-styles--down-triangle {
    top: 12px;
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
  }

  .book-styles--up-triangle {
    @extend .book-styles--down-triangle;
    border-top: 0;
    border-bottom: 5px solid currentColor;
  }

  .book-styles--price-up-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-price-up-flash;
  }

  .book-styles--price-down-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-price-down-flash;
  }

  .book-styles--neutral-flash {
    animation-duration: 1.2s;
    animation-name: book-styles-neutral-flash;
  }

  @keyframes book-styles-price-up-flash {
    50% {
      background: $book-styles-up;
      color: white;
    }
  }

  @keyframes book-styles-price-down-flash {
    50% {
      background: $book-styles-down;
      color: white;
    }
  }

  @keyframes book-styles-neutral-flash {
    50% {
      background: $book-styles-neutral-flash;
      color: white;
    }
  }

  &[data-last-price-color="up"] {
    [data-column-id="lastPrice"] {
      color: $book-styles-up;

      &:after {
        @extend .book-styles--up-triangle;
        right: 4px;
      }
    }
  }

  &[data-last-price-color="down"] {
    [data-column-id="lastPrice"] {
      color: $book-styles-down;

      &:after {
        @extend .book-styles--down-triangle;
        right: 4px;
      }
    }
  }
  
  &[data-bid-order-is-bait="true"] {
    [data-column-id^="bid"] {
      color: $book-styles-bait-order-color;
    }
  }

  &[data-ask-order-is-bait="true"] {
    [data-column-id^="ask"] {
      color: $book-styles-bait-order-color;
    }
  }
}