
sgx-widget-rich-text {
  
  & > * {
    font-size: 14px;
    
    margin: 0.4rem 0 0.4rem .4rem;
    line-height: 1.25rem;
  }

  [class*=text-h] {
    font-size: 18px;
    font-weight: 600;

    margin-top: 0.8rem;

    a:not(:hover):not(:focus):not(:active) {
      color: unset;
    }
  }

  p.text-caption {
    color: $sgx-theme-v2-medium-emp-default;
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
  
}