
sgx-platts-market-data-table {
  height: 100%;
  min-height: 100%;
}

.sgx-platts-market-data-table-wrapper {
  sgx-platts-market-data-table & {
    height: 100%;
    min-height: 100%;
  }
  
  sgx-platts-market-data-table & .sgx-platts-market-data-table-container {
    height: 100%;
    min-height: 100%;
  }
}

sgx-table {
  sgx-platts-market-data-table .sgx-platts-market-data-table-container & {
    height: 100%;
    min-height: 100%;
  }
}

.sgx-cell-col {
  sgx-platts-market-data-table sgx-table-cell-market-data .sgx-table-cell-row-group & {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 60px;
    text-align: right;
    padding-right: 8px;
    box-sizing: border-box;
    max-width: 33.33%;
    min-width: 33.33%;
  }
  sgx-platts-market-data-table sgx-table-cell-market-data .sgx-table-cell-row-group &:not(:last-child) {
    border-right: solid 1px $sgx-theme-v2-lower-emp-default;
  }
}

header {
  sgx-platts-market-data-table sgx-table sgx-table-column-headers-manager sgx-table-header-text & {
    line-height: 50px;
  }
}

sgx-table-toolbar {
  sgx-platts-market-data-table & {
    display: none;
  }
}

sgx-scroll-shield {
  sgx-platts-market-data-table sgx-table & {
    top: 50px;
  }
}

sgx-table-header-text {
  sgx-platts-market-data-table sgx-table-column-headers-manager .sgx-table-header-container &:first-child {
    text-align: left;
  }
  sgx-platts-market-data-table sgx-table-column-headers-manager .sgx-table-header-container &:last-child {
    text-align: left;
  }
}
