template-not-mobile-ready-page {
  display: block;
  text-align: center;
  height: 100%;
  overflow-y: auto;
 
  .not-mobile-ready-template-page {
    display: flex;
    align-items: flex-start;
    height: 100%;
    margin-bottom: 0px;
    box-shadow: none;
  }

  .not-mobile-ready-template {
    padding: 2rem;
    width: 100%;
  }

  .not-mobile-ready-title {
    font-weight: 400;
    font-size: 28px;
    margin-top: 1rem;
    color: theme-color($sgx-theme-text-01, tint 10%, tint 45%);

    p:last-child {
      margin-top: 0.8rem;
      margin-bottom: 1rem;
    }
  }

  .not-mobile-ready-sub-title {
    font-size: 16px;
    color: theme-color($sgx-theme-text-01, tint 10%, tint 45%);
  }

  .sgx-icon-coming-soon {
    display: flex;
    justify-content: center;
    color: theme-color($sgx-theme-text-01, tint 85%, shade 25%);
    &:before {
      icon-font: url("@sgx/sgx-base-code/svgs/light/rocket.svg");
      font-size: 140px;
    }
  }
}
