$sgx-routing-view-font-header: darken($ui-text-default, 30%);
sgx-routing-group-view {
  padding: 0 12px;
  > h1 {
    margin-top: 1.2em;
  }
  h1 {
    color: $sgx-routing-view-font-header;
    font-size: 1em;
    margin-bottom: 0.5em;
    text-transform: capitalize;
  }

  #route-to-internal {
    border-bottom: 1px solid rgba($sgx-routing-view-font-header, 0.3);
    margin: 0.8rem 0;
    sgx-input-checkbox-toggle {
      margin-bottom: 0.5em;
      .sgx-input-label {
        font-size: 1em;
      }
    }
  }
  #route-to-companies {
    margin-top: 1em;
    h1 {
      margin: 1em 0;
    }

    .sgx-input-label {
      display: inline-block;
      font-size: 0.9em;
      margin-right: 0;
      margin-bottom: 0.5rem;
      margin-top: auto;
      overflow: hidden;
      padding-right: 0px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 175px;
    }
  }
  #route-to-brokers,
  #route-to-brokers-header,
  #route-to-brokers-fcm,
  #route-to-fcms-header {
    display: block;
    &.route-to-brokers--hidden {
      display: none;
    }
  }
  #route-to-companies,
  #route-to-brokers-header,
  #route-to-fcms-header {
    border-top: 1px solid rgba($sgx-routing-view-font-header, 0.3);
  }

  #route-to-brokers-header,
  #route-to-fcms-header {
    padding-top: 1em;
  }
  sgx-input-checkbox-toggle {
    text-align: right;
    &.sgx-input-checkbox-toggle--checked {
      .sgx-input-checkbox-toggle-circle {
        background-color: #fff;
      }
    }
    .sgx-input-checkbox-toggle-pill {
      background-color: #abadb5;
    }
    .sgx-input-checkbox-toggle-circle {
      background-color: #e7e8ea;
    }
  }

  &[browser="ie"] {
    .sgx-text-dropdown-header-container {
      padding-left: 0px !important;
    }
  }
}

#route-to-groups {
  width: 78%;
  .sgx-text-dropdown-list {
    margin-left: 1.2em;
  }

  sgx-text-dropdown {
    .sgx-text-dropdown-header-container {
      flex-direction: row-reverse;
      position: relative;
    }

    .sgx-text-dropdown-list-container--toggle-closed,
    .sgx-text-dropdown-list-container--toggle-open {
      &:before {
        position: absolute;
        top: 8px;
        right: 10px;
      }

      margin-top: 3px;
      margin-left: -1px;
    }

    .sgx-text-dropdown-header-container p {
      margin-right: 0;
      overflow: hidden;
      padding-right: 0px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 175px;
    }
  }
}

.sgx-route-group-items {
  .sgx-input-checkbox-toggle-container {
    .sgx-input-control {
      width: 95%;
      height: 1.5rem;
      padding: 0;
    }
  }
}

.sgx-route-group-link-to-userprofile-container {
  display: flex;
  align-items: center;
  & svg {
    color: $sgx-green;
    padding-right: 5px;
    width: 1.3rem;
    height: 1.3rem;
  }
}

.sgx-route-group-link-to-userprofile {
  padding-top: 6px;
  height: 1.5rem;
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.sgx-widget-routing-view-status-message {
  position: relative;
  font-size: 0.9rem;
  padding: 0.3rem 1rem;
  background: #fff5cc;
  line-height: 1.2rem;
  opacity: 1;
  height: 50px;
  transition: opacity 0.5s ease-out, height 0.5s ease-out, padding 0.5s ease-out;
  color: black;

  &--hidden {
    padding: 0 1rem;
    height: 0;
    opacity: 0;
  }

  .sgx-widget-routing-view-status-message-close-button {
    position: absolute;
    top: 50%;
    right: 1rem;
    content: "\F00D";
    font-family: FontAwesome;
    color: black;
  }
}

.sgx-route-group-update-anim {
  animation-duration: 0.7s;
  animation-name: sgx-route-group-update-keyframes;
}

.sgx-route-group-add-anim {
  animation-duration: 0.7s;
  animation-name: sgx-route-group-add-keyframes;
  animation-timing-function: ease-out;
}

.sgx-route-group-delete-anim {
  animation-duration: 0.7s;
  animation-name: sgx-route-group-add-keyframes;
  animation-direction: reverse;
  animation-timing-function: ease-in;
}

@keyframes sgx-route-group-update-keyframes {
  50% {
    background: $sgx-green;
    color: white;
  }
}

@keyframes sgx-route-group-add-keyframes {
  from {
    opacity: 0;
    margin-left: 300px;
  }
  to {
    opacity: 1;
    margin-left: 0px;
  }
}
