sgx-platts-market-data-table [data-sort=false]>sgx-table-header-market-data.sgx-table-header-element {
  padding-left: 0px;
  width: 100%;
  border-right: solid 1px $sgx-theme-v2-lower-emp-default;
}

.sgx-table-header-container {
  sgx-platts-market-data-table sgx-table sgx-table-column-headers-manager & {
    height: 52px;
  }
}

.sgx-table-header-resizer {
  sgx-platts-market-data-table sgx-table sgx-table-column-headers-manager sgx-table-header-market-data + & {
    display: none;
  }
}

.sgx-table-header-market-data {
  sgx-platts-market-data-table sgx-table sgx-table-column-headers-manager & {
    border-right: solid 1px $sgx-theme-v2-lower-emp-default;
  }
}

.sgx-header-row-group {
  sgx-table-header-market-data & .sgx-row-group:first-child {
    text-align: left;
    text-indent: $space-2;
    border-bottom: solid 1px $sgx-theme-v2-lower-emp-default;
  }
  sgx-table-header-market-data & .sgx-row-group .sgx-col {
    text-align: left;
    text-indent: $space-2;

    &:not(:last-child) {
      border-right: solid 1px $sgx-theme-v2-lower-emp-default;
    }
  }
}

.sgx-header-row-group {
  .sgx-table-header-wrapper & {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
}

.sgx-row-group {
  height: 23px;
  min-height: 23px;
  max-height: 23px;
  line-height: 23px;

  display: flex;
  overflow-y: hidden;
  text-align: left;
  text-indent: $space-2;
  flex-direction: row;
  justify-content: space-around;
}

.sgx-row-group .sgx-col {
  flex: 1;
}

.sgx-row-group.sgx-multi-colspan {
  width: 100%;
  justify-content: left;
}

.sgx-header-row-group {
  sgx-table-header-market-data & {
    height: 49px;
    min-height: 49px;
    max-height: 49px;
    line-height: 49px;
  }
}
