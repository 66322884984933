$community-user-plus-tab-bg: #f5f5f5 !default;
$community-user-plus-tab-bg-hover: #000 !default;
$sgx-header-height: 50px;


template-community-user-page {
  width: 100%;
  height: 100%;
  font-family: Lato;
  display: flex;
  
  box-sizing: border-box;
  padding: 0px 1rem;
  overflow: hidden;
}

.sgx-card {
  template-community-user-page & {
    height: calc(100vh - 60px);
  }
}

body[data-type='mobile'] {
  template-community-user-page {
    padding: 0px;
  }
}
