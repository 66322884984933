$page-tabs-padding: 0 1rem !default;
$page-tabs-tablet-padding: 0 1rem !default;
$page-tabs-mobile-padding: 0 !default;
.page-tabs {
  font-size: 88%;
  background: $sgx-tabmenu-link-background;
  border-bottom: 4px solid $sgx-tabmenu-link-active-background;
  margin: $page-tabs-padding;
  display: block;
  .sgx-tabmenu-link {
    background: transparent;
  }
}

body[data-type="tablet"] {
  .page-tabs {
    padding: $page-tabs-tablet-padding;
  }
}
