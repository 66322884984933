.page-table-title {
	position: relative;
	font-size: 1rem;
	line-height: 2.5rem;
	border: 1px solid #ddd;
	color: $sgx-blue;
	background: #f5f5f5 no-repeat;
	background-position: 0.5rem center;
	background-size: 1.5rem;
	padding-left: 2.5rem;

	body[data-type=mobile] &{
		font-size:0.9rem;
		line-height: 2rem;
	}
}

.page-table {
	min-width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	border-left: 1px solid #ccc;
	border-top: 1px solid #ccc;

	body[data-type=mobile] &{
		font-size:0.9rem;
	}

	td,th {
		padding: 0.5rem;
		vertical-align: middle;
	}

	th {
		color: white;
		background: $sgx-blue;
		border-right: 1px solid black;
		border-bottom: 1px solid black
	}

	td {
		color: $sgx-dark-grey;
		border-right: 1px solid #ddd;
		border-bottom: 1px solid #ddd
	}

	tr:nth-child(even) td {
		background: #f5f5f5;
	}

	tr:nth-child(odd) td {
		background: #fff;
	}

	tr:hover td {
		background-color: rgba($sgx-light-blue, 0.1);
	}
}

.page-table--prices {
	font-size: 0.875rem;

	th,td {
		text-align: right;
	}

	th:first-child,
	td:first-child {
		text-align: left;
	}

	body[data-type=mobile] &{
		font-size:0.8rem;
	}

	// overwrite default flash animation
	// because it is very flaky for tables
	// due to setting position relative on cells
	td.table-decorator-flash {
		position: static !important;
		animation: flash_shadow 3s;

		&:after {
			display: none !important;
		}
	}
}

@keyframes flash_shadow {
  20% { box-shadow: inset 0 0 1.5rem 0px currentColor; }
}
