// TOTO: Need to replace the below two lines of theme-color with respective variables
// once all the colors are readily available in the base code
$sgx-news-featured-bg-color: theme-color($sgx-theme-v2-functional-03, tint 30%, tint 30%);
$sgx-news-category-item-color: theme-color($sgx-theme-v2-functional-03, shade 30%, tint 50%);

sgx-list-news-row {
  display: block;

  .sgx-news-row {
    padding-top: $space-2;
    padding-bottom: $space-2;
    border-bottom: solid 1px $sgx-theme-v2-lowest-emp-default;
  }

  .sgx-news-title {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sgx-news-title a {
    color: $sgx-theme-v2-high-emp-default;
    &:hover {
      text-decoration: underline;
    }
  }

  .sgx-news-flag-featured {
    display: inline-block;
    width: 60px; // Font size changed from 8px to 10px, so width also changed from 45px to 60px;
    height: 14px;
    padding-left: 2px;
    position: relative;
    vertical-align: middle;
    line-height: $space-4; // This is to vertical align the text inside featured box
    background-color: $sgx-news-featured-bg-color;
    color: $sgx-theme-v2-high-emp-alt-reverse;
  }

  .sgx-news-others {
    padding-top: $space-1;
    color: $sgx-theme-v2-medium-emp-default;

    span {
      display: inline-block;
      padding-left: $space-2;

      &:first-child {
        padding-left: 0px;
      }
    }
  }

  .sgx-news-time {
    padding-left: $space-2;
  }
}
