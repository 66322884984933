$sgx-prices-card-text-colour: theme-color($sgx-theme-text-01, shade 70%, tint 95%);

sgx-prices-card {
  display: block;
  color: $sgx-prices-card-text-colour;

  /*value and caret*/
  .sgx-price-card-value {
    font-size: 1em;
    line-height: 1.4em;
    font-weight: 400;
  }

  .sgx-price-card-value:before,
  .sgx-price-card-value:after {
    font-size: 1.3em;
    line-height: 1.4em;
    font-family: fontAwesome;
    vertical-align: middle;
  }

  .sgx-price-card-value:before {
    padding-right: .3em;
  }

  .sgx-price-card-value:after {
    padding-left: .3em;
  }

  .sgx-price-card-change {
    font-size: .7em;
    line-height: 1.4em;
  }

  /* change caret/change color based on price change */
  &.sgx-price-card--up {
    &.sgx-price-card--before .sgx-price-card-value:before,
    &.sgx-price-card--after .sgx-price-card-value:after {
      content: '\f0d8';
    }

    .sgx-price-card-value:before,
    .sgx-price-card-value:after,
    .sgx-price-card-change {
      color: $green;
    }
  }

  &.sgx-price-card--down {
    &.sgx-price-card--before .sgx-price-card-value:before,
    &.sgx-price-card--after .sgx-price-card-value:after {
      content: '\f0d7';
    }

    .sgx-price-card-value:before,
    .sgx-price-card-value:after,
    .sgx-price-card-change {
      color: $sgx-red;
    }
  }

  /* change font/caret size based on size level e.g. sm, md, lg */
  &.sgx-price-card--md {
    font-size: 24px;
  }
}