$no-settings-icon: url(sgx-base-code/svgs/regular/cogs.svg);

$mobile-order-book-font-size-very-large: 16px;
$mobile-order-book-font-size-large: 14px;
$mobile-order-book-font-size-normal: 12px;

template-mobile-trading-page {
  height: 100%;
  width: 100%;
  font-family: Lato;
  display: flex;
  flex-direction: column;

  .pages-content {
    height: 100%;
    padding-top: 0px !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .mobile-menu-header {
    font-size: $mobile-order-book-font-size-very-large;
    font-weight: bold;
    padding-top: $space-3;
    padding-left: $space-2;
    padding-bottom: $space-1;
    color: $sgx-theme-v2-high-emp-default;
    background-color: $ui-mobile-order-book-title-color;
  }

  .mobile-order-book-tabs-content {
    width: 100%;
    .sgx-tabs-container {
      padding-left: $space-2;

      .sgx-tab:nth-of-type(1) {
        margin-left: 2px;
      }
    }
  }

  .mobile-order-book {
    height: 100%;
    padding-top: $space-1;
  }

  .mobile-order-book-product{
    display: flex;

    sgx-input-select {
      flex: 1 1 60%;
      font-size: $mobile-order-book-font-size-large;
      min-width: 210px;
      max-width: 230px;
      input {
        font-size: $mobile-order-book-font-size-large;
        text-align: left !important;
        padding-left: $space-2;
      }
    }

    .mobile-order-book-detail-view{
      flex: 2 2 40%;
      position: relative;

      span {
        position: absolute;
        right: 0px;
        cursor: pointer;
        color: $ui-text-default;

        &:before {
          position: absolute;
          right: 0.5rem;
          line-height: 36px;
          icon-font: url('@sgx/sgx-base-code/svgs/regular/list-alt.svg');
          font-size: 18px;
        }
      }
    }
  }

  .mobile-order-book-no-settings-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-order-book-no-settings-icon {
    margin-top: $space-18;
    margin-bottom: $space-8;

    &:before {
      icon-font: $no-settings-icon;
      font-size: 140px;
      color: $sgx-mobile-order-cogs;
    }
  }

  .mobile-order-book-no-settings-text {
    width: 60%;
    text-align: center;
    color: $sgx-mobile-order-empty;
  }
}