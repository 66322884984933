sgx-tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100%;
}

.sgx-tab-container-tabs {
  width: 100%;
  justify-content: center;
  // to make symmetrical and thus flex centred correctly
  padding-right: $space-3;
  padding-top: $space-2;
}

sgx-tab-container [data-visible="false"] {
  display: none;
}
