// // FOR DEMO purposes ONLY!
$table-action-filter: $totc-light-purple !default;
$plattsIcons: '../static/images/platts_header_icon.svg';
$table-action-icon-color: theme-color(
  $sgx-theme-text-01,
  shade 70,
  tint 95
); // this has to be done, because that has not been implemented yet

.table-action {
  &:before,
  &:after {
    color: $table-action-icon-color;
  }
  .sgx-table-toolbar-icon {
    color: $table-action-icon-color;
  }
}

#page-container {
  height: 100%; // for mobile view 32px for the tab height
}

#website-header {
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
  height: 2.9rem;
  z-index: 1000;
}

// to avoid the weird scroll at the end
body[data-type="mobile"] {
  position: fixed;
}

// sgx-header.sgx-header--menu-open .sgx-header-sidebar {
//   height: 100vh;
// }

// #website-header,
// #sub-menu {
//   &.hidden {
//     display: none;
//   }
// }

.sgx-dialog-header {
  margin-bottom: 0px;
}

.lm_content {
  @if (lightness($ui-bg) < 50) {
    background-color: $ui-item-row;
    border: none !important;
  } @else {
    background-color: $totc-grey-4;
  }
}

.sgx-dialog-button-dismiss,
.sgx-dialog-footer .sgx-button--link,
sgx-state-tabs-dialog-body {
  color: $ui-text-default;
}

sgx-table-toolbar-filter {
  @if (lightness($ui-bg) >50) {
    > .filter-label {
      background: $totc-blue !important;
    }
  } @else {
    border: none;
    background-color: $totc-dark-grey-2;
    > .filter-label {
      background: $black !important;
    }
  }
}

// golden layout overwriting
.lm_header .lm_tab {
  border: none;
}

.lm_tabdropdown_list {
  .lm_tab {
    right: 5px !important;
    top: 5px !important;
    line-height: 2.2rem !important;
  }
}

.lm_header .lm_tabdropdown_list .lm_tab .lm_title {
  height: 100%;
}

sgx-table-toolbar,
.table-filters-container {
  @if (lightness($ui-bg) < 50) {
    background-color: $ui-item-row !important;
  } @else {
    background-color: $totc-grey-4 !important;
  }
}

.sgx-table-header-wrapper {
  @if (lightness($ui-bg) < 50) {
    background-color: $ui-item-row !important;
  }
}

//
// order blotter state tabs
//
sgx-state-tabs {
  @if (lightness($ui-bg) < 50) {
    box-shadow: inset 0 -1px 0 0 $black;
  }
}

// table toolbar
@if (lightness($ui-bg) < 50) {
  sgx-table-toolbar .table-action.filters {
    color: $table-action-filter !important;
  }
}

sgx-table-toolbar > span:before,
sgx-table-toolbar span:before {
  color: $table-action-filter;
}

sgx-table-toolbar .table-action-label {
  color: $ui-text-link;
  margin-top: 1px;
}

// table
@if (lightness($ui-bg) <50) {
  .sgx-table-cell,
  sgx-table-column-headers-manager .sgx-table-header-container,
  body[data-type="desktop"] sgx-table .table-container,
  sgx-table-column-headers-manager .sgx-table-header-resizer {
    border-color: $black;
  }

  .sgx-header-row-group {
    sgx-table-header-market-data & .sgx-row-group:first-child {
      border-bottom: solid 1px $black;
    }
    sgx-table-header-market-data & .sgx-row-group .sgx-col:not(:last-child) {
      border-right: solid 1px $black;
    }
  }

  .sgx-table-header-market-data {
    sgx-platts-market-data-table sgx-table sgx-table-column-headers-manager & {
      border-right: solid 1px $black;
    }
  }

  sgx-platts-market-data-table [data-sort=false]>sgx-table-header-market-data.sgx-table-header-element {
    border-right: solid 1px $black;
  }

  .sgx-cell-col {
    sgx-platts-market-data-table sgx-table-cell-market-data .sgx-table-cell-row-group &:not(:last-child) {
      border-right: solid 1px $black;
    }
  }
}

// kebab
@if (lightness($ui-bg) <50) {
  #sgx-kebab-menu-dialog .sgx-kebab-menu-triangle {
    border-bottom: 10px solid $totc-dark-grey-2;
  }

  #sgx-kebab-menu-dialog .sgx-kebab-menu-triangle > div:before {
    border-bottom: 12px solid $ui-shadow;
  }
}

.sgx-flexible-layout-kebab-menu-container {
  color: $ui-text-default;
}

@if (lightness($ui-bg) <50) {
  #sgx-flexible-layout-kebab-menu-dialog .sgx-flexible-layout-tab-kebab-triangle {
    border-bottom-color: $totc-grey-1;
  }
  #sgx-flexible-layout-kebab-menu-dialog .sgx-flexible-layout-tab-kebab-triangle > div:before {
    border-bottom-color: #252626;
  }
  .sgx-state-tab,
  .sgx-state-tab-btn-add {
    color: #878787;
  }
  body[data-type="desktop"] sgx-table-list {
    background-image: linear-gradient(180deg, $totc-grey-1, $totc-grey-1 50%, $totc-grey-2 0, $totc-grey-2);
  }
  body[data-type="desktop"] sgx-table-list sgx-table-row:nth-child(odd) {
    background-color: $totc-grey-1;
  }
  body[data-type="desktop"] sgx-table-list sgx-table-row:nth-child(2n) {
    background-color: $totc-grey-2;
  }
}

.lm_header .lm_tab .lm_title {
  margin: 0;
  display: inline-block;
}

.lm_header .lm_tab .lm_close_tab {
  @extend .sgx-icon--before;
  background-image: none;
  &:before {
    icon-font: url("sgx-base-code/svgs/solid/times.svg");
    color: $sgx-theme-v2-medium-emp-default;
  }
}

.lm_header .lm_controls .lm_close {
  display: none !important;
}

.sgx-notifications-panel-footer-button {
  line-height: 0.6em;
}

.sgx-notifications-panel--attached {
  .sgx-notifications-panel-body-outer {
    overflow-y: auto;
  }
}

//notifications not wide enough for some data
.sgx-notifications-panel {
  width: 400px;
}

.sgx-input-checkbox-toggle,
.sgx-input-toggle {
  margin-bottom: 0;
  text-align: right;
  &--checked {
    .sgx-input-toggle-circle,
    .sgx-input-checkbox-toggle-circle {
      background-color: #fff;
    }
  }
  &-pill {
    background-color: #abadb5;
  }
  &-circle {
    background-color: #e7e8ea;
  }

  &[disabled] {
    color: #fff;
    cursor: not-allowed;
  }
}

sgx-input-checkbox-toggle,
sgx-input-toggle {
  &[disabled] * {
    color: #fff !important;
    cursor: not-allowed;
  }
}

// Flexible layout overrides
.lm_header .lm_controls .lm_tabdropdown:before {
  color: $ui-text-default !important;
}

.lm_header .lm_tabdropdown_list {
  background: $sgx-table-cell-action-menu-bg;
  color: $ui-text-default;
}

.lm_header .lm_tabdropdown_list .lm_tab {
  padding: 0 0 0 1rem;
  height: 2.2rem;
  position: relative;
  top: -3px;
}

// HOTFIX: Consider moving that logic to shared components?
// ======================================


.sgx-header-menu-icon--platts {
  .sgx-header-wrapper &.sgx-icon {
    background-image: url($plattsIcons);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 28px 28px;
    margin-top: $space-1;
    text-align: center;
    height: $space-8;
    width: $space-8;
    font-size: 28px;
    display: block;
  }
}

.sgx-header-menu-icon--orders::after {
  font-size: 28px;
}

.sgx-header-menu-icon--admin::after {
  font-size: 25px;
}
.sgx-header-menu-icon--home:after {
  font-size: 28px;
}
.sgx-header-menu-icon--trades:after {
  font-size: 24px;
}
.sgx-header-menu-icon--reports:after {
  font-size: 26px;
}
#sgx-header-sidebar-revision-info-env {
  font-size: 10px;
}
.sgx-header-menu-icon--admin:after {
  font-size: 25px;
}

.sgx-header-menu-icon--support:after {
  font-size: 25px;
}

.sgx-header-menu-item-wrapper {
  margin-bottom: 0.3rem;
}
// ======================================

// #sgx-header-sidebar-revision-info-env {
//   text-transform: lowercase !important;
// }

// #sgx-header-sidebar-revision-info {
//   padding: 0;
//   text-align: center;
// }

// sgx-header .sgx-header-button-container-utility {
//   padding-left: 0;
//   padding-right: 0;
// }

// leave space for header that appears at top on lower width screens
// breakpoint follows sgx-header
@media (max-width: 1023px) {
  .website-main-layout {
    margin-top: 48px;
  }
  .website-main-layout {
    height: calc(100% - 48px); // tab height
  }
}

// body[data-type="tablet"], body[data-type="mobile"] {
//   .sgx-header-sidebar {
//     height: 100%;
//   }
// }

// make sgx table config show above order history dialog (z-index increase by 1)
#sgx-table-config-dialog {
  z-index: 1002 !important;
}

//TODO: desktop order book change filter label sizes. Temporary, to be replaced by other component
sgx-order-book-filter-select {
  & sgx-input-checkbox {
    margin-top: auto;
    margin-bottom: auto;
  }

  & .sgx-input-label {
    font-size: 13px;
  }

  & .sgx-input-label:before {
    // fix icon and neighboring text span not looking aligned even when both are flex align-item centered vertically
    margin-bottom: 3px;
  }
}

// make notifications panel shrink width to screen if screen is less than panel default width (some mobile devices)
// TODO: should be added to shared component at some point

@media (max-width: 399px) {
  .sgx-notifications-panel {
    width: 100%;
  }
  sgx-notifications {
    width: 100%;
  }
}

// TODO: Kill it with fire when the style is finally ready and implemented in the core...
sgx-widget-news,
sgx-widget-publications,
sgx-widget-jobs,
#performance-tracker-dialog {
  sgx-list {
    background: transparent;
  }
}

sgx-status-indicator {
  opacity: 1;
  background: transparent;
  color: $sgx-theme-v2-medium-emp-default;
}

// resolve native checkbox showing up in sgx-table filter input-select, blocking Select All checkbox
// shows as fine in sgx-table demo because there is additional ccs ( possibly _normalize.css but unconfirmed due to css sourcemaps not working)
// that sets padding to 0, so that the element is only 1 by 1 pixel in size
sgx-input-checkbox .sgx-input-control {
  z-index: -1;
}

// TOTC-2710
#sgx-select-dialog {
  z-index: 2000; // weird times, it goes behind the existing dialog.
}

// override for setting the maximize and min control
.lm_header .lm_controls > li {
  opacity: 1;
}
