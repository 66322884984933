$sgx-appheader-header-text: theme-color($sgx-theme-text-02, tint 60%, tint 45%) !default;

.sgx-header-lang {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 48px;
  font-size: 16px;
  cursor: pointer;
}
