$mobile-order-book-row-height: 2rem !default;
$mobile-order-book-border: $sgx-theme-v2-lowest-emp-default !default;

$widget-mobile-font-size-large: 14px;
$widget-mobile-font-size-normal: 12px;
$widget-mobile-font-size-small: 10px;

$widget-mobile-last-price-up: #72af00 !default;
$widget-mobile-last-price-down: #f30643 !default;

sgx-widget-mobile-order-book {
  display: flex;
  
  sgx-contract-view{
    flex: 1 1 30%;
    font-size: $widget-mobile-font-size-normal;
    background-color: inherit !important;

    .sgx-t-volume,
    .sgx-last-price,
    .sgx-strike-price {
      color: $ui-text-bright-grayed !important;
    }

    .sgx-last-price {
      padding-right: 4px !important;
    }

    .sgx-contract-view-list {
      background-image: none;
    }

    .sgx-contract-view-item {
      height: 50px;
      min-height: initial;
      padding-top: $space-2;
      padding-left: $space-2;
      padding-bottom: $space-2;
      border-bottom: solid 1px $mobile-order-book-border;
    }

    .sgx-contract-view-icon {
      height: 37px;
      position: relative;

      svg {
        position: absolute;
        right: $space-2;
        width: 5px;
        height: 10px;
      }
    }

    .sgx-contract-view-row-wrapper {
      width: 100%;

      .sgx-contract-view-text {
        display: inline-block;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px !important;
        white-space: nowrap;
        font-size: $widget-mobile-font-size-normal;
      }

      .sgx-contract-view-strike-price {
        font-size: $widget-mobile-font-size-normal;
        white-space: nowrap;
        padding-left: $space-2;
        color: $ui-text-bright-grayed;
      }

      .sgx-contract-view-last-price {
        flex: 1 1 52.25px;
        font-size: $widget-mobile-font-size-normal;
        white-space: nowrap;
        min-width: 52.25px;
        max-width: 52.25px;

        .sgx-contract-view-last-price-label {
          font-size: $widget-mobile-font-size-small;
        }

        .sgx-contract-view-last-price-value {
          color: $ui-text-bright-grayed;
        }
      }

      .sgx-contract-view-total-volume {
        flex: 1 1 auto;
        font-size: $widget-mobile-font-size-normal;
        white-space: nowrap;
        
        .sgx-contract-view-total-volume-label {
          font-size: $widget-mobile-font-size-small;
        }

        .sgx-contract-view-total-volume-value {
          color: $ui-text-bright-grayed;
        }
      }
      
    }

    .sgx-contract-view-item--wrapper{
      min-height: calc(50px - 2 * #{$space-2});
      max-height: calc(50px - 2 * #{$space-2});
      flex-wrap: nowrap;
      padding-left: 0px;
      padding-top: 0px;

      &[data-last-price-color="up"] {
        .sgx-contract-view-last-price-value {
          color: $widget-mobile-last-price-up;
        }
      }

      &[data-last-price-color="down"] {
        .sgx-contract-view-last-price-value {
          color: $widget-mobile-last-price-down;
        }
      }
    }

    .sgx-contract-view-header {
      padding-left: $space-2;
      background-color: $ui-mobile-order-book-header-color;
      border-top: solid 1px $mobile-order-book-border;
      border-bottom: solid 1px $mobile-order-book-border;
    }

    &[data-detail-mode="true"] {
      &[data-landscape-view="false"] {
        .sgx-contract-view-icon {
          height: 15px;
        }

        .sgx-contract-view-row-wrapper {
          .sgx-contract-view-row {
            &:nth-of-type(1) {
              margin-top: -2px;
            }
  
            &:nth-of-type(2) {
              padding-top: 4px;
            }
          }
        }

        .sgx-contract-view-strike-price {
          padding-top: 1px;
        }
      }

      .sgx-contract-view-header {
        padding: 0.5rem 0.5rem 0.5rem $space-2;
        height: calc(2 * #{$mobile-order-book-row-height} - 14px);
      }

      .sgx-contract-view-header-row{
        height: $mobile-order-book-row-height;
        white-space: nowrap;
      }
    }

    &[data-detail-mode="true"][data-landscape-view="true"],
    &[data-detail-mode="false"]{
      .sgx-contract-view-item--wrapper {
        line-height: calc(50px - 2 * #{$space-2});
      }

      .sgx-contract-view-header {
        height: $mobile-order-book-row-height;
        padding: 0 0 0 $space-2;

        .sgx-contract-view-header-row .sgx-contract {
          line-height: $mobile-order-book-row-height;
          white-space: nowrap;
        }
      }
    }
  }

  .mobile-order-book-orders{
    flex: 2 2 70%;
    display: flex;
    flex-direction: column;

    sgx-list {
      height: 100%;
      border-top: 0px;
      border-bottom: 0px;
      border-left: 0px;
      border-left-color: $mobile-order-book-border;
      border-right-color: transparent;
      background-color: inherit;
    }
  }
}