sgx-platts-widget-metals-alert-row {
  display: block;
  max-height: 52px;

  .sgx-platts-widget-metals-alert-row-container {
    text-align: left;
    padding: $space-2 $space-3;

    & > *:not(:last-child) {
      margin-bottom: $space-2;
    }

    .sgx-platts-widget-metals-alert-row-headline {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: $space-1;

      max-width: 140px + $space-4 + 230px; // From .sgx-list-details-list-toolbar-row:last-child
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sgx-platts-widget-metals-alert-row-metadata {
      position: relative;
      font-size: 12px;
      line-height: $space-4;
      margin-right: $space-2;

      color: #777777;

      & > :first-child {
        display: inline-block;

        & > * {
          margin-right: $space-3;
        }
      }

      // Normalize metadata blurring due to gradient (from Flash rows):
      & > :first-child {
        background: -webkit-linear-gradient(0.25turn, #6c6c6c, 100%, transparent);
        background: linear-gradient(0.25turn, #6c6c6c, 100%, transparent);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        overflow: hidden;
        white-space: nowrap;
      }

      // Fade out right-most text to not clash with Flash tag:
      &.sgx-platts-widget-metals-alert-row-flash {
        & > :first-child {
          background: -webkit-linear-gradient(0.25turn, #6c6c6c, 85%, transparent);
          background: linear-gradient(0.25turn, #6c6c6c, 85%, transparent);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        & > :last-child {
          display: inline-block;

          position: absolute;
          top: 0;
          right: 0;
          padding: 1px $space-1;
          font-size: 9px;
          font-weight: 200;
        }
      }
    }

    .sgx-platts-widget-metals-alert-row-flash-tag {
      height: 15px;
      border-radius: 1px;
      background-color: $v2-blue;
      color: #fff;
      text-align: center;
      line-height: 15px;
      font-size: 11px;
    }
    .sgx-platts-widget-metals-alert-row-flash-tag--hidden {
      display: none;
    }
  }

  &.sgx-platts-widget-metals-alert-row--selected {
    background-color: $sgx-theme-v2-bg-interaction-set-05-hover;
  }
}
