// TODO: Converge this and duplicate `template-settings-page.scss`
// into a single stylesheet.

$sgx-tab-menu-height: 45px;

template-community-settings-page {
  height: 100%;
  .pages-content {
    display: flex;
    height: calc(100% - #{$sgx-tab-menu-height});
    overflow-y: auto;
  }

  .sgx-card {
    position: relative;
    padding-left: $space-5;
    padding-right: $space-5;
  }

  .sgx-card:last-child {
    margin-left: $space-5;
  }

  .sgx-user-profile-content-chk-mailing-list {
    .sgx-input-label {
      color: $sgx-readonly-text-title;
      font-size: 14px;
    }
  }

  // Hiding Settings column when switching to Profile Edit mode:
  .sgx-user-profile-content-parent--hidden {
    visibility: hidden;
    width: 0;
    height: 0;
    margin: 0 !important;
    padding: 0 !important;
  }
  // Make Profile column width half the width
  .sgx-user-profile-content-parent--view {
    sgx-user-profile-content {
      width: 50%;
    }
  }
}

body[data-type="mobile"] {
  template-community-settings-page {
    .pages-content {
      flex-wrap: wrap;
    }

    .sgx-card:last-child {
      margin-left: 0;
    }

    // Hiding Settings column when switching to Profile Edit mode:
    // Keep Profile column width 100% in mobile view.
    .sgx-user-profile-content-parent--view {
      sgx-user-profile-content {
        width: 100%;
      }
    }
  }
}

body[data-type="tablet"] {
  template-community-settings-page {
    .pages-content {
      padding-top: 0;
    }
  }
}
