$sgx-confirmation-dialog-icon--success: $ui-state-success !default;
$sgx-confirmation-dialog-icon--error: $ui-state-error !default;

.template-platts-terms-and-conditions-wrapper {
  height: 100%;
}

.template-platts-terms-and-conditions {
  .template-platts-terms-and-conditions-wrapper & {
    height: 100%;
  }
}

sgx-platts-tnc-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: stretch;
}

main {
  sgx-platts-tnc-page &.sgx-card {
    height: 80%;
    padding: $space-5;
    position: relative;
    margin-top: 10%;
  }
}

.sgx-platts-tnc-page-content-container {
  .sgx-platts-tnc-page-main & {
    height: calc(100% - 81px);
  }
}

.sgx-platts-tnc-page-wrapper {
  sgx-platts-tnc-page & {
    width: 1092px;
    margin: 0 auto;
    padding: 0 $space-5;
    box-sizing: border-box;
    height: calc(100% - 64px);
  }

  body[data-type="mobile"] sgx-platts-tnc-page & {
    width: 100%;
  }
}

.sgx-platts-tnc-page-page-logo {
  sgx-platts-tnc-page & {
    clear: both;
    display: block;
    top: 10px;
    left: 10px;
    width: 80px;
    height: 45px;
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.sgx-platts-tnc-page-content {
  .sgx-platts-tnc-page-wrapper & {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .sgx-platts-tnc-page-wrapper & sgx-alert-bar {
    margin-top: $space-5;
  }
}

.sgx-platts-tnc-page-content-holder {
  .sgx-platts-tnc-page-wrapper & {
    height: 100%;
    overflow-y: scroll;
    padding: $space-5 0 0 0;
  }

  .sgx-platts-tnc-page-wrapper & p {
    margin: 0px;
    padding: 0 0 $space-2 0;
    padding-top: 1.2em;
    @extend .sgx-base-text-body-14;
    color: $sgx-theme-v2-medium-emp-default;
  }

  .sgx-platts-tnc-page-wrapper & ol {
    color: $sgx-theme-v2-medium-emp-default;
  }

  .sgx-platts-tnc-page-wrapper & ol li {
    padding-top: 1.2em;
    @extend .sgx-base-text-body-14;
    color: $sgx-theme-v2-medium-emp-default;
    list-style: decimal;
    list-style-position: inside;
  }

  .sgx-platts-tnc-page-wrapper & ol li p {
    @extend .sgx-base-text-body-14;
    text-align: justify;
    display: inline;
  }

  .sgx-platts-tnc-page-wrapper & > ol > li > p::before {
    content: '\00a0\00a0\00a0';
  }

  .sgx-platts-tnc-page-wrapper & ol li ul {
    padding-left: $space-5;
  }

  .sgx-platts-tnc-page-wrapper & ol li ul li {
    list-style: lower-alpha;
    list-style-position: inside;
  }

}

button {
  .sgx-platts-tnc-page-wrapper .sgx-platts-tnc-page-controls & {
    margin-left: $space-3;
  }
}

.sgx-section {
  .sgx-platts-tnc-page-wrapper & {
    margin-top: $space-8;
    width: 100%;
    background-color: $ui-bg;
    box-shadow: 0 1px 4px $sgx-card-shadow-color;
  }
}

.sgx-platts-tnc-page-controls {
  .sgx-platts-tnc-page-wrapper & {
    display: flex;
    padding: $space-5 0px 0px;
    justify-content: flex-end;
  }
}

h2 {
  .sgx-platts-tnc-page-header &.sgx-platts-tnc-page-title {
    @extend .sgx-base-text-title-20;
    color: $sgx-theme-v2-medium-emp-default;
  }
}
