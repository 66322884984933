$status-index: (
  "neutral": (
    $sgx-theme-v2-medium-emp-default, // Grey
    info
  ),
  "informational": (
    $sgx-theme-v2-functional-03, // Blue
    info-alt
  ),
  "success": (
    $sgx-theme-v2-functional-02, // Green
    success
  ),
  "warning": (
    $sgx-theme-v2-functional-04, // Yellow
    warning
  ),
  "alert": (
    $sgx-theme-v2-functional-01, // Red
    warning
  ),
  "error": (
    $sgx-theme-v2-functional-01, // Red
    error-alt
  )
);

@each $status, $values in $status-index {
  $color: nth($values, 1);
  $icon: nth($values, 2);

  sgx-alert-bar[data-status=#{$status}] {

    .sgx-alert-bar-accent {
      background-color: $color;
    }

    .sgx-alert-bar-icon::before {
      @include sgx-icon($icon);
      color: $color;
    }
  }
}

sgx-alert-bar {
  display: flex;
}

sgx-alert-bar.sgx-alert-bar--hide {
  display: none;
}

sgx-alert-bar[data-background=true] {
  background-color: $sgx-theme-v2-lowest-emp-default;
}

sgx-alert-bar[data-background=true] > .sgx-alert-bar-accent {
  width: $space-1;
  align-self: stretch;
  flex-shrink: 0;
}

sgx-alert-bar .sgx-alert-bar-icon {
  margin: $space-2;
  line-height: 1.2;
}

sgx-alert-bar p.sgx-alert-bar-text {
  padding: unset;
  color: $sgx-theme-v2-high-emp-default;
  margin: $space-2 $space-2 $space-2 0;
  flex-grow: 1;
}

sgx-alert-bar:not([data-static])[data-position=top] {
  top: 0px;
  left: 0px;
  z-index: 2000;
  position: absolute;
  width: 100%;
}

sgx-alert-bar:not([data-static])[data-position=bottom] {
  bottom: 0px;
  left: 0px;
  z-index: 2000;
  position: absolute;
  width: 100%;
}
