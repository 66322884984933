$suspend-indicator-order-type-0: $ui-text-default !default; // Regular orders
$suspend-indicator-order-type-1: $ui-text-default !default; // Prechecked orders
$suspend-indicator-order-type-2: $sgx-yellow !default;
$suspend-indicator-hover-color: $ui-border-primary !default;
$suspend-indicator-white: $ui-item-row !default;
$suspend-indicator-corel: #ff6666 !default;
$suspend-indicator-up: #72af00 !default;
$suspend-indicator-down: #f30643 !default;
$suspend-indicator-neutral-flash: #c0d730 !default;
$suspend-indicator-odd-row: #fff !default;
$suspend-indicator-even-row: #f5f5f5 !default;
$suspend-row-cell-text: theme-color($sgx-dark-grey, tint 10, tint 45);
$suspend-row-bg-color: theme-color($sgx-dark-grey, tint 85, shade 25);
$suspend-row-border-color: theme-color($sgx-dark-grey, tint 75, shade 15);

.sgx-table-cell {
  sgx-table-row.suspend-indicator--bid &[data-column-id="bidId"],
  sgx-table-row.suspend-indicator--bid &[data-column-id="bidQuantity"],
  sgx-table-row.suspend-indicator--bid &[data-column-id="bidPrice"] {
    color: $suspend-row-cell-text;
    background-color: $suspend-row-bg-color;
    border-top: solid 1px $suspend-row-border-color;
    border-bottom: solid 1px $suspend-row-border-color;
  }

  sgx-table-row.suspend-indicator--ask &[data-column-id="askId"],
  sgx-table-row.suspend-indicator--ask &[data-column-id="askQuantity"],
  sgx-table-row.suspend-indicator--ask &[data-column-id="askPrice"] {
    color: $suspend-row-cell-text;
    background-color: $suspend-row-bg-color;
    border-top: solid 1px $suspend-row-border-color;
    border-bottom: solid 1px $suspend-row-border-color;
  }

  sgx-table-row.suspend-indicator--all &[data-column-id="askId"],
  sgx-table-row.suspend-indicator--all &[data-column-id="askQuantity"],
  sgx-table-row.suspend-indicator--all &[data-column-id="askPrice"],
  sgx-table-row.suspend-indicator--all &[data-column-id="bidId"],
  sgx-table-row.suspend-indicator--all &[data-column-id="bidQuantity"],
  sgx-table-row.suspend-indicator--all &[data-column-id="bidPrice"] {
    color: $suspend-row-cell-text;
    background-color: $suspend-row-bg-color;
    border-top: solid 1px $suspend-row-border-color;
    border-bottom: solid 1px $suspend-row-border-color;
  }
}
