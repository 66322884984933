$sgx-jobs-featured-bg-color: theme-color($sgx-theme-v2-functional-03, tint 30%, tint 30%);
$sgx-jobs-category-item-color: theme-color($sgx-theme-v2-functional-03, shade 30%, tint 50%);

sgx-list-jobs-row {
  display: block;

  .sgx-jobs-row {
    padding-top: $space-2;
    padding-bottom: $space-2;
    border-bottom: solid 1px $sgx-theme-v2-lowest-emp-default;
  }

  .sgx-jobs-title {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sgx-jobs-title a {
    color: $sgx-theme-v2-high-emp-default;
    &:hover {
      text-decoration: underline;
    }
  }

  .sgx-jobs-others {
    padding-top: $space-1;
    color: $sgx-theme-v2-medium-emp-default;

    span {
      display: inline-block;
      padding-left: $space-2;

      &:first-child {
        padding-left: 0px;
      }
    }
  }

  .sgx-jobs-time {
    padding-left: $space-2;
  }
}
