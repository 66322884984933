$order-book-border: #b6bdd0 !default;

sgx-widget-platts-historical {
  height: 100%;
  min-height: 100%;
  position: relative;
}

.sgx-widget-platts-historical {
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sgx-platts-header-container {
  .sgx-widget-platts-historical & {
    width: 100%;
    min-height: 30px;
    max-height: 30px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 18px;
  }

  .sgx-widget-platts-historical & .header-section-left {
    text-align: left;
  }

  .sgx-widget-platts-historical & .header-section-left {
    text-align: left;
  }
}

.sgx-platts-view-container {
  .sgx-widget-platts-historical & {
    width: 100%;
    overflow-y: hidden;
    box-sizing: border-box;
    height: calc(100% - 50px);
    min-height: calc(100% - 50px);
  }
}

.code-block-container {
  .sgx-widget-platts-historical .sgx-platts-view-container & {
    height: 100%;
    position: relative;
  }
}

.code-block-container:not(.active) {
  .sgx-widget-platts-historical .sgx-platts-view-container & {
    display: none;
  }
}

sgx-tabs[data-type='state'] {
  sgx-widget-platts-historical & {
    background-color: transparent;
    height: 30px;
  }
}

.sgx-tabs-scroll-wrapper {
  sgx-widget-platts-historical .sgx-platts-header-container sgx-tabs & {
    background-color: $sgx-theme-v2-bg-basic-07;
  }
}

// Extended Dialog Style
.sgx-platts-dialog-content {
  .sgx-platts-expanded-view-modal .sgx-dialog-box & {
    height: calc(100% - 30px);
  }
}

.sgx-platts-dialog-content {
  .sgx-platts-expanded-view-modal .sgx-dialog-box & sgx-chart {
    height: 100%;
  }
}

.sgx-market-view-select-header {
  .sgx-platts-expanded-view-modal & {
    width: 100%;
  }
}

// Overriding Tab Styles
sgx-tabs {
  sgx-widget-platts-historical & {
    height: $space-7;
  }

  sgx-widget-platts-historical & .sgx-tabs-scroll-wrapper {
    border-radius: 2px;
    line-height: $space-7;
  }

  sgx-widget-platts-historical & .sgx-tabs-scroll-wrapper ul.sgx-tabs-container {
    border-radius: 2px;
    line-height: $space-7;
  }

  sgx-widget-platts-historical & .sgx-tabs-scroll-wrapper  ul.sgx-tabs-container li.sgx-tab {
    border-radius: 2px;
    line-height: $space-7;
    @extend .sgx-base-text-body-14;
  }
}
