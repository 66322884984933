sgx-widget-partner-metrics-table {
  position: relative;

  // NOTE: Found no better way of doing this. Otherwise 
  // need to update sgx-table in Core.
  sgx-table-toolbar {
    display: none;
  }
  sgx-scroll-shield {
    top: 2rem;
  }

  .sgx-widget-partner-metrics-table-container {
    position: relative;

    .sgx-widget-partner-metrics-table-content {
      height: 100%;

      .sgx-widget-partner-metrics-table-table {
        height: 100%;
      }
    }

    .sgx-widget-partner-metrics-table-bottom {
      margin-top: $space-5;
      display: flex;

      .sgx-widget-partner-metrics-table-footer,
      .sgx-widget-partner-metrics-table-footer p,
      .sgx-widget-partner-metrics-table-timestamp {
        font-size: 12px;
        line-height: 12px;
        color: $sgx-theme-v2-medium-emp-default;
        flex: 0 1 50%;
      }

      .sgx-widget-partner-metrics-table-footer {
        & > :first-child,
        & > :first-child > :first-child {
          margin-top: 0;
        }
        
        & > :last-child,
        & > :last-child > :last-child {
          margin-bottom: 0;
        }

        & > *,
        & > * > * {
          margin-left: 0;
        }
      }

      .sgx-widget-partner-metrics-table-timestamp {
        text-align: right;
      }
    }
  }
}