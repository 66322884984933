$sgx-status-bar-error: #f2dede;
$sgx-status-bar-information: #d9edf7;
$sgx-status-bar-success: #dff0d8;
$sgx-status-bar-warning: #fcf8e3;
$sgx-status-bar-height: 48px;
$sgx-nav-bar-width: 4rem;

[data-alert-type='error'] {
  background: $sgx-status-bar-error;
}

[data-alert-type='information'] {
  background: $sgx-status-bar-information;
}

[data-alert-type='success'] {
  background: $sgx-status-bar-success;
}

[data-alert-type='warning'] {
  background: $sgx-status-bar-warning;
}

sgx-status-bar {
  height: 3rem;
  bottom: 0;
  left: $sgx-nav-bar-width;
  right: 0;
  z-index: 9999;
  transition: transform .3s;
  .sgx-status-bar-wrapper {
    border-radius: 2px;
    margin: 0;
    padding: 0;
    transition: all 0.18s ease-out;
    transform: translate3d(0px, 0px, 0px);
    will-change: transform;
    &[data-alert-type='error'],
    &[data-alert-type='information'],
    &[data-alert-type='success'],
    &[data-alert-type='warning']{
      padding: 1rem .8rem;
    }
    &.sgx-status-bar-sticky {
      position: fixed;
      margin: 0;
      bottom: 0;
      border-radius: 0;
      left: $sgx-nav-bar-width;
      right: 0;
      .sgx-status-bar-close-btn {
        right: .7rem;
      }
    }
  }

  .sgx-status-bar-message {
    font-size: .95em;
    text-transform: initial;
  }

  .sgx-status-bar-close-btn {
    cursor: pointer;
    opacity: .55;
    position: absolute;
    right: .8rem;
    top: 1rem;
    &:hover,
    &:active {
      opacity: 1;
    }
    &.sgx-status-bar-close-btn--hidden {
      display: none;
    }
    span {
      font-size: .85em;
      text-indent: -9999em;
      position: absolute;
    }
    &:after {
      font-family: 'fontAwesome';
      content: '\f00d';
      display: block;
    }
  }
}

body[data-type='mobile'],
body[data-type='tablet'],
body[data-type='desktop'][data-resolution='small'] {
  sgx-status-bar {
    .sgx-status-bar-wrapper {
      &.sgx-status-bar-sticky {
        left: 0;
      }
    }
  }
}
