$sgx-order-book-product-name-max-width: 206px;
$sgx-order-book-grid-view-flag-font-size: 12px;
$sgx-order-book-grid-view-flag-height: 15px;
$sgx-order-book-grid-view-flag-width: 17px;
$sgx-order-book-grid-view-flag-margin: 4px;
$sgx-order-book-grid-view-header-top-height: 30px;
$sgx-order-book-grid-view-flag-padding: 2px;
$sgx-order-book-grid-view-header-top-height: 30px;
$sgx-table-border-color: $cloudy-blue !default;

sgx-order-book-grid-view {
  border-right: 1px solid $sgx-table-border-color;
  display: flex;
  flex: auto;
  margin: 0 2px 0 0;
  flex-direction: column;
  &:last-child {
    margin: 0;
    border-right: 0;
  }
  svg {
    pointer-events: none;
  }
  .sgx-order-book-grid-view-topbar {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 0 0 auto;
    height: $sgx-order-book-grid-view-header-top-height;

    .sgx-order-book-grid-view-topbar-controls {
      text-align: right;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      .sgx-tool-settings {
        width: 70px;
        text-align: left;
        display: inline-block;
        padding-left: $space-1;
        font: normal 13px/1.1 Lato,HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif;
      }
    }

    .sgx-order-book-grid-view-topbar-controls-session-timer {
      font-size: 0.8rem;
      white-space: nowrap;
      color: $ui-text-default-grayed;
      span {
        color: theme-color($sgx-theme-functional-01, shade 10);
      }
    }

    .sgx-order-book-grid-view-product-meta {
      display: flex;
      width: 100%;
      align-items: center;
      flex-basis: 60rem; // so that items dont break line when on different lengths/screen size.
    }
  }

  &[data-browser='ie']{
    .sgx-order-book-grid-view-topbar-controls sgx-order-book-filter-select sgx-input-toggle{
      .sgx-input-toggle-centrebox{
        flex: 0 0 auto;
      }

      .sgx-input-toggle-sidebox{
        flex: 0 0 auto;
      }
    }
  }

  .sgx-order-book-grid-view-topbar-product-name-content {
    max-width: $sgx-order-book-product-name-max-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
  }

  .sgx-order-book-grid-view-topbar-product-name {
    color: $ui-text-default;
    font-size: 0.8rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    white-space: nowrap;
    
    .sgx-flag {
      display: inlnie-block;
      text-align: center;
      padding: $sgx-order-book-grid-view-flag-padding;
      margin-left: $sgx-order-book-grid-view-flag-margin;
      width: $sgx-order-book-grid-view-flag-width;
      height: $sgx-order-book-grid-view-flag-height;
      background-color: $v2-blue;
      font-size: $sgx-order-book-grid-view-flag-font-size;
      color: $sgx-theme-functional-05;

      &:first-child {
        margin-left: 8px;
      }
    }
  }
  .sgx-widget-order-book-grid-view-container {
    display: flex;
  }
  sgx-table {
    flex: 1 1 auto;
    [data-column-id="contract"] {
      display: none;
    }
    .sgx-table-header-wrapper[data-id="contract"] {
      display: none;
    }
    .table-container {
      border-bottom: 0;
    }
    &:first-child .table-container {
      border-left: 0;
    }
    &:last-child .table-container {
      border-right: 0;
    }
    sgx-table-toolbar {
      display: none;
    }
    [data-locked="true"] {
      font-style: italic;
    }
  }
  .sgx-table-cell-inline-edit {
    padding-left: 0px;
    padding-right: 0px !important;

    & sgx-input-number {
      width: 100%;
      height: 32px;

      &.sgx-input--has-error .sgx-input-control {
        outline: 1px solid red;
        border: 0px;
      }
    }

      & .sgx-input-control {
      height: 32px;
      font-size: 13px;
      padding-left: 5%;
      padding-right: 20%;
      padding-bottom: 4px;
    }

    & .sgx-input-number-up {
      width: 10%;
      right: 3px;
    }

    & .sgx-input-number-down {
      width: 10%;
      bottom: 5px;
      right: 3px;
    }
  }
}
