
sgx-widget-advert {
  
  $-advert-header-height: 5rem;
  $-advert-padding: $space-2;
  
  @mixin -rich-text() {
    overflow: hidden;
    padding: 0 $-advert-padding $-advert-padding $-advert-padding;
  }

  @mixin -fill-space() {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .sgx-widget-advert-content-text,
  .sgx-widget-advert-content-image,
  .sgx-widget-advert-content-video {
    max-width: 100%;
    position: relative;

    &::after {
      display: block;
      content: '';
      padding-bottom: 50%;
    }
  }

  .sgx-widget-advert-header-wrapper {
    height: calc(#{$-advert-header-height} + #{$-advert-padding});
  }

  .sgx-widget-advert-header {
    @include -rich-text();

    display: block;
    max-height: $-advert-header-height;
    height: $-advert-header-height;

    [class*=text-h] {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > *:not([class*=text-h]) {
      @include line-clamp(2, 1.5);
    }
  }

  .sgx-widget-advert-content-image {
    .sgx-widget-advert-content-image-content {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .sgx-widget-advert-content-image-link {
      display: block;
      text-decoration: none;
      height: 100%;
    }
  }
  
  .sgx-widget-advert-content-image-content {
    @include -fill-space();
  }

  .sgx-widget-advert-content-text-content {
    @include -fill-space();
    @include -rich-text();

    margin-top: - $-advert-padding;
  }

}