$sgx-confirmation-dialog-icon--success: $ui-state-success !default;
$sgx-confirmation-dialog-icon--error: $ui-state-error !default;
$sgx-confirmation-dialog-icon--default: $ui-state-primary !default;

$dialog-title-color: #222222 !default;

.sgx-alert-dialog {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100vw;
  height: 100vh;
  z-index: 1000;

  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  & .sgx-dialog-box {
    position: relative;

    body[data-type="mobile"] & {
      margin: 10px;
    }
  }

  &--hidden {
    display: none;
  }

  &-status-message {
    font-size: 0.9rem;
    padding: 0.3rem 1rem;
    background: $sgx-theme-functional-01;
    line-height: 1.2rem;
    opacity: 1;
    height: auto;
    transition: opacity 0.5s ease-out;
    color: $ui-text-default;

    &--hidden {
      display: none;
    }
  }
}

.sgx-alert-dialog-close {
  position: absolute;
  top: 16px;
  right: 12px;
  cursor: pointer;
  color: $dialog-title-color;
}

.sgx-alert-dialog-footer {
  margin-top: 0;
  flex-direction: row;
  justify-content: flex-end;
}

.sgx-alert-dialog-body {
  padding: 20px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sgx-alert-dialog-content {
  flex: 2 2 auto;
  color: $dialog-title-color;
}

.sgx-alert-dialog-subtitle {
  padding: 20px 40px 0px;
  color: $dialog-title-color;
}

.sgx-alert-dialog-icon {
  color: $sgx-confirmation-dialog-icon--default;
  text-align: right;
  border: 1px solid $sgx-confirmation-dialog-icon--default;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 70px;

  body[data-type="mobile"] & {
    margin-right: 0px; 
  }
}

.sgx-alert-dialog-icon--success {
  color: $sgx-confirmation-dialog-icon--success;
  border-color: $sgx-confirmation-dialog-icon--success;
}

.sgx-alert-dialog-icon--error {
  color: $sgx-confirmation-dialog-icon--error;
  border-color: $sgx-confirmation-dialog-icon--error;
}

.sgx-alert-dialog button {
  &:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }
}
