sgx-widget-partner-metrics {
  .sgx-widget-partner-metrics-description {
    display: block;
    margin-bottom: $space-5;
    font-size: 14px;

    & > :first-child,
    & > :first-child > :first-child {
      margin-top: 0;
    }
    
    & > :last-child,
    & > :last-child > :last-child {
      margin-bottom: 0;
    }

    & > *,
    & > * > * {
      margin-left: 0;
    }
  }
  
  .sgx-widget-partner-metrics-tabs {
    margin-bottom: $space-5;

    .sgx-tabs-container :first-child {
      margin-left: 0;
    }
  }

  .sgx-table-header-wrapper > .sgx-table-header-element,
  .sgx-table-cell {
    padding-left: $space-3;
    padding-right: $space-3;
  }

  .sgx-widget-partner-metrics-table-wrapper {
    position: relative;
  }
}