@import './sub-components/sgx-platts-widget-metals-alert-row.scss';
@import './sub-components/sgx-platts-widget-metals-alert-view.scss';
@import './sub-components/sgx-platts-widget-metals-alert-filters.scss';

sgx-platts-widget-metals-alert {
  width: 100%;
  height: 100%;
  text-align: left;

  .sgx-platts-widget-metals-alert-filters-wrapper-top {
    position: absoulte;
    width: 100%;
    height: 100%;
  }

  sgx-empty-image-placeholders {
    text-align: center;
  }

  sgx-list-details {
    border: none !important;

    width: 100%;
    height: 100%;

    .sgx-list-details-list sgx-status-indicator {
      background: $sgx-theme-v2-bg-basic-02;
    }

    .sgx-list-details-list-toolbar-right-icon {
      cursor: pointer;

      & > * {
        margin-left: 8px;
      }
    }
    .sgx-list-details-list-toolbar-right-icon-filter {
      font-size: 14px;
      position: relative;

      &:after {
        @include sgx-icon(filter);
      }

      .sgx-list-details-list-toolbar-right-icon-filter-badge {
        font-family: $lato-font-family;
        color: white;

        border-radius: 100%;
        position: absolute;
        right: -10px;
        top: -6px;
        width: 12px;
        height: 12px;
        background-color: $v2-sgx-light-blue;
        padding: 2px;
        font-size: 10px;
        text-align: center;
      }
      .sgx-list-details-list-toolbar-right-icon-filter-badge--empty {
        display: none;
      }
    }

    .sgx-list-details-list-toolbar-row:last-child {
      height: 29px;
      color: $white;
      background-color: #666666;
      font-size: 12px;
      font-weight: 300;

      & > * > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & > :first-child > * {
        margin-right: $space-4;
      }
    }
  }

  // NOTE: Quick fix for unclickable list items issue. I confirmed
  //  that valid elements are not affected by this style.
  // TODO: Investigate how this issue appears and upgrade components 
  //  if needed.
  .sgx-platts-widget-metals-alert-row-container:not(:first-child) {
    display: none;
  }
}

body[data-type='desktop'] {
  sgx-platts-widget-metals-alert {
    .sgx-platts-widget-metals-alert-input-source-initial {
      padding: 8px 12px;
    }

    sgx-list-details {
      .sgx-list-details-list-toolbar-row:last-child {
        & > :first-child > * {
          max-width: 120px;
        }
        & > :last-child > * {
          max-width: 210px;
        }
      }

      .sgx-list-details-list {
        min-width: 400px;
      }

      .sgx-list-details-details {
        flex: 1;

        position: relative;

        sgx-platts-widget-metals-alert-view {
          height: 100%;

          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;

          .sgx-platts-widget-metals-alert-view-container {
            height: 100%;

            & > * {
              overflow: auto;
            }

            .sgx-platts-widget-metals-alert-view-html {
              height: calc(100% - 65px);
            }
          }
        }
      }
    }
  }
}
