sgx-widget-generic-partner {
  height: 100%;
  position: relative;
  .sgx-widget-generic-partner-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    .sgx-widget-generic-partner-inner-wrapper {
      width: 100%;
      height: 100%;
      &--hide {
        display: none;
      }
    }
  }
}
