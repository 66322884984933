$adaptive-header-border: $sgx-theme-v2-lowest-emp-default !default;
$adaptive-header-row-height: 2rem !default;
$adaptive-header-font-size-large: 14px;
$adaptive-header-font-size-normal: 12px;

sgx-list-adaptive-header{
  display: flex;
  position: relative;
  background: $ui-mobile-order-book-header-color;
  color: $ui-text-default;
  min-height: $adaptive-header-row-height;
  max-height: $adaptive-header-row-height;
  line-height: $adaptive-header-row-height;
  border-top: solid 1px transparent;
  border-left: solid 1px $adaptive-header-border; 
  border-top: solid 1px $adaptive-header-border;
  border-bottom: solid 1px $adaptive-header-border;
  white-space: nowrap;
  cursor: pointer;
  z-index: 2;

  &[data-adaptive-mode='mobile'][data-detail-mode='true'] {
    min-height: calc(2 * #{$adaptive-header-row-height} - 14px);
    max-height: calc(2 * #{$adaptive-header-row-height} - 14px);
  }

  .sgx-list-adaptive-header-cell:nth-of-type(1) {
    border-left: 0px;
  }

  .sgx-list-adaptive-header-cell{
    border-left: solid 1px $adaptive-header-border;
    flex: 1 1 auto;
    font-size: $adaptive-header-font-size-large;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    text-align: right;

    &[data-column-id='totalVolume'],
    &[data-column-id='lastPrice'] {
      padding-right: $space-4;
      padding-left: 0px;
    }
  }

  .sgx-list-adaptive-header-main-detail-cell,
  .sgx-list-adaptive-header-detail-cell,
  .sgx-list-adaptive-header-seperator-cell {
    font-size: $adaptive-header-font-size-normal;
  }

  .sgx-list-adaptive-header-composite-cell {
    padding-right: 0px;
    display: grid;

    &[data-cell-align='right']{
      text-align: right;
    }

    &[data-cell-align='left']{
      text-align: left;
    }
  }

  &[data-adaptive-mode='mobile'] {

    .sgx-list-adaptive-header-composite-cell{
      flex: 1 1 50%;
    }

    .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] {
      grid-template-columns: 2fr 4fr $space-1 50px $space-2;
      grid-template-rows: 1fr 1fr;
      line-height: normal;
      padding: calc(0.5rem - 2px) 0px;
      margin-right: 0px;

      .sgx-list-adaptive-header-content-cell {
        grid-row: 1/2;
        grid-column: 1/5;
      }

      .sgx-list-adaptive-header-main-detail-cell {
        grid-row: 2/3;
        grid-column: 4/5;
        color: $ui-text-bright-grayed;
        display: flex;
        position: relative;

        header {
          position: absolute;
          right: 0px;
        }
      }

      .sgx-list-adaptive-header-seperator-cell {
        grid-row: 2/3;
        grid-column: 3/4;
        text-align: right;
        color: $ui-text-bright-grayed;
        padding-right: $space-1;
      }

      .sgx-list-adaptive-header-detail-cell {
        grid-row: 2/3;
        grid-column: 2/3;
        color: $ui-text-bright-grayed;
        padding-right: $space-1;
      }
    }

    .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] {
      grid-template-columns: $space-2 50px $space-1 4fr 2fr;
      grid-template-rows: 1fr 1fr;
      line-height: normal;
      padding: calc(0.5rem - 2px) 0px;

      .sgx-list-adaptive-header-content-cell {
        grid-row: 1/2;
        grid-column: 2/6;
      }

      .sgx-list-adaptive-header-main-detail-cell {
        grid-row: 2/3;
        grid-column: 2/3;
        color: $ui-text-bright-grayed;
        display: flex;
        position: relative;

        header {
          position: absolute;
          left: 0px;
        }

      }

      .sgx-list-adaptive-header-seperator-cell{
        grid-row: 2/3;
        grid-column: 3/4;
        text-align: left;
        color: $ui-text-bright-grayed;
        margin-left: 2px;
      }

      .sgx-list-adaptive-header-detail-cell {
        grid-row: 2/3;
        grid-column: 4/5;
        color: $ui-text-bright-grayed;
        padding-left: $space-1;
      }
    }
  }

  &[data-adaptive-mode='mobile-landscape'],
  &[data-adaptive-mode='complete'] {

    .sgx-list-adaptive-header-cell{
      flex: 1 1 15%;
    }

    .sgx-list-adaptive-header-composite-cell{
      padding-left: 0px;
      padding-right: 0px;
      flex: 1 1 35%;
    }

    .sgx-list-adaptive-header-composite-cell[data-cell-align='right'] {
      grid-template-columns: 4fr 2fr 10px 3fr 2fr $space-2;
      grid-template-rows: 1fr;

      .sgx-list-adaptive-header-content-cell {
        grid-row: 1/1;
        grid-column: 5/6;
      }

      .sgx-list-adaptive-header-main-detail-cell {
        grid-row: 1/1;
        grid-column: 4/5;
        color: $ui-text-bright-grayed;
        display: flex;

        &:after{
          content: '|';
          width: 10px;
          text-align: center;
          color: $ui-text-bright-grayed;
        }
      }

      .sgx-list-adaptive-header-seperator-cell{
        grid-row: 1/1;
        grid-column: 3/4;
        text-align: center;
        color: $ui-text-bright-grayed;
      }

      .sgx-list-adaptive-header-detail-cell {
        grid-row: 1/1;
        grid-column: 2/3;
        color: $ui-text-bright-grayed;
      }
    }

    .sgx-list-adaptive-header-composite-cell[data-cell-align='left'] {
      grid-template-columns: $space-2 2fr 3fr 10px 2fr 4fr;
      grid-template-rows: 1fr;

      .sgx-list-adaptive-header-content-cell {
        grid-row: 1/1;
        grid-column: 2/3;
      }

      .sgx-list-adaptive-header-main-detail-cell {
        grid-row: 1/1;
        grid-column: 3/4;
        color: $ui-text-bright-grayed;
        display: flex;

        &:before{
          content: '|';
          width: 10px;
          text-align: center;
          color: $ui-text-bright-grayed;
        }
      }

      .sgx-list-adaptive-header-seperator-cell {
        grid-row: 1/1;
        grid-column: 4/5;
        text-align: center;
        color: $ui-text-bright-grayed;
        padding-left: $space-1;
      }

      .sgx-list-adaptive-header-detail-cell {
        grid-row: 1/1;
        grid-column: 5/6;
        color: $ui-text-bright-grayed;
        padding-right: $space-1;
      }
    }
  }

  &[data-detail-mode='false'] {
    .sgx-list-adaptive-header-main-detail-cell {
      visibility: hidden;
    }

    .sgx-list-adaptive-header-seperator-cell {
      visibility: hidden;
    }

    .sgx-list-adaptive-header-detail-cell { 
      visibility: hidden;
    }
  }
}