sgx-kebab-menu-list {
  color: $ui-text-default;
  .sgx-kebab-menu-container {
    min-width: 180px;
  }

  ul {
    list-style: none;
    padding: 0.5rem 0;
    margin: 0;
  }

  .sgx-kebab-menu-list-item {
    padding: 0.1rem 0;
    cursor: pointer;
    &:hover {
      // as per the fathom designs.
      background-color: lighten($color: #0c236b, $amount: 73%); // mimic  opacity: 0.05;
      color: $black;
    }

    >div {
      padding-left: 1rem;
      font-size: 14px;
      &:before {
        font-size: 0.8rem;
        margin: 0.5rem;
      }
    }

  }
}
