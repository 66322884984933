.sgx-widget-performance-tracker-header-description,
.sgx-widget-performance-tracker-progress-bar {
  margin-bottom: $space-12;
}

.sgx-widget-performance-tracker-header-title {
  color: $sgx-theme-v2-high-emp-default;
  margin-bottom: $space-1;
}

.sgx-widget-performance-tracker-header-description {
  color: $sgx-theme-v2-medium-emp-default;
}

.sgx-widget-performance-tracker-caption {
  color: $sgx-theme-v2-medium-emp-default;
}

.sgx-widget-performance-tracker-separator {
  border: solid 1px $sgx-theme-v2-lowest-emp-default;
}

.sgx-widget-performance-tracker-disclaimer {
  color: $sgx-theme-v2-medium-emp-default;
}

#performance-tracker-accounts-list {
  display: flex;
  flex-grow: 1;
  border: none;
  padding: 0px;
}