sgx-dialog-body {
  display: block;
  padding: 1rem;

  .sgx-dialog-body-title {
    color: theme-color($sgx-theme-text-01, shade 70%, tint 95%);
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 50px;
  }

  .sgx-dialog-body-content {
    display: block;
    padding: 60px;
    padding-top: 50px;
    font-size: 24px;
    line-height: 32px;
    color: theme-color($sgx-theme-text-01, tint 10%, tint 45%);
  }

  .sgx-dialog-body-content-actions {
    margin-top: 45px;

    button:first-child {
      height: 44px;
      width: 220px;
    }

    button {
      font-weight: 600;
      font-size: 18px;
    }
  }
}
