template-nlt-page {
  #nlt-table {
    padding-top: 12px;
    sgx-table {
      height: calc(100vh - 160px);
    }
  }
}

template-nlt-aggregation-page {
  #nlt-table {
    padding-top: 12px;
    sgx-table {
      height: calc(100vh - 160px);
    }
  }
}

body[data-type="desktop"] {
  template-nlt-aggregation-page,
  template-nlt-page {
    padding: 0 1rem;
    overflow: hidden;
  }
}

body[data-type="mobile"] {
  template-nlt-aggregation-page,
  template-nlt-page {
    padding: 0 0.5rem;
  }
}
