$support-page-content-item-title-color: theme-color($sgx-theme-text-01, shade 70%, tint 95%);
$support-page-content-item-body-color: theme-color($sgx-theme-text-01, tint 10%, tint 45%);

template-support-page {
  display: block;
  height: 100%;
  overflow-y: auto;

  .template-support-page-wrapper {
    padding-left: calc(#{$space-27} + #{$space-8});
    padding-right: calc(#{$space-27} + #{$space-8});
  }

  sgx-card-widget-wrapper {
    box-shadow: none;
    padding: 0px;
    padding-bottom: $space-4;
    @media screen and (min-width: 992px) {
      sgx-widget-media {
        width: 500px;
      }
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    li {
      padding-left: $space-3;
    }
  }

  p,
  li,
  .support-page-strongger-text {
    color: $support-page-content-item-body-color;
    font-size: 14px;
  }

  strong {
    color: $support-page-content-item-title-color;
    font-weight: 600;
    font-size: $space-4;
  }

  a {
    text-decoration: underline;
  }

  .support-page-strongger-text {
    font-weight: 700;
  }

  .pages-content {
    p {
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }

    h3,
    h4,
    h5 {
      color: $support-page-content-item-title-color;
      font-weight: 600;
      font-size: $space-5;
      padding: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0px;

      strong {
        color: $support-page-content-item-title-color;
        font-weight: 600;
        font-size: $space-5;
      }
    }
  }

  &[data-is-mobile='true'] {
    .template-support-page-wrapper {
      height: auto;
    }

    ul {
      li {
        padding-left: 0px;
      }
    }

    a {
      word-break: break-all;
    }
  }
}
