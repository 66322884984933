template-error-page .sgx-card-error {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

template-error-page .sgx-card-error-title {
  font-size: 28px;
  text-align: center;
  padding-bottom: $space-5;
  color: $sgx-theme-v2-high-emp-default;
  border-bottom: solid 2px $sgx-theme-v2-low-emp-default;
}

template-error-page .sgx-card-error-desc {
  font-size: 16px;
  text-align: center;
  padding-top: $space-5;
  color: $sgx-theme-v2-high-emp-default;
}
