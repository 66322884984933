// TODO: Check if iteration 2 of DDS for colours
$sgx-widget-treemap-more-button-colour: theme-color($sgx-light-blue, tint 0%, tint 0%);
$sgx-widget-treemap-timestamp-colour: theme-color($sgx-theme-text-01, tint 10%, tint 45%);
$sgx-widget-treemap-footer-height: 80px;

sgx-widget-treemap {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: -15px;

  .hightcharts-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .sgx-widget-treemap-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    .sgx-widget-treemap-inner-wrapper {
      height: 100%;
      width: 100%;

      &--hide {
        display: none;
      }

      .sgx-treemap-sub-title {
        color: $sgx-theme-v2-medium-emp-default;
        padding-bottom: $space-3;
        line-height: 12px;
      }
    }
  }

  .sgx-treemap-content-container {
    height: calc(100% - #{$sgx-widget-treemap-footer-height});
    position: relative;
  }

  .sgx-widget-treemap-wrapper .highcharts-legend .highcharts-legend-title text tspan {
    fill: $sgx-theme-v2-medium-emp-default;
  }


  .sgx-widget-treemap-footer {
    display: flex;
    height: 50px;
    width: 100%;
    flex-direction: row;
    padding-top: $space-3;

    .sgx-widget-treemap-legend {
      width: 50%;

      .sgx-widget-treemap-legend-title {
        padding-top: $space-2;
        color: $sgx-theme-v2-medium-emp-default;
      }

      .sgx-widget-treemap-color-axis {
        display: flex;
        flex-direction: row;
        margin-top: $space-1;

        .sgx-widget-treemap-color-axis-suffix,
        .sgx-widget-treemap-color-axis-prefix {
          width: 30px;
          text-align: left;
          line-height: 10px;
        }

        .sgx-widget-treemap-color-axis-suffix {
          text-align: right;
        }

        .sgx-widget-treemap-color-axis-gradient {
          width: 220px;
          height: 8px;
          background: linear-gradient(90deg, rgba(156,8,36,1) 0%, rgba(156,8,36,1) 9.1%, rgba(180,31,39,1) 9.1%, rgba(180,31,39,1) 18.2%, rgba(204,49,43,1) 18.2%, rgba(204,49,43,1) 27.3%, rgba(232,103,83,1) 27.3%, rgba(232,103,83,1) 36.4%, rgba(252,180,165,1) 36.4%, rgba(252,180,165,1) 45.5%, rgba(255,255,255,1) 45.5%, rgba(255,255,255,1) 54.5%, rgba(185,215,183,1) 54.5%, rgba(185,215,183,1) 63.6%, rgba(116,175,114,1) 63.6%, rgba(116,175,114,1) 72.7%, rgba(66,143,73,1) 72.7%, rgba(66,143,73,1) 81.8%, rgba(41,120,57,1) 81.8%, rgba(41,120,57,1) 90.9%, rgba(9,98,42,1) 90.9%, rgba(9,98,42,1) 100%);
        }
      }
    }

    .sgx-widget-treemap-timestamp {
      width: 50%;
      text-align: right;
      color: $sgx-theme-v2-medium-emp-default;
    }
  }
}

sgx-chart {
  sgx-widget-treemap .sgx-treemap-content-container & {
    width: 100%;
    height: 100%;
  }
}
