sgx-user-profile-content {
  display: block;

  /**
   * Common styles
   */

  .sgx-user-profile-content {
    &--hidden {
      display: none !important;
      // ^^^ `!important` used as this is a utility class
    }
  }

  sgx-input-text,
  sgx-readonly-text {
    margin-top: $space-5;
  }

  sgx-input-checkbox {
    display: block !important;
  }

  sgx-form {
    button {
      margin-top: $space-5;
      float: right;
    }
  }

  /**
   * Title + Views Buttons
   */

  .sgx-user-profile-content-header {
    .sgx-user-profile-content-title,
    .sgx-user-profile-view-buttons {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
      position: relative;      
      z-index: 0;
    }

    .sgx-user-profile-view-buttons {
      button::before {
        font-family: FontAwesome;
        margin-right: $space-1;
      }

      // Each views' icon:
      .sgx-user-profile-content-view-edit-button::before {
        content: "\F040"; // pencil icon
      }
    }
  }

  /**
   * Views
   */

  sgx-user-profile-content-view-edit {
    sgx-input-checkbox,
    sgx-input-select {
      margin-top: $space-5;
    }

    .sgx-user-profile-content-edit-buttons {
      margin-top: $space-5;
      float: right;
    }
  }

  sgx-readonly-text[data-config-key="emailAddress"] .sgx-readonly-text-content,
  sgx-readonly-text[data-config-key="firstName"] .sgx-readonly-text-content,
  sgx-readonly-text[data-config-key="lastName"] .sgx-readonly-text-content {
    text-transform: none;
  }
}
