sgx-card-widget-wrapper {
  background-color: $sgx-theme-v2-bg-basic-02;
  color: $sgx-theme-v2-high-emp-default;
  display: flex;
  flex-direction: column;
  padding: $space-5;
  padding-bottom: $space-3;
  box-shadow: 0 1px 4px $sgx-theme-v2-shadow-01;

  @include media-breakpoint-down(xxs) {
    padding: $space-3;
    padding-top: $space-5;
  }

  .sgx-card-widget-header {
    width: 100%;
    padding-bottom: $space-5;
    display: flex;
    justify-content: space-between;

    .sgx-card-widget-kebab {
      cursor: pointer;
      padding:0 $space-1;
      font-size: 20px;

      &:after {
        icon-font: url('sgx-base-code/svgs/light/ellipsis-v.svg');
        color: $sgx-theme-v2-medium-emp-default;
      }
    }
  }

  .sgx-card-widget-title {
    display: flex;
    width: 100%;

    .sgx-card-widget-title-clickable-link {
      height: calc(27px + 2 * #{$space-1});
    }

    img,
    .sgx-card-title-category {
      align-self: center;
    }

    img {
      // https://confluence.sgx.com/pages/viewpage.action?spaceKey=DIGITAL&title=Titan+OTC+-+Dashboard+-+Partner%27s+brand+widget
      margin: $space-1 $space-2 $space-1 0;
      width: 54px;
      height: 27px;
    }
  }

  .sgx-card-empty-message {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }

  sgx-status-indicator {
    z-index: 998;
  }
}

.sgx-context-menu-item .sgx-category-selection-icon:before {
  icon-font: url('sgx-base-code/svgs/light/edit.svg');
}
