#order-cancellation-dialog {
  .sgx-dialog-box {
    min-width: 572px;
    min-height: 350px;
  }

  [data-dialog="title"] {
    font-size: 1.1rem;
    font-weight: bold;
    color: $ui-text-default;
  }

  .order-cancellation-dialog-body-single-order,
  .order-cancellation-dialog-body-all-orders {
    display: none;
  }

  .sgx-dialog-body {
    color: $ui-text-default;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
    display: flex;

    p {
      font-size: 16px;
      margin: 1.5rem 0;
    }
  }
  
  .order-cancellation-dialog-text {
    width: 320px;
  }

  .order-cancellation-dialog-text-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  .order-cancellation-dialog-icon {
    text-align: center;
    width: 40%;
    margin-top: 3rem;
    &:before {
      content: "\f06a";
      font-family: fontAwesome;
      color: #ff0042;
      font-size: 6rem;
    }
  }
}
