sgx-widget-announcements {
  display: block;
  font-size: 12px;
  position: relative;

  &[data-expanded="false"] .sgx-announcements-list-item:nth-child(n + 2) {
    display: none;
  }

  &[data-expanded="true"] .sgx-announcements-list-item {
    display: flex;
  }
  
  .sgx-announcements-list-item {
    padding-right: $space-2 + 2 * $space-3;
  }
}

.sgx-widget-announcements-expander {
  position: absolute;
  top: 0;
  right: 0;
  padding: $space-3;
  cursor: pointer;

  [data-expanded="false"] &:after {
    icon-font: url("@sgx/sgx-base-code/svgs/solid/chevron-down.svg");
  }

  [data-expanded="true"] &:after {
    icon-font: url("@sgx/sgx-base-code/svgs/solid/chevron-up.svg");
  }
}

sgx-widget-announcements p,
.sgx-widget-announcements-expander p {
  font-size: 14px;
  color: $sgx-theme-v2-high-emp-default;
}
