$order-book-plus-tab-bg: #f5f5f5 !default;
$order-book-plus-tab-bg-hover: #000 !default;
$sgx-header-height: 50px;

body{
  template-trading-page {
    height: 100%;
    font-family: Lato;
    width: 100%;
    display: flex;
  }

  .sgx-flexible-layout-container {
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
    padding: 1rem;
    padding-right: 2rem;
  }

  sgx-flexible-layout {
    height: 100%;
    width: 100%;
    display: block;

    sgx-flexible-layout-add-tab {
      background: $order-book-plus-tab-bg;
      transition: color 0.4s ease;
      color: #6b6f80;

      &:hover {
        color: $order-book-plus-tab-bg-hover;
      }
    }

    .sgx-flexible-layout-add-tab-icon {
      &:before {
        content: "";
      }
    }
  }

  #product-contract-selection-modal-dialog {
    .sgx-dialog-box {
      width: 903px;
      height: 628px;
    }
    [data-dialog="body"] {
      overflow-x: hidden;
      overflow-y: auto;
      .sgx-product-selector-title {
        color: $ui-text-default;
        padding: 1.3rem 0;
        margin-bottom: 0.9rem;
      }
      sgx-product-selector {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
