sgx-partner-iframe-container {
  display: block;
  width: 100%;
  height: 100%;
  .sgx-partner-iframe-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .sgx-partner-iframe {
      width: 100%;
    }
  }
}
