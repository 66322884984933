$sgx-text-dropdown-color: $ui-text-default !default;

sgx-text-dropdown {
  color: $sgx-text-dropdown-color;
  .sgx-text-dropdown-header-container {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    .sgx-text-dropdown-title-container {
      width: 100%;
    }
    p {
      cursor: pointer;
      &.sgx-text-dropdown-list-container--toggle-closed,
      &.sgx-text-dropdown-list-container--toggle-open {
        &:before {
          font-family: "fontTotc";
          margin-right: 0.5rem;
          font-size: 0.75em;
          position: relative;
          top: -1px;
        }
      }
      &.sgx-text-dropdown-list-container--toggle-closed {
        &:before {
          content: "\e827";
        }
      }

      &.sgx-text-dropdown-list-container--toggle-open {
        &:before {
          content: "\e826";
        }
      }
      width: 100%;
    }
  }

  .sgx-text-dropdown-toggle-container {
    padding-top: 6px;
  }

  .sgx-text-dropdown-list {
    display: none;
    font-size: 0.8rem;
    li {
      margin: 0.5rem;
    }
  }

  .sgx-text-dropdown-list-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 80%;
    margin-left: 1.6em;
  }
}
