@import "./subcomponents/sgx-widget-order-blotter-bulk-actions/sgx-widget-order-blotter-bulk-actions.scss";
$sgx-table-state-tab-height: 32px;

sgx-widget-bait-order-blotter,
sgx-widget-order-blotter {
  height: 100%;
  padding: 12px;
  background: $order-book-bg;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  border-radius: 3px;

  sgx-table {
    margin: 0;
    height: calc(100% - #{$sgx-table-state-tab-height});
  }

  sgx-table,
  sgx-state-tabs {
    @if (lightness($ui-bg) < 50) {
      background-color: $ui-item-row;
    } @else {
      background-color: $totc-grey-4;
    }
  }

  sgx-state-tabs {
    padding-left: 1rem;
  }

  .table-action-disabled {
    pointer-events: none;
    opacity: 0.8;
    color: #ccc;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
