$sgx-tab-menu-height: 45px;

template-settings-page {
  height: 100%;
  .pages-content {
    display: flex;
    height: calc(100% - #{$sgx-tab-menu-height});
    overflow-y: auto;
  }

  .sgx-card {
    position: relative;
    padding-left: $space-5;
    padding-right: $space-5;
  }

  .sgx-card:last-child {
    margin-left: $space-5;
  }

  .sgx-user-settings-userId {
    .sgx-readonly-text-content {
      text-transform: none;
    }
  }

  .sgx-user-settings-email {
    .sgx-readonly-text-content {
      text-transform: lowercase;
    }
  }

  .checkbox-group-container sgx-input-checkbox:not(:first-child) {
    margin-left: $space-5;
  }
}

body[data-type="mobile"] {
  template-settings-page {
    .pages-content {
      flex-wrap: wrap;
    }

    .sgx-card:last-child {
      margin-left: 0;
    }
  }
}

body[data-type="tablet"] {
  template-settings-page {
    .pages-content {
      padding-top: 0;
    }
  }
}
