$alert-form-content-color: #6d6d6d !default;

.alert-dialog-form-title {
  padding: 12px 0px 20px 0px;
  display: block;
  // font-size: 17px;
  color: $alert-form-content-color;
}

.alert-dialog-form {
  padding: 0px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.alert-dialog-form-item {
  display: inline-block;
  min-height: 40px;
  width: 500px;
  padding: 0 20px 9px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: $alert-form-content-color;
}

.alert-dialog-form-label {
  width: 200px;
  text-align: right;
  margin: auto 20px auto 0;
}

.alert-dialog-form-control-container {
  display: inline-block;
  width: 300px;
  text-align: left;
  margin: auto 0;
  & .sgx-input-number-wrapper {
    width: 200px;
  }
  & sgx-input-text {
    width: 200px;
  }
  & sgx-input-select {
    width: 200px;
  }
}

.alert-dialog-form-detail-title {
  justify-self: flex-start;
  padding-right: 20px;
  flex: 1 1 auto;
  color: $grey;
  line-height: 1.2em;
  font-size: 12px;
}

.alert-dialog-form-detail-description {
  justify-self: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: $black;
  line-height: 1.2em;
  flex: 1 1 auto;
}
