$sgx-confirmation-dialog-icon--error: $ui-state-error !default;

.sgx-backend-error-modal-content {
  width: 20rem;
  color: $dialog-title-color;
  min-width: 500px;
  max-width: 500px;
}

.sgx-backend-error-dialog .sgx-alert-dialog-container .sgx-alert-dialog-body .sgx-backend-error-modal-content-body i:before {
  color: $sgx-confirmation-dialog-icon--error;
  icon-font: url("sgx-base-code/svgs/solid/times-circle.svg");
  margin-right: $space-2;
  vertical-align: text-bottom;
}

.sgx-backend-error-dialog .sgx-alert-dialog-container .sgx-alert-dialog-body {
  padding-left: 0px;
  padding-right: 0px;
  line-height: 1.4em;
}

body:not([data-type='desktop']) {

  .sgx-backend-error-modal-content {
    min-width: 80%;
    max-width: 400px;
  }
}
