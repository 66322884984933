sgx-platts-widget-metals-alert-view {
  text-align: left;

  .sgx-platts-widget-metals-alert-view-header {
    border-bottom: 1px solid rgba(127,127,127,0.2);

    padding: $space-3;
    
    .sgx-platts-widget-metals-alert-view-headline {
      font-size: 16px; // TODO: Grab this from a variable
      font-weight: 700;
      padding: $space-1;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sgx-platts-widget-metals-alert-view-metadata {
      position: relative;
      font-size: 12px;
      line-height: $space-4;

      color: #777777;

      & > :first-child {
        display: inline-block;

        & > * {
          padding: $space-1;
        }
      }

      .sgx-platts-widget-metals-alert-view-source {
        font-weight: 800;
      }

      // Fade out right-most text to not clash with Flash tag:
      &.sgx-platts-widget-metals-alert-view-flash {

        & > :last-child {
          display: inline-block;

          position: absolute;
          right: 0;
          padding: 1px $space-1;
          font-size: 9px;
          font-weight: 200;
        }
      }
    }

    .sgx-platts-widget-metals-alert-view-flash-tag {
      height: 15px;
      border-radius: 1px;
      background-color: $v2-blue;
      text-align: center;
      line-height: 15px;
      font-size: 11px;
      color: $white;
    }
    .sgx-platts-widget-metals-alert-view-flash-tag--hidden {
      display: none;
    }
  }

  .sgx-platts-widget-metals-alert-view-html {
    padding: $space-2;

    sgx-widget-rich-text {
      white-space: pre-wrap;
      font-family: Consolas, Menlo, monospace;
    }

    .sgx-platts-widget-metals-alert-view-banner {
      text-align: center;
      margin-top: $space-10;
      display: inline-block;
      width: 100%;
    }
  }
}
