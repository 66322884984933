sgx-list-publications-row {
  .sgx-publications-row {
    height: 100%;
    padding-top: $space-2;
    padding-bottom: $space-2;
    border-bottom: solid 1px $sgx-theme-v2-lowest-emp-default;
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .sgx-publications-title {
      display: flex;
      align-items: center;
      width: calc(100% - 100px);
      overflow: hidden;
      text-overflow: ellipsis;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      & a {
        width: 100%;
        height: 1.5em;
        line-height: 1.5em;
        display: block;
        color: $sgx-theme-v2-high-emp-default;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover,
        &:active {
          text-decoration: underline;
        }
      }

      .sgx-publications-flag-featured {
        width: 60px;
        height: 14px;
        padding-left: 2px;
        position: relative;
        vertical-align: middle;
        white-space: nowrap;
        display: inline-block;
        color: $sgx-theme-v2-high-emp-alt-reverse;
        background-color: $sgx-news-featured-bg-color;
      }
    }

    .sgx-publications-date {
      text-align: right;
      color: $sgx-theme-v2-medium-emp-default;
      width: 100px;
      vertical-align: middle;

      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }
  }
}
