$page-feed-container-line-height: 1.4 !default;
$page-feed-container-padding: 1rem 10% 0 !default;
$page-feed-container-bg-color: #f5f5f5 !default;
$page-feed-container-tablet-padding: 1rem 1rem 0 !default;
$page-feed-container-mobile-padding: 1rem 1rem 0 !default;

/* feed item */
$page-feed-item-margin: 0 2% 0 0 !default;
$page-feed-item-mobile-margin: 0.5rem 0 !default;

.page-feed-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: $page-feed-container-bg-color;
	padding: $page-feed-container-padding;
	line-height: $page-feed-container-line-height;

	sgx-feed {
		flex: 1;
		margin: $page-feed-item-margin;

		&:last-child {
			margin: 0;
		}
	}
}

body[data-type='tablet'] {
	.page-feed-container {
		padding: $page-feed-container-tablet-padding;
	}
}

body[data-type='mobile'] {
	.page-feed-container {
		flex-direction: column;
		padding: $page-feed-container-mobile-padding;

		sgx-feed {
			margin: $page-feed-item-mobile-margin;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
