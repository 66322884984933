sgx-widget-side-panel,
sgx-platts-widget-side-panel {
  display: flex;
  flex-direction: column;
  width: 320px;
  position: fixed;
  top: 0;
  background: $sgx-theme-v2-bg-basic-02;
  box-shadow: 2px 0px 15px $sgx-theme-v2-shadow-02;
  height: 100%;
  z-index: 1000;
  padding: $space-5 $space-3;
  right: -320px;
  opacity: 0;
  transition: right 0.5s ease-in-out;
  overflow-y: auto;

  &.sgx-widget-side-panel--active {
    display: block;
    right: 0;
    opacity: 1;
    transition: right 0.5s ease-in-out;
  }

  .sgx-side-panel-toggle-btn {
    bottom: $space-8;
    left: $space-3;
    width: 100%;
  }

  .sgx-widget-side-panel-title {
    color: $sgx-theme-v2-high-emp-default;
  }

  .sgx-widget-side-panel-content-wrapper {
    display: block;
    margin-top: $space-5;

    .sgx-flex-widget-panel-item {
      display: flex;
      height: 35px;
      line-height: 35px;
      background: $sgx-theme-v2-bg-basic-07;
      border-radius: 2px;
      margin: $space-1 0;
      padding-left: $space-1;
      color: $sgx-theme-v2-high-emp-default;
      text-transform: capitalize;
      position: relative;
      cursor: move;

      &:after {
        icon-font: url("@sgx/sgx-base-code/svgs/solid/bars.svg");
        position: absolute;
        right: 10px;
        top: 10px;
        color: $sgx-theme-v2-low-emp-default;
      }

      img {
        // https://confluence.sgx.com/pages/viewpage.action?spaceKey=DIGITAL&title=Titan+OTC+-+Dashboard+-+Partner%27s+brand+widget
        margin: $space-1 $space-2 $space-1 0;
        width: 54px;
        height: 27px;
      }
    }

    .sgx-flex-widget-panel-item-partner-icon {
      height: 100%;
    }
  }
}
