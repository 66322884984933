
$sgx-widget-idb-ranking-product-height: 36px;
$sgx-widget-idb-ranking-list-footer-height: 28px;
$sgx-widget-idb-ranking-product-select-width: 343px;


sgx-widget-idb-ranking {
  display: flex;
  flex-direction: column;

  .sgx-widget-idb-ranking-product {
    display: flex;
    flex-direction: row;
    height: $sgx-widget-idb-ranking-product-height;

    sgx-input-select {
      input {
        text-align: left !important;
      }
    }

    .sgx-widget-idb-ranking-product-period {
      line-height: $sgx-widget-idb-ranking-product-height;
      margin-left: $space-3;
      color: $sgx-theme-v2-medium-emp-default;
    }
  }

  .sgx-widget-idb-ranking-list-wrapper {
    //this is to set specifically the height of the sgx-list so that the scrolling will be handled properly
    height: calc(100% - #{$sgx-widget-idb-ranking-product-height} - #{$sgx-widget-idb-ranking-list-footer-height});
  }

  &[data-adaptive-mode='mobile'] {
    .sgx-widget-idb-ranking-list-wrapper {
      height: calc(100% - 2 * #{$sgx-widget-idb-ranking-product-height} - #{$sgx-widget-idb-ranking-list-footer-height});
    }

    .sgx-widget-idb-ranking-product {
      flex-direction: column;
      height: calc(2 * #{$sgx-widget-idb-ranking-product-height});
    }
  }

  sgx-list-simple-row:nth-of-type(1) {
    border-top: solid 1px $sgx-theme-v2-lowest-emp-default;
  }

  .sgx-widget-idb-ranking-list-footer {
    padding-top: $space-3;
    text-align: right;
    height: $sgx-widget-idb-ranking-list-footer-height;
    span {
      color: $sgx-theme-v2-medium-emp-default;
      margin-right: $space-2;
    }
  }

  sgx-list {
    border: none;
    height: 100%;
  }

}
