/* page header  */
$page-title-line-height: 1.4 !default;
$page-title-font-size: 156% !default;
$page-title-font-weight: 400 !default;
$page-title-font-color: #0B236B !default;
$page-title-padding: 1rem 10% !default;
$page-title--margin-top: 2rem !default;
$page-title--margin-bottom: 2rem !default;

$page-title-tablet-padding: 1rem !default;

$page-title-mobile-font-size: 120% !default;
$page-title-mobile-padding: 0.5rem 1rem !default;
$page-title-mobile-bg-color: #F5F5F5 !default;

$page-header-banner-height: 220px !default;

.page-header {
	background: #efefef;
}

.page-header-banner {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: $page-header-banner-height;
}

.page-header-carousel {
	width: 100%;
	height: auto;
}

.page-title {
	line-height: $page-title-line-height;
	font-size: $page-title-font-size;
	font-weight: $page-title-font-weight;
	color: $page-title-font-color;
	padding: $page-title-padding;
}

.page-title--margin {
	padding-top: $page-title--margin-top;
	padding-bottom: $page-title--margin-bottom;
}

body[data-type='tablet'] {
	.page-title {
		padding: $page-title-tablet-padding;
	}

	.page-title--margin {
		padding-top: $page-title--margin-top;
		padding-bottom: $page-title--margin-bottom;
	}
}

body[data-type='mobile'] {
	.page-header-banner,
	.page-header-carousel {
		display: none;
	}

	.page-title {
		padding: $page-title-mobile-padding;
		font-size: $page-title-mobile-font-size;
	}

	.page-title--margin {
		background: $page-title-mobile-bg-color;
	}
}
