$sgx-readonly-text-title: theme-color($sgx-theme-text-03, tint 10%, tint 45%);
$sgx-readonly-text-content: theme-color($sgx-theme-text-03, shade 70%, tint 95%);

sgx-readonly-text {
  display: block;

  .sgx-readonly-text-title {
    color: $sgx-readonly-text-title;
    font-size: 14px;
  }

  .sgx-readonly-text-content {
    margin-top: $space-1;
    font-size: 16px;
    color: $sgx-readonly-text-content;
    text-transform: capitalize;
  }
}
