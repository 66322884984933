$sgx-base-code-css-reset: true;
$sgx-base-code-folder-path: "../node_modules/@sgx/sgx-base-code/";
$sgx-header-logo-path: "../static/images/sgx-logo-white.png";
$sgx-header-logo-alt: "../static/images/sgx-logo.svg"; // for mobile
$sgx-identity-card-mobile-content-max-height: 4.9rem;
$sgx-table-font-family: Lato;
$sgx-table-height: 32rem;
$sgx-state-tabs-height: 32px;
$sgx-table-font-size: 0.76rem;
$sgx-table-columns-header-height: 2rem;
$sgx-icon-menu-i-trading: "f0db";
$sgx-base-code-use-font-cdn: false;
$dark-theme: false !default;

@import "sgx-base-code/variables.scss";

// Overwritten colors(new)
$totc-red: #f30643 !default;
$totc-dark-grey: #2a2c2f !default;
$totc-dark-grey-2: #2a2c30 !default; // sgx-header
$totc-dark-grey-3: #2e2f33 !default; // dialog header
$totc-grey-1: #36383e !default;
$totc-grey-2: #303237 !default;
$totc-grey-3: #525559 !default;
$totc-grey-4: #efefef;
$totc-black: #19191a !default;
$totc-green: #bdd832 !default;
$totc-light-purple: #b5bdd2 !default;
$totc-blue: #184e7f !default;
$totc-blue-text: #4954a1 !default;
$totc-action-btn: #818caf;
$totc-blue-dark: #0c236b;

$sgx-shadow-color: theme-color($sgx-theme-ui-04, tint 25%, tint 0%);
$sgx-shadow-width: 2px;
$sgx-shadow-right: $sgx-shadow-width 0 15px rgba($sgx-shadow-color, 0.5);
$sgx-shadow-left: calc(-1 * #{$sgx-shadow-width}) 0 15px rgba($sgx-shadow-color, 0.4);

@function customHighlightColor($color, $percentage) {
  @if (lightness($color) >50) {
    @return darken($color, $percentage);
  } @else {
    @return lighten($color, $percentage);
  }
}


/**
 * Sick of us using the native scrollbar. Since most of our users are on modern
 * browsers, lets use the vendor prefix to get a better look. We use the same styles
 * for http://brand.qasgx.com/. Copy pasta from there :).
 * Till we get a better solution, i.e using a scroll shield container.
 */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}
