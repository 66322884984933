@import "./subcomponents/sgx-contract-view/sgx-contract-view.scss";

$order-book-bg: #efefef !default;
$order-book-add-product-bg: #efefef !default;
$order-book-add-product-color-hover: #222222 !default;
$order-book-border: #b6bdd0 !default;
$order-book-header-top-height: 30px;

sgx-widget-order-book {
  height: 100%;
  padding: 12px;
  background: $order-book-bg;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  border-radius: 3px;

  .sgx-widget-order-book-grid-view-wrapper {
    border: 1px solid $order-book-border;
    overflow: hidden;
    display: flex;
    height: 100%;
  }

  .sgx-widget-order-book-grid-view-container {
    display: none;
    position: relative;
    margin: 1px;
    height: calc(100% - 2px);
    width: 96%;

    &[data-horizontal="true"] {
      padding-bottom: 12px;
    }

    &[data-vertical="true"] {
      padding-right: 12px;
    }

    .sgx-widget-order-book-contract-view-container {
      position: relative;
      flex: 0 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 100;

      &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        height: 100%;
        left: calc(100% - 15px);
        width: 15px;
        box-shadow: 4px 0px 12px 0px rgba(0, 0, 0, 0.25);
        pointer-events: none;
      }
    }

    sgx-scroll-shield {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .table-horizontal-scrolling-wrapper {
        border: 1px solid $sgx-table-border-color;
        border-left: 0;
      }

      .table-vertical-scrolling-wrapper {
        border: 1px solid $sgx-table-border-color;
        border-top: 0;
      }

      &.no-scrollbars .table-vertical-scrolling-wrapper,
      &.only-horizontal-scrollbar .table-vertical-scrolling-wrapper {
        width: 0;
        border-left: 0;
        bottom: 0;
      }

      &.only-horizontal-scrollbar .table-horizontal-scrolling-wrapper {
        right: 0;
      }

      &.only-vertical-scrollbar .table-vertical-scrolling-wrapper {
        bottom: 0;
      }
    }

    .sgx-widget-order-book-grid-view-container-wrapper {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      height: 100%;
      z-index: 1;

      sgx-table {
        width: 100%;
        height: calc(100% - #{$order-book-header-top-height});

        .table-container {
          border-bottom: 0;
          border-right: 0;
        }

        &:first-child .table-container {
          border-left: 0;
        }

        sgx-table-toolbar {
          display: none;
        }
      }
    }
  }

  .sgx-widget-order-book-add-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: $order-book-add-product-bg;
    transition: color 0.4s ease;
    cursor: pointer;

    color: #6a7082;

    &:hover {
      color: $order-book-add-product-color-hover;
    }

    &.sgx-widget-order-book-add-product--added {
      width: 4%;
      background: $order-book-add-product-bg;
      & svg {
        width: 16px;
        height: 16px;
      }
    }

    .sgx-widget-order-book-add-product-icon {
      padding: 12px 16px;
    }

    .sgx-widget-order-book-add-product-icon-text {
      font-size: 1.8rem;
      font-weight: 100;
      position: relative;
      // top: -1rem;
    }
  }

  .sgx-table-header-wrapper[data-id="bidPrice"] {
    text-align: right;
  }
}
