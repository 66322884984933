sgx-confirm-reject-traders-content {
  .sgx-confirm-reject-traders-content {
    display: flex;
    margin-top: 1rem; 
  }

  .sgx-confirm-reject-traders-content-buyer-account, 
  .sgx-confirm-reject-traders-content-seller-account {
    width: 200px;
    margin: 0.5rem;
  }
  
  sgx-input-select {
    margin-top: 0.5rem;
    // width: 100%;
  }

  .sgx-confirm-reject-traders-content div:first-child {
    margin-left: 0;
  }
  
  // overriding as sometimes, accountID's are long
  .sgx-input-label {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
