$sgx-confirmation-dialog-icon--error: $ui-state-error !default;
  
h2 {
  sgx-widget-community-admin-user-blotter .sgx-widget-community-admin-user-blotter-title & {
    font-size: $space-5;
    font-family: Lato, sans-serif;
    color: theme-color($sgx-theme-text-01, shade 70%, tint 95%);
  }
}

.sgx-widget-community-admin-user-blotter-title {
  sgx-widget-community-admin-user-blotter & {
    padding-bottom: $space-5;
  }
}

sgx-state-tabs {
  sgx-widget-community-admin-user-blotter sgx-table & {
    background: none;
    padding-left: $space-4;
  }
}

sgx-table {
  sgx-widget-community-admin-user-blotter & {
    height: calc(100% - 64px);
  }
}

.sgx-alert-dialog-container {
  sgx-alert-dialog.sgx-user-blotter-config-dialog--desktop & {
    min-width: 600px;
    max-width: 600px;
  }
  sgx-alert-dialog.sgx-user-blotter-config-dialog--mobile & {
    min-width: 90%;
    max-width: 400px;
  }
}

.sgx-user-blotter-confirmation-content {
  sgx-alert-dialog.sgx-user-blotter-config-dialog--mobile & {
    max-width: 350px;
  }
}

h2 {
  sgx-alert-dialog.sgx-user-blotter-config-dialog--success .sgx-alert-dialog-content & i:before {
    margin-right: $space-2;
    vertical-align: baseline;
    color: $sgx-confirmation-dialog-icon--success;
    icon-font: url("sgx-base-code/svgs/solid/check-circle.svg");
  }
}

.sgx-user-blotter-action-reason-input {
  sgx-alert-dialog .sgx-user-blotter-confirmation-content & .sgx-input-textarea-input {
    width: 100%;
    height: 150px;
    max-height: 150px;
    margin-top: $space-4;
  }
}

.sgx-user-blotter-action-reason-input {
  sgx-alert-dialog .sgx-user-blotter-confirmation-content & {
    width: 100%;
  }
}

.sgx-alert-dialog-body {
  sgx-alert-dialog.sgx-user-blotter-config-dialog & {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.sgx-user-blotter-confirmation-content {
  .sgx-user-blotter-config-dialog--error .sgx-alert-dialog-content & i:before {
    margin-right: $space-2;
    vertical-align: baseline;
    @extend .sgx-base-text-title-20;
    color: $sgx-confirmation-dialog-icon--error;
    icon-font: url("sgx-base-code/svgs/solid/times-circle.svg");
  }
}

sgx-input-textarea.sgx-user-blotter-action-reason-input {
  .sgx-user-blotter-confirmation-content & textarea {
    resize: none;
  }
}


body:not([data-type='desktop']) {
  sgx-widget-community-admin-user-blotter .sgx-widget-community-admin-user-blotter-title {
    display: none;
  }

  sgx-widget-community-admin-user-blotter sgx-table sgx-table-toolbar .table-action.filters {
    display: none;
  }

  sgx-widget-community-admin-user-blotter sgx-table .table-container sgx-table-column-headers-manager .sgx-table-header-container {
    height: auto;
  }

  sgx-widget-community-admin-user-blotter sgx-table sgx-table-list sgx-table-row {
    height: auto;
    align-content: flex-start;
  }

  sgx-widget-community-admin-user-blotter sgx-table sgx-table-list sgx-table-row sgx-table-cell-action-menu .sgx-table-cell-menu-text {
    margin-left: 0px;
  }

  sgx-widget-community-admin-user-blotter sgx-table sgx-table-list sgx-table-row .sgx-table-cell {
    color: $ui-text-default;
  }
   
  sgx-widget-community-admin-user-blotter sgx-table .table-container {
    height: calc(100vh - 200px);
    overflow-y: auto;
  }

  sgx-widget-community-admin-user-blotter sgx-table .table-container .sgx-table-show-more-row-button {
    background-color: $sgx-theme-v2-bg-basic-02;
    color: $ui-text-default;
  }

  sgx-widget-community-admin-user-blotter sgx-table sgx-table-cell-action-menu .sgx-table-cell-menu-kebab-icon {
    left: 0px;
    top: -10px;
    width: 10px;
    height: 40px;

    svg {
      fill-opacity: 100;
      fill: #999;
    }
  }

  sgx-widget-community-admin-user-blotter sgx-table sgx-state-tabs {
    padding-left: 0px;

    .sgx-state-tabs-scroll-wrapper {
      overflow-x: hidden !important;
    }
  }
}

// Handle capitalization of column values
sgx-widget-community-admin-user-blotter {
  sgx-table-cell-text {
    &[data-column-id="jobRole"],
    &[data-column-id="companyType"],
    &[data-column-id="country"],
    &[data-column-id="status"] {
      text-transform: capitalize;
    }
  }
}
