$sgx-contract-view-bg: $ui-bg !default;
@if (lightness($ui-bg) >50) {
  $sgx-contract-view-bg: $totc-grey-4;
}

$contract-view-gradient: linear-gradient(
  180deg,
  #f9f9f9,
  #f9f9f9 50%,
  #efefef 0,
  #efefef
) !default;
$contract-border: #b6bdd0 !default;
$contract-icon: #222 !default;

.sgx-widget-order-book-contract-view-container {
  box-shadow: 1px 0 1px 0px rgba(0,0,0,.1);
  z-index: 100;
}

.sgx-contract-view {
  display: flex;
  flex-direction: column;
  min-width: 121px;
  background: $sgx-contract-view-bg;
  color: $ui-text-default;
  font-family: Lato;
  font-size: 13px;
  height: 100%;
}

.sgx-contract-view-row-wrapper {
  display: flex;
  flex-direction: column;

  .sgx-contract-view-strike-price {
    display: none;

    &[data-display-strike='true'] {
      display: block;
    }
  }

  .sgx-contract-view-row {
    &:nth-of-type(2) {
      display: none;
      &[data-display-details='true'] {
        display: flex;
      }
    }
  }
}

.sgx-contract-view-row {
  display: flex;
  flex-direction: row;
  min-height: 25px;

  .sgx-contract-view-detail .sgx-contract-view-row-wrapper & {
    min-height: 16px;
  }

  .sgx-contract-view-detail .sgx-contract-view-row-wrapper &:nth-child(2) {
    padding-top: 0px;
    padding-bottom: 2px;
  }

}

.sgx-contract-view-text {
  min-width: auto;

  .sgx-contract-view-detail .sgx-contract-view-row & {
    min-width: auto;
    margin-right: $space-2;
    max-width: 110px;
    background-color: transparent;
  }
}

.sgx-contract-view-strike-price {
  color: $ui-text-default-grayed;
  background-color: transparent;
}

.sgx-contract-view-last-price {
  .sgx-contract-view-detail .sgx-contract-view-row & {
    padding-right: $space-2;
    color: $ui-text-default-grayed;
    background-color: transparent;
  }
}

.sgx-contract-view-total-volume {
  .sgx-contract-view-detail .sgx-contract-view-row & {
    color: $ui-text-default-grayed;
    background-color: transparent;
  }
}

.sgx-contract-view-header {
  flex: none;
  height: 3.85rem;
  background-color: $sgx-contract-view-bg;
  padding-top: calc(2.45rem - 2px);
  padding-left: 12px;
  padding-right: 5px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  border-bottom: solid 2px $contract-border; // to be updated with themed variables
  align-items: flex-end;
  padding-bottom: 0.5rem;
  font-size: 14px;

  &:hover .sgx-contract-view-header-plus {
    cursor: pointer;
    visibility: visible;
    opacity: 1;
  }

  .sgx-contract-view-detail & {
    padding: calc(1.45rem - 2px) 5px .5rem 12px;
  }
}

.sgx-contract-view-header-text {
  width: 100%;

  .sgx-contract-view-detail & {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
  }
}

.sgx-last-price {
  .sgx-contract-view-header-text & {
    font-size: 12px;
    padding-right: $space-2;
    color: $ui-text-default-grayed;
  }
}

.sgx-contract {
  .sgx-contract-view-header-text & {
    padding-right: $space-2;
  }
}

.sgx-t-volume {
  .sgx-contract-view-header-text & {
    color: $ui-text-default-grayed;
    font-size: 12px;
  }
  .sgx-contract-view-header-text &:before {
    margin-right: $space-1;
    content: '|';
  }
}

.sgx-strike-price {
  .sgx-contract-view-header-text & {
    color: $ui-text-default-grayed;
    font-size: 12px;
  }

  .sgx-contract-view-header-text &:before {
    margin-right: $space-1;
    content: '|';
  }
}

.sgx-contract-view-header-plus {
  &:before {
    font-size: 0.8rem;
  }
}

.sgx-contract-view-list {
  background-image: $contract-view-gradient;
  background-size: 100% 4rem;

  .sgx-contract-view-detail & {
    background-size: 100% 6rem;
  }
}

.sgx-contract-view-list-container {
  overflow: hidden;
  flex: 1 1 auto;
  display: block;
  height: 100%;
}

.sgx-contract-view-item {
  width: 100%;
  color: $ui-text-default;
  height: 32px;

  &[data-visible-contract='false'] {
    display: none;
  }
  // cannot transition at the current time
  // transition: min-height 0.4s ease;
}

.sgx-contract-view-item--wrapper {
  padding: 8px 0px 0 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0;
  height: 32px;
}

.sgx-contract-view-icon {
  display: none;
  color: $contract-icon;
  transition: transform 0.4s ease;

  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;

  margin-top: -3px;

  svg {
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }
}

.sgx-contract-view-collapsed-icon {
  display: flex;
  transform: rotate(0deg);
}

.sgx-contract-view-expanded-icon {
  display: flex;
  transform: rotate(90deg);
}