sgx-titan-loader {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 105;
    background-color: $ui-shadow; /*dim the background*/
    font-size: 1rem;

    &:after {
      content: attr(data-content);
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      margin: 0 auto;
      text-align: center;
    }

    .sgx-titan-loader--icon {
      display: inline-block;
      width: 100%;
      position: absolute;
      left: 0;
      top: 46.5%;
      right: 0;
      margin: 0 auto;
      text-align: center;
      &:after {
        content: "\f1ce";
        font-family: fontAwesome;
      }
    }
  }
}

.sgx-titan-loader--spin {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
