.template-platts-page-copyright-section {
  padding-top: $space-5;
}

.sgx-platts-page-copyrights-footer {
  sgx-platts-copyrights & {
    text-align: center;
    color: $sgx-theme-v2-low-emp-default;
  }
}

.sgx-platts-page-copyrights-footer {
  sgx-platts-copyrights & .sgx-button-copyright {
    padding: auto 10px;
    color: $sgx-theme-v2-brand-03;
    @extend .sgx-base-text-body-14;
    padding: 5px 5px 0px 5px !important;
  }
}

.sgx-button-copyright[class*=sgx-button] {
  sgx-platts-copyrights .sgx-platts-page-copyrights-footer & {
    padding: 5px 5px 0px 5px !important;
    vertical-align: bottom;
  }

  sgx-platts-copyrights .sgx-platts-page-copyrights-footer &:focus,
  sgx-platts-copyrights .sgx-platts-page-copyrights-footer &:hover {
    background-color: transparent;
    border: none;
    outline-color: none;
  }
}

sgx-alert-dialog.sgx-platts-copyright-dialog .sgx-alert-dialog-content {
  max-width: 640px;
  line-height: 1.3em;
  color: $sgx-theme-v2-medium-emp-default;
}

.sgx-alert-dialog-header {
  sgx-alert-dialog.sgx-platts-copyright-dialog & {
    font-size: 25px;
  }
  sgx-alert-dialog.sgx-platts-copyright-dialog & img {
    height: 40px;
    vertical-align: bottom;
  }
}
