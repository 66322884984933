$sgx-table-decorator-flash-animation-duration: 1s !default;

.table-decorator-flash {
    position: relative;
    animation: flash $sgx-table-decorator-flash-animation-duration; // was iefix

    // browsers dont support changing pseudo style changes dynamically
    // and apart from chrome, no other browsers support transitions / animations
    // http://caniuse.com/#feat=css-gencontent
    &:after {
        content:'';
        animation-name: flash;
        animation-duration: $sgx-table-decorator-flash-animation-duration;
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
        opacity: 0;
    }
}

@keyframes iefix {
    from, to { transform: translateZ(0) }
}

@keyframes flash {
    10% {
        opacity:0.5;
    }
}
