template-configure-client-permissions-page {
  padding: 0 $space-5;
  .template-configure-client-permissions {
    padding: 0 $space-8;
  }

  .template-configure-client-permissions-wrapper {
    width: auto;
  }

  sgx-table {
    height: 400px;
    sgx-table-row[data-row-id="0"] {
      background: theme-color($sgx-theme-ui-01, tint 85, shade 50);
      border: theme-color($sgx-theme-ui-01, tint 65, tint 45)
    }
  }

  .template-configure-client-permissions-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $space-5;
    font-size: 16px;
    font-weight: 600;
    background: theme-color($sgx-theme-ui-01, tint 95, shade 50);
    border: 1px solid theme-color($sgx-theme-ui-01, tint 65, tint 45);
    margin-bottom: $space-2;
    padding: $space-2;
  }

  .template-configure-client-permissions-panel-description {
    font-size: 14px;
    color: $sgx-readonly-text-title;
    p {
      margin-bottom: $space-2;
    }
  }

  .template-configure-client-permissions-panel-description-indent {
    margin-left: $space-3;
  }

  .template-configure-client-permissions-information-icon {
    &:before {
      font-size: 14px;
      margin-right: $space-2;
      color: theme-color($v2-blue, tint 0, tint 100);
      icon-font: url('@sgx/sgx-base-code/svgs/regular/info-circle.svg')
    }
  }

  .template-configure-client-permissions-information {
    display: flex;
    align-items: center;
    margin-top: $space-5;
    padding: $space-2 $space-3;
    font-size: 14px;
    color: theme-color($sgx-theme-text-01, shade 10, tint 95);
    background: theme-color($v2-blue, tint 90, tint 0); //theme-color($sgx-theme-text-01, tint 10, shade 45)
  }

  .template-configure-client-permissions-save {
    display: flex;
    justify-content: flex-end;
  }
  
  button.template-configure-client-permissions-save-btn {
    width: 240px;
    height: 50px;
    margin-top: $space-5;
    font-size: 16px;
  }
}
