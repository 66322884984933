platts-admin-subscription-blotter {
  sgx-table {
    height: calc(100% - 64px);
  }

  .platts-admin-subscription-blotter-title {
    font-size: $space-5;
    font-family: Lato, sans-serif;
    color: theme-color($sgx-theme-text-01, shade 70%, tint 95%);
    padding-bottom: $space-5;
  }

  sgx-state-tabs {
    background: none;
    padding-left: 16px;
  }
}
