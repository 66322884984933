template-platts-admin-enquiry-page {
  width: 100%;
  height: 100%;
  font-family: Lato;
  display: flex;
  
  box-sizing: border-box;
  padding: 0px 1rem;
  overflow: hidden;
}

template-platts-admin-enquiry-page .sgx-card {
  height: calc(100vh - 60px);
}

body[data-type='mobile'] {
  template-platts-admin-enquiry-page {
    padding: 0px;
  }
}
