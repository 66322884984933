$sgx-widget-derivatives-prices-chart-text-colour: theme-color($sgx-theme-text-01, shade 70%, tint 95%);
$sgx-widget-derivatives-prices-chart-date-colour: theme-color($sgx-theme-text-01, tint 10%, tint 45%);
$sgx-widget-derivatives-prices-chart-state-tabs-line-colour: theme-color($sgx-theme-ui-01, tint 75%, shade 15%);

sgx-widget-derivatives-prices-chart {
  display: block;
  position: relative;

  .sgx-product-price-chart-header > div {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }

  .sgx-product-price-chart-header + div .sgx-price-card {
    margin-bottom: 15px;
  }

  .sgx-product-price-chart-main-content.sgx-product-price-chart-main-content--hidden {
    display: none;
  }

  .widget-derivatives-prices-and-chart-contract-month-dropdown-container.widget-derivatives-prices-and-chart-contract-month-dropdown-container--hidden {
    display: none;
  }

  .sgx-product-price-chart-table {
    table-layout: fixed;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .sgx-product-price-chart-container {
    position: relative;

    sgx-chart {
      height: 300px;
    }
  }

  .sgx-product-price-chart-wrapper {
    position: relative;
  }

  .sgx-product-price-chart-prices-wrapper--hide, .sgx-product-price-chart-wrapper--hide {
    display: none;
  }

  .widget-derivatives-prices-and-chart-dropdown {
    width: 100%;
  }

  .widget-derivatives-prices-and-chart-contract-month-dropdown-container {
    margin-left: auto;
  }

  .sgx-product-price-chart-symbol-dropd {
    margin-left: auto;
  }

  .sgx-product-price-chart-symbol-dropd-title {
    font-size: 13px;
    padding-bottom: 5px;
  }

  .sgx-product-price-chart-date {
    color: $sgx-widget-derivatives-prices-chart-date-colour;
    float: right;
  }

  /* WIDGET-SCOPE OVERWRITE */
  /* TODO: REMOVE WHEN DDS2 STATE TABS IS READY TO BE IMPLEMENTED IN TITAN */
  sgx-state-tabs {
    background-color: theme-color($sgx-theme-ui-01, tint 100%, shade 50%);
    box-shadow: inset 0 -1px 0 0 $sgx-widget-derivatives-prices-chart-state-tabs-line-colour;
  }
}
