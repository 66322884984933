@import "./subcomponents/sgx-kebab-menu-list/sgx-kebab-menu-list.scss";

$kebab-menu-icon: #222 !default;

sgx-kebab-menu {
  cursor: pointer;
  color: $ui-text-default;

  &.sgx-kebab-menu-container {
    padding: 0 0.5rem;
    color: $kebab-menu-icon;
  }

  &.sgx-kebab-menu-container--show {
    opacity: 1;
  }
}

#sgx-kebab-menu-dialog {
  .sgx-dialog-box {
    border-radius: 5px;
  }

  &.is-visible {
    animation: fadeIn 0.5s;
  }

  .sgx-kebab-menu-triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid $ui-shadow;
    content: "";
    margin-bottom: 10px;
    top: -9px;
    right: 14px;

    > div {
      &:before {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 12px solid #ccc; // cant use theming var here.
        content: "";
        margin-bottom: 10px;
        top: -2px;
        right: -8px;
        z-index: -1;
      }
    }
  }
}
