.sgx-consent-banner {
  position: fixed;
  display: block;
  width: 100%;
  padding: 20px 0;
  left: 0;
  bottom: 0;
  background-color: $sgx-blue;
  z-index: 10000;

  &--hidden {
    display: none;
  }
}

.sgx-consent-banner-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @include media-breakpoint-down(xxs) {
    align-items: initial;
    flex-direction: column;
  }
}

.sgx-consent-banner-content {
  line-height: 1.2rem;
  padding-right: 1rem;

  @include media-breakpoint-down(xxs) {
    padding-right: 0;
    padding-bottom: 1rem;
    max-width: 100%;
  }
}

.sgx-consent-banner-acceptance-button {
  flex: 0 0 auto;
}

.sgx-consent-banner-content,
.sgx-consent-banner-link {
  color: $sgx-white;
}
