$sgx-list-row-cell-padding: 10px;
$sgx-list-row-border: $sgx-theme-v2-lowest-emp-default !default;
$sgx-list-font-size-large: 14px;
$sgx-list-font-size-normal: 12px;

sgx-list-adaptive-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: $ui-text-default;
  white-space: nowrap;
  border-bottom: solid 1px $sgx-list-row-border;
  border-left: solid 1px $sgx-list-row-border;
  padding-top: $space-2;
  padding-bottom: $space-2;


  .sgx-list-cell {
    display: inline-block;
    position: relative;
    height: 100%;
    padding: 0 $space-2;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    text-align: right;
    font-size: $sgx-list-font-size-normal;

    &[data-column-id='totalVolume'],
    &[data-column-id='lastPrice'] {
      padding-right: $space-4;
      padding-left: 0px;
    }
  }

  .sgx-list-composite-main-detail-cell,
  .sgx-list-composite-detail-cell {
    font-size: $sgx-list-font-size-normal;
    color: $ui-text-bright-grayed;
  }

  .sgx-list-composite-content-cell{
    font-size: $sgx-list-font-size-large;
  }

  .sgx-list-composite-cell[detail-mode='false']{
    grid-template-columns: 0 0 100%;
    grid-template-rows: 1fr;

    .sgx-list-composite-content-cell{
      grid-row: 1/1;
      grid-column: 3/4;
    }

    .sgx-list-composite-main-detail-cell{
      grid-row: 1/1;
      grid-column: 2/3;
      visibility: hidden;
    }

    .sgx-list-composite-detail-cell{
      grid-row: 1/1;
      grid-column: 1/2;
      visibility: hidden;
    }

    &[cell-align='right']{
      text-align: right;
    }

    &[cell-align='left']{
      text-align: left;
    }
  }

  &[adaptive-mode='mobile'] {
    .sgx-list-composite-cell{
      flex: 1 1 50%;
    }

    .sgx-list-composite-cell[detail-mode='true'][cell-align='right']{
      grid-template-columns: 3fr 2fr;
      grid-template-rows: 1fr 1fr;
      text-align: right;
      line-height: normal;

      .sgx-list-composite-content-cell{
        grid-row: 1/2;
        grid-column: 1/3;
      }

      .sgx-list-composite-main-detail-cell{
        grid-row: 2/3;
        grid-column: 2/3;
        position: relative;

        span {
          position: absolute;
          right: 0px;
          bottom: 0px;
        }
      }

      .sgx-list-composite-detail-cell{
        grid-row: 2/3;
        grid-column: 1/2;
        position: relative;

        span {
          position: absolute;
          bottom: 0px;
          right: 0px;
        }
      }
    }

    .sgx-list-composite-cell[detail-mode='true'][cell-align='left']{
      grid-template-columns: 2fr 3fr;
      grid-template-rows: 1fr 1fr;
      text-align: left;
      line-height: normal;

      .sgx-list-composite-content-cell{
        grid-row: 1/2;
        grid-column: 1/3;
      }

      .sgx-list-composite-main-detail-cell{
        grid-row: 2/3;
        grid-column: 1/2;
        position: relative;

        span {
          position: absolute;
          left: 0px;
          bottom: 0px;
        }
      }

      .sgx-list-composite-detail-cell{
        grid-row: 2/3;
        grid-column: 2/3;
        position: relative;

        span {
          position: absolute;
          bottom: 0px;
          left: 0px;
        }
      }
    }
  }

  &[adaptive-mode='mobile-landscape'],
  &[adaptive-mode='complete'] {

    .sgx-list-cell {
      flex: 1 1 15%;
    }

    .sgx-list-composite-cell{
      flex: 1 1 35%;
    }

    .sgx-list-composite-cell[detail-mode='true'][cell-align='right']{
      grid-template-columns: 4fr 8fr 1fr 4fr 1fr 6fr;
      grid-template-rows: 1fr;
      text-align: right;

      .sgx-list-composite-content-cell{
        grid-row: 1/1;
        grid-column: 6/7;
      }

      .sgx-list-composite-main-detail-cell{
        grid-row: 1/1;
        grid-column: 4/5;
      }

      .sgx-list-composite-detail-cell{
        grid-row: 1/1;
        grid-column: 2/3;
      }
    }

    .sgx-list-composite-cell[detail-mode='true'][cell-align='left']{
      grid-template-columns: 6fr 1fr 4fr 1fr 8fr 4fr;
      grid-template-rows: 1fr;
      text-align: left;

      .sgx-list-composite-content-cell{
        grid-row: 1/1;
        grid-column: 1/2;
      }

      .sgx-list-composite-main-detail-cell{
        grid-row: 1/1;
        grid-column: 3/4;
      }

      .sgx-list-composite-detail-cell{
        grid-row: 1/1;
        grid-column: 5/6;
      }
    }
  }

}