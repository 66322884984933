
$mobile-order-book-font-size-very-large: 16px;

sgx-mobile-widget-order-submission {
  &.sgx-order-submission-widget {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  & #route-to-groups sgx-text-dropdown .sgx-text-dropdown-header-container p {
    padding: 0px;

    &:before {
      top: 6px;
    }
  }

  & #route-to-groups {
    width: 100%;
  }

  & sgx-input-checkbox.sgx-route-tc.sgx-route-group-items,
  & sgx-input-checkbox.sgx-route-br.sgx-route-group-items {
    display: block;
  }
}

.sgx-order-submission-widget-wrapper {
  sgx-mobile-widget-order-submission & {
    position: relative;
  }
}

.sgx-order-submission-widget-view-controls {
  sgx-mobile-widget-order-submission & {
    width: 100%;
  }
}

.sgx-dynamic-form-row {
  sgx-mobile-widget-order-submission &:last-child {
    margin-bottom: 120px;
  }
}

.sgx-order-submission-close-button {
  sgx-mobile-widget-order-submission & {
    display: inline-block;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 4px;
    top: 4px;
    border: none;
    background-color: transparent;
  }
  sgx-mobile-widget-order-submission &:before {
    font-size: 16px;
    icon-font: url("sgx-base-code/svgs/solid/times.svg");
  }
}

h3.sgx-order-submission-widget-header  {
  sgx-mobile-widget-order-submission .sgx-order-submission-widget-header-container & {
    font-size: $mobile-order-book-font-size-very-large;
    text-align: left;
    font-weight: bold;
    // padding-top: $space-3;
    padding-left: $space-2;
    // padding-bottom: $space-1;
    color: $sgx-theme-v2-high-emp-default;
    background-color: $ui-bg;
  }
}

.mobile-menu-header {
  sgx-mobile-widget-order-submission & {
    background-color: $ui-bg;
  }
}
