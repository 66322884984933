$template-platts-page-background-colour: theme-color(
  $sgx-dark-grey,
  tint 95%,
  shade 65%
);
$sgx-card-header-text: theme-color($sgx-theme-text-01, shade 70%, tint 95%);

template-platts-page {
  height: 100%;
  background-color: $template-platts-page-background-colour;
  overflow: auto;
  position: relative;

  .template-platts-add-widget-btn {
    position: absolute;
    top: 0;
    right: $space-5;
    margin-top: $space-3;

    &:before {
      icon-font: url("@sgx/sgx-base-code/svgs/light/plus.svg");
    }
  }

  .template-platts-page-container {
    padding: 0 $space-5;
    height: 100%;
    padding-bottom: $space-5;
    overflow-y: auto;
  }

  .template-platts-page-header-section {
    padding: $space-3 0;

    .template-platts-page-title {
      color: $sgx-card-header-text;
    }
  }

  .template-platts-page-widgets-section {
    height: 100%;

    body[data-type="mobile"] & {
      padding-top: $space-3;
    }
  }

  .sgx-platt-item-hide {
    display: none;
  }
  
  // TODO: vvv Simplify these, and move if necessary.
  .sgx-card-widget-kebab {
    cursor: pointer;
    padding: 0 $space-1;
    font-size: $space-5;

    font-family: icon-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-rendering: auto;
    line-height: 1;
    vertical-align: -.125em;

    &::after {
      content: '\F153';
      color: hsla(0,0%,100%,.6);

      // font-family: icon-font;
      // -webkit-font-smoothing: antialiased;
      // -moz-osx-font-smoothing: grayscale;
      // display: inline-block;
      // font-style: normal;
      // font-variant: normal;
      // font-weight: 400;
      // text-rendering: auto;
      // line-height: 1;
      // vertical-align: -.125em;
    }
  }
}
