sgx-partner-image-container {
  display: block;
  width: 100%;
  height: 100%;
  .sgx-partner-image-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
