#order-history-dialog {
  min-height: 28rem;
  & h2 {
    width: 100%;
    text-align: center;
  }
  & .details-content {
    max-width: 100%;
    display: block;
    padding: 20px;
    width: 60vw;
    & sgx-table {
      height: 50vh;
      max-width: 100%;
    }
  }
  & .wait-message {
    text-align: center;
    display: block;
  }
}
