$sgx-header-notifications-balloon-color: $sgx-white;
$sgx-header-notifications-balloon-background-color: $sgx-theme-functional-01;
$sgx-header-notification-status-text-color: theme-color($sgx-theme-text-01, tint 10%, tint 45%);
$sgx-header-notification-content-body-color: theme-color($sgx-theme-text-01, tint 10%, tint 45%);

$sgx-header-notification-content-title-color: theme-color($sgx-theme-text-01, shade 70%, tint 95%);
$sgx-header-notification-stamp-text-color: theme-color($sgx-theme-text-01, tint 10%, tint 45%);
$sgx-header-notifications-text-title-color: theme-color($sgx-theme-text-04, tint 0%, tint 30%);
$sgx-notification-tag-text-color: theme-color($sgx-theme-ui-01, tint 100%, tint 100%);
$sgx-notification-tag-width: 42px;
$sgx-notification-tag-height: 15px;
$sgx-notification-tag-border-radius: 1px;
$sgx-notification-tag-font-size: 11px;
$sgx-notification-tag-background-deal: theme-color($v2-blue);
$sgx-notification-tag-background-order: #F79232; // specific color for both
$sgx-notification-panel-bg: theme-color($sgx-theme-ui-01, tint 100%, shade 50%);


// shared classes (WILL BE MOVED)
.sgx-text-small-body {
  font-size: 12px;
  font-weight: normal;
}

.sgx-text-small-title {
  font-size: 14px;
  font-weight: 600;
}

.sgx-text-small-message {
  font-size: 10px;
  font-weight: normal;
}
// done

.sgx-notifications-panel-header-container {
  padding: 8px;
  justify-content: flex-start;

}

.sgx-hide-mute-button {
  display: none;
}

sgx-header-notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 64px;
  height: 40px;

  &.sgx-header-notifications--count:after {
    content: attr(data-count);
    position: absolute;
    top: 1px;
    right: 12px;
    background: $sgx-header-notifications-balloon-background-color;
    border-radius: 50%;
    padding: 4px 6px;
    color: $sgx-header-notifications-balloon-color;
    font-size: 0.7rem;
  }
}

.sgx-header-notifications-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba($sgx-shadow-color, 0.5);

  &--hidden {
    display: none;
  }
}

#notifications-detached,
#notifications-attached {
  position: fixed;
  top: 0;
  z-index: 3000;
}

#notifications-detached {
  right: 0;
  padding: 0 20px;
  height: auto;
}

#notifications-attached {
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.4s ease;

  &.active {
    transform: translateX(0%);
  }
}

.sgx-notifications-panel--detached {
  width: 450px !important; // need to override
  .sgx-notification-body {
    padding: $space-2;
  }
}

.sgx-notifications-panel--attached {
  width: 390px;
  box-shadow: $sgx-shadow-right;
  background-color: $sgx-notification-panel-bg;
}

.sgx-notification--detached {
  .sgx-notification-container {
    &--order {
      border-left: 2px solid $sgx-notification-tag-background-order;
    }

    &--deal {
      border-left: 2px solid $sgx-notification-tag-background-deal;
    }
  }
}

.sgx-notifications-panel-body--attached {
  overflow-x: hidden;
}

.sgx-notifications-template {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  &-title {
    font-family: Lato;
    display: flex;
    width: 100%;
    align-items: center;
  }

  .sgx-notification-title {
    color: $sgx-header-notification-status-text-color;
    font-weight: normal;
    width: 100%;
    margin-left: $space-1;
    margin-top: 2.5px;
  }

  .sgx-notifications-template-body {
    color: $sgx-header-notification-content-title-color;
  }

  .sgx-notification-title-tag {
    text-transform: uppercase;
    flex: 0 0 $sgx-notification-tag-width;
    height: $sgx-notification-tag-height;
    border-radius: $sgx-notification-tag-border-radius;
    color: $sgx-notification-tag-text-color;
    text-align: center;
    line-height: $sgx-notification-tag-height;
    font-size: $sgx-notification-tag-font-size;

    &--order {
      background: $sgx-notification-tag-background-order;
    }

    &--deal {
      background: $sgx-notification-tag-background-deal;
    }
  }

  &-date {
    opacity: 0.8;
    font-size: 11px;
    color: $sgx-header-notification-stamp-text-color;
    font-weight: normal;
    text-align: right;
    text-transform: none;
    width: auto;
    line-height: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-body {
    color: $sgx-header-notification-content-title-color;
    margin-top: $space-1;
  }

  &-sup-title {
    margin: $space-1 0;
    color: $sgx-header-notification-content-body-color;
  }

  &-sub-title {
    color: $sgx-header-notification-content-body-color;
  }
}

body[data-type="mobile"] {
  #notifications-detached {
    padding: 0;
    left: 10px;
    width: calc(100% - 20px);
    .sgx-notifications-panel {
      width: auto !important;
    }
  }
}
