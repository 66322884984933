$sgx-table-cell-action-menu-bg: $ui-bg;
@if (lightness($ui-bg) < 50) {
  $sgx-table-cell-action-menu-bg: $totc-grey-1;
}

sgx-table-cell-action-menu {
  cursor: pointer;

  .sgx-table-cell-menu-kebab-icon {
    visibility: hidden;
    position: absolute;
    left: 0;
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    svg {
      pointer-events: none;
    }

    &.visible {
      visibility: visible;
    }
  }

  .sgx-table-cell-menu-text {
    margin-left: 0.5rem;
  }

  &:hover {
    .sgx-table-cell-menu-kebab-icon {
      visibility: visible;
    }
  }
}

sgx-table {
  sgx-table-row {
    &:hover {
      sgx-table-cell-action-menu {
        .sgx-table-cell-menu-kebab-icon {
          visibility: visible;
        }
      }
    }
  }
}

.sgx-table-action-menu-cell-triangle {
  position: absolute;
  left: -9px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -1px;
    border-right: 10px solid $ui-shadow;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  &:after {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    border-right: 9px solid $sgx-table-cell-action-menu-bg;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
  }
}

.sgx-table-cell-action-menu-line {
  height: 1px;
  background: $ui-bg-alt;
  opacity: 0.07;
}