sgx-table-cell-checkbox {

  sgx-input-checkbox {
    margin-top: 10px;

    .sgx-input-checkbox-label {
      font-size: 0.8rem;
    }
  }

  &.sgx-table-cell {
    text-overflow: unset;    
  }
}
