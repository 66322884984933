sgx-contract-selector {
  display: block;
  height: 100%;
  .sgx-contract-selector-container {
    display: flex;
    border-top: 0;
    width: 100%;
    height: 100%;
  }
  .sgx-contract-selector-container-child{
    font-size: 13px;
    text-transform: capitalize;
    background: theme-color($sgx-theme-ui-01, tint 85, shade 60);
    color: $ui-text-default;
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
    margin:0 .3rem;

    .sgx-contract-selector-fragment-title{
      font-size: 16px;
      margin: .4rem;
      width: 100%;
    }

    .sgx-contract-selector-dynamic-update {
      width: 100%;
      margin: .4rem 0 .4rem .8rem;
    }

    sgx-input-select {
      width: 100%;
    }

    .horizontal-scrolling-bar,
    .vertical-scrolling-bar{
      background: $sgx-dark-grey;

      &:hover {
        background: $sgx-green;
      }
  
      &:active {
        background: $sgx-dark-green;
      }
    }
  }
  .sgx-contract-selector-container-group-title {
    margin: 1.5rem 0;
  }
  .sgx-contract-selector-container-group .sgx-input-label & {
    font-size: 13px;
  }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  sgx-contract-selector .sgx-contract-selector-container sgx-input-checkbox-toggle-list {
    width: 100%;
  }
}

@supports (-ms-accelerator:true) {
  sgx-contract-selector .sgx-contract-selector-container sgx-input-checkbox-toggle-list {
    width: 100%;
  }
}

.sgx-contract-selector-container-child {
  & .sgx-input-checkbox-label {
    font-size: 0.85rem;
  }
}