// TODO: Check specs. Looks like arbitrary number
$sgx-progress-bar-height: 30px;

sgx-progress-bar {
  display: block;
}

.sgx-progress {
  width: 100%;
  height: $sgx-progress-bar-height;
  appearance: none;
  border: none;
}

.sgx-progress-bar-header {
  color: $sgx-theme-v2-medium-emp-default;
  margin-bottom: $space-1;
}

.sgx-progress-bar-caption {
  color: $sgx-theme-v2-low-emp-default;
}

.sgx-progress::-webkit-progress-bar {
  background-color: $sgx-theme-v2-lowest-emp-default;
}

.sgx-progress::-webkit-progress-value {
  background-color: $sgx-theme-v2-functional-03;
}
