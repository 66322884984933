$sgx-confirmation-dialog-icon--success: $ui-state-success !default;
$sgx-confirmation-dialog-icon--error: $ui-state-error !default;

sgx-platts-landing-page {
  width: 100%;
  height: 100vh;
  display: flex;
  margin: $space-16 auto 0px auto;
  min-height: 100vh;
  max-height: 100vh;
  justify-content: stretch;
}

main {
  sgx-platts-landing-page &.sgx-card {
    height: auto;
    position: relative;
  }
}

.sgx-platts-landing-page-container {
  sgx-platts-landing-page & {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 $space-5;
    width: 740px;
  }

  body[data-type="mobile"] sgx-platts-landing-page & {
    width: 100%;
  }
}

.sgx-platts-landing-page-page-logo {
  sgx-platts-landing-page & {
    top: 10px;
    left: 10px;
    width: 80px;
    height: 45px;
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.sgx-platts-landing-page-image {
  sgx-platts-landing-page .sgx-platts-landing-page-content & {
    width: 662px;
    height: 326px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    margin: $space-5 auto 0px;
    background-position: center center;
  }
}

.sgx-platts-landing-page-header h2.sgx-platts-landing-page-title {
  padding: 0;
  margin: 0px;
  color: $sgx-theme-v2-high-emp-default;
}

.sgx-platts-landing-page-desc {
  .sgx-platts-landing-page-container & {
    padding: $space-5 0 0 0;
  }

  .sgx-platts-landing-page-container & p {
    text-align: left;
    line-height: 24px;
    padding: 0 0 $space-2 0;
    margin: 0px;
    color: $sgx-theme-v2-medium-emp-default;
  }

  .sgx-platts-landing-page-container & span {
    display: block;
    width: 100%;
    color: $sgx-theme-v2-medium-emp-default;
  }
}

.sgx-platts-landing-page-content {
  .sgx-platts-landing-page-container & {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .sgx-platts-landing-page-container & sgx-alert-bar {
    margin-top: $space-5;
  }
}

sgx-platts-landing-page sgx-alert-bar.sgx-platts-landing-page-alert-bar.sgx-alert-bar--show {
  top: unset;
  transition: unset;
}

.sgx-section {
  .sgx-platts-landing-page-container & {
    margin-top: $space-8;
    width: 100%;
    background-color: $ui-bg;
    box-shadow: 0 1px 4px $sgx-card-shadow-color;
  }

}

.sgx-platts-landing-page-controls {
  .sgx-platts-landing-page-container & {
    display: flex;
    padding: $space-5 0px 0px;
    justify-content: flex-end;
  }
}
