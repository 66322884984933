sgx-quick-search {
  cursor: pointer;
}

#sgx-quick-search-dialog {
  overflow: hidden;

  .sgx-dialog-overlay {
    overflow: auto;
    height: 100%;
  }

  .sgx-button--icon-search {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .sgx-dialog-box {
    border: 0;
    min-width: 630px;
    box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.15);
    padding: 0;
  }
  .sgx-input-control {
    height: 3.7rem;
    padding: 0.8rem;
    border-top: 0;
    border-bottom: 1px solid #ccc;
  }
  sgx-input-select {
    width: 100%;
  }
  sgx-input-text {
    .sgx-input-message {
      padding: 10px 0;
      margin-left: -90px;
    }
    .sgx-input--has-error .sgx-input-control {
      outline: none;
      border-color: #b7b7b7;
    }
  }
}

[data-resolution="small"] {
  #sgx-quick-search-dialog {
    .sgx-dialog-box {
      min-width: auto;
    }
    sgx-input-text {
      .sgx-input-message {
        margin-left: 10px;
      }
    }
  }
}

.website-header-button--search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 40px;
}
