$sgx-dialog-background-contrast: theme-color($sgx-theme-ui-01, tint 90, tint 0) !default;

sgx-product-selector {

  .sgx-product-selector-wrapper {
    width: 100%;
  }
  .sgx-product-selector-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .sgx-product-selector-list-item {
    margin: 0.5rem;
    text-align: center;
    cursor: pointer;
    min-width: 14rem;
    max-width: 14rem;
    min-height: 9rem;
    border-radius: 5px;
    background-color: $sgx-dialog-background-contrast;
    padding: 1rem;

    .sgx-product-selector-list-item-image {
      margin-top: 1rem;
      margin-left: 0;
      margin-right: 0;
      color: $ui-text-default;

      .sgx-product-selector-list-item-image-icon {
        &:before {
          font-size: 2rem;
          content: '\f0ac';
          font-family: fontTotc, fontAwesome;
        }
      }
    }

    & > div {
      padding: 1.5rem 1rem 0rem 1rem;
    }

    p {
      font-size: 15px;
      color: $ui-text-default;
    }

    img {
      width: 4rem;
    }
  }

  .sgx-product-selector-list-item--active {
    box-shadow: inset 0 0 10px $ui-shadow;
    background-color: $ui-state-secondary;
    p {
      color: $ui-text-inverse;
    }

    .sgx-product-selector-list-item-image {
      color: $ui-text-inverse;
    }
  }

  .sgx-product-selector-list-item--selected {
    & > div {
      opacity: 0.6;
    }
    background-color: lighten($ui-state-disabled, 80%);
    pointer-events: none;
    border: 1px solid $ui-shadow;
  }
}

.sgx-product-selector-input-select {
  width: 100%;

  sgx-list {
    height: 100%;
  }
}
